import { Diamond, DiamondOutlined, Edit } from "@mui/icons-material";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled, useTheme, useMediaQuery } from "@mui/material";
import { padding } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import LinkedIn from "./../../../images/linkedin.png";
import Facebook from "./../../../images/facebook.png";
import Instagram from "./../../../images/instagram.png";
import Twitter from "./../../../images/twitter.png";
import Wechat from "./../../../images/wechat.png";
import Tiktok from "./../../../images/tiktok.png";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import HelmetMetaData from "../../HelmetMetaData";
import NoImage from "./../../../images/no-pictures.png";
import Icon from "./../../../images/FW_ Final Hour improvement/assets-2.png"
import Assets1 from "./../../../images/FW_ Final Hour improvement/Assets-1.png"
import MetaIcon from './../../../images/metaicon.png'
import Xicon from './../../../images/Xicon.png'
import LinkedinLogo from './../../../images/linkedinIcon.png'
import { useParams } from 'react-router-dom';

const ProfileSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: theme.palette.primary.lightest,
  "& p1": {
    margin: "30px 50px ",
    fontSize: "13px",
    fontWeight: 600,
    color: "#9a6d97"
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
    "& p1": {
    marginLeft: "30%",
  },
  },
}));

const ImageSection = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  "& img": {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "50%",
    marginBottom: "20px",
  },
 
}));
const SocialDiv = styled("div")(({ theme }) => ({
  "& img": {
    width: "25px",
    height: "25px",
  },
  "& a": {
    margin: "0 3px",
  },
}));

const EditDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  position: "absolute",
  right: "35px",
  bottom: "60px",
}));
const GemDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "50%"
}));

const ProfileGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 0.75fr",
  gridGap: "10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const ProfileGridGrey = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.5fr 3fr 1fr",
  gridGap: "10px",
  background: theme.palette.primary.grey,
  margin: "20px 0",
  borderRadius: "10px",
  padding: "0px 0px 0px 10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "0.95fr",
  },
}));

const InfoBlock = styled("div")(({ theme }) => ({
  "& strong": {
    fontSize: "20px",
  },
  "& span": {
    fontSize: "20px",
  },
  "& span1": {
    color: theme.palette.primary.greyText,
  },
}));

const RedBlock = styled("div")(({ theme }) => ({
  padding: "10px 40px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.primary.red,
  borderRadius: "100px",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0px 0px 30px !important",
    "& div": {
      fontSize: "30px !important",
      marginRight: "10px !important",
    },
    "& span": {
      fontWeight: "400 !important",
      lineHeight: "1.2 !important",
      fontSize: "14px !important",
    },
  },
  "& div": {
    fontSize: "55px",
    marginRight: "20px",
  },
  "& span": {
    fontWeight: "300",
    lineHeight: "1.4",
    fontSize: "16px",
  },


}));

const BlockWrapper = styled("div")(({ theme }) => ({
  marginTop: "5px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "start",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));


const Block = styled("div")(({ theme }) => ({
  padding: "0px 20px 0px 5px",
  color: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "5px",
  //background: theme.palette.primary.red,
  borderRadius: "100px",
  border: `1px solid #cdccc3`,
  maxWidth: "290px",
  [theme.breakpoints.down("md")]: {
    padding: "5px 5px 0px 5px !important",
    "& div": {
      fontSize: "18px !important",
      marginRight: "10px !important",
    },
    "& span": {
      fontWeight: "300 !important",
      //lineHeight: "1.2 !important",
      fontSize: "14px !important",
    },
  },
  "& icon": {
    fontSize: "18px",
    marginRight: "0px",
  },
  "& div": {
    fontSize: "18px",
    marginRight: "0px",
    color: theme.palette.primary.red
  },
  "& span": {
    fontWeight: "300",
    // lineHeight: "1.4",
    fontSize: "16px",
  },


}));

// const GemBlock = styled("div")(({ theme }) => ({
//   padding: "10px 30px",
//   color: "#fff",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   cursor: "pointer",
//   background: theme.palette.primary.red,
//   borderRadius: "100px",
//   [theme.breakpoints.down("md")]: {
//     padding: "10px",
//   },
//   "& div": {
//     fontSize: "55px",
//     marginRight: "20px",
//   },
//   "& span": {
//     fontWeight: "300",
//     lineHeight: "1.4",
//     fontSize: "16px",
//   },


// }));

const GemBlock = styled("div")(({ theme }) => ({
  padding: "10px 0px 0px 0px",
  color: "#fff",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  //background: "#D8BFD8",
  background: "#b991b3",
  border: "2px solid #800080",
  borderRadius: "100px",
  marginLeft: "15%",
  width: "150px",
  height: "40px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0px 0px 0px",
    marginLeft: "30%",
  },
  "& div": {


  },

  "& span": {
    fontWeight: "300",
    marginLeft: "20px",
    lineHeight: "1.4",
    fontSize: "16px",
  },
 
}));


const TextComponent = styled("div")(({ theme }) => ({
  fontWeight: "500",
  //lineHeight: "1.4",
  fontSize: "20px !important",
  color: "red",
  //marginLeft: "20px",

}));

const MemberFlex = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  "& h5": {

  },
  "& h2": {
    marginTop: "-15px",
    fontWeight: "300",
    color: "#ffffff"
  },
}));

const HoursDiv = styled("div")(({ theme }) => ({
  background: "#a3a095",
  textAlign: "center",
  marginLeft: "60px",
  borderRadius: "10px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
  },

  "& span": {
    fontSize: "32px",
    margin: "0",
    fontWeight: "300",
    color: "#ffffff"
  },
  "& span1": {
    fontSize: "18px",
    margin: "0",
    fontWeight: "300",
    color: "#ffffff"
  },
}));


const ItemsBox1 = styled(Box)(({ theme }) => ({
  minWidth: "700px",
  borderBottom: "1px solid #999",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",

}));

const ItemsBox = styled(Box)(({ theme }) => ({

  borderBottom: "1px solid #999",
  padding: "10px 0px",
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "left",

}));

const Name = styled(Box)(({ theme }) => ({
  width: "410px",
  marign: "0 10px",

}));

const Hours = styled(Box)(({ theme }) => ({
  width: "240px",

}));
const Token = styled(Box)(({ theme }) => ({
  width: "240px",

}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexContent1 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "280px",
  maxWidth: "320px",
  //fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginRight: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  "& input": {
    padding: "10px",
    width: "100px",
  },
  "& .MuiSelect-select": {
    padding: "10px",
  },
  "& button": {
    padding: "11px !important",
    minWidth: "40px",
  },
}));




const MainProfileNew = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [totalHours, setTotalHours] = useState("");
  const [memberStatus, setMemberStatus] = useState({});
  const [userData, setUserData] = useState({});
  const [dashboardImage, setDashboardImage] = useState("");
  const [gemOpen, setGemOpen] = useState(false);
  // const user = localStorage.getItem("login");
  // const userData = JSON.parse(user);


const { id } = useParams();

//console.log("ii", id)

  useEffect(() => {
    // getMemberStatus();
    getTotalHours();
  }, [id]);

  useEffect(() => {
    generateImageFromDiv();
  }, [userData])

  const generateImageFromDiv = async (divElement) => {
    var divElement = document.getElementById("dashboard_status");
    try {
      const canvas = await html2canvas(divElement);
      const canvasImage = canvas.toDataURL('image/png');
      setDashboardImage(canvasImage);

    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  // console.log('user data', userData);

  // const getMemberStatus = () => {
  //   httpClient.get('loyalty-rules').then(({data}) => {
  //     if(data.success){
  //       setMemberStatus(data.data)
  //     }
  //   })
  // }

  const getTotalHours = () => {
    httpClient.get(`total-hours/${id}`).then(({ data }) => {
      if (data.success) {
        setUserData(data);
        setTotalHours(data.sumHours);
        setMemberStatus(data);
      }
    });
  };

  const handleProfile = () => {
    navigate("/dashboard/account_settings");
  };

  // console.log('user data', userData);
  // console.log('dashboard image', dashboardImage)
  // console.log("window", window.location);
  const gemList = [
    {
      id: 1,
      tokensAsTime: "The Final Hour",
      hours: "1",
      token: "60"
    },
    {
      id: 2,
      tokensAsTime: "Working Hours / Day",
      hours: "8",
      token: "480"
    },
    {
      id: 3,
      tokensAsTime: "Working Hours / Week",
      hours: "38",
      token: "2,280"
    },
    {
      id: 4,
      tokensAsTime: "Working Hours / Month",
      hours: "152",
      token: "9,120"
    },
    {
      id: 5,
      tokensAsTime: "Working Hours / 3 months",
      hours: "456",
      token: "27,360"
    },
    {
      id: 6,
      tokensAsTime: "Working Hours / 6 months",
      hours: "912",
      token: "54,720"
    },
    {
      id: 7,
      tokensAsTime: "Working Hours / Year",
      hours: "1,824",
      token: "109,440"
    },

  ];

  return (
    <ProfileSection id="dashboard_status">
      {/* <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Test description" />
          <meta property="url" content={window.location.href} />
          <meta property="og:image" content={dashboardImage} />
          <meta property="og:type" content="website" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:site_name" content="FinalHour" />

        </Helmet> */}
      <HelmetMetaData
        title={"Final Hour " + userData?.member?.firstName + " " + userData?.member?.lastName}
        description={userData?.member?.firstName + " has contributed " + totalHours + "hours and supported many causes."}
        image={userData?.member?.image}
      />

      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item md={2} xs={12}>
            <ImageSection>
              <img
                src={userData?.member?.memberImageUrl? userData?.member?.memberImageUrl : NoImage}
                alt={userData?.member?.firstName + " " + userData?.member?.lastName}
              />
             
              <SocialDiv>
                {userData?.member?.linkedInURL && (
                  <a href={`https://${userData?.member?.linkedInURL}`} target="_blank">
                    <img src={LinkedIn} alt="linkedin" />
                  </a>
                )}
                {userData?.member?.twitterURL && (
                  <a href={userData?.member?.twitterURL} target="_blank">
                    <img src={Twitter} alt="twitter" />
                  </a>
                )}
                {userData?.member?.weChatURL && (
                  <a href={`https://${userData?.member?.weChatURL}`} target="_blank">
                    <img src={Wechat} alt="wecaht" />
                  </a>
                )}
                {userData?.member?.facebookURL && (
                  <a href={`https://${userData?.member?.facebookURL}`} target="_blank">
                    <img src={Facebook} alt="facebook" />
                  </a>
                )}
                {userData?.member?.instagramURL && (
                  <a href={`https://${userData?.member?.instagramURL}`} target="_blank">
                    <img src={Instagram} alt="insta" />
                  </a>
                )}
                {userData?.member?.tiktokURL && (
                  <a href={userData?.member?.tiktokURL} target="_blank">
                    <img src={Tiktok} alt="tiktok" />
                  </a>
                )}
              </SocialDiv>


            </ImageSection>
          </Grid>
          <Grid item md={10} xs={12}>
            <ProfileGrid>
              <InfoBlock>
                <div>
                  {/* <strong>Name</strong> -{" "} */}
                  <span>
                    {userData?.member?.firstName +
                      " " +
                      userData?.member?.lastName || "-"}
                  </span>
                </div>
                <div>
                  {/* <span>Occupation</span> -{" "} */}
                  <span>{userData?.member?.occupation || "-"}</span>
                </div>
                <div>
                  {/* <span>Location</span> -{" "} */}
                  <span1>
                    {userData?.member?.address1 +
                      (userData?.member?.address2 &&
                        ", " + userData?.member?.address2) || "-"}
                  </span1>
                </div>
                <BlockWrapper>
                  <Block>
                    <icon><img src={Icon} style={{ marginTop: "5px" }} /></icon>
                    <div><strong>{(totalHours * 60) || 0}</strong></div>
                    <span>{""}Tokens Collected</span>
                  </Block>
                  <Block style={{ background: "#ffe655", border: "1px solid #edb348", cursor: "pointer" }} onClick={() => setGemOpen(true)}>
                    <icon><img src={Icon} style={{ marginTop: "5px" }} /></icon>
                    <span>View Token Ranking</span>
                  </Block>
                </BlockWrapper>
              </InfoBlock>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextComponent>
                <span>Total Given Hours</span>
                </TextComponent>

                <RedBlock>
                  <div>{totalHours || 0}</div>
                  <span>hrs</span>
                </RedBlock>

              </div>


            </ProfileGrid>
            <ProfileGridGrey>
            <img src={Assets1} style={{ marginTop: "5px" }} />
              <MemberFlex>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {/* <h5>Member Status</h5> */}
                  {/* <h5>{memberStatus.currentLevel?.levelInternalName}</h5> */}
                  <h5>Current Level</h5>
                  <h2>{memberStatus.currentLevel?.levelStatusName || "-"}</h2>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                
                  {/* <h5>{memberStatus.nextLevel?.levelInternalName}</h5> */}
                  <h5>Next Level</h5>
                  <h2>{memberStatus.nextLevel?.levelStatusName || "-"}</h2>
                </div>
              </MemberFlex>
              <HoursDiv>
                <h5>Hours Until Next Level</h5>
                <span>{memberStatus.hoursForNextLevel || "-"} </span><span1>hrs</span1>
              </HoursDiv>
            </ProfileGridGrey>
            

          </Grid>
        </Grid>
      </Container>
      <Dialog open={gemOpen} onClose={() => setGemOpen(false)} style={{ minWidth: '800px !important' }} maxWidth="xl">
        <DialogTitle>
          <Box mb={3} textAlign="center">
            <h3>Gamification Points</h3>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!isMobile &&
            <>
              <div>
                <ul>
                  <ItemsBox1>
                    <Name><b>Tokens As Time In Minutes</b></Name>
                    <Hours><b>Hours</b></Hours>
                    <Token><b>Tokens</b></Token>
                  </ItemsBox1>
                </ul>
              </div>

              <div>
                <ul>
                  {gemList.map((gem, index) => (
                    <ItemsBox1 key={gem.id}>
                      <Name>{gem.tokensAsTime}</Name>
                      <Hours>{gem.hours}</Hours>
                      <Token>{gem.token}</Token>
                    </ItemsBox1>
                  ))}
                </ul>
              </div>
            </>
          }
          {isMobile &&
            <div>
              <ul>
                {gemList.map((gem, index) => (
                  <ItemsBox key={gem.id}>
                    {/* <Name>ABC{gem.tokensAsTime}</Name>
                  <Hours>QQQ{gem.hours}</Hours>
                  <Token>WWW{gem.token}</Token> */}

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FlexContent1>
                          <Values>
                            Token as times in minutes
                          </Values>
                          <FlexInnerTitle>
                            <span>{gem.tokensAsTime}</span>
                          </FlexInnerTitle>
                        </FlexContent1>

                        <FlexContent>
                          <Values>
                            Hours
                          </Values>
                          <FlexInnerTitle>
                            <span>{gem.hours}</span>
                          </FlexInnerTitle>
                        </FlexContent>
                        <FlexContent>
                          <Values>
                            Token
                          </Values>
                          <FlexInnerTitle>
                            <span>{gem.token}</span>
                          </FlexInnerTitle>
                        </FlexContent>
                      </Grid>
                    </Grid>
                  </ItemsBox>
                ))}
              </ul>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGemOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </ProfileSection>

  );
};

export default MainProfileNew;
