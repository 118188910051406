import { Box, Container } from "@mui/material";
import React from "react";
import parse from "html-react-parser";

const TermsBody = ({data}) => {
  return (
    <Container>
      <Box pt={4} pb={4}> 
        {parse(data.content || "")}

      </Box>
    </Container>
  );
};

export default TermsBody;
