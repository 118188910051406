import { Button, Container, Grid, styled, Snackbar, Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Video from "./../../../images/video.mp4";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import parse from "html-react-parser";
import { Add, Remove } from "@mui/icons-material";
import { httpClient } from "../../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
import CancelSubscription from "../CancelSubscription";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import MetaIcon from '../../../images/metaicon.png'
import Xicon from '../../../images/Xicon.png'
import LinkedinLogo from '../../../images/linkedinIcon.png'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CausesDiv = styled("div")(({ theme }) => ({
  background: "#B8B6A9",
  padding: "40px 0",
  marginTop: "20px",
  position:"relative",
  "& h1": {
    margin: "0",
    fontWeight: "300",
    padding: "40px 0",
    fontSize: "70px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0",
      marginTop: "20px",
      fontSize: "40px",
    },
  },
  "& p": {
    margin: "0",
    paddingBottom: "20px",
    fontWeight: "300",
  },
  "& .video-react .video-react-big-play-button": {
    left: "43%",
    top: "43%",
    [theme.breakpoints.down("md")]: {
      left: "37%",
      top: "37%",
    },
  },
}));

const LearnDiv = styled("div")(({ theme }) => ({
  textAlign: "right",
  marginTop: "20px",
  "& button": {
    border: "1px solid transparent",
    borderRadius: "25px",
    background: " #000",
    color: "#fff",
    outline: "none",
    padding: "8px 20px",
    fontSize: "14px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "transparent",
      border: "1px solid #000",
      color: "#000",
    },
  },
}));

const ImageBox = styled("div")(({ theme }) => ({
  "& img": {
    width: "100%",
    height: "400px",
    objectFit: "contain",
  },
}));

const CancelDiv = styled("div")(({theme}) => ({
  position:"fixed",
  bottom:"50%",
  right:"-93px",
  transform:"rotate(-90deg)"
}))

const login = localStorage.getItem("token");
const token = JSON.parse(login);

const CausesTop = (props) => {
  const AddFavourite = styled("div")(({ theme }) => ({
    position: "fixed",
    bottom: "20px",
    zIndex: "99",
    "& button": {
      border: "1px solid transparent",
      borderRadius: "25px",
      background: addedToFav ? theme.palette.primary.red : "#fff",
      color: addedToFav ? "#fff" : theme.palette.primary.red,
      outline: "none",
      padding: "8px 20px",
      fontSize: "14px",
      cursor: "pointer",
      transition: "0.3s",
      "&:hover": {
        background: addedToFav ? "#fff" : theme.palette.primary.red,
        border: "1px solid #fff",
        color: addedToFav ? theme.palette.primary.red : "#fff",
      },
    },
    "& svg": {
      fontSize: "18px",
      marginRight: "10px",
    },
  }));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [addedToFav, setAddedToFav] = useState(false);
  const [favouriteID, setFavouriteID] = useState("");
  const [showCancel, setShowCancel] = useState(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);


  useEffect(() => {
    setAddedToFav(props.allData && props.allData.favorite === 1 ? true : false);
    setFavouriteID(props.allData && props.allData.favoriteID);
  }, [props.allData]);


  const handleLearn = () => {
    props.handleLink();
  };

  const handleAddToFav = () => {
    props.causeID &&
      httpClient
        .post("member-fav-cause", {
          causeID: props.causeID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setAddedToFav(true);
            setFavouriteID(data.data.favCauseID);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });

    props.orgID &&
      httpClient
        .post("member-fav-orgs", {
          orgID: props.orgID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setAddedToFav(true);
            setFavouriteID(data.data.favID);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
  };

  const handleRemoveToFav = () => {
    props.causeID &&
      httpClient.delete(`member-fav-cause/${favouriteID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setAddedToFav(false);
          setFavouriteID("");
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });

    props.orgID &&
      httpClient.delete(`member-fav-orgs/${favouriteID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setAddedToFav(false);
          setFavouriteID("");
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
  };

  const handleCancelSubs = () => {
    setOpenCancelDialog(true);
  }

  const callBack = (call) => {
    if(call.open === false){
      setOpenCancelDialog(false)
    }
    if(call.success === true){
      httpClient.post(`subscription-cancel`,{
        ruleID: props.allData?.myDonations?.ruleID
      }).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setShowCancel(false);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const Line = styled("div")(({ theme }) => ({
    background: "gray",
    width: "14%",   
    height: "2px",
    borderRadius: "5px",
    alignItems: "flex-start",
  }));


  return (
    <CausesDiv>
      {props.showFav && (props.allData?.myDonations?.ruleID) && (
      showCancel &&
      <CancelDiv>
        <Button onClick={handleCancelSubs} color="primary" variant="contained">
          Cancel Subscription
        </Button>
      </CancelDiv>
      )}
      <Container maxWidth="xl">
        <AddFavourite>
          {token && (
          props.showFav &&
            (!addedToFav ? (
              <Button onClick={handleAddToFav}>
                <Add fontSize="small" /> Add To Favorites
              </Button>
            ) : (
              <Button onClick={handleRemoveToFav}>
                <Remove fontSize="small" /> Remove From Favorites
              </Button>
            )))}
        </AddFavourite>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <h1>{props.header ? props.header : <Skeleton animation="wave" />}</h1>
            
            
            {/* {props.orgID && */}
            <Box display={"flex"} alignItems={"center"} pb={2}>
                <span style={{ marginRight: "10px",  }}>Share : </span>
                <FacebookShareButton
                  url={window.location.href}
                  size={30}
                  style={{ marginRight:"5px" }}
                >
                  <img src={MetaIcon} alt="meta_icon" style={{ height:"30px", width:"30px" }} />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  style={{ marginRight:"5px" }}
                >
                  <img src={LinkedinLogo} alt="linkedin_icon" style={{ height:"30px", width:"30px" }} />
                </LinkedinShareButton>
                <TwitterShareButton url={window.location.href}>
                  <img src={Xicon} alt="X_icon" style={{ height:"30px", width:"30px" }} />
                </TwitterShareButton>
            </Box>
            {/* } */}

            {props.hours ?
            <>
            <h4>{props.hours || "0"} hours</h4> 

            <Line></Line>
            </>
           : null }
           {props.members ?
            <>
            <h4>{props.members || "0"} members</h4> 
            <Line></Line>
            </>
           : null }
           {props.organization?
            <h4>Organization: {props.organization || "0"}</h4> : null }

            {props.description ?
            parse(props.description || "")
            :
            <p>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              </p>
            }


            {props.handleLink && (
              <LearnDiv>
                <Button onClick={handleLearn}>Learn More</Button>
              </LearnDiv>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            {props.image ? (
              <ImageBox>
                <img src={props.image} alt={props.header} />
              </ImageBox>
            ) : (
              // props.data ?
              // <Player
              //   playsInline
              //   src={Video}
              // /> 
              // :
              <Skeleton variant="rectangular" width={350} height={300} animation="wave" />
            )}

           

          </Grid>
          
        </Grid>
      </Container>

      {openCancelDialog && <CancelSubscription callBack={callBack} allData={props.allData}/>}  

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </CausesDiv>
  );
};

export default CausesTop;
