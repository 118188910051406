import { Box, Grid, Container, styled, useMediaQuery,
  useTheme, Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import DonationBox from "./DonationBox";
import MetaIcon from './../../../images/metaicon.png'
import Xicon from './../../../images/Xicon.png'
import LinkedinLogo from './../../../images/linkedinIcon.png'
import NoImage from './../../../images/no-pictures.png'
import CrisisImage from './../../../images/emergency.png'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import { Add } from "@mui/icons-material";

const DonationSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#ED6B76",
  //paddingLeft: "3%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "0",
  },
}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& h2": {
    margin: "0",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#D7D7D7",
    color: "#000",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c",
    },
  },
}));

const Causes = styled("div")(({ theme }) => ({
  padding: "10px 0",
  display: "grid",
  gridTemplateColumns: "1fr 2.5fr 1.5fr 1fr 1fr 1fr",
  rowGap: "10px",
  width: "100%",
  borderBottom: "1px dashed #ffffff",
  justifyItems: "start",
  alignItems: "center",
  "& img": {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    border: "1px solid black",
    objectFit: "cover",
  },
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center", // Add this line
  },

}));

const CauseName = styled(Box)(({ theme }) => ({
  // width: "360px",
  margin: "0 0px",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const CauseButtonDiv = styled("div")(({ theme }) => ({
  // width: "175px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //textAlign: "left",
    marginTop: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const GiveButton = styled("button")(({ theme }) => ({
  padding: "6px 20px",
  borderRadius: "100px",
  border: "1px solid black !important",
  color: "black",
  background: "pink",
  marginTop: "10px",
  transition: "0.3s",
  cursor: "pointer",
  "& span": {
   fontSize:"18px"
    
  },
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
    marginTop: "0",
  },
  "&:hover": {
    background: `${theme.palette.primary.red} !important`,
    color: "#fff !important",
    border: `1px solid gray !important`,
  },
}));

const PaginationBox = styled("div")(({ theme }) => ({
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#4A4A4A",
  "& span": {
    fontSize: "16px",
    marginRight: "10px",
  },
  "& button": {
    color: "#4A4A4A",
  },
}));

const CrisisImg = styled("div")(({ theme }) => ({
  "& img": {
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    bottom: "60px !important",
    left: "65px !important",
    border: "none !important",
    borderRadius: "initial !important"
  },
}));

const RecentDonations = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [donationList, setDonationList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRecentDonations();
  }, []);

  const getRecentDonations = () => {
    setLoading(true);
    httpClient.get("member-causes").then(({ data }) => {
      if (data.success) {
        setDonationList(data.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const handleView = () => {
    navigate("/dashboard/donation_history",);
  };

  const handleClickCause = (cause) => {
    var newSlug = cause.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: cause });
  };

  const handleClickCauseGive = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, {
      state: { cause: cats, give: true },
    });
  };

//console.log("dddddd", donationList)
  return (
    <DonationSection>
      <Container maxWidth="xl">
        <HeaderDiv>
          <h2>My Causes</h2>
          <button onClick={handleView}>View all</button>
        </HeaderDiv>
        {/* <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <span style={{ marginRight: "10px" }}>Share : </span>

          <FacebookShareButton
            url={`${window.location.origin}/dashboard/donation_history`}
            size={30}
            style={{ marginRight: "5px" }}
          >
            <img src={MetaIcon} alt="meta_icon" style={{ height: "30px", width: "30px" }} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={`${window.location.origin}/dashboard/donation_history`}
            style={{ marginRight: "5px" }}
          >
            <img src={LinkedinLogo} alt="linkedin_icon" style={{ height: "30px", width: "30px" }} />
          </LinkedinShareButton>
          <TwitterShareButton url={`${window.location.origin}/dashboard/donation_history`}>
            <img src={Xicon} alt="X_icon" style={{ height: "30px", width: "30px" }} />
          </TwitterShareButton>
        </Box> */}

        {/* <div>
          {!loading ? (
            donationList.length > 0
              ? donationList.map((donation, index) => (
                index <= 4 &&
                <DonationBox
                  key={donation.ruleID}
                  date={moment(donation.paidDate).format(
                    "ddd, MMM Do YYYY"
                  )}
                  cause={donation.causeID.causeName || ""}
                  hours={donation.donationRuleID.donationHour + " Hrs"}
                  amount={"$" + donation.amount_paid}
                  handleClickCause={() => handleClickCause(donation.causeID)}
                />
              ))
              : "No recent donations!"
          ) : (
            <>Fetching Donations..</>
          )}
        </div> */}

        {/* <h3>My Donations</h3> */}

        {!isMobile &&
          <Causes sx={{ borderBottom: "none"}}>
            <strong></strong>
            <strong></strong>
            <strong>Frequency</strong>
            <strong>Tokens Earned</strong>
            <strong>Hours</strong>
            <strong>Action</strong>
          </Causes>
        }

        {!loading ? (
          donationList.length > 0 ?
            donationList.map((don, index) => (
     
              <Causes id={don.causeID}>
                <div
                  style={{ display: 'flex', alignItems: 'center', position: "relative", cursor: "pointer" }}
                  onClick={() => handleClickCause(don)}
                >
                  <img src={don.image || NoImage} alt="logo" style={{ marginRight: '10px', opacity: '100%', constraint: '100%' }} />
                  {don.crisis === 1 ? (
                    <CrisisImg>
                      <img src={CrisisImage} />
                    </CrisisImg>
                  ) : (
                    ""
                  )}

                </div>

                <CauseName>
                  <>
                    <p>{don.name}</p>
                  </>
                </CauseName>
                <CauseName>
                  <>
                    <p>{don.myFrequency?.charAt(0).toUpperCase() + don.myFrequency?.slice(1)}</p>
                  </>
                </CauseName>
                <CauseName>
                  <>
                    <p>{don.myHours * 60}</p>
                  </>
                </CauseName>
                <CauseName>
                  <>
                    <p>{don.myHours}</p>
                  </>
                </CauseName>

                <CauseButtonDiv>
                  <GiveButton onClick={() => handleClickCauseGive(don)}>
                
                    <Add sx={{fontSize:"20px", color:"red",}}/> <span style={{ }}>Adjust</span>
                    
                  </GiveButton>
                </CauseButtonDiv>
              </Causes>
            )) : <Causes><div></div><div></div>No Recent Donations! </Causes>) : (<Causes><div></div><div></div>Fetching Donations...</Causes>)}

        {/* <Grid item xs={12}>
          <PaginationBox>
            <Pagination
              count={lastPage}
              page={currPage}
              onChange={(e, value) => handleChangePage(e, value)}
              variant="outlined"
              color="primary"
            >


            </Pagination>
          </PaginationBox>


        </Grid> */}


      </Container>

    </DonationSection>
  );
};

export default RecentDonations;
