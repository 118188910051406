import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import AccountSettings from "../Private/Dashboard/AccountSettings";
import DonationHistory from "../Private/Dashboard/DonationHistory";
import FavOrganizationPage from "../Private/Dashboard/FavOrganizationPage";
import FriendsList from "../Private/Dashboard/FriendsList";
import GivingPreference from "../Private/Dashboard/GivingPreference";
import MainDashboard from "../Private/Dashboard/MainDashboard";
import MyCauses from "../Private/Dashboard/MyCauses";
import MyCausesNewsFeed from "../Private/Dashboard/MyCausesNewsFeed";
import About from "../Public/About";
import BecomeAPartner from "../Public/BecomeAPartner";
import CauseCategoryDetail from "../Public/Causes/CauseCategoryDetail";
import CauseCategoryPage from "../Public/Causes/CauseCategoryPage";
import CauseSinglePage from "../Public/Causes/CauseSinglePage";
import CausesMain from "../Public/Causes/CausesMain";
import FavouriteCausePage from "../Public/Causes/FavouriteCausePage";
import Featured from "../Public/Causes/Featured";
import EmailVerify from "../Public/EmailVerify";
import ForgotPassword from "../Public/ForgotPassword";
import HomePage from "../Public/HomePage";
import MemberDetail from "../Public/MemberDetail";
import Members from "../Public/Members";
import NewsSingle from "../Public/NewsSingle";
import NewsSingleCause from "../Public/NewsSingleCause";
import OrganizationDetail from "../Public/OrganizationDetail";
import OrganizationPage from "../Public/OrganizationPage";
import ResetPassword from "../Public/ResetPassword";
import SignUp from "../Public/SignUp";
import CharityPage from "../Public/CharityPage";
import WorkplacePage from "../Public/WorkplacePage";
import Contact from "../Public/Contact";
import Terms from "../Public/Terms";
import PrivacyPoicy from "../Public/PrivacyPolicy";
import UserAgreement from "../Public/UserAgreeement";
import CookiePolicy from "../Public/CookiePolicy";
import SearchPage from "../Public/SearchPage";
import FAQs from "../Public/FAQs";
import SwitchSubscription from "../Public/Causes/SwitchSubscription";
import Workplaces from "../Public/Workplaces";
import Community from "../Public/Community";
import FriendsActivity from "../Private/Dashboard/FriendsActivity";
import WorkplaceDetails from "../Public/WorkplaceDetails";
import WorkplaceNewsFeed from "../Public/MoreNewsFeed";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Sitemap from "../Public/Sitemap";
import InfographicDashboard from "../Private/Dashboard/InfographicDashboard";
import Invitation from "../Private/Dashboard/Invitation";



let tokenData = localStorage.getItem("token");
let token = JSON.parse(tokenData);

var timestamp = new Date().getTime();
localStorage.setItem("guestToken", timestamp);

const InfographicRoute = ({ children, value }) => {
  return (
    <>

      <div style={{ flex: "1 0 auto" }}>{children}</div>
      <Footer />

    </>
  );

};

const PrivateRoute = ({ children, value }) => {
  const { id } = useParams();
  return token ? (
    <>
      <Header token={token && token ? token : ""} />
      <div style={{ flex: "1 0 auto" }}>{children}</div>
      <Footer />
    </>
  ) : (
    <Navigate to="/sign_up" state={{ id }} />
  );
};

const PublicRoute = ({ children }) => {
  return (
    <>
      <Header token={token && token ? token : ""} />
      <div style={{ flex: "1 0 auto" }}>{children}</div>
      <Footer />
    </>
  );
};

const NonSignedInRoute = ({ children }) => {
  return !token ? (
    <>
      <Header token={""} />
      <div style={{ flex: "1 0 auto" }}>{children}</div>
      <Footer />
    </>
  ) : (
    <Navigate to="/dashboard" />
  );
};

const Routing = () => {
  return (
    <>
      {/* <Routes>
      <Route
          path="/linkedin"
          element={
            <LinkedInCallback/>
          }
        />

    </Routes> */}

      <BrowserRouter>

        <Routes>
          {/* HOME */}
          <Route
            path="/linkedin"
            element={
              <LinkedInCallback />
            }
          />

          <Route
            path="/"
            element={
              <PublicRoute>
                {" "}
                <HomePage />{" "}
              </PublicRoute>
            }
          />
          {/* HOME */}

          {/* Sign Up */}
          <Route
            path="/sign_up"
            element={
              <NonSignedInRoute>
                <SignUp />
              </NonSignedInRoute>
            }
          />

          <Route
            path="/EmailVerify"
            element={
              <PublicRoute>
                <EmailVerify />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/ResetPassword"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          {/* Sign Up */}

          {/* Dashboard */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <MainDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/infographic/:id"
            element={
              <InfographicRoute>
                <InfographicDashboard />
              </InfographicRoute>
            }
          />
          <Route
            path="/dashboard/causes"
            element={
              <PrivateRoute>
                <MyCauses />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/causes_news_feed"
            element={
              <PrivateRoute>
                <MyCausesNewsFeed />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/friends_list"
            element={
              <PrivateRoute>
                <FriendsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/friends_activity"
            element={
              <PrivateRoute>
                <FriendsActivity />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/donation_history"
            element={
              <PrivateRoute>
                <DonationHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/giving_preferences"
            element={
              <PrivateRoute>
                <GivingPreference />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/account_settings"
            element={
              <PrivateRoute>
                <AccountSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/charity_partners"
            element={
              <PrivateRoute>
                <FavOrganizationPage />
              </PrivateRoute>
            }
          />
          {/* Dashboard */}

          {/* About */}
          <Route
            path="/about"
            element={
              <PublicRoute>
                <About />
              </PublicRoute>
            }
          />
          {/* About */}
          {/* FAQ */}
          <Route
            path="/faqs"
            element={
              <PublicRoute>
                <FAQs />
              </PublicRoute>
            }
          />
          {/* FAQ */}

          {/* Beacome a partner */}
          <Route
            path="/become_a_partner"
            element={
              <PublicRoute>
                <BecomeAPartner />
              </PublicRoute>
            }
          />
          <Route
            path="/become_a_partner/charity"
            element={
              <PublicRoute>
                <CharityPage />
              </PublicRoute>
            }
          />
          <Route
            path="/become_a_partner/workplace"
            element={
              <PublicRoute>
                <WorkplacePage />
              </PublicRoute>
            }
          />
          {/* Beacome a partner */}

          {/* CAUSES */}
          <Route
            path="/causes"
            element={
              <PublicRoute>
                <CausesMain token={token} />
              </PublicRoute>
            }
          />
          <Route
            path="/causes/featured"
            element={
              <PublicRoute>
                <Featured />
              </PublicRoute>
            }
          />
          <Route
            path="/causes/favourite"
            element={
              <PublicRoute>
                <FavouriteCausePage />
              </PublicRoute>
            }
          />
          <Route
            path="/causes/switchSubscription"
            element={
              <PublicRoute>
                <SwitchSubscription />
              </PublicRoute>
            }
          />
          <Route
            path="/causes/category/:id"
            element={
              <PublicRoute value={"category"}>
                <CauseCategoryPage />
              </PublicRoute>
            }
          />
          <Route
            path="/causes/category/detail/:id"
            element={
              <PublicRoute>
                <CauseCategoryDetail />
              </PublicRoute>
            }
          />
          {/* <Route path="/causes/detail/:id" element={<PrivateRoute><CauseSinglePage /></PrivateRoute>}/> */}
          <Route
            path="/causes/detail/:id"
            element={
              <PublicRoute>
                <CauseSinglePage />
              </PublicRoute>
            }
          />
          {/* CAUSES */}

          {/* Member List Page */}
          <Route
            path="/members"
            element={
              <PrivateRoute>
                <Members />
              </PrivateRoute>
            }
          />
          <Route
            path="/members/detail/:id"
            element={
              <PublicRoute>
                <MemberDetail />
              </PublicRoute>
            }
          />
          {/* Member List Page */}

          {/* Organization List Page */}
          <Route
            path="/charity_partners"
            element={
              <PublicRoute>
                <OrganizationPage />
              </PublicRoute>
            }
          />
          <Route
            path="/charity_partners/detail/:id"
            element={
              <PublicRoute>
                <OrganizationDetail />
              </PublicRoute>
            }
          />
          {/* Organization List Page */}

          {/* NEWS Single */}
          <Route
            path="/news/detail/:id"
            element={
              <PublicRoute>
                <NewsSingle />
              </PublicRoute>
            }
          />
          <Route
            path="/more-news/detail/:id"
            element={
              <PublicRoute>
                <NewsSingleCause />
              </PublicRoute>
            }
          />
          {/* NEWS Single */}

          {/* Contact page */}
          <Route
            path="/contact"
            element={
              <PublicRoute>
                <Contact />
              </PublicRoute>
            }
          />
          <Route
            path="/terms_and_conditions"
            element={
              <PublicRoute>
                <Terms />
              </PublicRoute>
            }
          />
          <Route
            path="/privacy_policy"
            element={
              <PublicRoute>
                <PrivacyPoicy />
              </PublicRoute>
            }
          />
          <Route
            path="/user_agreement"
            element={
              <PublicRoute>
                <UserAgreement />
              </PublicRoute>
            }
          />
          <Route
            path="/cookie_policy"
            element={
              <PublicRoute>
                <CookiePolicy />
              </PublicRoute>
            }
          />
          {/* Contact page */}

          {/* Search page */}
          <Route
            path="/search"
            element={
              <PublicRoute>
                <SearchPage />
              </PublicRoute>
            }
          />
          {/* Search page */}

          {/* Workplaces*/}
          <Route
            path="/workplaces"
            element={
              <PublicRoute>
                <Workplaces />
              </PublicRoute>
            }
          />
          <Route
            path="/news/more_news_list"
            element={
              <PublicRoute>
                <WorkplaceNewsFeed />
              </PublicRoute>
            }
          />
          <Route
            path="/workplaces/detail/:id"
            element={
              <PublicRoute>
                <WorkplaceDetails />
              </PublicRoute>
            }
          />
          {/* Workplaces*/}

          {/* Community */}
          <Route
            path="/community"
            element={
              <PublicRoute>
                <Community />
              </PublicRoute>
            }
          />
          {/* Community */}

          <Route
            path="/sitemap.xml"
            element={

              <Sitemap />

            }
          />

          <Route
            path="/invitation/:id"
            element={
              <PrivateRoute>
                <Invitation />
              </PrivateRoute>

            }
          />

        </Routes>

      </BrowserRouter>
    </>
  );
};

export default Routing;
