import { styled } from "@mui/material";
import React from "react";
import BlankImage from './../../../images/no-pictures.png'

const MemberDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  cursor:"pointer",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    paddingTop:"20px"
  },
  "& img": {
    backgroundColor:"#fff",
    objectFit: "contain",
    marginBottom: "20px",
    borderRadius: "50%",
    width: "280px",
    height: "280px",
    [theme.breakpoints.down("md")]: {
      height: "250px",
      width: "250px",
    },
  },
  "& small": {
    position: "absolute",
    top: "-25px",
    left: "0",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: {
      left: "40px",
      top:"0"
    },
  },
  "& p": {
    margin: "0",
  },
  "& span": {
    color: theme.palette.primary.grey,
    fontSize: "14px",
  },
}));

const LeaderBoardComponent = (props) => {
  return (
    <MemberDiv onClick={props.handleClick}>
      <small>{props.index}</small>
      <img src={props.image || BlankImage } alt={props.memberName} />
      <p>{props.memberName}</p>
      {props.hours ?
      <span>{props.hours || "0"} {(props.hours != "0" || props.hours != "1" || props.hours != null) ? "hours" : "hour"} </span> : ""}
    </MemberDiv>
  );
};

export default LeaderBoardComponent;
