import React, { useEffect, useState, useRef } from 'react'

import SimilarCauses from '../../../Components/Causes/SimilarCauses'
import CauseAll from '../../../Components/Causes/CauseAll'
import FeatureCauses from '../../../Components/Causes/FeatureCauses'

import { httpClient } from '../../../appUtility/Api'
import { useNavigate } from "react-router-dom";

import CauseToInvest2 from '../../../Components/Causes/CausesToInvest2'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EnvironmentGrid from '../../../Components/Causes/EnvironmentGrid'
import {
  Box,

  IconButton,

  styled,

} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import FooterGive from '../../../Components/FooterGive'
import HelmetMetaData from '../../../Components/HelmetMetaData'

const tokenData = localStorage.getItem("token");
const token = JSON.parse(tokenData);

const SliderContainer = styled(Box)(({ theme }) => ({

  height: "400px",
  margin: "0 0px",
  width: "100%",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },


}));

const CausesMain = ({ token }) => {
  const navigate = useNavigate();
  const [homeData, setHomeData] = useState({});
  const [newsData, setNewsData] = useState({});
  const [category, setCategory] = useState([]);
  const [invested, setInvested] = useState([]);
  const [charityCausesList, setCharityCausesList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (token) {
      getFeaturedCategories();
    }
    // getInvestInCauses();
    //getHomeData();
    // getAllCausesCategory();

    // getNewsData();
    getAllCharityCauses();

  }, []);


  const getAllCharityCauses = () => {
    setLoading(true);
    httpClient.get("all-charity-causes").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setCharityCausesList(data.data);
      }
    });
  };



  const getFeaturedCategories = () => {
    setLoading(true);
    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategory(data.data);
        setLoading(false);
      }
    });
  };

  const getInvestInCauses = () => {
    setLoading(true);
    httpClient.get("invest-in-causes").then(({ data }) => {
      if (data.success) {
        setInvested(data.data);
        setLoading(false);
      }
    });
  };

  const getHomeData = () => {
    setLoading(true);
    httpClient.get('cause').then(({ data }) => {
      if (data.success) {
        setHomeData(data.data);
        setLoading(false);
      }
    })
  }


  const getNewsData = () => {
    httpClient.get('member-news').then(({ data }) => {
      if (data.success) {
        setNewsData(data.data);
      }
    })
  }
  const causesAllRef = useRef(null);

  const handleView = () => {
    causesAllRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const handleRefetch = () => {
    getAllCharityCauses();
    getFeaturedCategories();
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IconButton onClick={onClick} style={{ right: "20px", bottom: "50%", position: "absolute", zIndex: "9999" }} >
        <ArrowForwardIos style={{ color: "#fff" }} />
      </IconButton>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IconButton onClick={onClick} style={{ left: "20px", bottom: "50%", position: "absolute", zIndex: "9999" }}>
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  }


  return (
    <div>
      {/* <BreadCrumb header="Causes" subHeader1="Causes" /> */}
      {/* <CausesTop header="Causes" description={
           `<p>
              It doesn’t matter, who you are or what you do for a living, your
              time is valuable.
              <br />
              <br />
              Help make a difference in the world by donating an hour of it.
              <br />
              <br />
              Give the final hour of your working day, week or month to your
              preferred cause(s).
            </p>`
        } /> */}
      {/* <CausesTop header={homeData.title} description={homeData.body} data={homeData} /> */}

      {/* <CauseTitle data={homeData && homeData} /> */}
      {/* {token && homeData && <AboutTimeVideo data={homeData && homeData} /> }  */}
      <HelmetMetaData
        title={"Give the last hour of your workday, week, or month to a cause you love."}
      />
      {token && !loading && (
        <SliderContainer>
          {category && (
            <Slider
              dots={true}
              arrows={true}
              fade={true}
              pauseOnHover={false}
              infinite={true}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3000}
              // prevArrow={<ArrowBackIos sx={{ zIndex: 9999 }}  />}
              // nextArrow={<ArrowForwardIos />}
              nextArrow={<SampleNextArrow />}
              prevArrow={<SamplePrevArrow />}
            >
              {category.map((cats, index) => (
                index < 6 && (
                  <div key={index}>
                    <EnvironmentGrid category={cats} handleRefetch={handleRefetch} />
                  </div>
                )
              ))}
            </Slider>
          )}
        </SliderContainer>
      )}


      <CauseToInvest2 />
      {/* <MoveToTopButton /> */}
      <FeatureCauses handleView={handleView} />
      {<SimilarCauses />}
      {<div ref={causesAllRef}>
        <CauseAll charityCausesList={charityCausesList} handleRefetch={handleRefetch} loading={loading} />
      </div>}

      <FooterGive />

      {/* {newsData.length > 0 && <EventNews data={newsData && newsData} /> } */}
      {/* <GiveHours /> */}
      {/* <FeaturedMemberCause /> */}
    </div>
  )
}

export default CausesMain