import { Add, Diversity1, Pending, Sync } from "@mui/icons-material";
import { Button, Container, Grid, styled, Snackbar, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpClient } from "../../appUtility/Api";
import BreadCrumb from "../../Components/BreadCrumb";
import BlankImage from "./../../images/no-pictures.png";
//import medalImage from "./../../images/trophy.png"
import MuiAlert from "@mui/material/Alert";
import { Box } from "@mui/system";
import moment from "moment/moment";
import LinkedIn from "./../../images/linkedin.png";
import Facebook from "./../../images/facebook.png";
import Instagram from "./../../images/instagram.png";
import Twitter from "./../../images/twitter.png";
import Wechat from "./../../images/wechat.png";
import Tiktok from "./../../images/tiktok.png";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton } from "react-share";
import HelmetMetaData from "../../Components/HelmetMetaData";
import Outer from './../../images/circle_outer.gif';
import BadgeEarned from './../../images/Badge-earned.png';
import MetaIcon from "../../../src/images/metaicon.png";
import Xicon from "../../../src/images/Xicon.png";
import LinkedinLogo from "../../../src/images/linkedinIcon.png";
import Confirm from "./confirm";
import RecentDonations from "../../Components/Dashboard/RecentDonations";
import MainProfile from "../../Components/Dashboard/MainProfile";
import NoImage from "../../../src/images/no-pictures.png";
import Icon from "../../../src/images/FW_ Final Hour improvement/assets-2.png"
import Assets1 from "../../../src/images/FW_ Final Hour improvement/Assets-1.png"


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  background: theme.palette.primary.lightest,
}));

const PersonDiv = styled("div")(({ theme }) => ({
  position: "absolute !important",
  right: "0",
  left: "0",
  top: "-10px",
  "& img": {
    width: "175px !important",
    height: "175px !important",
  }
}))

const ImageSection = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  "& img": {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "50%",
    marginBottom: "20px",
  },
}));

const BadgeSection = styled("div")(({ theme }) => ({
  textAlign: "center",
  "& img": {
    width: "30px",
    height: "30px",
    objectFit: "contain",
  }
}))

const SocialDiv = styled("div")(({ theme }) => ({
  marginTop: "20px",
  textAlign: "center",

  "& img": {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
  "& a": {
    margin: "0 3px",
  },
  "& p": {
    margin: "10px 10px 10px -20px",
    fontSize: "16px",
    fontWeight: 600,
    color: "#9a6d97"
  },

}));

// const InfoBlock = styled("div")(({ theme }) => ({
//   backgroundColor: "#ffffff",
//   padding: "10px 20px",
//   "& strong": {
//     fontSize: "20px",
//   },
//   "& span": {
//     fontSize: "20px",
//   },
// }));

const StatusBlock = styled("div")(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: "2px solid #eaecee",
  minHeight: "800px",
  marginTop: "-225px",
  display: "flex",
  flexDirection: "column",
  //justifyContent: "center", // Center vertically
  alignItems: "center", // Center horizontally

  "& strong": {
    fontSize: "20px",
  },
  "& span": {
    fontSize: "20px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "20px !important",
  },
}));


const DonationBlock = styled("div")(({ theme }) => ({
  padding: "10px 10px",
  background: "#ed6b77",
  border: "2px solid #eaecee",
  minHeight: "480px",
  maxHeight: "500px",
  overflow: "hidden", // Set overflow to "hidden" by default

  "&:hover": {
    overflow: "auto", // Set overflow to "auto" when hovering
  },
  "&::-webkit-scrollbar": {
    width: "6px", // Adjust the width as desired
    backgroundColor: "#f5f5f5", // Set the background color
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Set the color of the thumb
    borderRadius: "3px", // Adjust the border radius to make it rounder or sharper
  },

  "& strong": {
    fontSize: "20px",
  },
  "& span": {
    fontSize: "20px",
    color: "#ffffff"
  },
  "& h6": {
    fontSize: "20px",
    color: "#ffffff"
  },
}));


const Medal = styled("div")(({ theme }) => ({
  marginTop: "100px",
  "& img": {
    width: "150px",
    height: "150px",
  },

  "& h5": {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "0.1",
  },
}));




const ButtonDiv = styled("div")(({ theme }) => ({
  marginTop: "0px",
  fontFamily: "Epilogue !important",
  "& button": {
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.red,
    color: "#fff",
    fontSize: "16px",
    textTransform: "inherit",
    outline: "none",
    border: "transparent",
    padding: "3px 15px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c"
    }
  },
  "& svg": {
    fontSize: '17px',
    marginRight: "5px"
  }
}))





// ------------------------------------
const DonationBox = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid gray",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center", // Add this line
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center", // Add this line
  },
}));

const DonationName = styled(Box)(({ theme }) => ({

  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "700",
  //float: "left",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const DonationMain = styled(Box)(({ theme }) => ({
  width: "90%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const DonationTime = styled(Box)(({ theme }) => ({

  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "300",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const DonationHours = styled(Box)(({ theme }) => ({
  width: "10%",
  margin: "0 0px",
  fontSize: "16px",
  marginBottom: "-20px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "20px !important",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const DonationAmount = styled(Box)(({ theme }) => ({
  width: "12%",
  margin: "0 0px",
  fontSize: "16px",
  marginBottom: "-20px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "20px !important",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const ProfileGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 0.75fr",
  gridGap: "10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const ProfileGridGrey = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.5fr 3fr 1fr",
  gridGap: "10px",
  background: theme.palette.primary.grey,
  margin: "20px 0",
  borderRadius: "10px",
  padding: "0px 0px 0px 10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "0.95fr",
  },
}));

const InfoBlock = styled("div")(({ theme }) => ({
  "& strong": {
    fontSize: "20px",
  },
  "& span": {
    fontSize: "20px",
  },
  "& span1": {
    color: theme.palette.primary.greyText,
  }
}));

const RedBlock = styled("div")(({ theme }) => ({
  padding: "10px 40px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.primary.red,
  borderRadius: "100px",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0px 0px 30px !important",
    "& div": {
      fontSize: "30px !important",
      marginRight: "10px !important",
    },
    "& span": {
      fontWeight: "400 !important",
      lineHeight: "1.2 !important",
      fontSize: "14px !important",
    },
  },
  "& div": {
    fontSize: "55px",
    marginRight: "20px",
  },
  "& span": {
    fontWeight: "300",
    lineHeight: "1.4",
    fontSize: "16px",
  },


}));

const BlockWrapper = styled("div")(({ theme }) => ({
  marginTop: "5px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "start",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));


const Block = styled("div")(({ theme }) => ({
  padding: "0px 20px 0px 5px",
  color: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "5px",
  //background: theme.palette.primary.red,
  borderRadius: "100px",
  border: `1px solid #cdccc3`,
  maxWidth: "290px",
  [theme.breakpoints.down("md")]: {
    padding: "5px 5px 0px 5px !important",
    "& div": {
      fontSize: "18px !important",
      marginRight: "10px !important",
    },
    "& span": {
      fontWeight: "300 !important",
      //lineHeight: "1.2 !important",
      fontSize: "14px !important",
    },
  },
  "& icon": {
    fontSize: "18px",
    marginRight: "0px",
  },
  "& div": {
    fontSize: "18px",
    marginRight: "0px",
    color: theme.palette.primary.red
  },
  "& span": {
    fontWeight: "300",
    // lineHeight: "1.4",
    fontSize: "16px",
  },


}));

const GemBlock = styled("div")(({ theme }) => ({
  padding: "10px 0px 0px 0px",
  color: "#fff",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  //background: "#D8BFD8",
  background: "#b991b3",
  border: "2px solid #800080",
  borderRadius: "100px",
  marginLeft: "15%",
  width: "150px",
  height: "40px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0px 0px 0px",
    marginLeft: "30%",
  },
  "& div": {


  },

  "& span": {
    fontWeight: "300",
    marginLeft: "20px",
    lineHeight: "1.4",
    fontSize: "16px",
  },
}));


const TextComponent = styled("div")(({ theme }) => ({
  fontWeight: "500",
  //lineHeight: "1.4",
  fontSize: "20px !important",
  color: "red",
  //marginLeft: "20px",

}));

const MemberFlex = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  "& h5": {

  },
  "& h2": {
    marginTop: "-15px",
    fontWeight: "300",
    color: "#ffffff"
  },
}));

const HoursDiv = styled("div")(({ theme }) => ({
  background: "#a3a095",
  textAlign: "center",
  marginLeft: "60px",
  borderRadius: "10px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
  },

  "& span": {
    fontSize: "32px",
    margin: "0",
    fontWeight: "300",
    color: "#ffffff"
  },
  "& span1": {
    fontSize: "18px",
    margin: "0",
    fontWeight: "300",
    color: "#ffffff"
  },
}));


const ItemsBox1 = styled(Box)(({ theme }) => ({
  minWidth: "700px",
  borderBottom: "1px solid #999",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",

}));

const ItemsBox = styled(Box)(({ theme }) => ({

  borderBottom: "1px solid #999",
  padding: "10px 0px",
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "left",

}));

const Name = styled(Box)(({ theme }) => ({
  width: "410px",
  marign: "0 10px",

}));

const Hours = styled(Box)(({ theme }) => ({
  width: "240px",

}));
const Token = styled(Box)(({ theme }) => ({
  width: "240px",

}));


const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexContent1 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "280px",
  maxWidth: "320px",
  //fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginRight: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  "& input": {
    padding: "10px",
    width: "100px",
  },
  "& .MuiSelect-select": {
    padding: "10px",
  },
  "& button": {
    padding: "11px !important",
    minWidth: "40px",
  },
}));


const MemberDetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const login = localStorage.getItem("login");
  const loginData = JSON.parse(login);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  // const [memberID, setMemberID] = useState(location && (location.state.memberID || location.state));
  //const [donationList, setDonationList] = useState([]);
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [connectButton, setConnectButton] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [allData, setAllData] = useState("");
  const [loading, setLoading] = useState(false);
  const [gemOpen, setGemOpen] = useState(false);


  const gemList = [
    {
      id: 1,
      tokensAsTime: "The Final Hour",
      hours: "1",
      token: "60"
    },
    {
      id: 2,
      tokensAsTime: "Working Hours / Day",
      hours: "8",
      token: "480"
    },
    {
      id: 3,
      tokensAsTime: "Working Hours / Week",
      hours: "38",
      token: "2,280"
    },
    {
      id: 4,
      tokensAsTime: "Working Hours / Month",
      hours: "152",
      token: "9,120"
    },
    {
      id: 5,
      tokensAsTime: "Working Hours / 3 months",
      hours: "456",
      token: "27,360"
    },
    {
      id: 6,
      tokensAsTime: "Working Hours / 6 months",
      hours: "912",
      token: "54,720"
    },
    {
      id: 7,
      tokensAsTime: "Working Hours / Year",
      hours: "1,824",
      token: "109,440"
    },

  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // setAllData(location.state);
    getMemberSingle();
  }, [location.state]);

  // console.log('location <<', location.state);

  const getMemberSingle = () => {
    setLoading(true);
    httpClient.get(`team-members/${id}`).then(({ data }) => {
      if (data.success) {
        setAllData(data);
        setLoading(false);
      }
    })
  }

  const handleOpenAdd = () => {
    setOpenConfirm(true);
  }

  const handleAdd = (call) => {
    if (call.open === false) {
      setOpenConfirm(false)
    }
    if (call.success === true) {
      httpClient
        .post(`member-connection/add`, {
          friendMemberID: allData.member.memberID,
        })
        .then(({ data }) => {
          if (data.success) {
            // setMemberArr([...memberArr, id]);
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setConnectButton(true);
            getMemberSingle();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        }).catch(err => {
          if (err.response.status === 401) {
            navigate("/sign_up")
          }
        })
    }
  }

  //console.log('allll_member data', allData);


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const Logo = styled("div")(({ theme }) => ({
    transform: "rotate(90deg)",
    display: "inline-block",
    paddingTop:"10px",
    "& span": {
      background: "#ffffff",
      padding: "3px",
      display: "inline-block",
      borderRadius: "50%",
      margin: "12px 3px px 3px",
      [theme.breakpoints.down("md")]: {
        margin: "0",
        padding: "4px",
      },
    },
  }));

  return (
    <>
      <HelmetMetaData
        title={allData && allData.member.firstName}
        image={allData && allData.member.image}
      />
      <BreadCrumb
        header={"Members"}
        subHeader1="Members"
        Link1="/members"
        subHeader2={allData && allData.member.firstName}
      />
      <ProfileSection id="dashboard_status">
        {/* <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Test description" />
          <meta property="url" content={`https://www.finalhour.retailcare.com.au/dashboard`} />
          <meta property="og:image" content={dashboardImage} />
          <meta property="og:type" content="website" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:site_name" content="FinalHour" />

        </Helmet> */}

        <Container maxWidth="xl">
        {loading ? ("Fetching Records...") :
           
           allData && allData ?
             
             (
          <Grid container spacing={1}>
            <Grid item md={2} xs={12}>
              <ImageSection>
                <img
                  src={allData && allData.member.image ? allData && allData.member.image : NoImage}
                  alt={allData && allData.member.firstName + " " + allData && allData.member.lastName}
                />
                {/* <EditDiv>
                <Edit fontSize="small" onClick={handleProfile} />
              </EditDiv> */}
                <SocialDiv>
                  <p>Social Links:</p>
                  {allData && allData.member.linkedInURL && (
                    <a href={`https://${allData && allData.member.linkedInURL}`} target="_blank">
                      <img src={LinkedIn} alt="linkedin" />
                    </a>
                  )}
                  {allData && allData.member.twitterURL && (
                    <a href={allData && allData.member.twitterURL} target="_blank">
                      <img src={Twitter} alt="twitter" />
                    </a>
                  )}
                  {allData && allData.member.weChatURL && (
                    <a href={`https://${allData && allData.member.weChatURL}`} target="_blank">
                      <img src={Wechat} alt="wecaht" />
                    </a>
                  )}
                  {allData && allData.member.facebookURL && (
                    <a href={`https://${allData && allData.member.facebookURL}`} target="_blank">
                      <img src={Facebook} alt="facebook" />
                    </a>
                  )}
                  {allData && allData.member.instagramURL && (
                    <a href={`https://${allData && allData.member.instagramURL}`} target="_blank">
                      <img src={Instagram} alt="insta" />
                    </a>
                  )}
                  {allData && allData.member.tiktokURL && (
                    <a href={allData.member.tiktokURL} target="_blank">
                      <img src={Tiktok} alt="tiktok" />
                    </a>
                  )}
                </SocialDiv>


              </ImageSection>
              {/* <GemBlock onClick={() => handleInviteDialog()}>
              <div>
                <AccountCircleRounded sx={{
                  marginLeft: "-5px",
                  color: "#a483a1",
                  fontSize: "60px"
                }} />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-30%",
                    left: "20%",
                    color: "red",
                    fontSize: "38px",
                    fontWeight: "bold"
                  }}
                >
                  +
                </Typography>
              </div>

              <span>
                Invite friends
              </span>

            </GemBlock> */}

            </Grid>
            <Grid item md={10} xs={12}>
              <ProfileGrid>
                <InfoBlock>
                  <div>
                    {/* <strong>Name</strong> -{" "} */}
                    <strong><span>
                      {allData && allData.member.firstName +
                        " " +
                        allData.member.lastName || "-"}
                    </span>
                    </strong>
                  </div>
                  <div>
                    {/* <span>Occupation</span> -{" "} */}
                    <span>{allData && allData.member.occupation || "Test"}</span>
                  </div>
                  <div>
                    {/* <span>Location</span> -{" "} */}
                    <span1>
                      {allData && allData.member.suburb +
                        (allData.member.country.countryName &&
                          ", " + allData.member.country.countryName) || "-"}
                    </span1>
                  </div>
                  <BlockWrapper>
                    <Block>
                      <icon><img src={Icon} style={{ marginTop: "5px" }} /></icon>
                      <div><strong>{(allData && allData.sumHours * 60) || 0}</strong></div>
                      <span>{""}Tokens Collected</span>
                    </Block>
                    <Block style={{ background: "#ffe655", border: "1px solid #edb348", cursor: "pointer" }} onClick={() => setGemOpen(true)} >
                      <icon><img src={Icon} style={{ marginTop: "5px" }} /></icon>
                      <span>View Token Ranking</span>
                    </Block>
                  </BlockWrapper>
                  {/*  */}


                </InfoBlock>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextComponent>
                    {/* Token Collected: {(allData && allData.sumHours * 60) || 0} */}
                    <span>Total Given Hours</span>
                  </TextComponent>

                  <RedBlock>
                    <div>{allData && allData.sumHours || 0}</div>
                    <span>hrs</span>
                  </RedBlock>

                </div>


              </ProfileGrid>
              <ProfileGridGrey>
                <img src={Assets1} style={{ marginTop: "5px" }} />
                <MemberFlex>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    {/* <h5>Member Status</h5> */}
                    {/* <h5>{allData && allData.currentLevel?.levelInternalName}</h5> */}
                    {/* <h5 style={{ fontWeight: 400 }}>Earned Medallion</h5> */}
                    <h5>Current Level</h5>
                    <h2>{allData && allData.currentLevel?.levelStatusName || "-"}</h2>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    {/* <h5></h5> */}
                    {/* <h5>{allData && allData.nextLevel?.levelInternalName}</h5> */}
                    <h5>Next Level</h5>
                    <h2>{allData && allData.nextLevel?.levelStatusName || "-"}</h2>
                  </div>
                </MemberFlex>
                <HoursDiv>
                  <h5>Hours Until Next Level</h5>
                  <span>{allData && allData.hoursForNextLevel || "-"} </span><span1>hrs</span1>
                </HoursDiv>
              </ProfileGridGrey>



              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <div>
                  {/* <span style={{ marginRight: "10px" }}>Invite Friends : </span><IconButton onClick={() => handleInviteDialog()}><ContactMail /></IconButton>
                {loadingInvite && (
                  <CircularProgress
                    size={25}
                  />
                )} */}
                  {allData && (
                    (allData.member.friend === 0 && loginData?.member?.memberID !== allData?.member?.memberID) ? (
                      <ButtonDiv>
                        {!connectButton && (
                          <Button sx={{ fontFamily: "Epilogue" }} onClick={handleOpenAdd}>
                            <Add fontSize="small" /> Connect
                          </Button>
                        )}
                      </ButtonDiv>
                    ) : allData.member.friend === 1 ? (
                      <ButtonDiv>
                        <Button sx={{ fontFamily: "Epilogue" }}>
                          <Logo>
                            <span></span> <span></span>
                          </Logo>{" "} You are connected!
                        </Button>
                      </ButtonDiv>
                    ) : allData.member.friend === 2 ? (
                      <ButtonDiv>
                        <Button sx={{ fontFamily: "Epilogue" }}>
                          <Logo>
                            <span></span> <span></span>
                          </Logo>{" "} Request Pending
                        </Button>
                      </ButtonDiv>
                    ) : null
                  )}
                </div>
                <div>
                  <span style={{ marginRight: "10px" }}>Share : </span>
                  {/* <FacebookShareButton
                url={"http://finalhour.retailcare.com.au/dashboard"}
                size={30}
                style={{ marginRight: "5px" }}
              >
                <FacebookIcon size={30} round />
              </FacebookShareButton>
              <LinkedinShareButton
                url={"http://finalhour.retailcare.com.au/dashboard"}
              >
                <LinkedinIcon size={30} round />
              </LinkedinShareButton> */}

                  <FacebookShareButton
                    url={window.location.href}
                    size={30}
                    style={{ marginRight: "5px" }}
                  >
                    <img src={MetaIcon} alt="meta_icon" style={{ height: "30px", width: "30px" }} />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={window.location.href}
                    style={{ marginRight: "5px" }}
                  >
                    <img src={LinkedinLogo} alt="linkedin_icon" style={{ height: "30px", width: "30px" }} />
                  </LinkedinShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <img src={Xicon} alt="X_icon" style={{ height: "30px", width: "30px" }} />
                  </TwitterShareButton>
                </div>
              </Box>


            </Grid>

          </Grid>
          ) : "The public profile for the member is hidden!"}
        </Container>


        <Dialog open={gemOpen} onClose={() => setGemOpen(false)} style={{ minWidth: '800px !important' }} maxWidth="xl">
          <DialogTitle>
            <Box mb={3} textAlign="center">
              <h3>Gamification Points</h3>
            </Box>
          </DialogTitle>
          <DialogContent>
            {!isMobile &&
              <>
                <div>
                  <ul>
                    <ItemsBox1>
                      <Name><b>Tokens As Time In Minutes</b></Name>
                      <Hours><b>Hours</b></Hours>
                      <Token><b>Tokens</b></Token>
                    </ItemsBox1>
                  </ul>
                </div>

                <div>
                  <ul>
                    {gemList.map((gem, index) => (
                      <ItemsBox1 key={gem.id}>
                        <Name>{gem.tokensAsTime}</Name>
                        <Hours>{gem.hours}</Hours>
                        <Token>{gem.token}</Token>
                      </ItemsBox1>
                    ))}
                  </ul>
                </div>
              </>
            }
            {isMobile &&
              <div>
                <ul>
                  {gemList.map((gem, index) => (
                    <ItemsBox key={gem.id}>


                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FlexContent1>
                            <Values>
                              Token as times in minutes
                            </Values>
                            <FlexInnerTitle>
                              <span>{gem.tokensAsTime}</span>
                            </FlexInnerTitle>
                          </FlexContent1>

                          <FlexContent>
                            <Values>
                              Hours
                            </Values>
                            <FlexInnerTitle>
                              <span>{gem.hours}</span>
                            </FlexInnerTitle>
                          </FlexContent>
                          <FlexContent>
                            <Values>
                              Token
                            </Values>
                            <FlexInnerTitle>
                              <span>{gem.token}</span>
                            </FlexInnerTitle>
                          </FlexContent>
                        </Grid>
                      </Grid>
                    </ItemsBox>
                  ))}
                </ul>
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setGemOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        {openConfirm && <Confirm handleAdd={handleAdd} />}

        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={messageState}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </ProfileSection>
      {allData && allData && 
      <DonationBlock>
        <Container maxWidth="xl">
          <h6>Donation History</h6>
          <></>
          {allData && allData.donations.length > 0 ?
            allData &&
            allData.donations
              .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) // Sort donations in descending order based on created date
              .map((donation, index) => (
                <DonationBox>
                  <DonationMain>
                    <DonationTime>
                      {moment(donation.createdDate).format("ddd, MMM Do YYYY, h:mm a")}
                    </DonationTime>
                    <DonationName>{donation.causeID.causeName}</DonationName>
                  </DonationMain>
                  <DonationHours>{donation.donationRuleID.donationHour} hrs</DonationHours>

                </DonationBox>
              )) : "No donation records available yet."}
        </Container>
      </DonationBlock>
}



      {/* <Container maxWidth="xl"> */}
      {/* <div style={{ display:"flex", flexDirection:"row",  }}>
        
        </div> */}
      {/* </Container> */}


      {/* <Grid item xs={12} md={12} sx={{ 
          background: "#cca17a"
           }}> */}
      {/* <RecentDonations /> */}

      {/* </Grid> */}


      {/* <ProfileSection>
        <Container maxWidth="xl">
          {loading ? ("Fetching Records...") :
           
            allData && allData ?
              
              (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <ImageSection>
                      <div style={{ position: "relative" }}>
                       
                        <img
                          src={allData && (((allData.member.image !== "https://api.finalhour.org/uploaded/memImages" && allData.member.iamge !== "https://stagingapi.finalhour.org/uploaded/memImages") ? allData.member.image : BlankImage) || BlankImage)}
                          alt={allData && (allData.member.firstName + "_" + allData.member.lastName)}
                        />
                        {allData && allData?.member?.badges.length > 0 ?
                          <PersonDiv>
                            <img src={Outer} alt="outer" />
                          </PersonDiv> : ""}
                      </div>

                      {allData && allData?.member?.badges.length > 0 ?
                        <BadgeSection>
                          {allData?.member?.badges.map(badge => (
                            <Tooltip title={`${badge?.campaignID?.name} (${badge?.hour} ${(badge?.hour == "0" || badge?.hour == "1" || badge?.hour == null) ? "hour" : "hours"})`}>
                              <img src={BadgeEarned} />
                            </Tooltip>
                          ))}

                        </BadgeSection>
                        : ""}

                    </ImageSection>

                    <SocialDiv>
                      {allData && allData.member.linkedInURL && (
                        <a href={allData && allData.member.linkedInURL} target="_blank">
                          <img src={LinkedIn} alt="linkedin" />
                        </a>
                      )}
                      {allData && allData.member.twitterURL && (
                        <a href={allData && allData.member.twitterURL} target="_blank">
                          <img src={Twitter} alt="twitter" />
                        </a>
                      )}
                      {allData && allData.member.weChatURL && (
                        <a href={allData && allData.member.weChatURL} target="_blank">
                          <img src={Wechat} alt="wecaht" />
                        </a>
                      )}
                      {allData && allData.member.facebookURL && (
                        <a href={allData && allData.member.facebookURL} target="_blank">
                          <img src={Facebook} alt="facebook" />
                        </a>
                      )}
                      {allData && allData.member.instagramURL && (
                        <a href={allData && allData.member.instagramURL} target="_blank">
                          <img src={Instagram} alt="insta" />
                        </a>
                      )}
                      {allData && allData.member.tiktokURL && (
                        <a href={allData && allData.member.tiktokURL} target="_blank">
                          <img src={Tiktok} alt="tiktok" />
                        </a>
                      )}
                    </SocialDiv>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InfoBlock >
                      <div>
                        <strong>Name</strong> -{" "}
                        <span>{allData && (allData.member.firstName + " " + allData.member.lastName)}</span>
                      </div>
                      <div>
                        <span>Company</span> -{" "}
                        <span>{allData && (allData.member.memberCompanyName)}</span>
                      </div>
                      <div>
                        <span>Location</span> -{" "}
                        <span>{(allData && (allData.member.address1 + ", " + allData.member.address2))}</span>
                      </div>
                      {allData && (
                        (allData.member.friend === 0 && loginData?.member?.memberID !== allData?.member?.memberID) ? (
                          <ButtonDiv>
                            {!connectButton && (
                              <Button onClick={handleOpenAdd}>
                                <Add fontSize="small" /> Connect
                              </Button>
                            )}
                          </ButtonDiv>
                        ) : allData.member.friend === 1 ? (
                          <ButtonDiv>
                            <Button>
                              <Diversity1 fontSize="small" /> You are connected
                            </Button>
                          </ButtonDiv>
                        ) : allData.member.friend === 2 ? (
                          <ButtonDiv>
                            <Button>
                              <Pending fontSize="small" /> Request Pending
                            </Button>
                          </ButtonDiv>
                        ) : null
                      )}

                    </InfoBlock>

                    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} p={2}>
                      <span style={{ marginRight: "10px", }}>Share : </span>
                      <FacebookShareButton
                        url={window.location.href}
                        size={30}
                        style={{ marginRight: "5px" }}
                      >
                        <img
                          src={MetaIcon}
                          alt="meta_icon"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={window.location.href}
                        style={{ marginRight: "5px" }}
                      >
                        <img
                          src={LinkedinLogo}
                          alt="linkedin_icon"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </LinkedinShareButton>
                      <TwitterShareButton
                        url={window.location.href}
                      >
                        <img
                          src={Xicon}
                          alt="X_icon"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </TwitterShareButton>

                    </Box>

                  </Grid>
                  <Grid item xs={12} md={9}>
                    <h4 style={{ marginLeft: '10px' }}>Donation History</h4>
                    <DonationBlock>

                      <></>
                      {allData && allData.donations.length > 0 ?
                        allData &&
                        allData.donations
                          .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) // Sort donations in descending order based on created date
                          .map((donation, index) => (
                            <DonationBox>
                              <DonationMain>
                                <DonationTime>
                                  {moment(donation.createdDate).format("ddd, MMM Do YYYY, h:mm a")}
                                </DonationTime>
                                <DonationName>{donation.causeID.causeName}</DonationName>
                              </DonationMain>
                              <DonationHours>{donation.donationRuleID.donationHour} hrs</DonationHours>
                              
                            </DonationBox>
                          )) : "No donation records available yet."}
                    </DonationBlock>


                  </Grid>

                  <Grid item xs={12} md={3}>
                    <StatusBlock >

                      <Medal>
                        <img src={allData && allData.currentLevel.image} alt="medal" />
                        <h5><b>Member Status:</b> {allData && allData.currentLevel.levelStatusName}</h5>
                        <h5><b>Total hours:</b> {allData && allData.sumHours}</h5>
                        <h5><b>Causes signed in:</b> {allData && allData.countCause}</h5>
                      </Medal>


                    </StatusBlock>
                  </Grid>

                </Grid>
              ) : "The public profile for the member is hidden!"}
        </Container>
        
      </ProfileSection> */}
    </>
  );
};

export default MemberDetail;
