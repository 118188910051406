import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpClient } from "../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VerificationSection = styled("div")(({ theme }) => ({
  padding: "20px",
  "& h2": {
    fontFamily: "Inter",
    margin: "0",
    fontWeight: "500",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
  "& p": {
  },
}));

const FormDiv = styled("div")(({ theme }) => ({
  marginLeft: "15%",
  marginBottom: "100px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
    marginBottom: "40px",
  },
}));

const JoinButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.red,
  color: "#fff",
  borderRadius: "15px",
  padding: "8px 25px",
  outline: "none",
  border: "2px solid transparent",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    background: "#fff !important",
    color: theme.palette.primary.red,
    border: `2px solid ${theme.palette.primary.red}`,
  },
  "& span": {
    color: "#fff"
  }
}));

// const id = JSON.parse(accept);

const EmailVerify = () => {
  const id = localStorage.getItem("acceptFriend");
  const navigate = useNavigate();
  const location = useLocation();
  //   const [verificationDetails, setVerificationDetails] = useState({
  //     username: "",
  //     rcode: "",
  //     encrcode: "",
  //   });
  const [open, setOpen] = useState(false);
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  const handleAccept = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("acceptCode", id);
    httpClient.post("member/accept", formData).then(({ data }) => {
      if (data.success === true) {
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage(data.message);
        setLoading(false);
        localStorage.removeItem("acceptFriend");
      } else {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
      }

    }).catch((err) => {
      console.log((err))
      if (err.response.status === 404) {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(err.response.data.message);
      }
    });

  };


  var memberUserName = location.search
    .split("?memberUserName=")[1]
    .split("&")[0];
  var rcode = location.search.split("&rcode=")[1].split("&")[0];
  var encrcode = location.search.split("&encrcode=")[1];

  useEffect(() => {
    getEmailVery()
  }, [])

  const getEmailVery = () => {
    setLoading(true);

    httpClient.get(`EmailVerify${location.search}`).then(({ data }) => {
      // if(data.success){
      //   setLoading(false)
      //   setOpen(true);
      //   setSnackStatus("error");
      //   setSnackMessage(typeof(data.message) === "string" ? data.message : data.message[0]);
      //   setShowVerify(true);
      // }

      if (data.success === true) {
        localStorage.setItem("token", JSON.stringify(data.data.access_token));
        localStorage.setItem("login", JSON.stringify(data.data));
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage(data.message);
        setLoading(false)
        if (id) {
          handleAccept();
        }
        setTimeout(() => {
          navigate('/', { state: "reload" });
        }, 2000);
      } else {
        setLoading(false)
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
      }

    }).catch((err) => {
      if (err.response.status === 500) {
        setLoading(false)
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage("Sorry, the Link has been expired!");
        // setShowVerify(true);
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    httpClient
      .get(
        `EmailVerify?memberUserName=${memberUserName}&rcode=${rcode}&encrcode=${encrcode}`
      )
      .then(({ data }) => {
        if (data.success === true) {
          localStorage.setItem("token", JSON.stringify(data.data.access_token));
          localStorage.setItem("login", JSON.stringify(data.data));
          setOpen(true);
          setSnackStatus("success");
          setSnackMessage(data.message);
          setLoading(false)
          if (id) {
            handleAccept();
          }
          setTimeout(() => {
            navigate('/', { state: "reload" });
          }, 1000);
        } else {
          setLoading(false)
          setOpen(true);
          setSnackStatus("error");
          setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
        }
      }).catch((err) => {
        if (err.response.status === 500) {
          setLoading(false)
          setOpen(true);
          setSnackStatus("error");
          setSnackMessage("Sorry, the Link has been expired!");
        }
      })
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <VerificationSection>
      <Container maxWidth="xl">
        <h2>User Verification</h2>
        {/* <p>Please click the verification button to verify yourself.</p> */}
        <p>{loading ? "Verifying..." : "Verified!"}</p>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box mt={2} height={400} >
              {loading ?
                <JoinButton disabled={true} style={{ background: loading ? "#999" : "red" }}>
                  <CircularProgress style={{ height: "20px", width: "20px", marginRight: "10px" }} /> <span>Loading</span>
                </JoinButton>
                :
                <JoinButton onClick={handleSubmit} style={{ background: loading ? "#999" : "red" }} disabled={showVerify}>Verify Now</JoinButton>
              }
              {/* {loading &&
              <CircularProgress size={"small"} style={{ marginLeft:"10px" }} />
            }    */}
            </Box>
            {/* <FormDiv>
              <div>
                <InputLabel>User Name</InputLabel>
                <TextField
                  disabled
                  variant="outlined"
                  //   onChange={handleChange}
                  name="memberUserName"
                  value={memberUserName}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
              </div>
              <div>
                <InputLabel>Rc Code</InputLabel>
                <TextField
                  disabled
                  variant="outlined"
                  //   onChange={handleChange}
                  name="rcode"
                  value={rcode}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
              </div>
              <div>
                <InputLabel>Encr Code</InputLabel>
                <TextField
                  disabled
                  variant="outlined"
                  //   onChange={handleChange}

                  name="encrcode"
                  value={encrcode}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
              </div>
              <Box mt={2} textAlign="right">
                <JoinButton onClick={handleSubmit}>Verify Now</JoinButton>
              </Box>
            </FormDiv> */}
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackStatus}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </VerificationSection>
  );
};

export default EmailVerify;
