import React, { useEffect, useState } from 'react'
import TermsHeader from './TermsHeader'
import BreadCrumb from '../../Components/BreadCrumb'
import TermsBody from './TermsBody'
import { useLocation } from 'react-router-dom'
import { httpClient } from '../../appUtility/Api'
import HelmetMetaData from '../../Components/HelmetMetaData'

const Terms = () => {
  const [allData, setAllData] = useState({})
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getSinglePage()
  }, [])


  const getSinglePage = () => {
    httpClient.get(`page${location.pathname}`).then(({ data }) => {
      if (data.success) {
        setAllData(data.data)
      }
    })
  }

  return (
    <div>
      <HelmetMetaData
        title={"Important Policies & Guidelines | Final Hour"}
      />
      <BreadCrumb
        header={allData && allData.title}
        subHeader1={allData && allData.title}
      />
      <TermsHeader data={allData} />
      <TermsBody data={allData} />
    </div>
  )
}

export default Terms