import { Call, ChatBubble } from "@mui/icons-material";
import { Card, Container, Grid, styled } from "@mui/material";
import React from "react";

const ContactInfoDiv = styled("div")(({ theme }) => ({
  position: "relative",
  top: "-30px",
  
}));

const ContactInfo = () => {
  return (
    <ContactInfoDiv>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card
              style={{
                padding: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"column"
              }}
            >
              <Call fontSize="large" />
              <h3>Talk to Sales</h3>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              style={{
                padding: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"column"
              }}
            >
              <ChatBubble fontSize="large" />
              <h3>Contact Customer Support</h3>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ContactInfoDiv>
  );
};

export default ContactInfo;
