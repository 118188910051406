import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from "@mui/material";
import React, { useEffect, useState } from "react";


const RedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.red,
  color: "#fff",
  padding: "10px 40px",
  outline: "none",
  borderRadius: "25px",
  border: "1px solid transparent",
  cursor: "pointer",
  "&:hover":{
    border:`1px solid ${theme.palette.primary.red} !important`,
    background: "transparent !important",
    color: `${theme.palette.primary.red} !important` 
  },
  "& span":{
    color:"#fff"
  }
}));

const CancelButton = styled(Button)(({theme}) => ({
  background: "#fff",
  color: "#333",
  padding: "10px 40px",
  outline: "none",
  borderRadius: "25px",
  border: "1px solid #333",
  cursor: "pointer",
  "&:hover":{
    border:`1px solid ${theme.palette.primary.red} !important`,
    background: "transparent !important",
    color: `${theme.palette.primary.red} !important` 
  },
  "& span":{
    color:"#fff"
  }
}))

const RemoveFavouriteDialog = (props) => {
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });

  useEffect(() => {
    props.callBack(dialogDetails);

  },[dialogDetails])

  const handleClose = () => {
    setDialogDetails({
        ...dialogDetails,
        open:false
    })
  }

  const handleSend = ( ) => {
    setDialogDetails({
      ...dialogDetails,
      success: true,
      open:false
  })
  }


  return (
    <Dialog
      open={dialogDetails.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Remove from favourite</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this cause from your favourites?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <RedButton onClick={handleClose} variant="outlined">No</RedButton>
        <CancelButton onClick={handleSend} autoFocus variant="outlined">
          Yes
        </CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveFavouriteDialog;
