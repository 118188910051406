import React from 'react'
import ForCharity from '../../Components/BecomeAPartner/ForCharity'
import ForWorplace from '../../Components/BecomeAPartner/ForWorkplace'
import BreadCrumb from '../../Components/BreadCrumb'
import HelmetMetaData from '../../Components/HelmetMetaData'

const CharityPage = () => {
  return (
    <div>
      <HelmetMetaData
        title={"Final Hour boosts charity fundraising with daily, weekly, or monthly gifts"}
      />
      <BreadCrumb header="Become a Partner - Charity" subHeader1="Charity" />
      <ForCharity />
      {/* <ForWorplace /> */}
    </div>
  )
}

export default CharityPage