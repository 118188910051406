import { Add, ArrowForwardIos } from "@mui/icons-material";
import { Container, Grid, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import DottedImage from "./../../../images/samples/dotted.png";
import CommunityImage from "./../../../images/samples/community.jpg";

const CommunitySection = styled("div")(({ theme }) => ({
  // padding: "30px 0",
  // background: theme.palette.primary.greyBg,
  height: "1050px",
  background: "#ECE8DD",
  backgroundSize: "cover !important",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    paddingBottom: "20px",
  },
  "& h2": {
    fontSize: "50px",
    margin: "0",
    paddingBottom: "20px",
    textShadow: "1px 1px 2px black, 0 0 1em #252526, 0 0 0.2em #101010",
    fontWeight: "200",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      paddingBottom: "10px",
    },
  },

  "& h3": {
    fontWeight: "500",
    color: theme.palette.primary.red,
    // paddingTop:"30px",
    fontSize: "40px",
    margin: "0",
    marginLeft: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
}));

const DottedImageDiv = styled("div")(({ theme }) => ({
  zIndex: "1",
  "& img": {
    width: "100%",
    height: "230px",
    [theme.breakpoints.down("md")]: {
      height: "100px",
    },
  },
}));

const CommunityDiv = styled("div")(({ theme }) => ({
  height: "650px",
  display: "flex",
  alignItems: "flex-end",
  zIndex: "1",
  [theme.breakpoints.down("md")]: {
    height: "100%",
  },
}));

const FlexDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  "& svg": {
    color: "#888",
    fontSize: "25px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const FlexDivFunds = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  height: "100%",
  gap: "20px",
  "& svg": {
    color: "#888",
    fontSize: "25px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const RedCircle = styled("div")(({ theme }) => ({
  background: theme.palette.primary.red,
  color: "#fff",
  width: "190px",
  height: "190px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "50%",
  textAlign: "center",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    height: "150px",
    width: "150px",
    // margin:"25px"
  },
  "& p": {
    fontSize: "70px",
    margin: "0",
    fontWeight: "100",
    lineHeight: "1",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
  },
  "& span": {},
}));

const PlusDiv = styled("div")(({ theme }) => ({
  color: "#999",
  border: "2px solid #999",
  borderRadius: "50%",
  height: "30px",
  width: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "25px",
  "& svg": {
    fontSize: "25px",
  },
}));

const Equals = styled("div")(({ theme }) => ({
  fontSize: "60px",
  color: "#888",
  display: "flex",
  alignItems: "center",
  // justifyContent:"center",
  height: "100%",
}));

const FundRaised = styled("div")(({ theme }) => ({
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  "& p": {
    fontSize: "70px",
    margin: "0",
    lineHeight: "1",
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
  },
  "& span": {
    // margin:"0 40px"
  },
}));

const OurCommunity = ({ data }) => {
  const [communityData, setCommunityData] = useState({});

  useEffect(() => {
    getCommunityData();
  }, []);

  const getCommunityData = () => {
    httpClient.get("community-section").then(({ data }) => {
      if (data.success) {
        setCommunityData(data.data);
      }
    });
  };

  return (
    <CommunitySection
      style={{
        background: `url(${data?.images[0]?.image}) no-repeat center center fixed`,
      }}
    >
      {/* <Container maxWidth="xl">
        <h2>Our Community</h2>
        <Grid container>
          <Grid item md={8} xs={12}>
            <FlexDiv>
              <RedCircle>
                <p>{communityData.hours || "0"} <br/> Hours</p>
                <span>worked on behalf of</span>
                <p>{communityData.causes || "0"} <br/> Causes</p>
              </RedCircle>
              <ArrowForwardIos />
              <RedCircle>
                <span>by</span>
                <p>{communityData.members || "0"}<br/> Members</p>
                <span>from</span>
                <p>872 <br/>Workplaces</p>
              </RedCircle>
            </FlexDiv>
          </Grid>
          <Grid item md={1} xs={12}>
            <Equals>
              =
            </Equals>
          </Grid>
          <Grid item md={3} xs={12}>
            <FundRaised>
              <span>funds raised for causes and counting...</span>
              <p>${communityData.totalAmount || "0"}</p>
            </FundRaised>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3>Because better to work for a cause than donate to a cause.</h3>
        </Grid>
      </Container> */}
      <DottedImageDiv>
        <img src={DottedImage} alt="dotted_image" />
      </DottedImageDiv>
      {/* <div className="overlay_dark"></div> */}

      <Container maxWidth="xl">
        <h2>Our Community</h2>

        <CommunityDiv>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <FlexDiv>
                <RedCircle>
                  <p>{communityData.hours || "0"} </p> <span>Hours</span>
                </RedCircle>
                <PlusDiv>
                  <Add />
                </PlusDiv>

                <RedCircle>
                  <p>{communityData.orgs || "0"}</p> <span>Charity Partners</span>
                </RedCircle>
                <PlusDiv>
                  <Add />
                </PlusDiv>

                <RedCircle>
                  <p>{communityData.members || "0"}</p> <span>Members</span>
                </RedCircle>
                <PlusDiv>
                  <Add />
                </PlusDiv>

                <RedCircle>
                  <p>{communityData.causes || "0"} </p> <span>Causes</span>
                </RedCircle>
              </FlexDiv>
            </Grid>
            <Grid item xs={12} md={4}>
              <FlexDivFunds>
                <PlusDiv>=</PlusDiv>

                <FundRaised>
                  <p>${communityData.totalAmount || "0"}</p>
                  <span>funds raised for causes and counting...</span>
                </FundRaised>
              </FlexDivFunds>
            </Grid>
            <Grid item xs={12}>
              <h3>{data.section1}</h3>
            </Grid>
          </Grid>
        </CommunityDiv>
      </Container>
    </CommunitySection>
  );
};

export default OurCommunity;
