import React, { useEffect, useState } from "react";
import Governance from "../../Components/About/Governance";
import HowItWorks from "../../Components/About/HowItWorks";
import MemberStatus from "../../Components/About/MemberStatus";
import OurMission from "../../Components/About/OurMission";
import OurTeam from "../../Components/About/OurTeam";
import BreadCrumb from "../../Components/BreadCrumb";
import { httpClient } from "../../appUtility/Api";
import Intro from "../../Components/FAQs/Intro";
import Questions from "../../Components/FAQs/Questions";

const FAQs = () => {
  const [aboutData, setAboutData] = useState({});
  const [faqData, setFaqData] = useState("");

  useEffect(() => {
    getAboutdata();
    getFaqs();
  }, []);

  const getFaqs = () => {
    httpClient.get("faqs").then(({ data }) => {
      if (data.success) {
        setFaqData(data.data);
      }
    });
  };

  const getAboutdata = () => {
    httpClient.get("about").then(({ data }) => {
      if (data.success) {
        setAboutData(data.data);
      }
    });
  };

  //   const getTeamdata = () => {
  //     httpClient('our-team').then(({data}) => {
  //       if(data.success){
  //         setTeamData(data.data)
  //       }
  //     })
  //   }
  // const questions = [
  //     {
  //         id: 1,
  //         question: "What is your organization's mission?",
  //         answer: "Our mission is to transform the user interface for financial donations from money earned to time invested..",
  //         createdDate: "2023-05-15"
  //     },
  //     {
  //         id: 2,
  //         question: "How can I make a donation?",
  //         answer: "Donations can be made through our website by visiting the specific detail cause page that you want your hours to donate for. You will find various options and ranges to contribute, including online payment methods and instructions.",
  //         createdDate: "2023-05-16"
  //     },
  //     {
  //         id: 3,
  //         question: "Is my donation tax-deductible?",
  //         answer: "Yes, we are a registered nonprofit organization, and donations are typically tax-deductible. However, we recommend consulting with a tax professional or referring to your local tax laws for specific details.",
  //         createdDate: "2023-05-17"
  //     },
  //     {
  //         id: 4,
  //         question: "Can I volunteer with your organization?",
  //         answer: "Absolutely! We greatly appreciate volunteers. Visit our 'Become A Partner' page to learn more about available opportunities and how to get involved. We value your time and commitment.",
  //         createdDate: "2023-05-17"
  //     },
  //     {
  //         id: 5,
  //         question: "How can I become a sponsor or partner?",
  //         answer: "We welcome sponsorships and partnerships with individuals and organizations who share our vision. Please reach out to our team, and we will provide you with more details.",
  //         createdDate: "2023-05-17"
  //     },

  // ];

  return (
    <div>
      <BreadCrumb header={"Frequently Asked Questions"} subHeader1="FAQs" />
      <Intro />
      <Questions data={faqData && faqData} />
      <OurMission data={aboutData && aboutData} />

      {/* <Governance /> */}
    </div>
  );
};

export default FAQs;
