import { styled } from "@mui/material";

import { Box } from "@mui/system";
import moment from "moment/moment";

import React from "react";


const FriendDiv = styled("div")(({ theme }) => ({
    padding: "10px",
    display: "grid",
    gridTemplateColumns: "1fr 6fr",
    gridGap: "10px",
    alignItems: "center",

    "& img": {
        height: "100px",
        width: "100px",
        objectFit: "cover",
        borderRadius: "50%"
    }
}));



const Box1 = styled(Box)(({ theme }) => ({
    borderBottom: "1px solid #000",
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Add this line
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        textAlign: "center", // Add this line
    },
}));

const Name = styled(Box)(({ theme }) => ({
    width: "60%",
    margin: "0 0px",
    fontSize: "16px",
    fontWeight: "700",
    //float: "left",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "10px",
        flexDirection: "column",
        textAlign: "center",
    },
}));
const Main = styled(Box)(({ theme }) => ({
    width: "60%",

    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "10px",
        flexDirection: "column",
        textAlign: "center",
    },
}));
const Time = styled(Box)(({ theme }) => ({

    margin: "0 0px",
    fontSize: "16px",
    fontWeight: "500",

    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "10px",
        flexDirection: "column",
        textAlign: "center",
    },
}));

const Hours = styled(Box)(({ theme }) => ({
    width: "15%",
    margin: "0 0px",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "20px !important",
        flexDirection: "column",
        textAlign: "center",
    },
}));

const Amount = styled(Box)(({ theme }) => ({
    width: "15%",
    margin: "0 0px",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "20px !important",
        flexDirection: "column",
        textAlign: "center",
    },
}));






const FriendBox1 = (props) => {
    
    //console.log("ererr", props);

    return (
        <FriendDiv>
            <div>
                <img src={props.image} alt={props.name} />
            </div>

            <Box1>
                <Main>
                    <Time>
                    {moment(props.date).format('MMM Do YYYY, h:mm:ss a')}
                    </Time>
                    <Name>{props.name}</Name>
                </Main>
                <Name>{props.cause}</Name>
                <Hours>{props.hours}</Hours>
                <Amount>
                    {props.amount}
                </Amount>


            </Box1>



        </FriendDiv >
    );
};

export default FriendBox1;
