import { styled } from "@mui/material";
import React, { useEffect, useRef } from "react";
// import Video from "./../../../../images/video.mp4";
// import "video-react/dist/video-react.css";
// import { Player } from "video-react";
import RightImage from './../../../../../images/samples/editevening_disaster_relief_firefirefighters_climate_change_sho_f02c9bcd-505e-4e97-9463-ede148417eee-topaz-enhance.jpg'

const AboutVideoSection = styled("div")(({ theme }) => ({
  background: theme.palette.primary.orange,
  // padding: "40px 0px 40px 40px ",
  backgroundSize:"cover !important",
  height:"auto",
  zIndex:"0",
  // "& .video-react .video-react-big-play-button": {
  //   left: "43%",
  //   top: "43%",
  //   [theme.breakpoints.down("md")]: {
  //     left: "37%",
  //     top: "37%",
  //   },
  // },
  [theme.breakpoints.down("md")]: {
    height: "300px",
  },
}));

const AboutVideo = ({data}) => {

  return (
    <AboutVideoSection style={{ background:`url(${RightImage}) center center no-repeat` }}>
      {/* <Player
        playsInline
        //   poster="/assets/poster.png"
        // src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        // src={Video}
        src={data.video_url1}
      /> */}

    </AboutVideoSection>
  );
};

export default AboutVideo;
