import * as React from 'react';
import { Container, styled, Grid } from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import whiteDot from '../../../images/samples/white-dotted.png'

const GiveDiv = styled("div")(({ theme }) => ({
    //   padding: "50px 0",
    background: "#ffffff",
    padding: "50px 22%",
    textAlign: "center",
    "& h3": {
        fontSize: "22px",
        fontWeight: "500"
    },
    [theme.breakpoints.down("md")]: {
        padding: "20px 0",
    },
}));

const GiveText = styled("div")(({ theme }) => ({

    textAlign: "center",
    "& h2": {
        margin: "0",
        fontSize: "35px",
        paddingBottom: "20px",
    }, "& strong": {
        fontSize: "35px",
        color: theme.palette.primary.red,
        // margin:"0",
    },
}));

const FormDiv = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },

    "& h2": {
        fontSize: "22px",
        // margin:"0",
    },
    "& .MuiTextField-root ": {
        padding: "10px",
        width: "90px",
    },
    "& .MuiSelect-select ": {
        padding: "10px",
        width: "100px",
    },
    "& input": {
        padding: "10px",
    },
}));



const GiveButton = styled("a")(({ theme }) => ({
    padding: "10px 30px",
    borderRadius: "100px",
    border: "1px solid gray !important",
    color: theme.palette.primary.gray,
    background: "#ffffff",
    marginTop: "10px",
    transition: "0.3s",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
        padding: "5px 10px",
        marginLeft: "5px",
        marginTop: "0",
    },
    "&:hover": {
        background: `${theme.palette.primary.red} !important`,
        color: "#fff !important",
        border: `1px solid gray !important`,
    },
}));

const DottedImageDiv = styled("div")(({ theme }) => ({
    zIndex: "1",
    position: "relative",
    bottom:"30px",
    width: "100%",
    "& img": {
        width: "100%",
        height: "200px",
        [theme.breakpoints.down("md")]: {
            height: "100px",
        },
    },
}));
const linkList = [
    { "id": "1001", "title": "Website", "image": "https://images.unsplash.com/photo-1472289065668-ce650ac443d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
    { "id": "1002", "title": "Facebook", "image": "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
    { "id": "1003", "title": "Instagram", "image": "https://images.unsplash.com/photo-1534361960057-19889db9621e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
    { "id": "1003", "title": "LinkedIn", "image": "https://images.unsplash.com/photo-1534361960057-19889db9621e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
    { "id": "1003", "title": "Twitter", "image": "https://images.unsplash.com/photo-1534361960057-19889db9621e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
]

const MoreLinks = ({ allData }) => {
    return (
      <>
        <DottedImageDiv>
          <img src={whiteDot} alt="white-dot" />
        </DottedImageDiv>
  
        <GiveDiv>
          <Container maxWidth="xl">
            <GiveText>
              <h2>
                Additional <strong>{allData.name}</strong> links
              </h2>
            </GiveText>
            <FormDiv>
                {allData.weblink && <GiveButton href={`https://${allData.weblink}`} target='_blank'>Website</GiveButton>}
                {allData.facebook && <GiveButton href={`https://${allData.facebook}`} target='_blank'>Facebook</GiveButton>}
                {allData.instagram && <GiveButton href={`https://${allData.instagram}`} target='_blank'>Instagram</GiveButton>}
                {allData.twitter && <GiveButton href={`https://${allData.twitter}`} target='_blank'>Twitter</GiveButton>}
                {allData.linkedin && <GiveButton href={`https://${allData.linkedin}`} target='_blank'>LinkedIn</GiveButton>}
            </FormDiv>
          </Container>
        </GiveDiv>
      </>
    );
  };
  
  export default MoreLinks;
  