import { Container, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeaderBoardComponent from "../LeaderBoardComponent";
import { httpClient } from "../../../appUtility/Api";
import { useNavigate } from "react-router-dom";

const FeatureSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#EAB38B",

}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#fff",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "50px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px"
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c"
    }
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "120px",
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "20px",
  },
}));

const WorkspaceFeature = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [workplaceData, setWorkplaceData] = useState([]);
  useEffect(() => {
    getWorkplace();
  }, [])

  const getWorkplace = () => {
    setLoading(true);
    httpClient.get('featured-workplaces').then(({ data }) => {
      if (data.success) {
        setWorkplaceData(data.data)
        setLoading(false);
      }
    })
  }


  const handleClick = (work) => {
    // console.log('work is', work);

    navigate(`/workplaces/detail/${work.slug}`);
  }

  return (
    <FeatureSection>
      <Container maxWidth="xl">
        <HeaderDiv>
          <h2>Featured Workplaces</h2>
          {/* <button>View all</button> */}
        </HeaderDiv>
        <GridDiv>
          {!loading ? (
            workplaceData.length > 0 ? (
              workplaceData.map((work, index) => (
                // index < 4 &&
                <LeaderBoardComponent
                  image={work.image}
                  index={index + 1}
                  memberName={work.name}
                  handleClick={() => handleClick(work)}
                // hours="City, 95 hours"
                />
              ))) : "No workplace found") :
            <>
              <Skeleton variant="circular" width={250} height={250} />
              <Skeleton variant="circular" width={250} height={250} />
              <Skeleton variant="circular" width={250} height={250} />
              <Skeleton variant="circular" width={250} height={250} />
            </>
          }

          {/* <LeaderBoardComponent
              image={
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              }
              index="2nd"
              memberName="Sara Harrison"
              hours="City, 95 hours"
            />
            <LeaderBoardComponent
              image={
                "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              }
              index="3rd"
              memberName="Max Fury"
              hours="City, 95 hours"
            />
            <LeaderBoardComponent
              image={
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              }
              index="4th"
              memberName="Sara Harrison"
              hours="City, 95 hours"
            /> */}
        </GridDiv>
      </Container>
    </FeatureSection>
  );
};

export default WorkspaceFeature;
