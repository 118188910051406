import { Container, styled, Grid, Tooltip } from '@mui/material'
import React from 'react'
import parse from 'html-react-parser';
import { Info } from '@mui/icons-material';

const CauseDiv = styled("div")(({ theme }) => ({
    background: "#B8B6A9",
    padding: "40px 0",
    "& h1": {
        margin: "0",
        fontWeight: "300",
        padding: "20px 0",
        fontSize: "28px",
        color: "#fff",
        zIndex: "1",

        [theme.breakpoints.down("md")]: {
            padding: "0px 0",
            marginTop: "20px",
            fontSize: "40px",
        },
    },
    "& p": {
        margin: "0",
        color: "black",
        paddingBottom: "10px",
        fontWeight: "500",
    },

}))
const Slogan = styled("div")(({ theme }) => ({
    marginLeft: "40%",
    padding: "40px 0",
    lineHeight: "1",
    "& p": {
        margin: "0",
        color: "#1F1F1F",
        paddingBottom: "10px",
        fontWeight: "900",
        fontSize: "45px"
    }, "& strong": {
        margin: "0",
        color: "#fff",
        paddingBottom: "10px",
        fontWeight: "900",
        fontSize: "45px"
    }, "& p1": {
        margin: "0",
        color: "#fff",
        paddingBottom: "10px",
        fontWeight: "400",
        fontSize: "15px"
    },
    "& p2": {
        // margin: "0",
        color: "#1F1F1F",
        background: "#fff",
        padding: "0 10px 0 10px",
        marginTop: "20px",
        fontWeight: "800",
        fontSize: "12px"
    }, [theme.breakpoints.down("md")]: {
        marginLeft: "0%",
        padding: "0px 0",
        marginTop: "20px",
        fontSize: "40px",
    },

}))

const Events = () => {

    
    return (
        <CauseDiv>
            <Container maxWidth="xl">
                {/* <Tooltip title="The component is static for now">
                    <Info style={{ position: "absolute" ,color: "navyblue" }} />
                </Tooltip> */}
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>

                        {/* {props.content} */}
                        <h1>Recent News</h1>
                        <p>Australia is closer than it has ever been to ending live sheep export.
                            To ensure this actually happen, an end date for the date must be legislated
                            as soon as possible, and before the next federal election.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Slogan>
                            <p> THE END IS ON</p>
                            <p><strong> THE HORIZON</strong></p>
                            <p1>#LegislateTheDate</p1><br />

                            <p2>END LIVE SHEEP EXPORT</p2>
                        </Slogan>

                    </Grid>
                </Grid>
            </Container>
        </CauseDiv>
    )
}

export default Events