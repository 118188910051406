import { Tooltip, styled, Drawer, Avatar, TextField, Button, ListItem, ListItemAvatar, List, ListItemText, IconButton, Badge } from "@mui/material";

import { Box } from "@mui/system";
import { CancelOutlined, Edit, Favorite, FavoriteBorder, Image, InsertCommentOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../../appUtility/Api";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import EmojiPicker from 'emoji-picker-react';

//import { Picker } from 'emoji-mart';
import Popover from '@mui/material/Popover';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

const FriendDiv = styled("div")(({ theme }) => ({
  padding: "10px",
  display: "grid",
  gridTemplateColumns: "1fr 6fr",
  gridGap: "10px",
  alignItems: "center",

  "& img": {
    height: "100px",
    width: "100px",
    objectFit: "cover",
    borderRadius: "50%"
  }
}));

const FlexFriend = styled("div")(({ theme }) => ({
  padding: "10px",
  borderBottom: "1px solid #000",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  "& h4": {
    margin: "0",
  },
}));

const Box1 = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #000",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between", // Add this line
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center", // Add this line
  },
}));

const Name = styled(Box)(({ theme }) => ({
  width: "60%",
  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "700",
  //float: "left",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const Main = styled(Box)(({ theme }) => ({
  width: "60%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const Time = styled(Box)(({ theme }) => ({

  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "500",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const Hours = styled(Box)(({ theme }) => ({
  width: "15%",
  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "500",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "20px !important",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const Amount = styled(Box)(({ theme }) => ({
  width: "15%",
  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "500",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "20px !important",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const Favourite = styled(Box)(({ theme }) => ({
  width: "12%",
  margin: "0 0px",
  fontSize: "16px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //textAlign: "left",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const Line = styled("div")(({ theme }) => ({
  background: "#416C5B",
  width: "100%",
  margin: "10px auto",
  height: "2px",
  borderRadius: "5px",
}));
const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paper {
   
    max-width: 480px;
    height: 520px;
    padding: 20px;
    margin-top: 13%;
    opacity: 0.9;
    overflow-y: auto;
  }

  @media (max-width: 600px) {
   
    .MuiDrawer-paper {
      position: relative;
      min-width: auto;
      width: 100%;
      margin-top: 80%;
      height: 300px;
    }
  }
`;

const member = localStorage.getItem("login");
const memberData = JSON.parse(member);


const FriendBox = (props) => {
  const navigate = useNavigate();
  const [cartOpen, setCartOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [editComment, setEditComment] = useState('');
  const [currentValue, setCurrentValue] = useState("member");

  const [memberList, setMemberList] = useState([]);
  const [showEditStatus, setShowEditStatus] = useState({});

  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [emojiPickerAnchor, setEmojiPickerAnchor] = useState(null);
  const [emojiPickerAnchor1, setEmojiPickerAnchor1] = useState(null);

  const handleEmojiPickerOpen = (event) => {
    setEmojiPickerAnchor(event.currentTarget);
  };

  const handleEmojiPickerOpen1 = (event) => {
    setEmojiPickerAnchor1(event.currentTarget);
  };

  const handleEmojiPickerClose = () => {
    setEmojiPickerAnchor(null);
  };

  const handleEmojiPickerClose1 = () => {
    setEmojiPickerAnchor1(null);
  };

  const handleEmojiSelect = (emoji) => {
    //console.log("emoji", emoji);
    setEditComment((prev) => prev + emoji.emoji);
    handleEmojiPickerClose();
  };

  const handleEmojiSelect1 = (emoji) => {
    //console.log("emoji", emoji);
    setComment((prev) => prev + emoji.emoji);
    handleEmojiPickerClose1();
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    props.handleAddComment(comment);
    //console.log("ererr111111111", comment);
    setComment('');
  };
  const handleSubmit1 = (event, commentId) => {
    event.preventDefault();
    //const commentId = comment.donationCommentID;
    props.handleEditComment(editComment, commentId);
    //console.log("ererr1222222222222", editComment, commentId);
    setEditComment('');
    setShowEditStatus(false);
  };



  //console.log("ererr", props);

  return (
    <FriendDiv>
      <div>
        <img src={props.image} alt={props.name} />
      </div>

      <Box1>
        <Main>
          <Time>
         
            {moment(props.date).format('MMMM Do YYYY, h:mm:ss a')}
          </Time>
          <Name>{props.name}</Name>
        </Main>
        <Name>{props.cause}</Name>
        <Hours>{props.hours}</Hours>
        <Amount>
          {props.amount}
        </Amount>

        <Favourite>
          {props.donationLiked !== 1 ? (
            <Tooltip title="Like">
              <FavoriteBorder
                sx={{ color: "purple", cursor: "pointer" }}
                onClick={props.handleLike}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Dislike">
              <Badge color="info" badgeContent={props.likes}>
                <Favorite
                  sx={{ color: "#EA3323", cursor: "pointer" }}
                  onClick={props.handleDisLike}
                />
              </Badge>
            </Tooltip>
          )}
        </Favourite>


        <Favourite>
          <Badge badgeContent={props.comments?.length} color="info">
            <InsertCommentOutlined
              sx={{ color: "purple", cursor: "pointer" }}
              onClick={() => setCartOpen(true)}
            />
          </Badge>
        </Favourite>





      </Box1>

      <CustomDrawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>

        <Box mb={3} sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'start' }} textAlign="center">

          <IconButton
            sx={{ marginTop: '20px' }}
            onClick={() => setCartOpen(false)}
          >
            <CancelOutlined fontSize="small" color="primary" />

          </IconButton>
          <h3>Comments</h3>

        </Box>

        <div>
          <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>

            {props.comments?.length > 0 ? (
              props.comments.map((comment, index) => {

                const commentId = comment.donationCommentID; // Assuming you have a unique identifier for each comment
                const isShowEdit = showEditStatus[commentId] || false; // Get the sh
                return (
                  <ListItem key={index} sx={{
                    display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'start', borderBottom: '0.25px solid #888',
                  }}>
                    {!isShowEdit ? (
                      <>
                        <ListItemAvatar sx={{ marginTop: "10px" }}>

                          <Avatar src={comment.commentedBy.image} />

                        </ListItemAvatar>

                        <ListItemText
                          primaryTypographyProps={{ fontFamily: 'Epilogue, sans-serif' }}
                          secondaryTypographyProps={{ fontFamily: 'Epilogue, sans-serif' }}
                          primary={comment.comment}
                          secondary={moment(comment.commentedDate).format('MMMM Do YYYY, h:mm:ss a')}
                        />

                        <IconButton
                          onClick={() => {
                            const commentId = comment.donationCommentID; // Assuming you have a unique identifier for each comment
                            setShowEditStatus((prevState) => ({
                              ...prevState,
                              [commentId]: !prevState[commentId], // Toggle the showEdit status for the clicked item
                            }));
                          }}
                        > {memberData && memberData.member.memberID === comment.commentedBy.memberID && (
                          <Edit fontSize="small" color="success" />
                        )}
                        </IconButton>


                      </>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {memberData && (
                          <Avatar sx={{ marginRight: "20px" }} src={memberData.member.image}>
                            {memberData.member.firstName.charAt(0)}
                          </Avatar>
                        )}
                        <form onSubmit={(event) => handleSubmit1(event, comment.donationCommentID)}>
                          <TextField
                            InputProps={{
                              style: { fontFamily: 'Epilogue, sans-serif' },
                              endAdornment: (
                                <IconButton onClick={handleEmojiPickerOpen} edge="end">
                                  <InsertEmoticonIcon />
                                </IconButton>
                              ),
                            }}
                            sx={{ width: '100%' }}
                            id="standard-multiline-flexible"
                            label="Add comment here"
                            multiline
                            maxRows={4}
                            variant="standard"
                            value={editComment !== "" ? editComment : setEditComment(comment.comment)} // Bind the comment value to the state
                            onChange={(event) => setEditComment(event.target.value)} // Update the comment state on input change
                          />
                          <Popover
                            open={Boolean(emojiPickerAnchor)}
                            anchorEl={emojiPickerAnchor}
                            onClose={handleEmojiPickerClose}
                          >
                            <EmojiPicker onEmojiClick={handleEmojiSelect} />
                          </Popover>
                          <Button
                            sx={{ marginTop: '20px' }}
                            type="submit"
                            size="small"
                            color="info"
                            variant="contained"
                            disabled={props.loading}
                          >
                            Send
                          </Button>
                          <Button
                            sx={{ marginTop: '20px', marginLeft: '10px' }}
                            size="small"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              const commentId = comment.donationCommentID; // Assuming you have a unique identifier for each comment
                              setShowEditStatus((prevState) => ({
                                ...prevState,
                                [commentId]: false, // Set the showEdit status to false for the clicked item
                              }));
                              setEditComment("");
                            }}
                          >
                            Cancel
                          </Button>
                        </form>
                      </div>
                    )}
                  </ListItem>
                );
              })
            ) : (
              <ListItemText primary="No comments yet" />
            )}
          </List>

          <Line></Line>


          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ marginRight: "20px" }}

              src={memberData && memberData.member.image}
            >
              {memberData.member.firstName.charAt(0)}
            </Avatar>

            <form onSubmit={handleSubmit}>
              <TextField
                InputProps={{
                  style: { fontFamily: 'Epilogue, sans-serif' },
                  endAdornment: (
                    <IconButton onClick={handleEmojiPickerOpen1} edge="end">
                      <InsertEmoticonIcon />
                    </IconButton>
                  ),
                }}
                sx={{ width: '100%' }}
                id="standard-multiline-flexible"
                label="Add comment here"
                //name="comment"
                multiline
                maxRows={4}
                variant="standard"
                value={comment} // Bind the comment value to the state
                onChange={(event) => setComment(event.target.value)} // Update the comment state on input change
              />
              <Popover
                open={Boolean(emojiPickerAnchor1)}
                anchorEl={emojiPickerAnchor1}
                onClose={handleEmojiPickerClose1}
              >
                <EmojiPicker onEmojiClick={handleEmojiSelect1} />
              </Popover>
              <Button
                sx={{ marginTop: '20px' }}
                type="submit"
                size="small"
                color="info"
                variant="contained"
                disabled={props.loading}>
                Send
              </Button>
            </form>
          </div>

        </div>
      </CustomDrawer>

    </FriendDiv >
  );
};

export default FriendBox;
