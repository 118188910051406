import { Skeleton, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import LeftImage from './../../../../images/samples/causes-bkg-arts.jpg'
import CrisisImage from "./../../../../images/emergency.png";
import NoImage from "../../../../images/no-pictures.png";

const ButtonDiv = styled("div")(({ theme }) => ({
  textAlign: "right",
  paddingBottom: "30px",
  paddingTop: "60px",
  [theme.breakpoints.down("md")]: {
    textAlign: "left",
    paddingBottom: "20px",
    paddingTop: "0px",
  },
  "& button": {
    color: theme.palette.primary.grey,
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "10px 20px",
    outline: "none",
    border: "1px solid #fff",
    transition: "0.3s",
    cursor: "pointer",
  },
  "& button:hover": {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const ContentDiv = styled("div")(({ theme }) => ({
  // display:"flex",
  // flexDirection:"column",
  // justifyContent:"space-between"
  zIndex: "1"
}));

const CrisisImg = styled("div")(({ theme }) => ({
  "& img": {
    width: "30px !important",
    height: "30px !important",
    position: "absolute",
    bottom: "80px !important",
    left: "85px !important",
    border:"none !important",
    borderRadius:"initial !important"
  },
}));

const AboutTime = (props) => {
  const AboutSection = styled("div")(({ theme }) => ({
    background: theme.palette.primary.grey,
    backgroundSize: "cover !important",
    padding: "40px 60px",
    height: "450px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow:'hidden',
    [theme.breakpoints.down("md")]: {
      padding: "20px 10px",
      height: "300px",
    },
    "& h1": {
      margin: "0",
      fontWeight: "200",
      padding: "20px 0",
      fontSize: "70px",
      color: "#fff",
      zIndex: "1",
      lineHeight: "1",
  
      [theme.breakpoints.down("md")]: {
        padding: "0px 0",
        marginTop: "20px",
        fontSize: "30px",
      },
    },
    "& p": {
      margin: "0",
      color: "#fff",
      paddingBottom: "10px",
      fontWeight: "300",
      [theme.breakpoints.down("md")]: {
        fontWeight: "200",
       
      },
    },
    "& img": {
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      border: `6px solid ${props.props.allData.categoryID?.color}`,
      [theme.breakpoints.down("md")]: {
        height: "50px",
        width: "50px",
       
      },
    },
  
  }));
//console.log("asasawee", props.props.image);
  
  return (

    
    <AboutSection style={{ background: `url(${props?.props?.allData?.leftImage || LeftImage}) center center no-repeat` }}>
      <div className="overlay_dark"></div>
      {/* <h1>It's about time.</h1> */}
      <div style={{ display: 'flex', alignItems: 'center', position:"relative" }}>
        {props.props.image ? (
          <>
          <img src={props.props.image || NoImage } alt="logo" style={{ marginRight: '10px', opacity:'100%', constraint:'100%' }} />
          
          {props?.props?.allData?.crisis === 1 ? (
            <CrisisImg>
              <img src={CrisisImage} />
            </CrisisImg>
          ) : (
            ""
          )}
          </>
          ) : (
          <Skeleton animation="wave" style={{ marginRight: '10px' }} />
        )}
        <h1>{props.props.header ? props.props.header : <Skeleton animation="wave" />}</h1>
        
      </div>
      <p style={{marginTop: "5px", color:"white", fontSize:"20px"}}> Charity: {props.props.organization ? props.props.organization : <Skeleton animation="wave" />}</p>

      <ContentDiv>
        <div>
          {
            props.props.description ?
              parse(props.props.description || "")
              :
              <p>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </p>
          }
        </div>

      </ContentDiv>
    </AboutSection>
  );
};

export default AboutTime;
