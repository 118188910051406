import { Close, Info } from "@mui/icons-material";
import { Box, Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CategoryDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#C0C0C0",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h4": {
    margin: "0",
    paddingBottom: "20px",
  },
}));

const Tags = styled("div")(({ theme }) => ({
  padding: "7px 12px",
  borderRadius: "10px",
  display: "inline-flex",
  color: "#4A4A4A",
  border: "1px solid #4A4A4A",
  margin: "10px",
  fontSize: "16px",
  alignItems: "center",
  "& svg": {
    fontSize: "16px",
  },
  "& span": {
    margin: "0 5px",
  },
  "& img":{
      height:"20px",
      width:"20px",
      objectFit:"contain"
    },
}));

const allCats = [
  { id: "1", color: "#FFA5A5", name: "My Favourites", total: "5" },
  { id: "2", color: "#FFE456", name: "Featured", total: "123" },
  { id: "3", color: "#A5E3F0", name: "Global", total: "22" },
  { id: "4", color: "#AECAD6", name: "Local", total: "789" },
  { id: "5", color: "#ECAB3B", name: "Animals", total: "3" },
  { id: "6", color: "#B891B3", name: "Culture", total: "42" },
  { id: "7", color: "#8ADEBE", name: "Environment", total: "12" },
  { id: "8", color: "#7BC3FF", name: "Health", total: "44" },
  { id: "9", color: "#ACB9D9", name: "Security", total: "23" },
  { id: "10", color: "#EEC2EA", name: "Social Welfare", total: "523" },
  { id: "12", color: "#B0B0B0", name: "Others", total: "100" },
];

const CauseCategory = ({ categories }) => {

  const navigate = useNavigate();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  const handleDelete = (data) => {
    let res = category.filter((cats) => cats.id !== data.id);
    setCategory(res);
  };

  const handleClickCategory = (cats) => {
    navigate(`/causes/category/${cats.slug}`, { state: cats });
  };

  // console.log("cats", category);

  return (
    <CategoryDiv>
      <Container maxWidth="xl">
        <h4>Categories</h4>

        {(category && category.length > 0) ? (
          category && category.map((cats) => (
            <Tags
              key={cats.categoryID}
              style={{ background: cats.categoryColor}}
              
              onClick={() => handleClickCategory(cats)}
            >
              {/* <Info /> */}
              <img src={cats.categoryIcon} at={cats.categoryName} />
              <span>
                {cats.categoryName} {"(" + cats.causeCount + ")"}
              </span>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(cats)}
              />
            </Tags>
          ))
        ) : (
          <small>
            No Categories found</small>
        )}
      </Container>
    </CategoryDiv>
  );
};

export default CauseCategory;
