import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Snackbar,
  CircularProgress,
  Button,
  Autocomplete,
  Chip,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import MuiAlert from "@mui/material/Alert";
import { AddPhotoAlternate, RemoveRedEye } from "@mui/icons-material";
import Dropzone from "react-dropzone";
import LinkedIn from "./../../../images/linkedin.png";
import Facebook from "./../../../images/facebook.png";
import Instagram from "./../../../images/instagram.png";
import Twitter from "./../../../images/twitter.png";
import Wechat from "./../../../images/wechat.png";
import Tiktok from "./../../../images/tiktok.png";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AccountDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: theme.palette.primary.lightest,
  "& h2": {
    margin: "0",
    paddingBottom: "40px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const Section1 = styled("div")(({ theme }) => ({
  padding: "40px 10%",
  borderBottom: "1px solid #000",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& p": {
    fontWeight: "500",
    margin: "0",
  },
  "& h4": {
    margin: "0",
    paddingBottom: "30px",
  },
}));

const SocialImage = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& img": {
    width: "40px",
    height: "40px",
    objectFit: "cover",
  },
}));

const PasswordDiv = styled("div")(({ theme }) => ({
  position: "relative",
  "& svg": {
    position: "absolute",
    right: "8px",
    top: "22px",
    color: "#999",
    fontSize: "18px",
  },
}));

const ResetButton = styled("button")(({ theme }) => ({
  background: theme.palette.primary.grey,
  color: "#fff",
  borderRadius: "15px",
  padding: "6px 15px",
  outline: "none",
  border: "2px solid transparent",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    background: theme.palette.primary.greyText,
  },
}));

const DropzoneDiv = styled("div")(({ theme }) => ({
  // height: "100%",
  //   marginLeft: "15%",
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  "& img": {
    height: "350px",
    width: "350px",
    objectFit: "cover",
    border: `2px solid ${theme.palette.primary.greyBg}`,
  },
}));

const UploadDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  textAlign: "center",
  padding: "40px 0",
  height: "250px",
  width: "350px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2px",
  background: theme.palette.primary.greyBg,
  "&:hover": {
    //   border: `1px solid #333`,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "45px",
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  border: "1px solid transparent !important",
  outline: "none",
  borderRadius: "25px",
  padding: "10px 30px",
  background: theme.palette.primary.red,
  color: "#fff !important",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.red} !important`,
    color: `${theme.palette.primary.red} !important`,
    background: "#fff",
  },
}));

let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const AccountSettings = () => {
  const navigate = useNavigate();
  const member = localStorage.getItem("login");
  const memberData = JSON.parse(member);

  const SaveButton2 = styled(Button)(({ theme }) => ({
    border: "1px solid transparent !important",
    outline: "none",
    borderRadius: "25px",
    padding: "10px 30px",
    background:
      accountDetails.currentPassword && passwordValid && passwordValid2
        ? theme.palette.primary.red
        : "#aaa",
    color: "#fff !important",
    transition: "0.3s",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid ${theme.palette.primary.red} !important`,
      color: `${theme.palette.primary.red} !important`,
      background: "#fff",
    },
  }));

  const [accountDetails, setAccountDetails] = useState({
    userName: (memberData && memberData.member.userName) || "",
    email: (memberData && memberData.member.email) || "",
    memberType: (memberData && memberData.member.memberType) || "",
    company: (memberData && memberData.member.memberCompanyName) || "",
    personalTaxRego: (memberData && memberData.member.personalTaxRego) || "",
    companyRego: (memberData && memberData.member.companyRego) || "",

    firstName: (memberData && memberData.member.firstName) || "",
    lastName: (memberData && memberData.member.lastName) || "",
    occupation: (memberData && memberData.member.occupation) || "",
    dob: (memberData && memberData.member.dob) || "",
    address1: (memberData && memberData.member.address1) || "",
    address2: (memberData && memberData.member.address2) || "",
    suburb: (memberData && memberData.member.suburb) || "",
    stateID: (memberData && memberData.member.state.stateID) || "",
    countryID: (memberData && memberData.member.country.countryID) || "",
    postalCode: (memberData && memberData.member.postalCode) || "",
    publicProfile: (memberData && memberData.member.publicProfile) || "",
    timezoneID: (memberData && memberData.member.timezoneID) || "",
    currencyID: (memberData && memberData.member.currencyID) || "",
    allowedMarketing: (memberData && memberData.member.allowedMarketing) || "",
    linkedInURL: (memberData && memberData.member.linkedInURL) || "",
    facebookURL: (memberData && memberData.member.facebookURL) || "",
    twitterURL: (memberData && memberData.member.twitterURL) || "",
    instagramURL: (memberData && memberData.member.instagramURL) || "",
    tiktokURL: (memberData && memberData.member.tiktokURL) || "",
    weChatURL: (memberData && memberData.member.weChatURL) || "",
    image: (memberData && memberData.member.image) || "",
    checkImage: (memberData && memberData.member.image) || "",
    // accountActive: (memberData && memberData.member.accountActive) || "",
    accountActive: false || "",

    notification: [],
    // includeFeesOnDonation: (memberData && memberData.member.includeFeesOnDonation) || '',
    // receiveEmail: false,
    // receiveNewsLetter: false,
    // receiveNewsUpdates: false,
    // receiveFriendsUpdate: false,

    currentPassword: "",
    currentPasswordView: false,
    newPassword: "",
    newPasswordView: false,
    newPassword2: "",
    newPassword2View: false,
  });

  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordValid2, setPasswordValid2] = useState(true);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [memberNotification, setMemberNotification] = useState([]);

  useEffect(() => {
    if (passwordRegex.test(accountDetails.newPassword)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [accountDetails.newPassword]);

  useEffect(() => {
    if (accountDetails.newPassword2.length > 0) {
      if (accountDetails.newPassword === accountDetails.newPassword2) {
        setPasswordValid2(true);
      } else {
        setPasswordValid2(false);
      }
    }
  }, [accountDetails.newPassword2]);

  useEffect(() => {
    let notsData = [];

    notificationList &&
      notificationList.map((notis, index) => {
        if (memberNotification.includes(notis.notificationID)) {
          notsData[index] = notis.notificationID;
        }
      });
    setAccountDetails({
      ...accountDetails,
      notification: notsData,
    });
  }, [memberNotification, notificationList]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getAllInterest();
    getMemberInterest();
    getAllState();
    getAllCurrency();
    getAllTimezone();
    getAllNotification();
    getMemberNotification();
  }, []);

  const getAllInterest = () => {
    httpClient
      .get("tags")
      .then(({ data }) => {
        if (data.success) {
          setAllTags(data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getMemberInterest = () => {
    httpClient
      .get("member-interest/list")
      .then(({ data }) => {
        if (data.success) {
          setTags(data.data[0].tagID);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getAllState = () => {
    httpClient
      .get("state")
      .then(({ data }) => {
        if (data.success) {
          setStateList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getAllCurrency = () => {
    httpClient
      .get("currency")
      .then(({ data }) => {
        if (data.success) {
          setCurrencyList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getAllTimezone = () => {
    httpClient.get("timezone").then(({ data }) => {
      if (data.success) {
        setTimezoneList(data.data);
      }
    });
  };

  const getAllNotification = () => {
    setLoadingNotification(true);
    httpClient
      .get("notifications")
      .then(({ data }) => {
        if (data.success) {
          setNotificationList(data.data);
          setLoadingNotification(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getMemberNotification = () => {
    httpClient
      .get("member-notification/list")
      .then(({ data }) => {
        if (data.success) {
          let newData = [];

          data.data.map((not) => {
            newData.push(not.notificationID);
          });

          setMemberNotification(newData);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountDetails({
      ...accountDetails,
      [name]: value,
    });
  };

  const handleSavePassword = (e) => {
    setLoading(true);
    httpClient
      .post("change-password", {
        oldPassword: accountDetails.currentPassword,
        newPassword: accountDetails.newPassword,
        confirmPassword: accountDetails.newPassword2,
      })
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setAccountDetails({
            ...accountDetails,
            currentPassword: "",
            newPassword: "",
            newPassword2: "",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleDeleteTag = (data) => {
    // console.log("data", data);
    let res = tags.filter((tag) => tag.tagID !== data.tagID);
    setTags(res);
  };

  const handleDrop = (file) => {
    // console.log("file", file);
    setAccountDetails({
      ...accountDetails,
      image: file[0],
    });
  };

  const handleResetImage = () => {
    setAccountDetails({
      ...accountDetails,
      image: "",
      checkImage: "",
    });
  };

  const handleUpdateMember = () => {
    var formData = new FormData();
    formData.append("userName", accountDetails.userName);
    formData.append("email", accountDetails.email);
    formData.append("memberType", accountDetails.memberType);
    formData.append("personalTaxRego", accountDetails.personalTaxRego);
    formData.append("companyRego", accountDetails.companyRego);
    formData.append("firstName", accountDetails.firstName);

    formData.append("lastName", accountDetails.lastName);
    formData.append("memberCompanyName", accountDetails.company);
    formData.append("occupation", accountDetails.occupation);
    formData.append("dob", accountDetails.dob);
    formData.append("address1", accountDetails.address1);
    formData.append("address2", accountDetails.address2);

    formData.append("suburb", accountDetails.suburb);
    formData.append("stateID", accountDetails.stateID);
    formData.append("countryID", accountDetails.countryID);
    formData.append("publicProfile", accountDetails.publicProfile);
    formData.append("timezoneID", accountDetails.timezoneID);
    formData.append("currencyID", accountDetails.currencyID);
    formData.append("allowedMarketing", accountDetails.allowedMarketing);
    // formData.append("includeFeesOnDonation", accountDetails.includeFeesOnDonation);

    formData.append("linkedInURL", accountDetails.linkedInURL);
    formData.append("facebookURL", accountDetails.facebookURL);
    formData.append("twitterURL", accountDetails.twitterURL);
    formData.append("instagramURL", accountDetails.instagramURL);
    formData.append("tiktokURL", accountDetails.tiktokURL);
    formData.append("wechatURL", accountDetails.weChatURL);
    // formData.append("accountActive", accountDetails.accountActive);

    formData.append("postalCode", accountDetails.postalCode);
    // formData.append("memberImageName", accountDetails.checkImage ? null : accountDetails.image);
    formData.append(
      "memberImageName",
      accountDetails.checkImage !== "" ? "" : accountDetails.image
    );

    httpClient
      .post(`update-account`, formData)
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          memberData.member = data.data;

          //console.log("member data", memberData);
          localStorage.setItem("login", JSON.stringify(memberData));
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });

    var tagArr = [];
    tags.map((tag) => {
      tagArr.push(tag.tagID);
    });
    httpClient
      .post("member-interest/update", {
        tagID: tagArr,
      })
      .then(({ data }) => {
        if (data.success) {
          console.log("interest updated successfully");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleClickNotification = (e, notification, index) => {
    const { checked } = e.target;
    if (checked === true) {
      accountDetails.notification[index] = notification.notificationID;
    } else {
      accountDetails.notification[index] = null;
    }
    setAccountDetails({
      ...accountDetails,
      notification: accountDetails.notification,
    });
  };

  const handleCheckActive = (e) => {
    const { checked } = e.target;

    setAccountDetails({
      ...accountDetails,
      accountActive: checked,
    });
  };

  const handleDeactivate = (e) => {
    httpClient.get("activate-deactivate").then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        // memberData.member = data.data;

        // localStorage.setItem("login", JSON.stringify(memberData));

        localStorage.clear();
        setTimeout(() => {
          navigate("/", { state: "reload" });
        }, 1000);
      } else {
        setOpen(true);
        setMessageState("error");
        setMessage(
          typeof data.message === "string" ? data.message : data.message[0]
        );
      }
    });
  };

  const handleSaveNotification = () => {
    httpClient
      .post("member-notification/update", {
        notificationID: accountDetails.notification,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage("Account notifications updated successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  //console.log("accounbt", accountDetails);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero for months and days less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }


  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="Account Settings"
      />
      <AccountDiv>
        <Container maxWidth="xl">
          <h2>Account Settings</h2>
          <Section1>
            <h4>Personal Information</h4>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      value={accountDetails.firstName}
                      name="firstName"
                      label="First Name"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      value={accountDetails.lastName}
                      name="lastName"
                      label="Last Name"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      value={accountDetails.occupation}
                      name="occupation"
                      label="Occupation"
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      value={accountDetails.userName}
                      name="userName"
                      label="Username"
                      onChange={handleChange}
                    />
                  </Grid> */}

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      value={accountDetails.email}
                      name="email"
                      label="Email / Username"
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Member type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.memberType}
                        label="Member type"
                        name="memberType"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select member type</MenuItem>
                        <MenuItem value={"0"}>Individual</MenuItem>
                        <MenuItem value={"1"}>Company</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {accountDetails.memberType === "1" && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        value={accountDetails.company}
                        name="company"
                        label="Company"
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                  {accountDetails.memberType === "0" && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        value={accountDetails.personalTaxRego}
                        name="personalTaxRego"
                        label="Personal Tax Rego"
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                  {accountDetails.memberType === "1" && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        value={accountDetails.companyRego}
                        name="companyRego"
                        label="Company Rego"
                        onChange={handleChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={accountDetails.address1}
                      name="address1"
                      label="Address 1"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={accountDetails.address2}
                      name="address2"
                      label="Address 2"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.countryID}
                        label="Country"
                        name="countryID"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select Country</MenuItem>
                        <MenuItem value={13}>Australia</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.stateID}
                        label="State"
                        name="stateID"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {stateList &&
                          stateList.map((state) => (
                            <MenuItem key={state.stateID} value={state.stateID}>
                              {state.stateName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={accountDetails.suburb}
                      name="suburb"
                      label="Suburb"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {/* <TextField
                      fullWidth
                      helperText="Enter your date of birth"
                      value={accountDetails.dob}
                      name="dob"
                      label="Date of Birth"
                      type="date"
                      onChange={handleChange}
                      sx={{ width: "100%" }}
                      InputLabelProps={{ shrink: true }}
                    /> */}

                    <TextField
                      fullWidth
                      helperText="Enter your date of birth"
                      value={accountDetails.dob}
                      name="dob"
                      label="Date of Birth"
                      type="date"
                      onChange={handleChange}
                      sx={{ width: "100%" }}
                      InputLabelProps={{ shrink: true }}
                      // Set max attribute to the current date to disable future dates
                      inputProps={{ max: getCurrentDate() }}
                    />

                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={accountDetails.postalCode}
                      name="postalCode"
                      label="Postal Code"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Timezone
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.timezoneID}
                        label="Timezone"
                        name="timezoneID"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select Timezone</MenuItem>
                        {timezoneList &&
                          timezoneList.map((zone) => (
                            <MenuItem
                              key={zone.timezoneID}
                              value={zone.timezoneID}
                            >
                              {zone.timezoneName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Currency
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.currencyID}
                        label="Currency"
                        name="currencyID"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select currency</MenuItem>
                        {currencyList &&
                          currencyList.map((currency) => (
                            <MenuItem
                              key={currency.currencyID}
                              value={currency.currencyID}
                            >
                              {currency.currencyName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Account Active?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.accountActive}
                        label="Account Active?"
                        name="accountActive"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Public Profile
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.publicProfile}
                        label="Public Profile"
                        name="publicProfile"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Allowed Marketing?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.allowedMarketing}
                        label="Allowed Marketing?"
                        name="allowedMarketing"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Include Fees on Donation?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={accountDetails.includeFeesOnDonation}
                        label="Include Fees on Donation?"
                        name="includeFeesOnDonation"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      multiple
                      options={allTags}
                      getOptionLabel={(option) => option.tagName}
                      value={tags}
                      onChange={(e, tags) => setTags(tags)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          // placeholder="Tags"
                          label="Interest"
                        />
                      )}
                    />
                    <Box
                      mt={1}
                      sx={{
                        "& > :not(:last-child)": { marginRight: 1 },
                        "& > *": { marginBottom: 1 },
                      }}
                    >
                      {tags.map((tag) => (
                        <Chip
                          // icon={<Close fontSize="small" style={{ color:"#fff" }} />}
                          key={tag.tagID}
                          label={tag.tagName}
                          onDelete={() => handleDeleteTag(tag)}
                        // style={{ background: tag.color, color:"#fff", cursor:"pointer" }}
                        />
                      ))}
                    </Box>
                  </Grid>

                  {/* Social URLS */}
                  <Grid item xs={1}>
                    <SocialImage>
                      <img src={LinkedIn} alt={"linkedin icon"} />
                    </SocialImage>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      value={accountDetails.linkedInURL}
                      placeholder="linkedin.com/username"
                      name="linkedInURL"
                      label="Linkedin URL"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <SocialImage>
                      <img src={Facebook} alt={"facebook icon"} />
                    </SocialImage>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      value={accountDetails.facebookURL}
                      placeholder="facebook.com/username"
                      name="facebookURL"
                      label="Facebook URL"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <SocialImage>
                      <img src={Twitter} alt={"twitter icon"} />
                    </SocialImage>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      value={accountDetails.twitterURL}
                      placeholder="twitter.com/username"
                      name="twitterURL"
                      label="Twitter URL"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <SocialImage>
                      <img src={Instagram} alt={"instagram icon"} />
                    </SocialImage>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      value={accountDetails.instagramURL}
                      placeholder="instagram.com/username"
                      name="instagramURL"
                      label="Instagram URL"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <SocialImage>
                      <img src={Wechat} alt={"wechat icon"} />
                    </SocialImage>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      value={accountDetails.weChatURL}
                      placeholder="wechat.com/username"
                      name="weChatURL"
                      label="Wechat URL"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <SocialImage>
                      <img src={Tiktok} alt={"tiktok icon"} />
                    </SocialImage>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      value={accountDetails.tiktokURL}
                      placeholder="tiktok.com/username"
                      name="tiktokURL"
                      label="Tiktok URL"
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Social URLS */}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <DropzoneDiv>
                  <Dropzone
                    onDrop={handleDrop}
                  // accept="image/png, image/gif image/jpg" //whatever the file type needed
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                      rejectedFiles,
                    }) => {
                      return (
                        <div>
                          {accountDetails.image ? (
                            accountDetails.image.type ? (
                              <img
                                src={URL.createObjectURL(accountDetails.image)}
                                //   className={classes.imgClass}
                                alt={accountDetails.image.name}
                              />
                            ) : (
                              <img
                                src={accountDetails.image}
                                //   className={classes.imgClass}
                                alt={accountDetails.name}
                              />
                            )
                          ) : (
                            <UploadDiv {...getRootProps()}>
                              <input {...getInputProps()} />
                              <AddPhotoAlternate color="primary" />
                              <p>Upload Image here</p>
                            </UploadDiv>
                          )}
                          <div
                            style={{ textAlign: "center", marginTop: "5px" }}
                          >
                            <ResetButton
                              onClick={() =>
                                setAccountDetails({
                                  ...accountDetails,
                                  image: "",
                                  checkImage: "",
                                })
                              }
                            >
                              {" "}
                              Reset Image
                            </ResetButton>
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>
                </DropzoneDiv>
              </Grid>

              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} textAlign="right" sx={{ marginTop: "50px" }}>
                <SaveButton onClick={handleUpdateMember}>
                  Save Changes
                </SaveButton>
              </Grid>
            </Grid>
          </Section1>

          <Section1>
            <h4>Notifications</h4>
            <FormGroup>
              {!loadingNotification ? (notificationList.length > 0 ? (
                notificationList.map((notification, index) => (
                  <FormControlLabel
                    key={notification.notificationID}
                    control={
                      <Checkbox
                        value={
                          accountDetails.notification[
                          notification.notificationID
                          ]
                        }
                        checked={
                          accountDetails.notification[index] ===
                            notification.notificationID
                            ? true
                            : false
                        }
                        onClick={(e) =>
                          handleClickNotification(e, notification, index)
                        }
                      />
                    }
                    label={notification.title}
                  />
                ))) : "No Notification Options!" ) :               
                <>
                  Fetching Notification Options..
                </>
              }


              {/* <FormControlLabel
                control={<Checkbox value={accountDetails.receiveNewsLetter} />}
                label="Recieve Final Hour newsletter"
              />
              <FormControlLabel
                control={<Checkbox value={accountDetails.receiveNewsUpdates} />}
                label="Recieve email for any Followed Causes News updates"
              />
              <FormControlLabel
                control={
                  <Checkbox value={accountDetails.receiveFriendsUpdate} />
                }
                label="Recieve email for any Friends Updates"
              /> */}
            </FormGroup>

            <Box textAlign={"right"} mt={5}>
              <SaveButton onClick={handleSaveNotification}>
                Save Changes
              </SaveButton>
            </Box>
          </Section1>

          <Section1>
            <h4>Change Password</h4>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <PasswordDiv>
                  <TextField
                    fullWidth
                    type={
                      accountDetails.currentPasswordView ? "text" : "password"
                    }
                    value={accountDetails.currentPassword}
                    name="currentPassword"
                    label="Current Password"
                    onChange={handleChange}
                  />
                  <RemoveRedEye
                    onClick={() =>
                      setAccountDetails({
                        ...accountDetails,
                        currentPasswordView:
                          !accountDetails.currentPasswordView,
                      })
                    }
                  />
                </PasswordDiv>
              </Grid>
              <Grid item xs={12} md={3}>
                <PasswordDiv>
                  <TextField
                    fullWidth
                    type={accountDetails.newPasswordView ? "text" : "password"}
                    value={accountDetails.newPassword}
                    name="newPassword"
                    label="New Password"
                    onChange={handleChange}
                    helperText="Password must contain 8 lettes, 1 Special character, 1 Uppercase and 1 Number"
                    color={passwordValid ? "primary" : "error"}
                  />
                  <RemoveRedEye
                    onClick={() =>
                      setAccountDetails({
                        ...accountDetails,
                        newPasswordView: !accountDetails.newPasswordView,
                      })
                    }
                  />
                </PasswordDiv>
              </Grid>
              <Grid item xs={12} md={3}>
                <PasswordDiv>
                  <TextField
                    fullWidth
                    type={accountDetails.newPassword2View ? "text" : "password"}
                    value={accountDetails.newPassword2}
                    name="newPassword2"
                    label="Enter Password Again"
                    color={passwordValid2 ? "primary" : "error"}
                    onChange={handleChange}
                    helperText={
                      passwordValid2 ? "" : "Password does not match!"
                    }
                  // helperText={(accountDetails.newPassword2.length > 0 && (accountDetails.newPassword === accountDetails.newPassword2)) ? "" : "Password does not match!"}
                  />
                  <RemoveRedEye
                    onClick={() =>
                      setAccountDetails({
                        ...accountDetails,
                        newPassword2View: !accountDetails.newPassword2View,
                      })
                    }
                  />
                </PasswordDiv>
              </Grid>
              <Grid item xs={12} textAlign="right" sx={{ marginTop: "20px" }}>
                {loading ? (
                  <SaveButton2 disabled={true}>
                    <CircularProgress
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <span>Loading</span>
                  </SaveButton2>
                ) : (
                  <SaveButton2
                    type="button"
                    disabled={
                      accountDetails.currentPassword &&
                        passwordValid &&
                        passwordValid2
                        ? false
                        : true
                    }
                    onClick={handleSavePassword}
                  >
                    Save Changes
                  </SaveButton2>
                )}
              </Grid>
            </Grid>
          </Section1>

          <Section1>
            <h4>Deactivate your account</h4>
            <p style={{ marginBottom: "40px" }}>
              Deactivating your account will result in the deletion of all your
              settings, donations, and cancellation of your payment schedule.
            </p>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={accountDetails.accountActive}
                    checked={accountDetails.accountActive}
                    onClick={(e) => handleCheckActive(e)}
                  />
                }
                label={"Deactivate"}
              />
            </FormGroup>

            <Box textAlign={"right"} mt={5}>
              <SaveButton onClick={handleDeactivate}>Deactivate Now</SaveButton>
            </Box>
          </Section1>
        </Container>
      </AccountDiv>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccountSettings;
