import { Add, Check, Close, PersonAdd } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import React from "react";
import BlankImage from "./../../../images/no-pictures.png";

const CauseDiv = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  padding: "20px",
  color: "#fff",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  "& img": {
    objectFit: "cover",
    width: "220px",
    height: "220px",
    borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
      height: "150px",
      width: "150px",
    },
  },
  "& p": {
    margin: "0",
    fontWeight: "600",
    fontSize: "26px"
  },
  "& p1": {
    margin: "0",
    fontWeight: "500",
    fontSize: "22px",
    color: "black"
  },
  "& p2": {
    margin: "0",
    fontWeight: "400",
    fontSize: "18px",
    color: "#524c50"
  },
  "& small": {
    color: theme.palette.primary.greyText,
  },
}));



const NoImage = styled("div")(({ theme }) => ({
  background: "#fff",
  width: "220px",
  height: "220px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  "& img": {
    height: "120px !important",
    width: "120px !important",
    padding: "20px",
    borderRadius: "0 !important",
  },
  [theme.breakpoints.down("md")]: {
    height: "150px",
    width: "150px",
    //marginLeft:"30%",
    
  },
}));

const MyFriendsBox = (props) => {

  const AddBox = styled("div")(({ theme }) => ({
    position: "absolute",
    display: "flex",
    right: "20px",
    top: "20px",
    borderRadius: "50%",
    padding: "5px",
    color: props.messageState === "success" ? "#fff" : "#333",
    cursor: "pointer",
    backgroundColor: props.messageState === "success" ? "green" : "#fff",
    [theme.breakpoints.down("md")]: {
      right: "10px",
      top: "10px",
    }, "& span": {
      fontSize: "25px",
      lineHeight: "1"
    },
  }));

  const RemoveBox = styled("div")(({ theme }) => ({
    position: "absolute",
    display: "flex",
    right: props.currentValue === "request" ? "-25px" : "20px",
    top: "20px",
    borderRadius: "50%",
    padding: props.currentValue === "request" ? "7px" : "5px",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#808080",
    [theme.breakpoints.down("md")]: {
      //right: "10px",
      right: props.currentValue === "request" ? "-25px" : "20px",
      top: "10px",
    },
  }));

  const Line = styled("div")(({ theme }) => ({
    background: "#ffffff",
    width: "65%",
    margin: "10px auto",
    height: "2px",
    borderRadius: "5px",
  }));

  return (
    <CauseDiv>
      {props.currentValue === "member" && (
        <RemoveBox>
          <Tooltip title="Remove connection">
            <Close fontSize="small" onClick={props.handleDelete} />
          </Tooltip>
        </RemoveBox>
      )}

      {(props.currentValue === "pending" || props.currentValue === "request") && (
        <RemoveBox>
          <Tooltip title="Cancel Request">
            <Close fontSize="small" onClick={props.handleCancelRequest} />
          </Tooltip>
        </RemoveBox>
      )}

      {props.currentValue === "request" &&
        (props.messageState === "success" ? (
          <AddBox>
            <Tooltip title="Friend request accepted">
              <Check />
            </Tooltip>
          </AddBox>
        ) : (
          <AddBox>
            <Tooltip title="Accept request">
              <PersonAdd onClick={props.handleAcceptRequest} />
            </Tooltip>
          </AddBox>
        ))}

      {(props.memberList && props.friend !== 1) &&
        (props.messageState === "success" ? (
          <AddBox>
            <Tooltip title="Connection request sent">
              <Check />
            </Tooltip>
          </AddBox>
        ) : (
          props.handleAdd && (
            <AddBox>
              <Tooltip title="Connect with member">
                <Add onClick={props.handleAdd} />
              </Tooltip>
            </AddBox>
          )
        ))}

      <div onClick={props.handleClick} style={{ cursor: "pointer" }}>
        {props.image === "" ? (
          <NoImage>
            <img src={BlankImage} alt={props.memberName} />
          </NoImage>
        ) : (
          <img src={props.image} alt={props.memberName} />
        )}
        <p>{props.memberName.length > 14
          ? `${props.memberName.substring(0, 14)}...`
          : props.memberName}</p>
        <p1>{props.occupation ? props.occupation : "Occupation"}</p1><br />
        <p2>{props.city ? props.city.length > 16 ? `${props.city.substring(0, 14)}...` : props.city  : "Address, Address"}</p2>

        {/* <small>
          {props.city}
          {props.hours && (
            <>
              , <strong> {props.hours}</strong> hours
            </>
          )}
        </small> */}
        {/* {props.hours ? (
        <>
          <Line></Line>
          <span>
            <strong>{props.hours }</strong> hours
          </span>
        </>
       ) : (
        ""
      )
      }  */}
        <>
          <Line></Line>
          <span>
            <strong>{props.hours || 0}</strong> hours
          </span>
        </>
      </div>
    </CauseDiv>
  );
};

export default MyFriendsBox;
