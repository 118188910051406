import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/BreadCrumb";
import { Container, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OrgBox from "../OrganizationPage/OrgBox";
import FeatureBox1 from "../../Components/Causes/FeatureCauses/FeatureBox1";
import MyFriendsBox from "../../Components/Dashboard/MyFriendsBox";

const SearchListSection = styled("div")(({ theme }) => ({
  background: "#3B8775",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(5,1fr)",
  display: "flex",
  gap: "10px",
  width: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    //gridTemplateColumns: "1fr",
    //gridGap: "20px",
    gap: "50px"
  },
}));

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [allData, setAllData] = useState("");
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setAllData(location.state.data);
    setSearch(location.state.search);
    setType(location.state.type);
  }, [location.state]);

  const handleClickCause = (cause) => {
    // setOpenCauseDialog(true);
    var newSlug = cause.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: cause });
  };

  const handleClickMember = (member) => {
    // var newSlug = (member.firstName + " " + member.lastName)
    //   .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    //   .toLowerCase();
    navigate(`/members/detail/${member.slug}`, { state: member });
  };

  const handleClickOrg = (org) => {
    // var newSlug = org.orgName
    //   .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
    //   .toLowerCase();
    navigate(`/charity_partners/detail/${org.slug}`, { state: org });
  };

  console.log("search location", location.state);

  return (
    <div>
      <BreadCrumb
        header="Search"
        subHeader1="Home"
        Link1="/"
        subHeader2="Search"
      />
      <SearchListSection>
        <Container maxWidth="xl">
          <div style={{ color: "#fff", paddingBottom: "20px" }}>
            <h3>{type?.charAt(0).toUpperCase() + type?.slice(1)} : {search}</h3>
          </div>
          <GridDiv>
            {allData && allData.data.length === 0 && "No result found"}
            {type === "members" &&
              allData &&
              allData.data.map((member, index) => (
                <MyFriendsBox
                  key={member.memberID}
                  image={member.image}
                  memberName={member.firstName + " " + member.lastName}
                  city={
                    member.address1 +
                    (member.address2 && ", " + member.address2)
                  }
                  occupation={member.occupation}
                  // handleAdd={() => handleAdd(member.memberID)}
                  friend={member.friend}
                  // messageState={
                  //   memberArr.includes(member.memberID) ? "success" : false
                  // }
                  handleClick={() => handleClickMember(member)}
                  memberList={true}
                />
              ))}

            {type === "causes" &&
              allData &&
              allData.data.map((cause) => (
                <FeatureBox1
                  key={cause.causeID}
                  image={cause.image}
                  name={cause.name}
                  hours={cause.hours}
                  members={cause.totalMembers}
                  handleClickCause={() => handleClickCause(cause)}
                />
              ))}

            {type === "organization" &&
              allData &&
              allData.data.map((orgs) => (
                <OrgBox
                  image={orgs.orgLogoName}
                  name={orgs.orgName}
                  hours={orgs.hours}
                  members={orgs.members}
                  handleClickCause={() => handleClickOrg(orgs)}

                // liked={orgs.favorite}
                // favourite={true}
                />
              ))}
          </GridDiv>
        </Container>
      </SearchListSection>
    </div>
  );
};

export default SearchPage;
