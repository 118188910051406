import { styled } from "@mui/material";
import React from "react";
import { httpClient } from "../../../../appUtility/Api";
import axios from "axios";

const DonationDiv = styled("div")(({ theme }) => ({
  padding: "10px",
  borderBottom: "1px solid #000",
  display: "grid",
  gridTemplateColumns: "0.5fr 2fr 1fr",
  gridGap: "10px",
  "& h5": {
    margin: "0",
    cursor: "pointer",
  },
  "& img": {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr"
  },
}));

const RightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  gap: "40px",
  [theme.breakpoints.down("md")]: {
    gap: "10px"
  },
}));

const DownloadBlock = styled("small")(({ theme }) => ({
  cursor: "pointer",
  textDecoration: "underline",
  color: "#fff",
  "&:hover": {
    color: theme.palette.primary.red,
  },
}));

const MainDonationBox = (props) => {

  const handleDownload = (invoiceID) => {
    const loginData = JSON.parse(localStorage.getItem("token"));
    const token = loginData && loginData;

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF ",
      Authorization: `Bearer ${token}`,
    };

    const config = {
      headers: headers,
      responseType: "blob", // Set the desired response type here
    };

    const data = {
      invoiceID: invoiceID
    }

    httpClient
      .post(
        `member-tax-receipt`,
        data,
        config
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `tax_receipt_${invoiceID}.pdf`; // Set the desired file name
        link.click();
      });
  };

  return (
    <DonationDiv>
      <div>
        <img src={props.info.cause.image} />
      </div>
      <div>       
        <span>{props.date}</span>
        <h5 onClick={props.handleClickCause}>{props.cause}</h5>
      </div>
      <RightDiv>
        <DownloadBlock onClick={() => handleDownload(props.invoiceID)}>
          Download Receipt
        </DownloadBlock>
        <span>{props.hours}/{props.frequency}</span>
        <span>{props.amount}</span>
      </RightDiv>
    </DonationDiv>
  );
};

export default MainDonationBox;
