import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Snackbar,
  styled,
  TextField,
  useTheme,
  useMediaQuery,
  Autocomplete,
  InputAdornment,
  CircularProgress,
  Input
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { httpClient } from "../../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CharityDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#905932",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const ApplyButton = styled("button")(({ theme }) => ({
  marginTop: "100px",
  padding: "15px 35px",
  color: "#fff",
  borderRadius: "25px",
  background: theme.palette.primary.red,
  outline: "none",
  border: "1px solid transparent",
  transition: "0.3s",
  fontWeight: "700",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
  "&:hover": {
    background: "#fff",
    // border: `1px solid ${theme.palette.primary.red}`,
    color: theme.palette.primary.red,
  },
}));

const ApplyDiv = styled("div")(({ theme }) => ({
  paddingTop: "100px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
  },
  "& h4": {
    padding: "30px 0",
    fontWeight: "300",
    [theme.breakpoints.down("md")]: {
      padding: "10px 0",
    },
  },
  "& .MuiInputBase-root": {
    background: "#F4AC77",
  },
  "& .MuiInputBase-multiline": {
    background: "#F4AC77",
  },
  "& .MuiSelect-select": {
    background: "#F4AC77",
  },
  "& .MuiPaper-root": {
    background: "#F4AC77",
  },
}));

const NoteDiv = styled("div")(({ theme }) => ({
  paddingTop: "0px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
  },
  "& p": {
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      padding: "10px 0",
    },
  },
  "& input": {
    background: "#F4AC77",
  },
}));

const ImageDiv = styled("div")(({ theme }) => ({
  "& img": {
    width: "100%",
    height: "300px",
    objectfit: "cover",
  },
}));

const SaveButton = styled("button")(({ theme }) => ({
  border: "1px solid transparent !important",
  outline: "none",
  borderRadius: "25px",
  padding: "10px 30px",
  background: theme.palette.primary.red,
  color: "#fff",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.red,
    background: "#fff",
  },
}));

const ForWorplace = () => {
  const [openApply, setOpenApply] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [workplaceData, setWorkplaceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingABN, setLoadingABN] = useState(false);
  const [loadingACN, setLoadingACN] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const navigate = useNavigate();
  const [workplace, setWorkplace] = useState({
    employerName: "",
    ABN: "",
    ACN: "",
    website_url: "",
    contact_name: "",
    contact_email: "",
    phone_number: "",
    attachment: null,
    message: "",
  });

  const handleSearchFilter = (value) => {

    setWorkplace({
      ...workplace,
      employerName: value !== null ? (value['Company Name'] || "") : "",
      ABN: value !== null ? (value['ABN'] || "") : "",
      ACN: value !== null ? (value['ACN'] || "") : "",

    });
  };

  useEffect(() => {
    getWorkplaceCMSData();
  }, []);

  const handleApply = () => {
    setOpenApply((prev) => !prev);
  };

  const getWorkplaceCMSData = () => {
    setLoading(true);
    httpClient.get("become-partner/workplace").then(({ data }) => {
      if (data.success) {
        setWorkplaceData(data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkplace({
      ...workplace,
      [name]: value,
    });

    if (name === "ABN" || name === "ACN" || name === "employerName") {
      if (name === "ABN") {
        setLoadingABN(true);
      }
      if (name === "ACN") {
        setLoadingACN(true);
      }
      if (name === "employerName") {
        setLoadingNew(true);
      }
      let formData = new FormData();
      formData.append("fieldValue", value);
      formData.append("limit", 30);
      formData.append("fieldName", name);
      httpClient.post('company/search', formData).then(({ data }) => {
        if (data.responseCode === 200) {
          setLoadingNew(false);
          setLoadingABN(false);
          setLoadingACN(false);
        }

        if (data.success === true) {
          setCompanyData(data.result.records);
          setLoadingNew(false);
          setLoadingABN(false);
          setLoadingACN(false);
        }
      })
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    //console.log("ff", file);
    if (file) {
      setWorkplace({
        ...workplace,
        attachment: file,
      });
    }
  };
  //console.log("ww", workplace);
  const handleSubmit = () => {
    setLoading(true);

    // Create a FormData object
    const formData = new FormData();
    formData.append('workplace_name', workplace.employerName);
    formData.append('abn', workplace.ABN);
    formData.append('acn', workplace.ACN);
    formData.append('website_url', workplace.website_url);
    formData.append('contact_name', workplace.contact_name);
    formData.append('contact_email', workplace.contact_email);
    formData.append('phone_number', workplace.phone_number);
    formData.append('message', workplace.message);

    // Append the file
    if (workplace.attachment) {
      formData.append('attachment', workplace.attachment);
    }

    httpClient
      .post("apply/workplace", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        //console.log("data", data);
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          setOpenNote(true);
        }
        if (data.success === false) {
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          // setMessage(data.message[0] !== "T" ? data.message[0] : data.message);
          setOpen(true);
          setMessageState("error");
          setLoading(false);
        }
      });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CharityDiv>
      <Container maxWidth="xl">
        <h2>
          {loading ? (
            <Skeleton animation="wave" width={300} />
          ) : (
            workplaceData && workplaceData.title
          )}
        </h2>
        <Grid container spacing={6}>
          {isMobile &&
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img
                        src={
                          // "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          workplaceData && workplaceData.image1
                        }
                      />
                    </ImageDiv>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img
                        src={
                          // "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          workplaceData && workplaceData.image2
                        }
                      />
                    </ImageDiv>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item xs={12} md={6}>
            {/* <p>
              For Workplaces the Final Hour presents an opportunity to engage
              supporters in a more meaningful giving process.
            </p> */}

            {loading ? (
              <p>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </p>
            ) : (
              parse((workplaceData && workplaceData.body) || "")
            )}

            <Box>
              {!openNote ? (
                <ApplyButton onClick={handleApply}>
                  {!openApply ? "Apply Now" : "Dismiss"}
                </ApplyButton>
              )
                : null}
            </Box>
          </Grid>
          {!isMobile &&
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img
                        src={
                          // "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          workplaceData && workplaceData.image1
                        }
                      />
                    </ImageDiv>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img
                        src={
                          // "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          workplaceData && workplaceData.image2
                        }
                      />
                    </ImageDiv>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item xs={12}>
            {!openNote && openApply && (
              <ApplyDiv>
                <h2>Apply Now</h2>

                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <h4>
                      All Charity
                      Partners available on The Final Hour platform are available through the
                      workplace giving program.
                    </h4>
                  </Grid>
                  <Grid item md={6} xs={12}></Grid>

                  {/* <Grid item md={3} xs={12}>
                    <InputLabel id="demo-simple-select-label">
                      Charity Tax Status
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={preference.workTime}
                        // label="Charity Tax Status"
                        // name="workTime"
                        // onChange={handleChange}
                      >
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="Processing">Processing</MenuItem>
                        <MenuItem value="Remaining">Remaining</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <InputLabel id="demo-simple-select-label">
                      Charity / Cause Category
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={preference.workTime}
                        // label="Charity Tax Status"
                        // name="workTime"
                        // onChange={handleChange}
                      >
                        <MenuItem value="New Cause">New Cause</MenuItem>
                        <MenuItem value="Test">Test</MenuItem>
                        <MenuItem value="Remaining">Remaining</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}></Grid>

                  <Grid item md={3} xs={12}>
                    <InputLabel>ABN #</InputLabel>
                    <TextField name="ABN" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item md={9} xs={12}></Grid>
*/}
                  <Grid item md={4} xs={12}>
                    <InputLabel>Workplace Name *</InputLabel>
                    {/* <TextField
                      name="workplace_name"
                      variant="outlined"
                      value={workplace.workplace_name}
                      onChange={handleChange}
                      //helperText="This should be the same entity name associated with your ABN and DGR status."
                      fullWidth
                    /> */}

                    <Autocomplete
                      disablePortal
                      inputValue={workplace.employerName}
                      //options={rows.length > 0 ? rows : []}
                      options={companyData}
                      getOptionLabel={(option) => (option && option['Company Name']) || null}

                      renderOption={(props, option, index) => { return <li {...props} key={option._id}>{option && option['Company Name']}</li> }}

                      onChange={(event, newValue) => {
                        handleSearchFilter(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}

                          name="employerName"
                          //label="Company Name"
                          placeholder="Company Name"
                          value={workplace.employerName || ""}
                          onChange={handleChange}
                          helperText={
                            workplace.employerName === "" ?
                              "Add characters to get search results"
                              : ""
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingNew && (
                                  <InputAdornment position="end">
                                    <CircularProgress size={25} />
                                  </InputAdornment>
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel>ABN *</InputLabel>
                    <Autocomplete
                      disablePortal
                      inputValue={workplace.ABN}
                      //options={rows.length > 0 ? rows : []}
                      options={companyData}
                      getOptionLabel={(option) => (option && option['ABN']) || null}

                      renderOption={(props, option, index) => { return <li {...props} key={option._id}>{option && option['ABN']}</li> }}

                      onChange={(event, newValue) => {
                        handleSearchFilter(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          width="900px"
                          name="ABN"
                          //label="ABN"
                          placeholder="ABN"
                          value={workplace.ABN || ""}
                          onChange={handleChange}
                          helperText={
                            workplace.ABN === "" ?
                              "Add characters to get search results"
                              : ""
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingABN && (
                                  <InputAdornment position="end">
                                    <CircularProgress size={25} />
                                  </InputAdornment>
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel>ACN *</InputLabel>
                    <Autocomplete
                      disablePortal
                      inputValue={workplace.ACN}
                      //options={rows.length > 0 ? rows : []}
                      options={companyData}
                      getOptionLabel={(option) => (option && option['ACN']) || null}

                      renderOption={(props, option, index) => { return <li {...props} key={option._id}>{option && option['ACN']}</li> }}

                      onChange={(event, newValue) => {
                        handleSearchFilter(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          name="ACN"
                          //label="ACN"
                          placeholder="ACN"
                          value={workplace.ACN || ""}
                          onChange={handleChange}
                          helperText={
                            workplace.ACN === "" ?
                              "Add characters to get search results"
                              : ""
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingACN && (
                                  <InputAdornment position="end">
                                    <CircularProgress size={25} />
                                  </InputAdornment>
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel>Primary Contact Email *</InputLabel>
                    <TextField
                      name="contact_email"
                      value={workplace.contact_email}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth />
                  </Grid>


                  <Grid item md={4} xs={12}>
                    <InputLabel>Primary Contact Name *</InputLabel>
                    <TextField
                      name="contact_name"
                      value={workplace.contact_name}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel>Primary Contact Number *</InputLabel>
                    <TextField
                      name="phone_number"
                      value={workplace.phone_number}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>


                  <Grid item md={4} xs={12}>
                    <InputLabel>Website *</InputLabel>
                    <TextField
                      name="website_url"
                      value={workplace.website_url}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel>Proof of Registration(PDF) *</InputLabel>
                    <Input
                      type="file"
                      accept="application/pdf"
                      name="attachment"
                      onChange={handleFileChange}
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item md={8} xs={12}></Grid>

                  <Grid item md={3} xs={12}>
                    <InputLabel>Address</InputLabel>
                    <TextField name="address" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <InputLabel>Suburb</InputLabel>
                    <TextField name="suburb" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <InputLabel>PostCode</InputLabel>
                    <TextField name="postcode" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={preference.workTime}
                        // label="Charity Tax Status"
                        // name="workTime"
                        // onChange={handleChange}
                      >
                        <MenuItem value="State 1">State 1</MenuItem>
                        <MenuItem value="State 2">State 2</MenuItem>
                        <MenuItem value="State 3">State 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item md={8} xs={12}></Grid>
                  <Grid item md={8} xs={12}>
                    <InputLabel>Message *</InputLabel>
                    <TextField
                      multiline
                      rows={4}
                      name="message"
                      value={workplace.message}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid xs={8} sx={{ marginTop: "30px" }} textAlign="right">
                    <SaveButton onClick={handleSubmit}>Apply</SaveButton>
                  </Grid>
                </Grid>
              </ApplyDiv>
            )}
            {openNote && (
              <NoteDiv>
                <Grid item md={8} xs={12}>
                  <h1>Thank you!!</h1>
                  <p>Thank you so much for signing up with us! We truly appreciate your decision to join our community.</p>

                  <p> We're excited to have you on board, and we can't wait to connect with you soon. Our team will be in
                    touch shortly to provide you with all the necessary information and to answer any questions you may have.</p>

                  <p>Once again, thank you for becoming a part of our family. We look forward to a fruitful and fulfilling journey together.</p>
                </Grid>
              </NoteDiv>
            )}
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </CharityDiv>

  );
};

export default ForWorplace;
