import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import FriendBox from "./FriendBox";
import BlankImg from './../../../images/no-pictures.png';
import FriendBox1 from "./FriendBox1";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import MetaIcon from './../../../images/metaicon.png'
import Xicon from './../../../images/Xicon.png'
import LinkedinLogo from './../../../images/linkedinIcon.png'


const FriendsSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#B9A8B7",
  paddingRight: "3%",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    paddingRight: "0",
  },
}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#000",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& h2": {
    margin: "0",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c"
    }
  },
}));

const FriendsActivity = () => {
  const navigate = useNavigate();
  const [activityList, setActivityList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMemberActivity();
  }, [])

  const getMemberActivity = () => {
    setLoading(true);
    httpClient.get('member-connection/activity').then(({ data }) => {
      if (data.success) {
        setActivityList(data.data)
        setLoading(false);
      }
    }).catch((err) => {
      if (err.response.status === 500) {
        console.log('internal server error')
      }
    })
  }
  //console.log("acc", activityList)
  const handleView = () => {
    navigate("/dashboard/friends_activity");
  };

  return (
    <FriendsSection>
      <div className="leftContainerFull">
        <HeaderDiv>
          <h2>Friends Activity</h2>
          <button onClick={handleView}>View all</button>
        </HeaderDiv>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <span style={{ marginRight: "10px" }}>Share : </span>

          <FacebookShareButton
            url={`${window.location.origin}/dashboard/friends_activity`}
            size={30}
            style={{ marginRight: "5px" }}
          >
            <img src={MetaIcon} alt="meta_icon" style={{ height: "30px", width: "30px" }} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={`${window.location.origin}/dashboard/friends_activity`}
            style={{ marginRight: "5px" }}
          >
            <img src={LinkedinLogo} alt="linkedin_icon" style={{ height: "30px", width: "30px" }} />
          </LinkedinShareButton>
          <TwitterShareButton url={`${window.location.origin}/dashboard/friends_activity`}>
            <img src={Xicon} alt="X_icon" style={{ height: "30px", width: "30px" }} />
          </TwitterShareButton>
        </Box>
        <div>
          {!loading ? (
            activityList.length > 0 ? (
              activityList.map((activity, index) => (
                index <= 5 &&
                <FriendBox1
                  key={activity.id}
                  image={activity.memberImage ? activity.memberImage : BlankImg}
                  name={activity.memberName}
                  cause={activity.cause}
                  date={activity.date}
                  hours={activity.hours + " Hrs"}
                  amount={"$" + activity.amount}
                />
              ))) : "No Friends Activity") :
            <>
              Fetching Friends Activity..
            </>
          }

          {/* <FriendBox
            image={
              "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80"
            }
            name="Heny Blade"
            date="2022-09-18"
            hours="5 Hrs"
            amount="$320"
          />
          <FriendBox
            image={
              "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80"
            }
            name="Heny Blade"
            date="2022-09-18"
            hours="5 Hrs"
            amount="$320"
          />
          <FriendBox
            image={
              "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80"
            }
            name="Heny Blade"
            date="2022-09-18"
            hours="5 Hrs"
            amount="$320"
          />
          <FriendBox
            image={
              "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80"
            }
            name="Heny Blade"
            date="2022-09-18"
            hours="5 Hrs"
            amount="$320"
          />
          <FriendBox
            image={
              "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80"
            }
            name="Heny Blade"
            date="2022-09-18"
            hours="5 Hrs"
            amount="$320"
          /> */}
        </div>
      </div>
    </FriendsSection>
  );
};

export default FriendsActivity;
