import {
  Container,
  Grid,
  styled,
  Snackbar,
  Skeleton,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import DeleteDialog from "../../../Components/Dashboard/DeleteDialog";
import MyFriendsBox from "../../../Components/Dashboard/MyFriendsBox";
import MuiAlert from "@mui/material/Alert";
import BlankImg from './../../../images/no-pictures.png';
//import { useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import FriendSection1 from "./index1";
import FriendSection2 from "./index2";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FriendSection = styled("div")(({ theme }) => ({
  background: "#B891B3",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));


const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(5,1fr)",
  display: "flex",
  gap: "50px",
  width: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    //gridTemplateColumns: "1fr",
    //gridGap: "20px",
    gap: "50px"
  },
}));

const HeaderTabs = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  "& span": {
    cursor: "pointer",
    fontSize: "30px",
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
    "& span": {
      cursor: "pointer",
      fontSize: "22px",
    },

  },
}));

const Logo = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  paddingTop:"10px",
  "& span": {
    background: theme.palette.primary.red,
    padding: "5px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "12px 3px px 3px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "4px",
    },
  },
}));

const FriendsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [currentValue, setCurrentValue] = useState((location.state === "request" || location.state === "friendRequest") ? "request" : "member");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [memberArr, setMemberArr] = useState([]);
  const [deleteID, setDeleteID] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    getMembers();
  }, [currentValue]);

  const getMembers = () => {
    setLoading(true);
    setMemberList([]);
    httpClient.get(`member-connection/${currentValue}`).then(({ data }) => {
      if (data.success) {
        setMemberList(data.data);
        setLoading(false);
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        navigate("/")
      }
    })
  };

  const handleClick = (member) => {
    // var newSlug = member.friendMemberID.memberName
    //   .toLowerCase()
    //   .replace(/\s+/g, "-") // Replace whitespace with underscores
    //   .replace(/[^\w-]/g, ""); // Remove special characters except underscores and hyphens
    navigate(`/members/detail/${member.friendMemberID.slug || member.friendMemberID.friendMemberID}`, { state: member.friendMemberID.friendMemberID });
    // console.log("stateee", member.friendMemberID.friendMemberID);
    //console.log("slug", newSlug);
  };


  const handleAdd = (id) => {
    httpClient
      .post(`member-connection/add`, {
        friendMemberID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setMemberArr([...memberArr, id]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
  };

  const handleAcceptRequest = (id) => {
    httpClient
      .post(`member-connection/accept`, {
        friendMemberID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          getMembers();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
  };

  const handleCancelRequest = (id) => {

  };

  const handleDelete = (id) => {
    setDeleteID(id);
    setOpenDeleteDialog(true);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false);
    }

    if (call.success === true) {
      httpClient
        .post(currentValue === "request" ? `member-connection/reject` : `member-connection/remove`, {
          friendMemberID: deleteID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            getMembers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        }).catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/")
          }
        })
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const memberChunks = chunkArray(memberList, 5);

  // Render sections alternatively
  const renderSections = () => {
    return memberChunks.map((chunk, index) => {
      if (index % 2 === 0) {
        // Even index: Render FriendSection1
        return <FriendSection1 key={index} memberList={chunk} getMembers={getMembers} currentValue={currentValue} />;
      } else {
        // Odd index: Render FriendSection2
        return <FriendSection2 key={index} memberList={chunk} getMembers={getMembers} currentValue={currentValue}/>;
      }
    });
  };


  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="Friends List"
      />
      <FriendSection>
        <Container maxWidth="xl">
          <HeaderTabs>
            <span
              onClick={() => !loading && setCurrentValue("member")}
              style={{ color: currentValue === "member" ? "#fff" : "inherit" }}
            >
              Friends{" "}
            </span>
            <Logo>
              <span></span> <span></span>
            </Logo>{" "}
            <span
              onClick={() => !loading && setCurrentValue("request")}
              style={{ color: currentValue === "request" ? "#fff" : "inherit" }}
            >
              Requests{" "}
            </span>
            <Logo>
              <span></span> <span></span>
            </Logo>{" "}
            <span
              onClick={() => !loading && setCurrentValue("pending")}
              style={{ color: currentValue === "pending" ? "#fff" : "inherit" }}
            >
              Pending
            </span>
          </HeaderTabs>
          {loading && (
            <GridDiv>
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
            </GridDiv>
          )}
          {!loading && memberList.length === 0 && (
            <Box pt={3} style={{ color: "#fff", textAlign: "center" }}>
              {currentValue === "member" && "No Friends Found"}
              {currentValue === "request" && "No Request Found"}
              {currentValue === "pending" && "No Pending Request Found"}
            </Box>
          )}
        </Container>
      </FriendSection>
     
      {renderSections()}
      
    </div>
  );
};

export default FriendsList;