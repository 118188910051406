import { Container, Grid, styled } from "@mui/material";
import React from "react";
import MemberLevel from "./MemberLevel";
import parse from "html-react-parser";

const MemberDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
}));

const LevelFlex = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height:"100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const MemberStatus = ({data}) => {
  return (
    <MemberDiv>
      <Container maxWidth="xl">
        <h2>Member Status</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} >
            <LevelFlex>
              <MemberLevel color="#ECAB3B" level="Gold" />
              <MemberLevel color="#C8C8C8" level="Silver" />
              <MemberLevel color="#965F01" level="Bronze" />
            </LevelFlex>
          </Grid>
          <Grid item xs={12} md={6}>
            {parse(data.section3 || "")}
            {/* <p>
              The value of giving in this community is determined by the
              intrinsic value of time invested on behalf of a cause, over the
              material value of dollars donated to a cause.
              <br /> <br />
              Many of us passionate for a cause often lack the training or
              resources to directly contribute to the situation. However giving
              financially to organisations purpose built to address the cause is
              no less meaningful.
              <br /> <br />
              Regardless of wealth, income or financial contribution, the Final
              Hour community status is driven by time or work equivalent
              invested by the individual.
            </p> */}
          </Grid>
        </Grid>
      </Container>
    </MemberDiv>
  );
};

export default MemberStatus;
