import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpClient } from "../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetSection = styled("div")(({ theme }) => ({
  padding: "20px",
  "& h2": {
    fontFamily: "Inter",
    margin: "0",
    fontWeight: "500",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
  "& p": {},
}));

const FormDiv = styled("div")(({ theme }) => ({
  marginLeft: "15%",
  marginBottom: "100px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
    marginBottom: "40px",
  },
}));


const PasswordDiv = styled("div")(({ theme }) => ({
  position: "relative",
  "& svg": {
    position: "absolute",
    right: "8px",
    top: "50px",
    color: "#999",
    fontSize: "18px",
  },
}));

let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const ResetPassword = () => {
  const JoinButton = styled(Button)(({ theme }) => ({
    background: formValid ? theme.palette.primary.red : "#aaa",
    color: "#fff",
    borderRadius: "15px",
    padding: "8px 25px",
    outline: "none",
    border: "2px solid transparent",
    transition: "0.3s",
    cursor: "pointer",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.red,
      border: `2px solid ${theme.palette.primary.red}`,
    },
    "& span": {
      color: "#fff",
    },
  }));

  
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [messageState, setMessageState] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
    passwordView: false,
    confirmPasswordView: false,
  });

  const [passwordValid, setPassworValid] = useState(true);
  const [passwordValid2, setPassworValid2] = useState(true);
  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    if(passwordDetails.password && passwordDetails.confirmPassword){
      setFormValid(true)
    }else{
      setFormValid(false)
    }
  },[passwordDetails])

  useEffect(() => {
    if (passwordRegex.test(passwordDetails.password)) {
      setPassworValid(true);
    } else {
      setPassworValid(false);
    }
  }, [passwordDetails.password]);

  useEffect(() => {
    if (passwordDetails.confirmPassword.length > 0) {
      if (passwordDetails.password === passwordDetails.confirmPassword) {
        setPassworValid2(true);
      } else {
        setPassworValid2(false);
      }
    }
  }, [passwordDetails.confirmPassword]);

  var userName = location.search.split("?username=")[1].split("&")[0];
  var encUserName = location.search.split("&encUserName=")[1].split("&")[0];
  var rcode = location.search.split("&rcode=")[1].split("&")[0];
  var encrcode = location.search.split("&encrcode=")[1];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails({
      ...passwordDetails,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    httpClient
      .post("reset-password", {
        username: userName,
        encUserName: encUserName,
        rcode: rcode,
        encrcode: encrcode,
        password: passwordDetails.password,
        confirmPassword: passwordDetails.confirmPassword,
      })
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage("Password changed successfully");
          setTimeout(() => {
            navigate("/")
          },1500)
        } else {
          setLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.message ? data.message : data.message[0]);
        }
      });
  };

  return (
    <ResetSection>
      <Container maxWidth="xl">
        <h2>Reset Password</h2>
        <p>Please Enter a new password and confirm the password to reset. </p>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <FormDiv>
              <PasswordDiv>
                <InputLabel>Password</InputLabel>
                <TextField
                  variant="outlined"
                  type={passwordDetails.passwordView ? "text" : "password"}
                  color={passwordValid ? "primary" : "error"}
                  onChange={handleChange}
                  value={passwordDetails.password}
                  name="password"
                  sx={{ width: "100%", marginBottom: "20px" }}
                  helperText="Password must include 8 characters, Special character, 1 Uppercase and 1 Number."
                />
                <RemoveRedEye
                  onClick={() =>
                    setPasswordDetails({
                      ...passwordDetails,
                      passwordView: !passwordDetails.passwordView,
                    })
                  }
                />
              </PasswordDiv>

              <PasswordDiv>
                <InputLabel>Confirm Password</InputLabel>
                <TextField
                  variant="outlined"
                  type={
                    passwordDetails.confirmPasswordView ? "text" : "password"
                  }
                  color={passwordValid2 ? "primary" : "error"}
                  onChange={handleChange}
                  value={passwordDetails.confirmPassword}
                  name="confirmPassword"
                  helperText={passwordValid2 ? "" : "Password does not match!"}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
                <RemoveRedEye
                  onClick={() =>
                    setPasswordDetails({
                      ...passwordDetails,
                      confirmPasswordView: !passwordDetails.confirmPasswordView,
                    })
                  }
                />
              </PasswordDiv>
              <Box mt={2} textAlign="right">
                {loading ? (
                  <JoinButton disabled={true}>
                    <CircularProgress
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <span>Loading</span>
                  </JoinButton>
                ) : (
                  <JoinButton onClick={handleSubmit} disabled={(formValid) ? false : true}>Reset</JoinButton>
                )}
              </Box>
            </FormDiv>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ResetSection>
  );
};

export default ResetPassword;
