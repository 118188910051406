
import AboutTime from "./AboutTime";
import AboutVideo from "./AboutVideo";
import { Button, Container, Grid, styled, Snackbar, Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Video from "./../../../images/video.mp4";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import parse from "html-react-parser";
import { Add, Remove } from "@mui/icons-material";
import { httpClient } from "../../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
import CancelSubscription from "../../../Components/Causes/CancelSubscription";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const CancelDiv = styled("div")(({theme}) => ({
  position:"fixed",
  bottom:"50%",
  right:"-93px",
  zIndex: "99",
  transform:"rotate(-90deg)"
}))

const login = localStorage.getItem("token");
const token = JSON.parse(login);


const GridDiv = styled("div")(({ theme }) => ({
  padding:"25px 0",
  position:"relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    padding:"10px 0",

  },
}));


const LogoDiv = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  position: "absolute",
  display: "flex",
  alignItems:"center",
  justifyContent:"center",
  height:"100%",
  width:"100%",
  [theme.breakpoints.down("md")]: {
    transform: "rotate(0deg)",
    top:"18px",
  },
  "& span": {
    background: theme.palette.primary.red,
    padding: "32px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "0px 35px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
      padding: "22px",
    },
  },
}));

const AboutTimeVideo = (props) => {
  

  const AboutSection = styled("div")(({ theme }) => ({
    backgroundImage:
      `linear-gradient(to left, ${props.allData.categoryID?.color} 0%, ${props.allData.categoryID?.color} 50%,  #ecab3b 50%, #ecab3b 100%)`,
    [theme.breakpoints.down("md")]: {
      background: "#b8b6a9 ",
    },
    height:"100%"
    // height:"600px"
  }));


  const AddFavourite = styled("div")(({ theme }) => ({
    position: "fixed",
    bottom: "20px",
    zIndex: "99",
    "& button": {
      border: "1px solid transparent",
      borderRadius: "25px",
      background: addedToFav ? theme.palette.primary.red : "#fff",
      color: addedToFav ? "#fff" : theme.palette.primary.red,
      outline: "none",
      padding: "8px 20px",
      fontSize: "14px",
      cursor: "pointer",
      transition: "0.3s",
      "&:hover": {
        background: addedToFav ? "#fff" : theme.palette.primary.red,
        border: "1px solid #fff",
        color: addedToFav ? theme.palette.primary.red : "#fff",
      },
    },
    "& svg": {
      fontSize: "18px",
      marginRight: "10px",
    },
  }));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [addedToFav, setAddedToFav] = useState(false);
  const [favouriteID, setFavouriteID] = useState("");
  const [showCancel, setShowCancel] = useState(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);


  useEffect(() => {
    setAddedToFav(props.allData && props.allData.favorite === 1 ? true : false);
    setFavouriteID(props.allData && props.allData.favoriteID);
  }, [props.allData]);

  

  const handleLearn = () => {
    props.handleLink();
  };

  const handleAddToFav = () => {
    props.causeID &&
      httpClient
        .post("member-fav-cause", {
          causeID: props.causeID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setAddedToFav(true);
            setFavouriteID(data.data.favCauseID);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });

    props.orgID &&
      httpClient
        .post("member-fav-orgs", {
          orgID: props.orgID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setAddedToFav(true);
            setFavouriteID(data.data.favID);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
  };

  const handleRemoveToFav = () => {
    props.causeID &&
      httpClient.delete(`member-fav-cause/${favouriteID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setAddedToFav(false);
          setFavouriteID("");
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });

    props.orgID &&
      httpClient.delete(`member-fav-orgs/${favouriteID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setAddedToFav(false);
          setFavouriteID("");
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
  };

  const handleCancelSubs = () => {
    setOpenCancelDialog(true);
  }

  const callBack = (call) => {
    if(call.open === false){
      setOpenCancelDialog(false)
    }
    if(call.success === true){
      httpClient.post(`subscription-cancel`,{
        ruleID: props.allData?.myDonations?.ruleID
      }).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setShowCancel(false);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  
  return (
    <AboutSection>
      {props.showFav && (props.allData?.myDonations?.ruleID) && (
      showCancel &&
      <CancelDiv>
        <Button onClick={handleCancelSubs} color="primary" variant="contained">
          Cancel Subscription
        </Button>
      </CancelDiv>
      )}
      <Container maxWidth="xl">
      <AddFavourite>
          {token && (
          props.showFav &&
            (!addedToFav ? (
              <Button onClick={handleAddToFav}>
                <Add fontSize="small" /> Add To Favorites
              </Button>
            ) : (
              <Button onClick={handleRemoveToFav}>
                <Remove fontSize="small" /> Remove From Favorites
              </Button>
            )))}
        </AddFavourite>
        <GridDiv>
            <AboutTime props={props}/>
            <AboutVideo props={props} />
            <LogoDiv>
                  <span></span>
                  <span></span>
                </LogoDiv>
        </GridDiv>
      </Container>
      {openCancelDialog && <CancelSubscription callBack={callBack} allData={props.allData}/>}  

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </AboutSection>
  );
};

export default AboutTimeVideo;
