import React, { useEffect } from 'react'
import ForCharity from '../../Components/BecomeAPartner/ForCharity'
import ForWorplace from '../../Components/BecomeAPartner/ForWorkplace'
import BreadCrumb from '../../Components/BreadCrumb'
import WorkspaceLeaderBoard from '../../Components/Dashboard/WorkspaceLeaderBoard'
import { Container, Grid } from "@mui/material";
import FriendsLeaderBoard from '../../Components/Dashboard/FriendsLeaderBoard'
import FriendsActivity from '../../Components/Dashboard/FriendsActivity'
import FriendsList from '../../Components/Dashboard/FriendsList'
import RecentDonations from '../../Components/Dashboard/RecentDonations'
import CommunityStatistics from '../../Components/Dashboard/CommunityStatistics'


const Community = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
       // console.log('in dashboard')
    }, [])
    return (
        <div>
            <BreadCrumb header="Community" />
            {/* <ForCharity /> */}
            <FriendsLeaderBoard />
            <Grid container>

                <Grid item xs={12} md={6}>
                    <FriendsActivity />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FriendsList />
                </Grid>
                <Grid items xs={12} md={3} sx={{ background: "#ED6B76" }}></Grid>
                <Grid item xs={12} md={5} sx={{ background: "#ED6B76" }}>
                 
                    
                </Grid>
                <Grid items xs={12} md={4} sx={{ background: "#ED6B76" }}>
                <CommunityStatistics />
                </Grid>
            </Grid>

        </div>
    )
}

export default Community