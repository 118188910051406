import { Box, Button, Container, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import FeatureBox from "../../Home/FeatureCauses/FeatureBox";
import MemberBox from "../../Home/FeaturedOrganizations/MemberBox1";

const FeatureSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  //   background: theme.palette.primary.orange,
  background: "#CCA17A ",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
}));

const FeatureGrid = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "100px",
  flexWrap: "wrap",
  gridGap: "100px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));

const FeatureButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  backgroundColor: "#929292",
  color: "#fff",
  outline: "none",
  border: "transparent",
  padding: "5px 15px",
  fontSize: "14px",
  cursor: "pointer",
  transition: "0.3s",
  textTransform: "none",
  "&:hover": {
    background: "#6e6c6c",
  },
}));

const FavouriteOrganizations = () => {
  const navigate = useNavigate();
  const [orgList, setOrgList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    httpClient.get("member-fav-orgs").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setOrgList(data.data);
      }
    });
  }, []);

  const handleClickOrg = (org) => {
    var newSlug = org.orgName.replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_").toLowerCase();
    navigate(`/charity_partners/detail/${newSlug}`, { state: org });
  };
  
  const handleView = () => {
    navigate("/dashboard/charity_partners");
  };

  return (
    <FeatureSection>
      <Container maxWidth="xl">
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
        >
          <h2>Favorite Charity Partners</h2>
          <FeatureButton onClick={handleView}>View all</FeatureButton>
        </Box>
        {!loading ? (
          <FeatureGrid>
            {orgList.length > 0 ?
              orgList.map((org, index) => (
                index < 4 &&
                <MemberBox
                  key={org.orgID}
                  image={org.orgLogoName}
                  name={org.orgName}
                  handleClick={() => handleClickOrg(org)}
                  // favourite={false}
                />
              ))
              :
              "No charity found!" 
            
            }
          </FeatureGrid>
        ) : (
          <>
                Fetching Charity Partners..
              </>
          // <FeatureGrid>
          //   <Skeleton variant="circular" width={250} height={250} />
          //   <Skeleton variant="circular" width={250} height={250} />
          //   <Skeleton variant="circular" width={250} height={250} />
          //   <Skeleton variant="circular" width={250} height={250} />
          // </FeatureGrid>
        )}
      </Container>
    </FeatureSection>
  );
};

export default FavouriteOrganizations;
