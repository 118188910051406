import { Close } from "@mui/icons-material";
import { styled } from "@mui/material";
import React from "react";
import BlankImg from './../../../images/no-pictures.png'

const CauseDiv = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  cursor:"pointer",
  "& img": {
    objectFit: "cover",
    width: "250px",
    height: "250px",
    borderRadius: "50%",
  },
  "& p": {
    margin: "0",
    fontWeight: "600",
  },
  "& svg": {
    position: "absolute",
    right: "10px",
    top: "10px",
    borderRadius: "50%",
    padding: "5px",
    color: "#fff",
    backgroundColor: "#4A4A4A",
    cursor:"pointer",
    [theme.breakpoints.down("md")]: {
      right:"10px",
      top:"10px"
    },

  },
}));

const MyCausesBox = (props) => {
  // const handleClick = () => {
  //   props.handleDelete();
  // }
  // console.group('cause propss', props);
  return (
    <CauseDiv>
      {props.delete && <Close fontSize="small" onClick={props.handleDelete} />}
      <div onClick={props.handleClick}>
        <img src={props.image || BlankImg} alt={props.cause} />
        <p>{props.cause}</p>
        <small>
          <strong>{props.hours}</strong> hours
        </small>
      </div>
    </CauseDiv>
  );
};

export default MyCausesBox;
