import React, { useState, useEffect } from 'react';
import { KeyboardArrowUp } from '@mui/icons-material';
import { styled } from "@mui/material";

const TopButtonDiv = styled("div")(({ theme }) => ({
    position: "fixed",
    bottom: "20px",
    right: "20px",
    padding: "10px",
    cursor: "pointer",
    zIndex: "100",
    [theme.breakpoints.down("md")]: {
        bottom: "10px",  
        right: "10px", 
    },
    "& svg": {
        fontSize: "40px",
        background: "#D9D9D9",
        padding: "3px",
        borderRadius: "50%",
    },
}));

const MoveToTopButton = () => {
    
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        isVisible && (
            <TopButtonDiv onClick={scrollToTop}>
                <KeyboardArrowUp />
            </TopButtonDiv>
        )
    );
};

export default MoveToTopButton;
