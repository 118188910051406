import { Container, Skeleton, styled, Tooltip, Snackbar, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import FeatureBox from "../../Home/FeatureCauses/FeatureBox";
import MuiAlert from "@mui/material/Alert";
import { Box } from "@mui/system";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useRef } from 'react';
import CrisisImage from "./../../../images/emergency.png";
import CauseDialog from "../CauseDialog";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SimilarSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#ECE8DD",
}));

const GridDiv = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridGap: "100px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));



const CauseAll = (props) => {
  const loginData = JSON.parse(localStorage.getItem('token'));
  const navigate = useNavigate();
  const expandedRef = useRef(null);
  // const [charityCausesList, setCharityCausesList] = useState([]);
  const [causeArr, setCauseArr] = useState([]);
  const [openGive, setOpenGive] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [displayedItems, setDisplayedItems] = useState(7);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);


  // useEffect(() => {
  //   getAllCharityCauses();
  // }, []);


  // const getAllCharityCauses = () => {
  //   setLoading(true);
  //   httpClient.get("all-charity-causes").then(({ data }) => {
  //     if (data.success) {
  //       setLoading(false);
  //       setCharityCausesList(data.data);
  //     }
  //   });
  // };
  //console.log("all-charity", props.charityCausesList);

  const CategoryDiv = styled("div")(({ theme }) => ({
    margin: "10px 0",
    "& h3 a": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("md")]: {
      margin: "20px 0",
    },
  }));

  const CauseBox = styled(Box)(({ theme }) => ({
    borderBottom: "1px solid gray",
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Add this line
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center", // Add this line
    },
  }));

  const CauseName = styled(Box)(({ theme }) => ({
    width: "360px",
    margin: "0 0px",
    fontSize: "16px",
    fontWeight: "700",
    display:"flex",
    alignItems:"center",
    gap:"5px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px",
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const CauseHours = styled(Box)(({ theme }) => ({
    width: "160px",
    margin: "0 0px",
    fontSize: "16px",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const CauseMembers = styled(Box)(({ theme }) => ({
    width: "260px",
    margin: "0 0px",
    fontSize: "16px",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      //textAlign: "left",
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const CauseFavourite = styled(Box)(({ theme }) => ({
    width: "160px",
    margin: "0 0px",
    fontSize: "16px",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      //textAlign: "left",
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const CauseButtonDiv = styled("div")(({ theme }) => ({
    width: "175px",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      //textAlign: "left",
      marginTop: "10px",
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const CauseCategory = styled(Box)(({ theme }) => ({
    width: "360px",
    margin: "0 0px",
    fontSize: "16px",
    fontWeight: "700",
    "& a": {
      width: "fit-content",
      border: "1px solid #333",
      borderRadius: "10px",
      padding: "5px 10px",
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        height: "20px",
        width: "20px",
        objectFit: "contain",
        border: "none",
        marginBottom: "0px",
      },
      "& p": {
        margin: "0",
        fontWeight: "400",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center", // Add this line
      "& a": {
        justifyContent: "center", // Add this line
      },
    },
  }));


  const CrisisImg = styled("div")(({ theme }) => ({
    "& img": {
      width: "25px",
      height: "25px",
      // position: "absolute",
      // bottom: "70px",
      // left: "85px",
      // border:"none",
      borderRadius:"initial"
    },
  }));


  const GiveButton = styled("button")(({ theme, cats }) => ({
    padding: "10px 30px",
    borderRadius: "100px",
    border: "1px solid gray !important",
    color: cats?.isDonated === true ? "#fff !important" : theme.palette.primary.red,
    background: cats?.isDonated === true ? theme.palette.primary.red : "#fff",
    marginTop: "10px",
    transition: "0.3s",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "5px 10px",
      marginLeft: "5px",
      marginTop: "0",
    },
    "&:hover": {
      background: `${theme.palette.primary.red} !important`,
      color: "#fff !important",
      border: `1px solid gray !important`,
    },
  }));


  const handleFavourite = (id) => {
    setLoading(true);
    httpClient
      .post("member-fav-cause", {
        causeID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setCauseArr([...causeArr, data.data]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          // getAllCharityCauses();
          props.handleRefetch();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
          setLoading(false);
        }
      });
  };

  const handleFavoriteRemove = (id) => {
    // let favID;
    // causeArr.map(cause => {
    //   if(cause.causeID === id){
    //     favID = cause.favCauseID;
    //   }
    // })
    setLoading(true);

    httpClient.delete(`member-fav-cause/${id}`).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        let newArr = causeArr.filter((cause) => cause.causeID !== id);
        setCauseArr(newArr);
        setLoading(false);
        // getAllCharityCauses();
        props.handleRefetch();
      } else {
        setOpen(true);
        setMessageState("error");
        setMessage(data.message);
        setLoading(false);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const causeCallBack = (call) => {
    if (call.open === false) {
      setOpenGive(false);
    }
  };

  const handleClickCause = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: {cause:cats, give: false} });

  };

  const handleClickCauseGive = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    //navigate(`/causes/detail/${newSlug}`, { state: {cause:cats, give: true} });
    setId(newSlug);
    setOpenGive(true);
  }
  

  const handleViewAll = () => {
    setExpanded(!expanded);

    if (expandedRef.current) {
      expandedRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <SimilarSection>
      <Container maxWidth="xl">
        <h2>All Charities</h2>
        {!props.loading ? (
          <> <CategoryDiv>
            <div style={{ overflowX: "auto" }}>
              {props.charityCausesList.length > 0 &&
                props.charityCausesList
                  .slice(0, expanded ? props.charityCausesList.length : displayedItems)
                  .map((cats, index) => (
                    // index < 4 &&
                    <CauseBox>
                      <div style={{ cursor: "pointer" }} onClick={() => handleClickCause(cats)}>
                        <CauseName>{cats.name}
                        {cats.crisis === 1 ? (
                        <CrisisImg>
                          <img src={CrisisImage} />
                        </CrisisImg>
                      ) : (
                        ""
                      )}</CauseName>
                        
                      </div>

                      <CauseCategory>
                        <Link
                          to={`/causes/category/${cats.categoryID.categorySlug}`}
                          state={cats.categoryID}
                          style={{ background: cats.categoryID.categoryColor }}
                        >
                          <>
                            {/* <CategoryButton sx={{ background: cats.categoryID.categoryColor }}> */}
                            <img src={cats.categoryID.categoryIcon} alt="Icon" />
                            <p>{cats.categoryID.categoryName}</p>

                            {/* </CategoryButton> */}
                          </>
                        </Link>
                      </CauseCategory>
                      <CauseHours>
                        <strong>{cats.hours || "0"} </strong> {(cats.hours == "0" || cats.hours == "1" || cats.hours == null) ? "hour" : "hours"}
                      </CauseHours>
                      <CauseMembers>
                        <strong>{cats.totalMembers || "0"} </strong> {(cats.totalMembers == "0" || cats.totalMembers == "1" || cats.totalMembers == null) ? "member" : "members"}
                      </CauseMembers>
                    
                      <CauseFavourite>
                        {loginData ? (
                          // props.messageState !== "success" ?
                          cats.favorite !== 1 ? (
                            //tokenData &&

                            <Tooltip title="Add To Favorite">
                              
                              <IconButton
                                onClick={() => handleFavourite(cats.causeID)}
                                disableRipple
                                disableFocusRipple
                                disabled={loading}
                              >
                              <FavoriteBorder
                                sx={{ color: "gray", cursor: "pointer" }}
                              />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Remove from Favorite">
                              <IconButton
                                onClick={() => handleFavoriteRemove(cats.favoriteID)}
                                disableRipple
                                disableFocusRipple
                                disabled={loading}
                              >
                              <Favorite
                                sx={{ color: "#EA3323", cursor: "pointer" }}
                                // onClick={() => handleFavoriteRemove(cats.favoriteID)}
                              />
                              </IconButton>
                            </Tooltip>
                          )): (
                            <Tooltip title="Join now to add to favourites">
                              <IconButton
                                
                                disableRipple
                                disableFocusRipple
                                disabled={loading}
                              >
                              <FavoriteBorder
                                sx={{ color: "gray", cursor: "pointer" }}
                                // onClick={() => handleFavoriteRemove(cats.favoriteID)}
                              />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                      </CauseFavourite>
                      <CauseButtonDiv>
                        <GiveButton cats={cats}  onClick={() => handleClickCauseGive(cats)}> Give</GiveButton>
                      </CauseButtonDiv>
                    </CauseBox>

                  ))} 
            </div>
          </CategoryDiv>
            <Box textAlign={"right"}>
              <GiveButton onClick={handleViewAll}>
                {expanded ? "Collapse" : "View all"}
              </GiveButton>
            </Box>


          </>
        ) : (
          <CategoryDiv>


            <Box p={3} sx={{ position: "relative", width: "100%" }}>
              <Skeleton height={60} />
              <Skeleton height={60} />
              <Skeleton height={60} />
              <Skeleton height={60} />
              <Skeleton height={60} />
              <Skeleton height={60} />
              <Skeleton height={60} />
            </Box>
          </CategoryDiv>

        )}

        {/* <FeatureBox
            image={
              "https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
            name={"Cause Name"}
            hours={"95"}
          />
          <FeatureBox
            image={
              "https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
            name={"Cause Name"}
            hours={"95"}
          />
          <FeatureBox
            image={
              "https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
            name={"Cause Name"}
            hours={"95"}
          /> */}
      </Container>

      {openGive && <CauseDialog id={id} causeCallBack={causeCallBack} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </SimilarSection>
  );
};

export default CauseAll;
