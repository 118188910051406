import { Container, Grid, styled } from "@mui/material";
import React from "react";

const PrivacyDiv = styled("div")(({ theme }) => ({
  background: "#B8B6A9",
  marginTop:"20px",
  "& h1": {
    margin: "0",
    fontWeight: "300",
    padding: "40px 0",
    fontSize: "70px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0",
      marginTop: "20px",
      fontSize: "40px",
    },
  },
}));

const PrivacyHeader = ({data}) => {
  return (
    <PrivacyDiv>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <h1>{data.title}</h1>
          </Grid>

        </Grid>
      </Container>
    </PrivacyDiv>
  );
};

export default PrivacyHeader;
