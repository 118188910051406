import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MainDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
  },
  "& a":{
    textDecoration:"underline",
    color:"blue"
  }
}));

const MainTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& svg": {
    cursor: "pointer",
    position: "relative",
    top: "-35px",
    right: "-15px",
  },
}));

const GreyButton = styled("button")(({ theme }) => ({
  background: "transparent",
  padding: "10px 20px",
  color: "#777",
  ouline: "none",
  borderRadius: "25px",
  border: "1px solid #777",
  marginRight: "10px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    background: "#777",
    color: "#fff",
  },
}));

const IncomeDialog = (props) => {
  const [dialog, setDialog] = useState({
    open: true,
  });

  useEffect(() => {
    props.callBack(dialog);
  }, [dialog]);

  const handleCloseDialog = () => {
    setDialog({
      open: false,
    });
  };
  const stateObject = {
    state: window.location.href
  };
  return (
    <MainDialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
    >
      <MainTitle id="alert-dialog-title">
        <h1>Income Fill up </h1>
      <Close onClick={handleCloseDialog} />
      </MainTitle>
      <DialogContent>
        <p>
          Dear member, you need to fill your income details before you donate to any cause.
          So please fill up your income details <Link to="/dashboard/giving_preferences" state={stateObject}>here</Link>
        </p>
      </DialogContent>
      <DialogActions>
        <Box textAlign={"right"} pr={2}>
         <GreyButton onClick={handleCloseDialog}>Close</GreyButton>
        </Box>
      </DialogActions>
    </MainDialog>
  );
};

export default IncomeDialog;
