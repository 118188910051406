
import { Box, Button, styled, useTheme, useMediaQuery, Typography, Snackbar, Container } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import { useParams } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileSection = styled("div")(({ theme }) => ({
    padding: "40px 0",
    background: theme.palette.primary.lightest,
    [theme.breakpoints.down("md")]: {
        padding: "20px 0",
    },
}));

const member = localStorage.getItem("login");
const memberData = JSON.parse(member);


const Invitation = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("");
    const [snackMessage, setSnackMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [display, setDisplay] = useState("");

    const { id } = useParams();

    useEffect(() => {
        getInviteInfo();
    }, [id]);

    const getInviteInfo = () => {
        httpClient.get(`member/getinviter?acceptCode=${id}`).then(({ data }) => {
            if (data.success) {
                setUserData(data.data);
            }
            else {
                setDisplay(data.message);
            }
        });
    };

    useEffect(() => {
        if (userData.inviteEmail !== memberData?.member?.email) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
    }, [userData]);



    const handleDismiss = () => {
        navigate("/");
    };

    const handleAccept = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("acceptCode", id);
        httpClient.post("member/accept", formData).then(({ data }) => {
            if (data.success === true) {
                setOpen(true);
                setSnackStatus("success");
                setSnackMessage(data.message);
                setLoading(false);
                setTimeout(() => {
                    navigate("/dashboard/friends_list");
                }, 1000)
            } else {
                setLoading(false);
                setOpen(true);
                setSnackStatus("error");
                setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
            }

        }).catch((err) => {
            console.log((err))
            if (err.response.status === 404) {
                setLoading(false);
                setOpen(true);
                setSnackStatus("error");
                setSnackMessage(err.response.data.message);
            }
        });

    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    return (
        <ProfileSection id="dashboard_status">
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4} mb={4} p={2}>
                    <Typography sx={{ fontFamily: "Epilogue" }} variant={isMobile ? "h6" : "h4"} component="h1" gutterBottom>
                        {display ? (
                            <>This link is not valid for this user. Please log in using the same email address that received this link</>
                        ) : (
                            <>Do you wish to proceed further accepting friend request from {userData?.firstName} {userData?.lastName}?</>
                        )}

                    </Typography>
                    <Box display="flex" gap={2} mt={2}>
                        {!display ? (
                            <>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size={isMobile ? "medium" : "large"}
                                    onClick={() => handleAccept()}
                                    style={{ fontSize: isMobile ? '1rem' : '1.2rem', padding: isMobile ? '8px 16px' : '12px 24px' }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size={isMobile ? "medium" : "large"}
                                    onClick={() => handleDismiss()}
                                    style={{ fontSize: isMobile ? '1rem' : '1.2rem', padding: isMobile ? '8px 16px' : '12px 24px' }}
                                >
                                    No
                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                color="error"
                                size={isMobile ? "medium" : "large"}
                                onClick={() => handleDismiss()}
                                style={{ fontSize: isMobile ? '1rem' : '1.2rem', padding: isMobile ? '8px 16px' : '12px 24px' }}
                            >
                                Move To Homepage
                            </Button>

                        )}
                    </Box>
                </Box>
            </Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackStatus}
                    sx={{ width: "100%" }}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>


        </ProfileSection>

    );
};

export default Invitation;
