import {
  Container, Grid, styled, Snackbar, Button, Pagination,
  PaginationItem,
  Skeleton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/BreadCrumb";
import MyFriendsBox from "../../Components/Dashboard/MyFriendsBox";
import MuiAlert from "@mui/material/Alert";
import { httpClient } from "../../appUtility/Api";
import { useNavigate } from "react-router-dom";
import AddMemberDialog from "./AddMemberDialog";
import { ArrowCircleLeftOutlined, ArrowCircleLeftRounded, ArrowCircleRightOutlined, ArrowCircleRightRounded, NavigateBeforeRounded, NavigateNextRounded, NextPlanRounded } from "@mui/icons-material";
import Members1 from "./index1";
import Members2 from "./index2";
// import BlankImg from './../../images/no-pictures.png'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FriendSection = styled("div")(({ theme }) => ({
  background: "#B891B3",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  "& span1": {
    margin: "0",
    //paddingBottom: "20px",
    fontSize: "32px",

  },
  "& span2": {
    margin: "0",
    //paddingBottom: "20px",
    fontSize: "32px",
    color: "#ffffff"

  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
    "& span1": {
      fontSize: "26px",
    },
    "& span2": {
      fontSize: "26px",
    },
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  gridGap: "50px",
  marginTop: "20px",
  // display: "flex",
  // flexDirection: "row",
  // flexWrap: "wrap",
  // justifyContent: "center",
  // gap: "100px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));
const PaginationBox = styled("div")(({ theme }) => ({
  background: "#B891B3",
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#4A4A4A",
  "& span": {
    fontSize: "16px",
    marginRight: "10px",
  },
  "& button": {
    color: "#4A4A4A",
  },
}));

const Members = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [friendID, setFriendID] = useState("");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [memberList, setMembers] = useState([]);
  const [memberArr, setMemberArr] = useState([]);

  const [lastPage, setLastPage] = useState("");
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(15);
  const [total, setTotal] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [count, setCount] = useState(15);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setLoading(true);
    httpClient.get(`team-members?pagination=${count}`).then(({ data }) => {
      if (data.success) {
        setMembers(data.data);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setTotal(data.meta.total);
        setCount(data.meta.per_page);
        setCurrPage(data.meta.current_page);
        setLastPage(data.meta.last_page);
        setLoading(false);
      }
    });
  }, []);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   handleChangePage();
  // }, []);

  const handleChangePage = (e, value) => {
    setLoading(true);
    httpClient
      .get(`team-members?page=${value === undefined ? "" : value}&pagination=${count}`)
      .then(({ data }) => {
        if (data.success) {
          setMembers(data.data);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setTotal(data.meta.total);
          setCount(data.meta.per_page);
          setCurrPage(data.meta.current_page);
          setLastPage(data.meta.last_page);
          setLoading(false);
        }
      })
  };

  const handleAdd = (id) => {
    setOpenDialog(true);
    setFriendID(id);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenDialog(false);
      setFriendID("");
    }
    if (call.success === true) {
      httpClient
        .post(`member-connection/add`, {
          friendMemberID: friendID,
        })
        .then(({ data }) => {
          if (data.success) {
            setMemberArr([...memberArr, friendID]);
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDialog(false);
            setFriendID("");
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleClick = (member) => {
    // var newSlug = (member.firstName + " " + member.lastName)
    //   .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    //   .toLowerCase();
    navigate(`/members/detail/${member.slug}`, { state: member });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //console.log("memberAr", memberArr);
  const Logo = styled("div")(({ theme }) => ({
    transform: "rotate(90deg)",
    display: "inline-block",
    paddingTop:"10px",
    "& span": {
      background: theme.palette.primary.red,
      padding: "5px",
      display: "inline-block",
      borderRadius: "50%",
      margin: "12px 3px px 3px",
      [theme.breakpoints.down("md")]: {
        margin: "12px 0px 0px 3px",
        padding: "4px",
      },
    },
  }));


  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const memberChunks = chunkArray(memberList, 5);

  // Render sections alternatively
  const renderSections = () => {
    return memberChunks.map((chunk, index) => {
      if (index % 2 === 0) {
        // Even index: Render FriendSection1
        return <Members1 key={index} memberList={chunk} />;
      } else {
        // Odd index: Render FriendSection2
        return <Members2 key={index} memberList={chunk} />;
      }
    });
  };


  return (
    <div>
      <BreadCrumb
        header="Community"
        subHeader1="Community"
        Link1="/"
        subHeader2="Members"
      />
      <FriendSection>

        <Container maxWidth="xl">
          <span2>Community </span2><Logo>
            <span></span> <span></span>
          </Logo><span1>Members</span1>
          {/* <GridDiv>
            {!loading ? (
              memberList.length > 0 ? (
                memberList.map((member, index) => (
                  <MyFriendsBox
                    key={member.memberID}
                    image={member.image}
                    memberName={member.firstName + " " + member.lastName}
                    city={
                      member.address1 +
                      (member.address2 && ", " + member.address2)
                    }
                    occupation={member.occupation}
                    handleAdd={() => handleAdd(member.memberID)}
                    friend={member.friend}
                    messageState={
                      memberArr.includes(member.memberID) ? "success" : false
                    }
                    handleClick={() => handleClick(member)}
                    memberList={true}
                  />
                ))) : "No members found") :
              <>
                Fetching Members..
              </>
            }

          </GridDiv> */}
          {loading && (
            <GridDiv>
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
              <Skeleton variant="circular" width={220} height={220} />
            </GridDiv>
          )}




        </Container>


      </FriendSection>
      {renderSections()}
      <Grid item xs={12}>
        <PaginationBox>
          <span>
            {from}-{to} of {total} items
          </span>
          {/* <Pagination
                  page={currPage}
                  count={count}
                  siblingCount={1}
                  onChange={handleChangePage}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                /> */}
          <Pagination
            count={lastPage}
            page={currPage}
            onChange={(e, value) => handleChangePage(e, value)}
            showFirstButton
            showLastButton
            shape="rounded"
          >
            <PaginationItem
              page={currPage}
            // disabled
            />
          </Pagination>

        </PaginationBox>
      </Grid>

      {/* {openDialog && <AddMemberDialog callBack={callBack} />} */}

      {/* <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar> */}
    </div>
  );
};

export default Members;
