import {
  Box,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import CauseBox from "../../../Components/Dashboard/CausesNewsFeed/CauseBox";

const CausesDiv = styled("div")(({ theme }) => ({
  background: "#EAB38B",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const PaginationBox = styled("div")(({ theme }) => ({
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#4A4A4A",
  "& span": {
    fontSize: "16px",
    marginRight: "10px",
  },
  "& button": {
    color: "#4A4A4A",
  },
}));

const MyCausesNewsFeed = () => {
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [count, setCount] = useState(20);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
    getNewsList();
  }, []);

  const getNewsList = () => {
    httpClient.get("member-news").then(({ data }) => {
      if (data.success) {
        setNewsList(data.data);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setTotal(data.meta.total);
        setCount(data.meta.per.page);
        setCurrPage(data.meta.current_page);
        setLastPage(data.meta.last_page);
      }
    }).catch((err) => {
      if(err.response.status === 401){
        localStorage.clear();
        navigate("/")
      }
    })
  };

  const handleChangePage = (e, value) => {
    httpClient
      .get(`member-news?page=${value}&pagination=${count}`)
      .then(({ data }) => {
        if (data.success) {
          setNewsList(data.data);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setTotal(data.meta.total);
          setCount(data.meta.per.page);
          setCurrPage(data.meta.current_page);
          setLastPage(data.meta.last_page);
        }
      }).catch((err) => {
        if(err.response.status === 401){
          localStorage.clear();
          navigate("/")
        }
      })
  };

  const handleClickCause = (news) => {
    var newSlug = news.newsCause.causeName
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: news.newsCause });
  };

  const handleClickNews = (news) => {
    var newSlug = news.newsTitle
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
      .toLowerCase();
    navigate(`/news/detail/${newSlug}`, { state: news });
  };

  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="My Causes News Feed"
      />
      <CausesDiv>
        <Container maxWidth="xl">
          <h2>My Causes News Feed</h2>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {newsList.length > 0
                ? newsList.map((news, index) => (
                    <CauseBox
                      image={news.newsImage}
                      handleClickCause={() => handleClickCause(news)}
                      handleClickNews={() => handleClickNews(news)}
                      date={news.newsDate}
                      newsTitle={news.newsTitle}
                      cause={news.newsCause.causeName}
                    />
                  ))
                : "No News Found!"}
            </Grid>
            <Grid item xs={12}>
              <PaginationBox>
                <span>
                  {from}-{to} of {total} items
                </span>
                <Pagination
                  count={lastPage}
                  page={currPage}
                  
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  onChange={(e, value) => handleChangePage(e, value)}
                >
                  <PaginationItem
                    page={currPage}
                    // disabled
                  />
                </Pagination>
                {/* <Pagination
                  count={total}
                  page={currPage}
                  onChange={(e, value) => handleChangePage(e, value)}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      disabled={item.page !== currPage && item.page !== '...' ? true : false}
                    />
                  )}
                /> */}
               
              </PaginationBox>
            </Grid>
          </Grid>
        </Container>
      </CausesDiv>
    </div>
  );
};

export default MyCausesNewsFeed;
