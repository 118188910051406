import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { httpClient } from '../../appUtility/Api'
import BreadCrumb from '../../Components/BreadCrumb'
import CausesContent from '../../Components/Causes/CausesContent'
import CausesTop from '../../Components/Causes/CausesTop'
// import GiveHours from '../../Components/Causes/GiveHours'
import AllCauses from '../../Components/Causes/AllCauses'
import HelmetMetaData from '../../Components/HelmetMetaData'

const OrganizationDetail = () => {
  const location = useLocation();
  const {id} = useParams();

  const navigate = useNavigate();
  const [orgID, setOrgID] = useState("")
  const [allData, setAllData] = useState("");


  // console.log('id is', id)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
  //  setAllData(location.state)
    getOrganizationSingle();
  },[])


  const getOrganizationSingle = () => {
    httpClient.get(`organizations/${id}`).then(({data}) => {
      if(data.success){
        setAllData(data.data);
      }
    })
  }

  // console.log('orgID', orgID);

  const handleLink = () => {
    // navigate(`/causes/category/detail/${allData && allData.slug}`, { state: allData && allData });
  }

  // console.log('org all', allData);
  
  return (
    <div>
      <HelmetMetaData
        title={allData && allData.orgName}
        description={allData && allData.orgShortDescription}
        image={allData && allData.orgLogoName}
      />
        <BreadCrumb header={"Charity"} subHeader1="Charity" Link1="/charity_partners" subHeader2={allData && allData.orgName} />
        <CausesTop orgID={allData && allData.orgID} header={allData && allData.orgName} description={allData && allData.orgShortDescription || ""} showFav={true} image={allData && allData.orgLogoName} allData={allData && allData} />
        <CausesContent content={allData && allData.orgLongDescription || ""} />
        {/* <CauseCategory categories={allData && allData.subCategory} /> */}
        <AllCauses causeList={allData && allData.causes} />
        {/* <GiveHours organizaition={true} allData={allData && allData} /> */}
    </div>
  )
}

export default OrganizationDetail