import React, { useEffect, useState } from "react";
import {
  AboutTimeVideo,
  CauseToInvest,
  FeatureCauses,
  FeaturedMembers,
  FeatureOrganizations,
  HowItWorks,
  OurCommunity,
} from "../../Components/Home";
import { httpClient } from "../../appUtility/Api";
import FeatureWorkplaces from "../../Components/Home/FeaturedWorkplace";
import FooterGive from "../../Components/FooterGive";

const tokenData = localStorage.getItem("token");
const token = JSON.parse(tokenData);

const HomePage = () => {
  const [homeData, setHomeData] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
    getHomeData();
  },[]);

  const getHomeData = () => {
    httpClient.get('home').then(({data}) => {
      if(data.success){
        setHomeData(data.data);
        window.scroll(0,0)
      }
    })
  }

  // console.log('homeData',homeData)

  return (
    <div>
      <AboutTimeVideo data={homeData && homeData} />
      <CauseToInvest />
      {!token && <HowItWorks token={token} />}
      {/* {!token && homeData && <OurCommunity data={homeData && homeData} />} */}
      <FeatureCauses />
      {/* {homeData && <FeaturedMembers token={token} data={homeData && homeData} /> }
      
      <FeatureWorkplaces /> */}
      {/* <FeatureOrganizations /> */}
      <FooterGive />
    </div>
  );
};

export default HomePage;
