import React, {useEffect} from 'react'
import ForCharity from '../../Components/BecomeAPartner/ForCharity'
import ForWorplace from '../../Components/BecomeAPartner/ForWorkplace'
import BreadCrumb from '../../Components/BreadCrumb'
import WorkspaceLeaderBoard from '../../Components/Dashboard/WorkspaceLeaderBoard'
import MyWorkplaces from '../../Components/Dashboard/MyWorkplace'
import WorkspaceFeature from '../../Components/Dashboard/WorkspaceFeature'

const Workplaces = () => {
  const login = JSON.parse(localStorage.getItem("login"));
  const workplaceID = login?.member?.workplace?.workplaceID;


    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        //console.log('in dashboard')
      },[])
  return (
    <div>
        <BreadCrumb header="Workplace"/>
        {/* <ForCharity /> */}
        {workplaceID ? <MyWorkplaces /> : ""}
        <WorkspaceFeature />
        <WorkspaceLeaderBoard />
    </div>
  )
}

export default Workplaces