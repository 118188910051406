import {
  Box,
  Container,
  Grid,
  InputLabel,
  Skeleton,
  Snackbar,
  styled,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CharityDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#E78F4E",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const ApplyButton = styled("button")(({ theme }) => ({
  marginTop: "50px",
  padding: "15px 35px",
  color: "#fff",
  borderRadius: "25px",
  background: theme.palette.primary.red,
  outline: "none",
  border: "1px solid transparent",
  transition: "0.3s",
  fontWeight: "700",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
  "&:hover": {
    background: "#fff",
    // border: `1px solid ${theme.palette.primary.red}`,
    color: theme.palette.primary.red,
  },
}));

const ApplyDiv = styled("div")(({ theme }) => ({
  paddingTop: "100px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
  },
  "& p": {
    padding: "30px 0",
    [theme.breakpoints.down("md")]: {
      padding: "10px 0",
    },
  },
  "& input": {
    background: "#F4AC77",
  },
}));
const NoteDiv = styled("div")(({ theme }) => ({
  paddingTop: "100px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
  },
  "& p": {
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      padding: "10px 0",
    },
  },
  "& input": {
    background: "#F4AC77",
  },
}));

const ImageDiv = styled("div")(({ theme }) => ({
  "& img": {
    width: "100%",
    height: "300px",
    objectfit: "cover",
  },
}));

const SaveButton = styled("button")(({ theme }) => ({
  border: "1px solid transparent !important",
  outline: "none",
  borderRadius: "25px",
  padding: "10px 30px",
  background: theme.palette.primary.red,
  color: "#fff",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.red} !important`,
    color: theme.palette.primary.red,
    background: "#fff",
  },
}));

const ForCharity = () => {
  const [openApply, setOpenApply] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [charityData, setCharityData] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const navigate = useNavigate();
  const [charity, setCharity] = useState({
    charity_name: "",
    website_url: "",
    contact_name: "",
    contact_email: "",
    phone_number: "",
    message: "",
  });

  useEffect(() => {
    getCharityCMSData();
  }, []);

  const handleApply = () => {
    setOpenApply((prev) => !prev);
  };

  const getCharityCMSData = () => {
    setLoading(true);
    httpClient.get("become-partner/charity").then(({ data }) => {
      if (data.success) {
        setCharityData(data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCharity({
      ...charity,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    httpClient
      .post("apply/charity", {
        charity_name: charity.charity_name,
        website_url: charity.website_url,
        contact_name: charity.contact_name,
        contact_email: charity.contact_email,
        phone_number: charity.phone_number,
        message: charity.message,

      })
      .then(({ data }) => {
        //console.log("data", data);
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          setOpenNote(true);
        }
        if (data.success === false) {
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          // setMessage(data.message[0] !== "T" ? data.message[0] : data.message);
          setOpen(true);
          setMessageState("error");
          setLoading(false);
        }
      });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <CharityDiv>
      <Container maxWidth="xl">
        {/* <h2>For Charities</h2> */}
        <h2>
          {loading ? (
            <Skeleton animation="wave" width={300} />
          ) : (
            charityData && charityData.title
          )}
        </h2>
        <Grid container spacing={6}>
          {isMobile &&
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img src={charityData && charityData.image1} />
                    </ImageDiv>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img
                        src={
                          // "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          charityData && charityData.image2

                        }
                      />
                    </ImageDiv>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }

          <Grid item xs={12} md={6}>

            {loading ? (
              <p>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </p>
            ) : (
              parse((charityData && charityData.body) || "")
            )}

            <Box>
              {!openNote ? (
                <ApplyButton onClick={handleApply}>
                  {!openApply ? "Apply Now" : "Dismiss"}
                </ApplyButton>
              )
                : null}
            </Box>

            {!openNote && openApply && (
              <ApplyDiv>
                <h2>Apply Now</h2>
                <p>
                  Only charities that hold the necessary Deductible Gift
                  Recipient (DGR) Status in Australia will be eligible to
                  proceed as a registered partner.
                </p>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Charity Name *</InputLabel>
                    <TextField
                      name="charity_name"
                      variant="outlined"
                      value={charity.charity_name}
                      onChange={handleChange}
                      fullWidth />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Website URL *</InputLabel>
                    <TextField
                      name="website_url"
                      variant="outlined"
                      value={charity.website_url}
                      onChange={handleChange}
                      fullWidth />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Contact Person Name *</InputLabel>
                    <TextField
                      name="contact_name"
                      variant="outlined"
                      value={charity.contact_name}
                      onChange={handleChange}
                      fullWidth />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Contact Person Email *</InputLabel>
                    <TextField
                      name="contact_email"
                      variant="outlined"
                      value={charity.contact_email}
                      onChange={handleChange}
                      fullWidth />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Phone Number *</InputLabel>
                    <TextField
                      name="phone_number"
                      variant="outlined"
                      value={charity.phone_number}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <InputLabel>Message *</InputLabel>
                    <TextField
                      multiline
                      rows={3}
                      name="message"
                      style={{ background: "#F4AC77" }}
                      value={charity.message}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />

                  </Grid>
                  <Grid xs={12} sx={{ marginTop: "30px" }} textAlign="right">
                    <SaveButton onClick={handleSubmit}>Submit</SaveButton>
                  </Grid>
                </Grid>
              </ApplyDiv>
            )}
            {openNote && (
              <NoteDiv>
                <Grid item md={12} xs={12}>
                  <h1>Thank you!!</h1>
                  <p>Thank you so much for signing up with us! We truly appreciate your decision to join our community.</p>

                  <p> We're excited to have you on board, and we can't wait to connect with you soon. Our team will be in
                    touch shortly to provide you with all the necessary information and to answer any questions you may have.</p>

                  <p>Once again, thank you for becoming a part of our family. We look forward to a fruitful and fulfilling journey together.</p>
                </Grid>
              </NoteDiv>
            )}

          </Grid>
          {!isMobile &&
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img src={charityData && charityData.image1} />
                    </ImageDiv>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={300} />
                  ) : (
                    <ImageDiv>
                      <img
                        src={
                          // "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          charityData && charityData.image2

                        }
                      />
                    </ImageDiv>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Container>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}

          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </CharityDiv>

  );
};

export default ForCharity;


{/* <p>
              The FInal Hour presents an innovative approach to fundraising,
              empowering people to make meaningful contributions every working
              day, week or month.
              <br />
              <br />
              Join a marketplace for charities and not-for-profit organsiations
              to reach new donors who share your chairtable purpose.
            </p> */}
