import { Box, Container, styled } from "@mui/material";
import React from "react";
import MemberBox from "../../Home/FeaturedMembers/MemberBox";
import { useNavigate } from "react-router-dom";

const FeatureSection = styled("div")(({ theme }) => ({
  background: theme.palette.primary.purple,
  padding: "40px 0",
  position: "relative",
  "& h2": {
    fontSize: "50px",
    margin: "0",
    paddingBottom: "20px",
    fontWeight: "200",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      paddingBottom: "10px",
    },
    "& span": {
      color: "#333",
    },
  },
}));

const FeatureGrid = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "1fr 1fr 1fr 1fr",
  // gridGap: "100px",
  paddingTop: "30px",
  paddingBottom: "60px",
  display: "flex",
  gap: "100px",
  width: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "30px",
    paddingTop: "20px",
    paddingBottom: "30px",
  },
  "& span": {
    color: "#fff"
  }
}));

const WorkplaceMembers = (props) => {
  const navigate = useNavigate();

  const handleClick = (member) => {
    // var newSlug = (member.firstName + " " + member.lastName)
    //   .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    //   .toLowerCase();
    navigate(`/members/detail/${member.slug}`, { state: member });
  };

  return (
    <FeatureSection>
      <Container maxWidth="xl">
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
        >
          <h2>Members</h2>
        </Box>

        <FeatureGrid>
          {!props.loading ? (
            props.members.length > 0 ? (
              props.members.map((mem) => (
                <MemberBox
                  image={mem.image}
                  memberName={mem.name}
                  hours={mem.totalHour}
                  handleClick={() => handleClick(mem)}
                />
              )))
              :
              <span>No Members</span>) : <span>Fetching Members..</span>
          }
        </FeatureGrid>
      </Container>
    </FeatureSection>
  );
};

export default WorkplaceMembers;
