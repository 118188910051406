import {
  Box,

  styled,

  Snackbar,
  Container,
} from "@mui/material";
import React from "react";
import parse from "html-react-parser";




const RootDiv = styled("div")(({ theme }) => ({
  marginBottom: "0px",
}));


const GridDiv = styled("div")(({ theme }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const RightDiv = styled("div")(({ theme }) => ({
  "& img": {
    height: "400px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      height: "350px",
    },
  },
  height: "400px",
  //width:"100%",
  [theme.breakpoints.down("md")]: {
    height: "350px",
  },
}));



const CategoryContent = styled("div")(({ theme }) => ({
  // padding: "45px",
  // marginLeft: "160px",
  paddingTop:"40px",
  paddingRight:"40px",
  position: "absolute",
  // paddingLeft:theme.spacing(15),
  width: "45%",
  top: "0",
 
  "& h2": {
    fontSize: "35px",
    margin: "0",
    paddingBottom: "20px"
  },
  "& p": {
    margin: "0"
  },
  [theme.breakpoints.down("md")]: {
    margin: "0px",
    padding: "20px",
    width: "auto",
    "& h2": {
      fontSize: "25px"
    },
  },
}));

const EnvironmentGrid = (props) => {
  //console.log("environment props", props);

  const LogoDiv = styled("div")(({ theme }) => ({
    transform: "rotate(90deg)",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      transform: "rotate(0deg)",
    },
    "& span": {
      background: props.category.categoryColor,
      padding: "32px",
      display: "inline-block",
      borderRadius: "50%",
      margin: "0px 35px",
      [theme.breakpoints.down("md")]: {
        margin: "0 20px",
        padding: "22px",
      },
    },
  }));



  const LeftDiv = styled("div")(({ theme }) => ({
    "& img": {
      height: "400px",
      width: "100%",
      objectFit: "cover",
      opacity: "0.2",
      [theme.breakpoints.down("md")]: {
        height: "350px",
        
      },
    },
    backgroundColor: props.category.categoryColor + "80",
    height: "400px",
    //width:"100%",
    [theme.breakpoints.down("md")]: {
      height: "350px",
    },
  }));

  return (
    <RootDiv>

      <GridDiv>
        <LeftDiv>
          <img src = {props.category.leftImage} alt="Image_1" />
          <CategoryContent className="leftContainerSlider" >
              <h2>{props.category.categoryName}</h2>
              <p>{parse(props.category.categoryShortDescription || "")}</p>
          </CategoryContent>
        </LeftDiv>



        <RightDiv>
          <img src={props.category.rightImage} alt="Image_2" />
        </RightDiv>
        <LogoDiv>
          <span></span>
          <span></span>
        </LogoDiv>
      </GridDiv>

    </RootDiv>
  );
};

export default EnvironmentGrid;
