import {
  Container,
  Grid,
  styled,
  Snackbar,
  Skeleton,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import BreadCrumb from '../../Components/BreadCrumb';
import CausesContent from '../../Components/Causes/CausesContent';
import CausesTop from '../../Components/Causes/CausesTop';
import { httpClient } from '../../appUtility/Api';
import LikeComment from '../../Components/Causes/EventNews/LikeComment';
import MuiAlert from "@mui/material/Alert";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const NewsSingle = () => {

  const location = useLocation();
  const { id } = useParams();
  const [newsID, setNewsID] = useState()
  const [allData, setAllData] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [newsArr, setNewsArr] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])


  useEffect(() => {
    getNewsSingle();
  }, [id])

  const getNewsSingle = () => {
    httpClient.get(`member-news/${id}`).then(({ data }) => {
      if (data.success) {
        setAllData(data.data)
      }
    })
  }

  const handleRefetch = () => {
    getNewsSingle(id);
    // getFeaturedCategories();
  };

  const handleLike = () => {

    httpClient
      .post(`like-news`, {
        newsID: allData.newsID,
        likeStatus: 1,

      })
      .then(({ data }) => {
        if (data.success) {
          setNewsArr([...newsArr, newsID]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          handleRefetch();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      })
  };
  const handleDisLike = () => {

    httpClient
      .post(`like-news`, {
        newsID: allData.newsID,
        likeStatus: 0,

      })
      .then(({ data }) => {
        if (data.success) {
          setNewsArr([...newsArr, newsID]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          handleRefetch();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      })
  };
  const handleAddComment = (comment) => {
    // console.log("handle", comment)
    setLoading(true);
    httpClient
      .post(`comment-news`, {
        comment: comment,
        newsID: allData.newsID,
        commentStatus: 1,
      })
      .then(({ data }) => {
        if (data.success) {
          setNewsArr([...newsArr, id]);
          setOpen(true);
          setMessageState('success');
          setMessage(data.message);
          setLoading(false);
          handleRefetch();
        } else {
          setOpen(true);
          setMessageState('error');
          setMessage(data.message);
          setLoading(false);
        }
      })

  };
  const handleEditComment = (comment, commentId) => {
    // console.log("handle", comment, commentId)
    setLoading(true);
    httpClient
      .post(`comment-news/${commentId}`, {
        comment: comment,
        newsID: allData.newsID,
        commentStatus: 1,
      })
      .then(({ data }) => {
        if (data.success) {
          setNewsArr([...newsArr, id]);
          setOpen(true);
          setMessageState('success');
          setMessage(data.message);
          setLoading(false);
          handleRefetch();
        } else {
          setOpen(true);
          setMessageState('error');
          setMessage(data.message);
          setLoading(false);
        }
      })

  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <div>
        <BreadCrumb
          header={"News"}
          subHeader1="News"
          Link1="/dashboard/causes_news_feed"
          subHeader2={allData && allData.newsTitle}
        />
        <CausesTop
          header={allData && allData.newsTitle}
          description={allData && allData.newsDescription.slice(0, 300) || ""}
          image={allData && allData.newsImage}
        />
        <CausesContent content={allData && allData.newsDescription || ""} />
        <LikeComment title={allData && allData.newsTitle}
          likes={allData && allData.likes.length ? allData.likes[allData.likes.length - 1].likeStatus : "0"}
          comments={allData && allData.newsComments}
          loading={loading}
          handleLike={() =>
            handleLike(allData.newsID)
          }
          handleDisLike={() =>
            handleDisLike(allData.newsID)
          }
          handleAddComment={(comment) =>
            handleAddComment(comment, allData.newsID)
          }
          handleEditComment={(comment, commentId) =>
            handleEditComment(comment, commentId, allData.newsID)
          }
       
        />
      </div>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={messageState} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

    </div>
  );

}

export default NewsSingle