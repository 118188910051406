import { Container, Grid, styled } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import MissionBg from '../../../images/mission-bg.jpg'

const MissionDiv = styled("div")(({ theme }) => ({
  background: theme.palette.primary.orange,
  color:"#fff",
  padding: "40px 0",
  height:"300px",
  backgroundSize:"cover !important",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
    height:"200px",
  },
  "& h2": {
    margin: "0",
    paddingBottom: "50px",
    [theme.breakpoints.down("md")]: {
        paddingBottom: "20px",
    },  
},
  "& h3":{
    fontSize:"35px",
    fontWeight: "300",
    margin:"0",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
  }, 
  },
  "& span":{
    fontSize:"45px",
  },

  "& img":{
    width:"300px",
    height:"300px",
    objectFit:"cover",
  }
}));

const OurMission = ({data}) => {
  return (
  <MissionDiv style={{ background: `url(${MissionBg})center right no-repeat` }}>
    <Container maxWidth="xl">
      {parse(data.section2 || "")}
        {/* <Grid container spacing={2}>
            <Grid item md={5.5} xs={12} >
            <h2>Our Mission</h2>
            
            <h3>
                " To make everyday work more meaningful for everyone. "
            </h3>
            </Grid>
            <Grid item md={6.5} xs={12}>
                <img src={"https://images.unsplash.com/photo-1593896385987-16bcbf9451e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1297&q=80"} alt="About Final hour" />
            </Grid>
        </Grid> */}
        
    </Container>
  </MissionDiv>
  )
};

export default OurMission;
