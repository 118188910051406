import { styled } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
// import Video from "./../../../../images/video.mp4";
// import "video-react/dist/video-react.css";
// import { Player } from "video-react";
import RightImage1 from './../../../../images/samples1/finalhour-home-carousel-right-01-min.jpg'
import RightImage2 from './../../../../images/samples1/finalhour-home-carousel-right-02-min.jpg'
import RightImage3 from './../../../../images/samples1/finalhour-home-carousel-right-03-min.jpg'
import RightImage4 from './../../../../images/samples1/finalhour-home-carousel-right-04-min.jpg'
import RightImage5 from './../../../../images/samples1/finalhour-home-carousel-right-05-min.jpg'
import RightImage6 from './../../../../images/samples1/finalhour-home-carousel-right-06-min.jpg'
import RightImage7 from './../../../../images/samples1/finalhour-home-carousel-right-07-min.jpg'
import RightImage8 from './../../../../images/samples1/finalhour-home-carousel-right-08-min.jpg'
import RightImage9 from './../../../../images/samples1/finalhour-home-carousel-right-09-min.jpg'
import RightImage10 from './../../../../images/samples1/finalhour-home-carousel-right-10-min.jpg'
import RightImage11 from './../../../../images/samples1/finalhour-home-carousel-right-11-min.jpg'
import RightImage12 from './../../../../images/samples1/finalhour-home-carousel-right-12-min.jpg'
import RightImage13 from './../../../../images/samples1/finalhour-home-carousel-right-13-min.jpg'
import RightImage14 from './../../../../images/samples1/finalhour-home-carousel-right-14-min.jpg'
import RightImage15 from './../../../../images/samples1/finalhour-home-carousel-right-15-min.jpg'

const AboutVideoSection = styled("div")(({ theme }) => ({
  background: theme.palette.primary.orange,
  // padding: "40px 0px 40px 40px ",
  backgroundSize:"cover !important",
  height:"auto",
  zIndex:"0",
  // "& .video-react .video-react-big-play-button": {
  //   left: "43%",
  //   top: "43%",
  //   [theme.breakpoints.down("md")]: {
  //     left: "37%", 
  //     top: "37%",
  //   },
  // },
  [theme.breakpoints.down("md")]: {
    height: "300px",
  },
}));


// const vidRef = useRef(null);
// console.log("videref", vidRef);
// useEffect(() => {}, []);
// const handlePlayVideo = () => {
//   vidRef.current.play();
// };

const AboutVideo = ({ data }) => {


const imagePaths = data && data.sliders.map(slider => slider.data.rightImage);
// const [imagePaths, setImagePaths] = useState([]);

//   useEffect(() => {
//     const storedImagePaths = localStorage.getItem('imagePaths');
//     if (storedImagePaths) {
//       setImagePaths(JSON.parse(storedImagePaths));
//     } else {
//       const initialImagePaths = data && data.sliders.map(slider => slider.data.rightImage);
//       setImagePaths(initialImagePaths);
//     }
//   }, [data]);

//   useEffect(() => {
//     localStorage.setItem('imagePaths', JSON.stringify(imagePaths));
//   }, [imagePaths]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % imagePaths.length);
    }, 7000);

    return () => {
      clearInterval(intervalId);
    };
  }, [imagePaths]);

  useEffect(() => {
    const preloadImages = (imagePaths) => {
      for (let i = 0; i < imagePaths.length; i++) {
        const img = new Image();      
        img.src = imagePaths[i];
        //img.crossOrigin = 'anonymous';
      }
    };

    preloadImages(imagePaths);
  }, [imagePaths]);
  
  return (
    <AboutVideoSection
      style={{
          background: `url(${imagePaths[currentImageIndex]}) center center no-repeat`,
         
          transition: "background-image 0.5s ease-in-out"
        }}
      >

    </AboutVideoSection>
  );
};

export default AboutVideo;
{/* <Player
        playsInline
        //   poster="/assets/poster.png"
        // src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        // src={Video}
        src={data.video_url1}
      /> */}