import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../Components/BreadCrumb'
import OurTeam from '../../Components/About/OurTeam'
import { httpClient } from '../../appUtility/Api';
import ContactHeader from './ContactHeader';
import ContactInfo from './ContactInfo';

const Contact = () => {
    const [teamData, setTeamData] = useState({});
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      
      getTeamdata();
    },[]);
    
  const getTeamdata = () => {
    httpClient('our-team').then(({data}) => {
      if(data.success){
        setTeamData(data.data)
      }
    })
  }

  return (
    <div>
        <BreadCrumb 
          header={"Contact Us"}
          subHeader1="Contact"
        />
        <ContactHeader />
        <ContactInfo />
        <OurTeam data={teamData && teamData} />

    </div>
  )
}

export default Contact