import { Container, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import CauseBox from "./CauseBox";

const CauseSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#EAB38B",
  //paddingRight: "3%",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    paddingRight: "0",
  },
}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#000",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& h2": {
    margin: "0",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c",
    },
  },
}));

const CausesNewsFeed = () => {
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    setLoading(true);
    httpClient.get("member-news").then(({ data }) => {
      if (data.success) {
        setNewsList(data.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const handleView = () => {
    navigate("/dashboard/causes_news_feed");
  };

  const handleClickCause = (news) => {
    var newSlug = news.newsCause.causeName
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: news.newsCause });
  }

  const handleClickNews = (news) => {
    var newSlug = news.newsTitle
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
      .toLowerCase();
    navigate(`/news/detail/${newSlug}`, { state: news });
  }


  return (
    <CauseSection>
       <Container maxWidth="xl">
        <HeaderDiv>
          <h2>News Feed</h2>
          <button onClick={handleView}>View all</button>
        </HeaderDiv>
        <div>
          {!loading ? (
            newsList.length > 0 ?
              newsList.map((news, index) => (
                <CauseBox
                  image={news.newsImage}
                  handleClickCause={() => handleClickCause(news)}
                  handleClickNews={() => handleClickNews(news)}
                  date={news.newsDate}
                  newsTitle={news.newsTitle}
                  cause={news.newsCause.causeName}
                />
              )) :
              "No News Found!"
          ) :
            (
              <>
                Fetching News..
              </>
            )
          }

          {/* <CauseBox image={"https://images.unsplash.com/photo-1668396855491-8fc25bc7f6f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"} date="2022-11-19" newsTitle="Test News" cause="new cause" />
            <CauseBox image={"https://images.unsplash.com/photo-1668396855491-8fc25bc7f6f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"} date="2022-11-19" newsTitle="Test News" cause="new cause" />
            <CauseBox image={"https://images.unsplash.com/photo-1668396855491-8fc25bc7f6f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"} date="2022-11-19" newsTitle="Test News" cause="new cause" />
            <CauseBox image={"https://images.unsplash.com/photo-1668396855491-8fc25bc7f6f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"} date="2022-11-19" newsTitle="Test News" cause="new cause" />
            <CauseBox image={"https://images.unsplash.com/photo-1668396855491-8fc25bc7f6f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"} date="2022-11-19" newsTitle="Test News" cause="new cause" /> */}
        </div>
      </Container>
    </CauseSection>
  );
};

export default CausesNewsFeed;
