import { Container, Grid, styled } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import About1 from '../../../images/about1.png'
import About2 from '../../../images/about2.png'

const HowSection = styled("div")(({ theme }) => ({
  background: theme.palette.primary.grey,
  padding: "40px 0",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  "& h3": {
    margin: "0",
    paddingBottom: "30px",
    fontWeight: "300",
    fontSize: "33px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      paddingBottom:"15px"
    },
  },
  "& img":{
    width:"100%",
    height:"100%"
  }
}));

const Header = styled("div")(({theme}) => ({
  width:"60%",
  [theme.breakpoints.down("md")]: {
    width:"100%"
  },
}))

const HowItWorks1 = ({data}) => {
  return (
    <HowSection>
      <Container maxWidth="xl">
        {/* <h2>How It Works</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <h3>It's about time.</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <p>
                  The Final Hour transforms the user interface for financial
                  donations from money earned to time invested. <br />
                  <br />
                  By replacing the material value of a dollar with the intrinsic
                  value of work and effort, the Final Hour flips our perception
                  from donating to a cause to working for a cause.
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  The Final Hour is a social enterprise to help scale
                  not-for-profit fundraising.
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Header>
              <h3>It’s as simple as this, giving feels good.</h3>
            </Header>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <p>
                  Effort spent identifying what another person can really
                  benefit from in a lasting way stirs feelings of empathy and
                  love.
                  <br />
                  <br />
                  Giving without knowing the other person can be even more
                  rewarding.
                  <br />
                  <br />
                  Giving anonymously - and spontaneously - can feel extremely
                  gratifying.
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  This manner of giving means not expecting something in return,
                  and that’s the true meaning of altruism. <br />
                  <br />
                  Giving from your heart connects you to humankind, boosts your
                  serotonin levels, expands and fulfils your soul.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      
      <Grid container mb={3}>
        <Grid item xs={12} md={6}>
          <img src={About1} alt="about_1" />
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={About2} alt="about_2" />
        </Grid>
      </Grid>
      {parse(data.section1 || "")}
      </Container>
    </HowSection>
  );
};

export default HowItWorks1;
