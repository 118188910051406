import { styled } from '@mui/material'
import React from 'react'

const CauseBox = styled("div")(({theme}) => ({
  textAlign:"center",
  width:"100%",
  padding:"20px",
  [theme.breakpoints.down("md")]: {
    padding:"0",
    paddingBottom:"40px",
  },
  "& p":{
    fontSize:"20px",
    fontWeight:"400",
    margin:"0 10%",
    paddingTop:"15px"
  }
}))

const Image = styled("img")(({theme}) => ({
  objectFit:"cover",
  borderRadius:"50%",
  height:"250px",
  width:"250px",
  border:"12px solid #f4893c",
}))

const CauseImageBox = (props) => {
  return (
    <CauseBox>
      <Image src={props.image} />
      <p>{props.text}</p>
    </CauseBox>
  )
}

export default CauseImageBox