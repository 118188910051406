import { Container, Grid, styled, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../appUtility/Api";
import BreadCrumb from "../../Components/BreadCrumb";
import MyFriendsBox from "../../Components/Dashboard/MyFriendsBox";
// import FeatureBox from "../../Components/Home/FeatureCauses/FeatureBox";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import OrgBox from "./OrgBox";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrganizationSection = styled("div")(({ theme }) => ({
  background: "#3B8775",
  padding: "40px 0",
  color:"#fff",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(4,1fr)",
  // gridGap: "50px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "100px",
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "20px",
  },
}));

const OrganizationPage = () => {
  const navigate = useNavigate();
  const [organizationList, setOrganizationList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [loading, setLoading] = useState(false);

  const [orgsArr, setOrgsArr] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getAllOrgs();
  }, []);

  const getAllOrgs = () => {
    setLoading(true);
    httpClient.get("organizations").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setOrganizationList(data.data);
      } else {
        setLoading(false);
      }
    });
  };

  const handleFavourite = (id) => {
    httpClient
      .post("member-fav-orgs", {
        orgID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setOrgsArr([...orgsArr, data.data]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          getAllOrgs();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
  };

  const handleFavoriteRemove = (id) => {
    // let favID;
    // orgsArr.map(org => {
    //   if(org.orgID === id){
    //     favID = org.favID;
    //   }
    // })
    httpClient.delete(`member-fav-orgs/${id}`).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        let newArr = orgsArr.filter((org) => org.orgID !== id);
        setOrgsArr(newArr);
        getAllOrgs();
      } else {
        setOpen(true);
        setMessageState("error");
        setMessage(data.message);
      }
    });
  };

  const handleClickOrg = (org) => {
    var newSlug = org.orgName
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
      .toLowerCase();
    navigate(`/charity_partners/detail/${newSlug}`, { state: org });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <BreadCrumb
        header="Charity Partners"
        subHeader1="Home"
        Link1="/"
        subHeader2="Charity Partners"
      />
      <OrganizationSection>
        <Container maxWidth="xl">
          {/* <h2>Organizations</h2> */}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <GridDiv>
              {organizationList &&
                organizationList.map((orgs) => (
                  <OrgBox
                    image={orgs.orgLogoName}
                    name={orgs.orgName}
                    hours={orgs.hours}
                    members={orgs.members}
                    handleClickCause={() => handleClickOrg(orgs)}
                    handleFavourite={() => handleFavourite(orgs.orgID)}
                    handleFavoriteRemove={() =>
                      handleFavoriteRemove(orgs.favoriteID)
                    }
                    messageState={
                      orgsArr.map((org) => org.orgID).includes(orgs.orgID)
                        ? "success"
                        : "false"
                    }
                    liked={orgs.favorite}
                    causes={orgs.causes}
                    favourite={true}
                  />
                ))}
            </GridDiv>
          )}
        </Container>
      </OrganizationSection>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrganizationPage;
