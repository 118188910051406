import { Container, styled } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const BreadSection = styled("div")(({ theme }) => ({
  padding: "20px 0",
  //borderBottom:"1px solid #000",
  "& h2":{
    margin:"0",
    fontWeight:"600"
  },
  "& span":{
    color: theme.palette.primary.greyText,
    fontSize:"14px"
  }
}));

const BreadCrumb = (props) => {
  const location = useLocation();
  return (
    <BreadSection>
      <Container maxWidth="xl">
        <h2>{props.header}</h2>
        <div>
          {props.subHeader1 && <span><Link to={props.Link1}> {props.subHeader1}</Link></span>}
          {props.subHeader2 && <span>
            <Link to={props.Link2} style={{ pointerEvents: props.Link2 ? "auto" : "none" }}>  / {props.subHeader2}</Link>
            </span>
          }
          {props.subHeader3 && <span>
            <Link to={props.Link3} style={{ pointerEvents: props.Link3 ? "auto" : "none" }}> / {props.subHeader3}</Link>
            </span>}
        </div>
      </Container>
    </BreadSection>
  );
};

export default BreadCrumb;
