import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { httpClient } from '../../appUtility/Api'
import BreadCrumb from '../../Components/BreadCrumb'
import CausesContent from '../../Components/Causes/CausesContent'
import CausesTop from '../../Components/Causes/CausesTop'
// import GiveHours from '../../Components/Causes/GiveHours'
import AllCauses from '../../Components/Causes/AllCauses'
import HelmetMetaData from '../../Components/HelmetMetaData'
import WorkplaceMembers from '../../Components/Workplace/WorkplaceMembers'
import WorkplaceNewsFeed from '../../Components/Workplace/WorkplaceNewsFeed'

const WorkplaceDetails = () => {
  const location = useLocation();
  const {id} = useParams();

  const navigate = useNavigate();
  const [allData, setAllData] = useState("");
  const [workplaceNews, setWorkplaceNews] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingNews, setLoadingNews] = useState(false);

  // console.log('id is', id)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
  //  setAllData(location.state)
    getWorkplaceSingle();
  },[])

  useEffect(() => {
    if(allData){
  
      getWorkplaceNews(allData);
    }
  },[allData])


  const getWorkplaceSingle = () => {
    setLoading(true);
    setLoadingNews(true);
    httpClient.get(`workplaces/${id}`).then(({data}) => {
      if(data.success){
        setAllData(data.data);
        setLoading(false);
        setLoadingNews(false);
      }
    })
  }

  const getWorkplaceNews = (allData) => {
    setLoadingNews(true);
    httpClient.get(`news?workplaceID=${allData && allData.workplaceID}`).then(({ data }) => {
      if (data.success) {
        setWorkplaceNews(data.data)
        setLoadingNews(false);
      }
    })
}

  // console.log('orgID', orgID);

  const handleLink = () => {
    // navigate(`/causes/category/detail/${allData && allData.slug}`, { state: allData && allData });
  }

  // console.log('org all', allData);
  
  return (
    <div>
      <HelmetMetaData
        title={allData && allData.name}
        description={allData && allData.message}
        image={allData && allData.image}
      />
        <BreadCrumb header={"Workplace"} subHeader1="Workplace" Link1="/workplaces" subHeader2={allData && allData.name} />
        <CausesTop orgID={allData && allData.workplaceID} header={allData && allData.name} description={" "} showFav={false} image={allData && allData.image} allData={allData && allData} />
        <CausesContent content={allData && allData.message || ""} />

        {/* <AllCauses causeList={allData && allData.causes} /> */}
        <WorkplaceMembers members={allData && allData.members} loading={loading} />
        <WorkplaceNewsFeed newsList = {workplaceNews && workplaceNews} loadingNews={loadingNews}  />

    </div>
  )
}

export default WorkplaceDetails