import { styled } from '@mui/material'
import React from 'react'

const DonationDiv = styled("div")(({ theme }) => ({
    padding: "10px",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "& h5": {
        margin: "0",
        cursor: "pointer"
    },
    [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column", // Change flex direction to column for mobile view
    alignItems: "flex-start", // Adjust alignment for mobile view
},
}))
const Name = styled("div")(({ theme }) => ({
    width: "260px",
}))

const Hours = styled("div")(({ theme }) => ({
    width: "120px",
}))
const Amount = styled("div")(({ theme }) => ({
    width: "120px",
}))

const DonationBox = (props) => {
    return (
        <>
            <DonationDiv>
                <div>
                    <Name>
                    <span>{props.date}</span>
                    <h5 onClick={props.handleClickCause}>{props.cause}</h5>
                    </Name>
                </div>
                <div>
                    <Hours>{props.hours}</Hours>
                </div>
                <div>
                    <Amount>{props.amount}</Amount>
                </div>
            </DonationDiv>
                       
        </>
    )
}

export default DonationBox