import React, { useEffect, useState } from 'react'
import Governance from '../../Components/About/Governance'
import HowItWorks1 from '../../Components/About/HowItWorks'
import HowItWorks from '../../Components/Home/HowItWorks'
import MemberStatus from '../../Components/About/MemberStatus'
import OurMission from '../../Components/About/OurMission'
import OurTeam from '../../Components/About/OurTeam'
import BreadCrumb from '../../Components/BreadCrumb'
import { httpClient } from '../../appUtility/Api'
import Questions from '../../Components/FAQs/Questions'
import FooterGive from '../../Components/FooterGive'
import HelmetMetaData from '../../Components/HelmetMetaData'

const About = () => {
  const [aboutData, setAboutData] = useState({});
  const [teamData, setTeamData] = useState({});
  const [faqData, setFaqData] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getFaqs();
    getAboutdata();
    getTeamdata();
  }, []);

  const getAboutdata = () => {
    httpClient.get('about').then(({ data }) => {
      if (data.success) {
        setAboutData(data.data);
      }
    })
  }

  const getTeamdata = () => {
    httpClient('our-team').then(({ data }) => {
      if (data.success) {
        setTeamData(data.data)
      }
    })
  }
  const getFaqs = () => {
    httpClient.get("faqs").then(({ data }) => {
      if (data.success) {
        setFaqData(data.data);
      }
    });
  };


  return (
    <div>
      <HelmetMetaData
        title={"The Final Hour boosts charity giving by inspiring frequent donations."}
      />
      <BreadCrumb
        header={"About Final Hour"}
        subHeader1="About"
      />
      <HowItWorks1 data={aboutData && aboutData} />
      <OurMission data={aboutData && aboutData} />
      <HowItWorks />
      <FooterGive />
      {/* <Questions data={faqData && faqData} /> */}
      {/* <MemberStatus data={aboutData && aboutData} />
        <OurTeam data={teamData && teamData} /> */}
      {/* <Governance /> */}
    </div>
  )
}

export default About