import React, { useEffect, useState } from 'react'
import AgreementHeader from './AgreementHeader'
import BreadCrumb from '../../Components/BreadCrumb'
import AgreementBody from './AgreementBody'
import { httpClient } from '../../appUtility/Api'
import { useLocation } from 'react-router-dom'

const UserAgreement = () => {
  const [allData, setAllData] = useState({})
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
    getSinglePage()
  },[])


  const getSinglePage = () => {
    httpClient.get(`page${location.pathname}`).then(({data}) => {
      if(data.success){
        setAllData(data.data)
      }
    })
  }
  
  return (
    <div>
        <BreadCrumb 
          header={allData.title}
          subHeader1={allData.title}
        />
        
        <AgreementHeader data={allData} />
        <AgreementBody data={allData} />
    </div>
  )
}

export default UserAgreement