import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/material";
import React from "react";

const WorkingDiv = styled("div")(({ theme }) => ({
  width: "300px",
  padding: "30px",
  textAlign:"center",
  position:'relative',
  [theme.breakpoints.down("md")]: {
    padding: "0",
    textAlign:"center",
    paddingBottom:"20px"
  },

  "& p":{
    textAlign:"center",
  },
  "& span":{
    background: theme.palette.primary.red,
    borderRadius:"50%",
    padding:"5px",
    display:"flex",
    height:"20px",
    width:"20px",
    alignItems:"center",
    justifyContent:"center",
    color:"#fff",
  },

  "& img": {
    objectFit: "cover",
    borderRadius: "50%",
    height: "200px",
    width: "200px",
    border:"12px solid #B3B3A7",
  },
  "& svg":{
    position: "absolute",
    right:"0",
    border:"1px solid #999",
    color:"#999",
    paddding:"5px",
    top:"160px",
    borderRadius:"50%",
    // color: theme.palette.primary.greyText,
    [theme.breakpoints.down("md")]: {
      // textAlign:"center",
      // position:"relative",
      display:"none"
    },
  }
}));

const WorkingBox = (props) => {
  return (
    <WorkingDiv>
      {props.index ? <span>{props.index}</span> : ""}
      <img src={props.image} />
      {props.arrow ? <ChevronRight fontSize="small" /> : ""}
      <p>{props.text}</p>
    </WorkingDiv>
  );
};

export default WorkingBox;
