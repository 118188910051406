import { Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import OrangeDotted from "./../../../images/samples/causes-dotted.png";

const RootDiv = styled("div")(({ theme }) => ({
  // background: "#EAB38B",
  position:"relative",
  zIndex:"1",
}));

// const CauseDiv = styled("div")(({ theme }) => ({
//   // backgroundColor: "#EAB38B",
//   // background: "linear-gradient(to bottom, #EAB38B 50%, #E3A16D 50%)",
//   height: "400px",
//   padding: "10px 0",
//   position:"relative",
//   paddingBottom: "80px",
//   [theme.breakpoints.down("md")]: {
//     height:"100%",
//     paddingBottom: "0px",
//   },

// }));
// const CauseText = styled("div")(({ theme }) => ({
//   "& p": {
//     fontSize: "23px",
//     [theme.breakpoints.down("md")]: {
//       fontSize: "18px",
//     },
//   },
// }));

// const CauseImageDiv = styled("div")(({ theme }) => ({
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   justifyContent: "center",
//   gap:"40px",
//   [theme.breakpoints.down("md")]: {
//     display: "block",
//   },
// }));

const DottedImageDiv = styled("div")(({ theme }) => ({
  background: "#EAB38B",
  paddingTop:"80px",
    [theme.breakpoints.down("md")]: {
    paddingTop:"40px",
  },
  "& img": {
    width: "100%",
    height: "120px",
    // maxHeight:"170px",
    // maxWidth:"100%",
    // overflow:"hidden",
  },
  "& h2": {
    textAlign: "center",
    color: "#fff",
    margin: "0",
  },
}));

const BottomImage = styled("div")(({theme}) => ({
  position:"relative",
  top:"20px",
  [theme.breakpoints.down("md")]: {
    top:"10px",
  },
  "& img": {
    width: "100%",
    height: "120px",
    // maxHeight:"170px",
    // maxWidth:"100%",
    // overflow:"hidden",
  },
}))

const CategoryDiv = styled("div")(({ theme }) => ({
  paddingTop: "30px",
  paddingBottom: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
 
  gap: "15px",
 [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop:"20px",
    paddingBottom:"30px",
  },
  "& a": {
    border: "1px solid #333",
    borderRadius: "10px",
    padding: "5px 15px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "20px",
      width: "20px",
      objectFit: "contain",
    },
    "& p": {
      margin: "0",
    },
  },
}));

const CauseToInvest2 = (props) => {
  const [category, setCategory] = useState([]);
  // const [invested, setInvested] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFeaturedCategories();
  }, []);

  const getFeaturedCategories = () => {
    setLoading(true);
    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategory(data.data);
        setLoading(false);
      }
    });
  };

  return (
    <RootDiv>
      <DottedImageDiv>
      <Container maxWidth="lg" >
            <h2>What causes do you want to invest your time with?</h2>
            <CategoryDiv>
              {category && category.map((cats,index) => {
                if (cats.parentCategoryID == null) {
                  return (
                    <Link
                      to={`/causes/category/${cats.slug}`}
                      state={cats}
                      style={{ backgroundColor: cats.categoryColor }}
                    >
                      <img src={cats.categoryIcon} at={cats.categoryName} />

                      <p>{cats.categoryName}</p>
                    </Link>
                  );
                }
              })}
            </CategoryDiv>
          </Container>
        <BottomImage>
        <img src={OrangeDotted} alt="orange_dotted" />
        </BottomImage>
      </DottedImageDiv>
    </RootDiv>
  );
};

export default CauseToInvest2;
