import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/BreadCrumb';
import AllCauses from '../../../Components/Causes/AllCauses';
import CausesContent from '../../../Components/Causes/CausesContent';
import CausesTop from '../../../Components/Causes/CausesTop';
import GiveHours from '../../../Components/Causes/GiveHours';
import { httpClient } from '../../../appUtility/Api';

const CauseCategoryDetail = () => {
  const location = useLocation();
  const {id} = useParams();
  const [allData,setAllData] = useState("");


  // useEffect(() => {
  //   setAllData(location.state);
  // },[location.state])

  useEffect(() => {
    getCauseDetail()
  },[])

  const getCauseDetail = () => {
    httpClient.get(`cause-category/${id}`).then(({ data }) => {
      if (data.success) {
        setAllData(data.data)
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    })
  }

  return (
    <div>
        <BreadCrumb header={allData && allData.categoryName} subHeader1="Causes" Link1="/causes" subHeader2={allData && allData.categoryName} />
        <CausesTop header={allData && allData.categoryName} description={allData && allData.categoryShortDescription || ""} image={(allData && allData.categoryImage) || ""} />
        <CausesContent content={allData && allData.categoryDescription || ""} />
        <AllCauses causeList={[]} />
        <GiveHours />
    </div>
  )
}

export default CauseCategoryDetail