import { Container, Grid, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeaderBoardComponent from "../LeaderBoardComponent";
import { httpClient } from "../../../appUtility/Api";
import { useNavigate } from "react-router-dom";

const MySection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#3B8775",

}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#fff",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom:"50px",
  [theme.breakpoints.down("md")]: {
    marginBottom:"10px"
},
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor:"pointer",
    transition:"0.3s",
    "&:hover":{
      background:"#6e6c6c"
    }
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  display:"flex",
  flexDirection:"row",
  flexWrap:"wrap",
  justifyContent:"center", 
  gap:"120px",
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "20px",
  },
}));

const MyWorkplaces = () => {
  const navigate = useNavigate();
  const login = JSON.parse(localStorage.getItem("login"));
  const workplaceID = login?.member?.workplace?.workplaceID;

  const [workplaceData, setWorkplaceData] = useState([]);
  useEffect(() => {
    getMyWorkplace();
  },[])

  const getMyWorkplace = () => {
    httpClient.get(`my-workplace/${workplaceID}`).then(({data}) => {
      if(data.success){
        setWorkplaceData(data.data)
      }
    })
  }


  const handleClick = (work) => {
    // console.log('work is', work);

    navigate(`/workplaces/detail/${work.slug}`);
  }
   
  return (
    <MySection>
      <Container maxWidth="xl">
        <HeaderDiv>
          <h2>My Workplace</h2>
          {/* <button>View all</button> */}
        </HeaderDiv>
        <GridDiv>
          {workplaceData ?
            <LeaderBoardComponent
              image={workplaceData.image}
              // index={index+1}
              memberName={workplaceData.name}
              handleClick={()=> handleClick(workplaceData)}
              // hours="City, 95 hours"
            />
          : "No workplace found!"
        }
            
            {/* <LeaderBoardComponent
              image={
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              }
              index="2nd"
              memberName="Sara Harrison"
              hours="City, 95 hours"
            />
            <LeaderBoardComponent
              image={
                "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              }
              index="3rd"
              memberName="Max Fury"
              hours="City, 95 hours"
            />
            <LeaderBoardComponent
              image={
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              }
              index="4th"
              memberName="Sara Harrison"
              hours="City, 95 hours"
            /> */}
          </GridDiv>
      </Container>
    </MySection>
  );
};

export default MyWorkplaces;
