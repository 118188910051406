import { Container, styled } from '@mui/material'
import React from 'react'

const GovDiv = styled("div")(({theme}) => ({
    background:'#8D8656',
    padding:"40px 0",
}))

const Governance = () => {
  return (
    <GovDiv>
        <Container maxWidth="xl">
        <h2>Governance</h2>
        </Container>
    </GovDiv>
  )
}

export default Governance