import { Box, styled } from "@mui/material";
import React, { useEffect, useRef } from "react";
// import Video from "./../../../../images/video.mp4";
// import "video-react/dist/video-react.css";
// import { Player } from "video-react";
import RightImage from "./../../../../images/samples/editevening_Cinematic_shot_shot_on_Kodak_Gold_up_close_of_femal_739a89eb-2894-4fee-b9ab-ce6bc226b08f.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import HelmetMetaData from "../../../HelmetMetaData";
import MetaIcon from "../../../../images/metaicon.png";
import Xicon from "../../../../images/Xicon.png";
import LinkedinLogo from "../../../../images/linkedinIcon.png";

const AboutVideoSection = styled("div")(({ theme }) => ({
  background: theme.palette.primary.orange,
  // padding: "40px 0px 40px 40px ",
  backgroundSize: "cover !important",
  height: "auto",
  zIndex: "0",
  [theme.breakpoints.down("md")]: {
    height: "300px",
    zIndex: "0",
  },
}));

const AboutVideo = ({ props }) => {
  const vidRef = useRef(null);
  useEffect(() => { }, []);
  const handlePlayVideo = () => {
    vidRef.current.play();
  };

  //console.log('data video re', props);


  return (
    <>
      <HelmetMetaData
        title={props && props.allData.name}
        description={
          props.allData &&
          props.allData.shortDescription.substring(
            1,
            
            props.allData.shortDescription.length - 1
          )
        }
        image={props.allData && props.allData.image}
      />
      <AboutVideoSection
        style={{
          background: `url(${props?.allData?.rightImage || RightImage
            }) center center no-repeat`,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          p={2}
        >
          <span
            style={{
              marginRight: "10px",
              color: "#fff",
              mixBlendMode: "difference",

            }}
          >
            Share :{" "}
          </span>
          <FacebookShareButton
            url={window.location.href}
            size={30}
            style={{ marginRight: "5px" }}
          >
            <img
              src={MetaIcon}
              alt="meta_icon"
              style={{ height: "30px", width: "30px" }}
            />
          </FacebookShareButton>
          <LinkedinShareButton
            url={window.location.href}
            style={{ marginRight: "5px" }}
          >
            <img
              src={LinkedinLogo}
              alt="linkedin_icon"
              style={{ height: "30px", width: "30px" }}
            />
          </LinkedinShareButton>

          <TwitterShareButton
            url={window.location.href}
          >
            <img
              src={Xicon}
              alt="X_icon"
              style={{ height: "30px", width: "30px" }}
            />
          </TwitterShareButton>
        </Box>
      </AboutVideoSection>
    </>
  );
};

export default AboutVideo;
