import { styled } from "@mui/material";
import React from "react";

const CauseDiv = styled("div")(({ theme }) => ({
  padding: "10px",
  borderBottom: "1px solid #000",
  display: "flex",
  flexDirection:"row",
  alignItems:"center",
  "& img": {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    marginRight:'20px',
  },
  "& h3":{
    margin:"0",
    fontSize:"25px",
    fontWeight:"500",
    cursor:"pointer",
    [theme.breakpoints.down("md")]: {
      fontSize:"20px",
    },
  }
}));

const CauseBox = (props) => {
  return (
    <CauseDiv>
      <div>
        <img src={props.image} alt={props.newsTitle} />
      </div>
      <div>
        <span>{props.date}</span>
        <h3 onClick={props.handleClickNews}>{props.newsTitle}</h3>
        <span style={{ cursor:"pointer" }} onClick={props.handleClickCause}>{props.cause}</span>
      </div>
    </CauseDiv>
  );
};

export default CauseBox;
