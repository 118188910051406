import { Container, styled } from '@mui/material'
import React from 'react'
import parse from 'html-react-parser';

const CauseDiv = styled("div")(({theme}) => ({
    background: "#B8B6A9",
    padding: "40px 0",

}))
const CausesContent = (props) => {
  return (
    <CauseDiv>
        <Container maxWidth="xl">
            <div>
            {/* {props.content} */}
            {parse(props.content)}
            </div>
        </Container>
    </CauseDiv>
  )
}

export default CausesContent