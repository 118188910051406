import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import React from "react";

const FeatureDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  cursor:"pointer",
  position:"relative",
  zIndex:"0",
  [theme.breakpoints.down("md")]: {
    padding:'0',
  },
  "& img": {
    objectFit: "cover",
    height:"250px",
    width: "250px",
    borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
      height:"250px",
      width:"250px"
    },
  },
  "& p":{
    margin:"0",
    fontWeight:"600"
  }
}));


const OrgBox = (props) => {
  const AddBox = styled("div")(({theme}) => ({
      zIndex:"1",
      position: "absolute",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      height:"27px",
      width:"27px",
      right: "10px",
      top: "10px",
      borderRadius: "50%",
      padding: "6px",
      color: "pink",
      cursor:"pointer",
      backgroundColor: "#fafafa",
      [theme.breakpoints.down("md")]: {
        right:"10px",
        top:"10px"
      },
  }))

  return (
    <FeatureDiv >
      {props.remove && (
      <AddBox>
        <Tooltip title="Remove from Favorite">
          <Favorite onClick={props.handleFavoriteRemove} />
        </Tooltip>
      </AddBox>
      )}

      {props.favourite && (
      // props.messageState !== "success" ?
      props.liked !== 1 ?
        <AddBox>
          <Tooltip title="Add To Favorite">
            <FavoriteBorder onClick={props.handleFavourite} />
          </Tooltip>
        </AddBox>
        :
        <AddBox>
          <Tooltip title="Remove from Favorite">
            <Favorite onClick={props.handleFavoriteRemove} />
          </Tooltip>
        </AddBox>
      )}
      <div onClick={props.handleClick}>
        <img src={props.image} />
        <p>{props.name}</p>
        
        {props.hours &&
        <span><strong>{props.hours}</strong> hours</span>}
      </div>

    </FeatureDiv>
  );
};

export default OrgBox;
