import React from 'react'
import ForCharity from '../../Components/BecomeAPartner/ForCharity'
import ForWorplace from '../../Components/BecomeAPartner/ForWorkplace'
import BreadCrumb from '../../Components/BreadCrumb'
import HelmetMetaData from '../../Components/HelmetMetaData'

const WorkplacePage = () => {
  return (
    <div>
      <HelmetMetaData
        title={"The Final Hour boosts workplace giving with easy, flexible donation options."}
      />
      <BreadCrumb header="Become a Partner - Workplace" subHeader1="Workplace" />
      {/* <ForCharity /> */}
      <ForWorplace />
    </div>
  )
}

export default WorkplacePage