import { ThemeProvider } from "@mui/material";
import "./App.css";
import Routing from "./Routing";
import theme from "./theme/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { Helmet } from "react-helmet";
import HelmetMetaData from "./Components/HelmetMetaData";
import { HelmetProvider, Helmet } from "react-helmet-async";
// import HelmetMetaData from "./Components/HelmetMetaData";
// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
// const stripePromise = loadStripe("pk_test_51MZURlJPyNbFqS59l2okiV5Iq9sKkwtfg2PMjTBuuu5Wgz1Iq4GZu39TtJwihHFrvqkR5HINp3beelqkSEdSxk2e00PgyE8J3y");

function App() {
  return (
    <>
      <HelmetMetaData />

      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId="111449903614-m8mosuasrho48r18mov7a5kia5pb0sms.apps.googleusercontent.com">
          {/* <Helmet>  
        <html lang="en" />  
        <title>Final Hour Web App</title>  
        <meta name="description" content="Tutorial for React Helmet" />  
        <meta name="theme-color" content="#000000" />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:site_name" content="FinalHour" />
        <meta property="og:description" content="" />

        <meta property="og:image" content={"https://api.finalhour.org/uploaded/cms/section1/cms-2023060104330113.jpg"} />

      </Helmet>   */}

          <Routing />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
