import { Container, Grid, styled, Snackbar, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import FeatureBox from "../../../Components/Home/FeatureCauses/FeatureBox";
import BreadCrumb from "../../../Components/BreadCrumb";
import { httpClient } from "../../../appUtility/Api";
import RemoveOrganizationDialog from "./RemoveOrganizationDialog";
import OrgBox from "./OrgBox";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrganizationSection = styled("div")(({ theme }) => ({
  background: "#3B8775",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(4,1fr)",
  // gridGap: "50px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "100px",
  flexWrap: "wrap",

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));

const FavOrganizationPage = () => {
  const navigate = useNavigate();
  const [organizationList, setOrganizationList] = useState([]);
  const [opneViewDialog, setOpenViewDialog] = useState(false);
  const [organizationID, setOrganizationID] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [orgsArr, setOrgsArr] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getAllOrgs();
  }, []);

  const getAllOrgs = () => {
    setLoading(true);
    httpClient.get("member-fav-orgs").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setOrganizationList(data.data);

      }
    }).catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        navigate("/")
      }
    })
  };

  const handleClick = (orgs) => {
    var newSlug = orgs.orgName
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
      .toLowerCase();
    navigate(`/charity_partners/detail/${newSlug}`, { state: orgs });
  };

  // const handleFavourite = (id) => {
  //   httpClient.post('member-fav-orgs',{
  //     orgID: id
  //   }).then(({data}) => {
  //     if(data.success){
  //       setOrgsArr([...orgsArr,data.data])
  //       setOpen(true);
  //       setMessageState("success");
  //       setMessage(data.message);
  //     }else{
  //       setOpen(true);
  //       setMessageState("error");
  //       setMessage(data.message);
  //     }
  //   })
  // }

  const handleFavoriteRemove = (id) => {
    setOpenViewDialog(true);
    setOrganizationID(id);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenViewDialog(false);
      setOrganizationID("");
    }
    if (call.success === true) {
      httpClient.delete(`member-fav-orgs/${organizationID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          getAllOrgs();
          setOpenViewDialog(false);
          setOrganizationID("");
          // let newArr = orgsArr.filter(org => org.orgID !== id);
          // setOrgsArr(newArr);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
    }
  };

  //console.log("orgs arr", orgsArr);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <BreadCrumb
        header="Favorite Charity Partners"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="Charity Partners"
      />
      <OrganizationSection>
        <Container maxWidth="xl">
          {/* <h2>Organizations</h2> */}
          <GridDiv>
            {!loading ? (
              organizationList ? (
                organizationList.map((orgs) => (
                  // <FeatureBox
                  //   image={orgs.orgLogoName}
                  //   name={orgs.orgName}
                  //   // handleFavourite={() => handleFavourite(orgs.orgID)}
                  //   handleFavoriteRemove={() => handleFavoriteRemove(orgs.favID)}
                  //   messageState={
                  //     orgsArr.map((org) => org.orgID).includes(orgs.orgID)
                  //       ? "success"
                  //       : "false"
                  //   }
                  //   remove={true}
                  // />

                  <OrgBox
                    image={orgs.orgLogoName}
                    name={orgs.orgName}
                    // handleFavourite={() => handleFavourite(orgs.orgID)}
                    handleFavoriteRemove={() => handleFavoriteRemove(orgs.favID)}
                    messageState={
                      orgsArr.map((org) => org.orgID).includes(orgs.orgID)
                        ? "success"
                        : "false"
                    }
                    remove={false}
                    handleClick={() => handleClick(orgs)}
                  />
                ))) : "No charity partners found") :
              <>
                <Skeleton variant="circular" width={250} height={250} />
                <Skeleton variant="circular" width={250} height={250} />
                <Skeleton variant="circular" width={250} height={250} />
                <Skeleton variant="circular" width={250} height={250} />
              </>
            }
          </GridDiv>
        </Container>
      </OrganizationSection>

      {opneViewDialog && (
        <RemoveOrganizationDialog callBack={callBack} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FavOrganizationPage;
