import { Box, Button, Container, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import MemberBox from "./MemberBox";

const FeatureSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  //   background: theme.palette.primary.orange,
  background: "#3B8775",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    fontSize: "50px",
    margin: "0",
    paddingBottom: "20px",
    fontWeight: "200",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      paddingBottom: "10px",
    },
    "& span": {
      color: "#333",
    },
  },
}));

const FeatureGrid = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "1fr 1fr 1fr 1fr",
  // gridGap: "100px",
  display:"flex",
  gap:"100px",
  width:"100%",
  flexDirection:"row",
  flexWrap:"wrap",
  alignItems:"center",
  justifyContent:"center",

  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "30px",
  },
}));

const FeatureButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  backgroundColor: "#f1f1f1",
  outline: "none",
  border: "1px solid #333",
  padding: "5px 15px",
  fontSize: "14px",
  cursor: "pointer",
  transition: "0.3s",
  textTransform:"none",
  "&:hover": {
    background: "#6e6c6c",
    color:"#fff"
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    padding: "5px",
  },
}));

const FeatureOrganizations = () => {
  const navigate = useNavigate();
  const [orgList, setOrgList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    httpClient.get("featured-organizations").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setOrgList(data.data.data);
      }
    });
  }, []);

  const handleClickOrg = (org) => {
    var newSlug = org.orgName.replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_").toLowerCase();
    navigate(`/charity-partners/detail/${newSlug}`, { state: org });
  };

  const handleView = () => {
    navigate("/charity-partners");
  };

  return (
    <FeatureSection>
      <Container maxWidth="xl">
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
        >
          <h2>Featured Charity Partners</h2>
          <FeatureButton onClick={handleView}>View all</FeatureButton>
        </Box>
        {!loading ? (
          <FeatureGrid>
            {orgList &&
              orgList.map((org) => (
                // <FeatureBox
                //   key={org.orgID}
                //   image={org.orgLogoName}
                //   name={org.orgName}
                //   handleClickCause={() => handleClickOrg(org)}
                //   favorite={false}
                // />
                <MemberBox
                  key={org.orgID}
                  image={org.orgLogoName}
                  memberName={org.orgName}
                  members={org.members}
                  hours={org.hours}
                  handleClick={() => handleClickOrg(org)}
                />
              ))}
          </FeatureGrid>
        ) : (
          <FeatureGrid>
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
          </FeatureGrid>
        )}
      </Container>
    </FeatureSection>
  );
};

export default FeatureOrganizations;
