import { styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../../appUtility/Api'

const CommunitySection = styled("div")(({theme}) => ({
    //marginTop: "40px",
    padding:"40px 0",
    background: "#ED6B76",
    paddingLeft:"3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft:"0",
  },
    "& h2":{
        margin:"0",
        paddingBottom:"30px"
    }
}))

const WhiteTexts = styled("div")(({theme}) => ({
    color:"#fff",
    display:"flex",
    flexDirection:'column',
    "& h2":{
        margin:"0",
        paddingBottom:"20px"
    }
}))

const CommunityStatistics = () => {
    const [communityData, setCommunityData] = useState({});

    useEffect(() => {
        getCommunityData();
    },[])

    const getCommunityData = () => {
        httpClient.get('community-section').then(({data}) => {
            if(data.success){
                setCommunityData(data.data);
            }
        })
    }

  return (
    <CommunitySection>
        <div className='rightContainer'>
        <h2>Community Section</h2>
        <WhiteTexts>
            <h2>{communityData.hours} Hours given</h2>
            <h2>{communityData.members} Members</h2>
            <h2>{communityData.causes} Causes</h2>
            <h2>{communityData.orgs} Charity Partners</h2>
            
        </WhiteTexts>
        </div>
    </CommunitySection>
  )
}

export default CommunityStatistics