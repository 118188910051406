import * as React from "react";
import { Container, styled, Grid, Tooltip, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import { Info } from "@mui/icons-material";
import { Button, CardActionArea, CardActions } from "@mui/material";
import parse from "html-react-parser";
import moment from "moment";

const CauseDiv = styled("div")(({ theme }) => ({
  background: "#ffffff",
  padding: "40px 0",
  fontFamily: "Epilogue, sans-serif !important",
  "& h5": {
    margin: "0",
    fontWeight: "300",
    padding: "20px 0",
    fontSize: "18px",
    color: "#fff",
    zIndex: "1",

    [theme.breakpoints.down("md")]: {
      padding: "0px 0",
      marginTop: "20px",
      fontSize: "40px",
    },
  },
  "& p": {
    margin: "0",
    color: "black",
    paddingBottom: "10px",
    fontWeight: "500",
  },
}));
// const Event = styled("div")(({ theme }) => ({

//     display: "flex",
//     alignItems: "center",
//     gap: "100px",
//     [theme.breakpoints.down("md")]: {
//         width: "100%",
//         //marginBottom: "10px",
//         flexDirection: "column",
//         textAlign: "center",
//     },
// }))
const Event = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  alignItems: "center",
  gap: "30px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const Title = styled("div")(({ theme }) => ({
  margin: "0 0px 10px 0",
  fontSize: "16px",
  fontWeight: "900",
  fontFamily: "Epilogue, sans-serif !important",
  //float: "left",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const Description = styled("div")(({ theme }) => ({
  margin: "0 0px 10px 0",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Epilogue, sans-serif !important",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const GiveButton = styled("button")(({ theme }) => ({
  padding: "10px 18px",
  borderRadius: "100px",
  border: "1px solid gray !important",
  color: theme.palette.primary.gray,
  background: theme.palette.primary.grey,
  marginTop: "10px",
  transition: "0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
    marginTop: "0",
  },
  "&:hover": {
    background: `${theme.palette.primary.red} !important`,
    color: "#fff !important",
    border: `1px solid gray !important`,
  },
}));
const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#000",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
  marginRight: "200px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& h2": {
    margin: "0",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 10px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c",
    },
  },
}));

const Line = styled("div")(({ theme }) => ({
  background: "#416C5B",
  width: "100%",
  margin: "10px auto",
  height: "1px",
  borderRadius: "5px",
}));

const eventList = [
  {
    id: "1001",
    title: "Event Name1",
    image:
      "https://images.unsplash.com/photo-1472289065668-ce650ac443d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: "1002",
    title: "Event Name2",
    image:
      "https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: "1003",
    title: "Event Name3",
    image:
      "https://images.unsplash.com/photo-1534361960057-19889db9621e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getNewsPublishedDate = (dateString) => {
  const date = moment(dateString);
  return `${months[date.month()]} ${date.date()}, ${date.year()}`;
};

const UpcomingEvents = ({ newsList }) => {
  const navigate = useNavigate();

  const handleView = () => {
    navigate("/news/more_news_list");
  };

  const handleClickNews = (news) => {
    var newSlug = news.newsTitle
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
      .toLowerCase();
    navigate(`/more-news/detail/${newSlug}`, { state: news });
  };
  //console.log("newsList", newsList);

  return (
    <CauseDiv>
      <Container maxWidth="xl">
        {/* <Tooltip title="The component is static for now">

                    <Info style={{  color: "navyblue" }} />
                </Tooltip> */}
        <HeaderDiv>
          <h2>News Feed</h2>
          <button onClick={handleView}>View all</button>
        </HeaderDiv>
        <Event>
          {newsList.length > 0
            ? newsList
                .sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate))
                .map(
                  (news, index) =>
                    index < 3 && (
                      <Card
                        sx={{
                          width: 390,
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="260"
                            width="260"
                            image={news.newsImage}
                            alt={news.newsTitle}
                          />
                          <CardContent sx={{ pb: 0 }}>
                            {/* <Typography gutterBottom variant="h5" component="div">
                                            {event.title}
                                        </Typography> */}
                            <Title>
                              {parse(news.newsTitle?.slice(0, 50) + "...")}
                            </Title>
                            <Description sx={{ mb: 0 }}>
                              {parse(
                                news.newsDescription?.slice(0, 200) + "..."
                              )}
                            </Description>
                          </CardContent>
                        </CardActionArea>

                        <Box>
                          <CardContent sx={{ py: 0 }}>
                            <Line sx={{ opacity: 0.3 }} />
                            <Description sx={{ margin: 0 }}>
                              Published On:{" "}
                              {getNewsPublishedDate(news.newsDate)}
                            </Description>
                          </CardContent>
                          <CardActions sx={{ justifyContent: "flex-end" }}>
                            <GiveButton onClick={() => handleClickNews(news)}>
                              Read More
                            </GiveButton>
                          </CardActions>
                        </Box>
                      </Card>
                    )
                )
            : "No News Found"}
        </Event>
      </Container>
    </CauseDiv>
  );
};
export default UpcomingEvents;
