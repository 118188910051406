import { Close, KeyboardArrowDown, Menu } from "@mui/icons-material";
import {
  AppBar,
  ClickAwayListener,
  Container,
  Drawer,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  SwipeableDrawer,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import HeaderTop from "../HeaderTop";
import Logo from "./../../../images/finalhour_logo.png";

const MyAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "#fff",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MobileView = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "17px",
  left: "10px",
}));

const MobileDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    // background: theme.palette.primary.greyBg,
    boxShadow: theme.palette.primary.shadow,
  },
  "& img": {
    width: "215px",
  },
}));

const MobileMenuLeft = styled("div")(({ theme }) => ({
  paddingLeft: "20px",
}));

const MenuDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "10px",
  paddingBottom: "20px",

  "& a": {
    color: "#000",
    textDecoration: "none",
    transition: "0.3s",
    fontWeight: "700",
    "&:hover": {
      color: theme.palette.primary.red,
    },
  },
}));

const Navbar = ({ token }) => {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const anchorRef2 = useRef(null);
  const anchorRef3 = useRef(null);
  const anchorRef4 = useRef(null);
  const anchorRefCommunity = useRef(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuCause, setOpenMenuCause] = useState(false);
  const [openMenuAbout, setOpenMenuAbout] = useState(false);
  const [openMenuBecome, setOpenMenuBecome] = useState(false);
  const [openMenuCommunity, setOpenMenuCommunity] = useState(false);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (token) {
      getFeaturedCategories();
    }
  }, []);

  const getFeaturedCategories = () => {
    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategories(data.data);
      }
    });
  };


  //DASHBOARD
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    } else if (event.key === "Escape") {
      setOpenMenu(false);
    }
  };
  //DASHBOARD

  // CAUSE
  const handleMenuOpenCause = () => {
    setOpenMenuCause(true);
  };

  const handleMenuCloseCause = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpenMenuCause(false);
  };

  const handleMenuCloseCommunity = (event) => {
    if (anchorRefCommunity.current && anchorRefCommunity.current.contains(event.target)) {
      return;
    }
    setOpenMenuCommunity(false);
  };

  const handleListKeyDown2 = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuCause(false);
    } else if (event.key === "Escape") {
      setOpenMenuCause(false);
    }
  };
  // CAUSE

  //About Final Hour
  const handleMenuOpenAbout = () => {
    setOpenMenuAbout(true);
  };

  const handleMenuCloseAbout = (event) => {
    if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
      return;
    }
    setOpenMenuAbout(false);
  };

  const handleListKeyDown3 = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuAbout(false);
    } else if (event.key === "Escape") {
      setOpenMenuAbout(false);
    }
  };
  //About Final Hour

  //Become a partner
  const handleMenuOpenBecome = () => {
    setOpenMenuBecome(true);
  };

  const handleMenuCloseBecome = (event) => {
    if (anchorRef4.current && anchorRef4.current.contains(event.target)) {
      return;
    }
    setOpenMenuBecome(false);
  };

  const handleListKeyDown4 = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuBecome(false);
    } else if (event.key === "Escape") {
      setOpenMenuBecome(false);
    }
  };
  //Become a partner

  const handleListKeyDownCommunity = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuCommunity(false);
    } else if (event.key === "Escape") {
      setOpenMenuCommunity(false);
    }
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };


  const handleCategoryLink = (cats) => {
    navigate(`/causes/category/${cats.slug}`, { state: cats })
  }

  return (
    <>
      <MyAppBar position="sticky">
        <HeaderTop token={token} />

        <Container maxWidth="xl">
          <MenuDiv>
            <Link to="/">Home</Link>
            {token ? (
              <div
                onMouseEnter={() => setOpenMenu(true)}
                onMouseLeave={() => setOpenMenu(false)}
              >
                <Link to="/dashboard">
                  <span ref={anchorRef}>Dashboard</span>
                </Link>
                <Popper
                  open={openMenu}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                          <MenuList
                            autoFocusItem={openMenu}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                gridGap: "20px",
                              }}
                            >
                              <MenuItem>
                                <Link to="/causes/favourite">
                                  <span style={{ fontWeight: "300" }}>
                                    My Favourite Causes
                                  </span>
                                </Link>
                              </MenuItem>

                              <MenuItem>
                                <Link to="/dashboard/causes">
                                  <span style={{ fontWeight: "300" }}>
                                    My Donated Causes
                                  </span>
                                </Link>
                              </MenuItem>
                              <MenuItem>
                                <Link to="/dashboard/giving_preferences">
                                  <span style={{ fontWeight: "300" }}>
                                    Giving Preference
                                  </span>
                                </Link>
                              </MenuItem>
                              <MenuItem>
                                <Link to="/dashboard/friends_list">
                                  <span style={{ fontWeight: "300" }}>
                                    Friends List
                                  </span>
                                </Link>
                              </MenuItem>

                              <MenuItem>
                                <Link to="/dashboard/account_settings">
                                  <span style={{ fontWeight: "300" }}>
                                    Account Settings
                                  </span>
                                </Link>
                              </MenuItem>
                            </div>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            ) : (
              <Link to="/sign_up">Join Now</Link>
            )}
            <div
              onMouseEnter={() => setOpenMenuCause(true)}
              onMouseLeave={() => setOpenMenuCause(false)}
            >
              <Link to="/causes">
                <Link to="/causes">
                  <span ref={token && categories.length > 0 ? anchorRef2 : null}>Causes</span>
                </Link>

              </Link>
              {token && categories && (
                <Popper
                  open={openMenuCause}
                  anchorEl={anchorRef2.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: "111" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleMenuCloseCause}>
                          <MenuList
                            autoFocusItem={openMenuCause}
                            id="composition-menu2"
                            aria-labelledby="composition-button2"
                            onKeyDown={handleListKeyDown2}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: (token && categories) ? "1fr 1fr 1fr" : "1fr",
                                gridGap: "10px",
                              }}
                            >

                              {/* <MenuItem>
                              <Link to="/causes/featured">
                                <span style={{ fontWeight: "300" }}>
                                  Featured
                                </span>
                              </Link>
                            </MenuItem>*/}

                              {/* {token &&
                            <MenuItem>
                              <Link to="/causes">
                                <span style={{ fontWeight: "300" }}>
                                  Causes
                                </span>
                              </Link>
                            </MenuItem> 
                            } */}

                              {token && categories && (
                                categories.map((cats) => (
                                  <MenuItem key={cats.categoryID}
                                  // onClick={() => handleCategoryLink(cats)}
                                  >
                                    <Link
                                      to={`/causes/category/${cats.slug}`}
                                      state={cats}
                                    >
                                      <span style={{ fontWeight: "300" }}>
                                        {cats.categoryName}
                                      </span>
                                    </Link>
                                  </MenuItem>

                                )))}
                            </div>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              )}
            </div>

            {/* ABOUT FINAL HOUR */}

            {token ? (

              <Link to="/workplaces">
                <span>Workplaces</span>
              </Link>


            ) : (
              <Link to="/about">About Final Hour</Link>
            )}
            {/* <Popper
                open={openMenuAbout}
                anchorEl={anchorRef3.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: "111" }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleMenuCloseAbout}>
                        <MenuList
                          autoFocusItem={openMenuAbout}
                          id="composition-menu3"
                          aria-labelledby="composition-button3"
                          onKeyDown={handleListKeyDown3}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gridGap: "20px",
                            }}
                          >
                            <MenuItem>
                              <Link to="/about">
                                <span style={{ fontWeight: "300" }}>
                                  How it Works
                                </span>
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/about">
                                <span style={{ fontWeight: "300" }}>
                                  Our Mission
                                </span>
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/about">
                                <span style={{ fontWeight: "300" }}>
                                  Governance
                                </span>
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/about">
                                <span style={{ fontWeight: "300" }}>
                                  Member Status
                                </span>
                              </Link>
                            </MenuItem>
                          </div>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper> */}

            {/* ABOUT FINAL HOUR */}

            {/* Become A partner*/}
            {token ? (

              // <Link to="/community">
              //   <span >Community</span>
              // </Link>
              <div
                onMouseEnter={() => setOpenMenuCommunity(true)}
                onMouseLeave={() => setOpenMenuCommunity(false)}
              >
                <Link to="/community">
                  <span ref={anchorRefCommunity}>Community</span>
                </Link>
                <Popper
                  open={openMenuCommunity}
                  anchorEl={anchorRefCommunity.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: "111" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleMenuCloseCommunity}>
                          <MenuList
                            autoFocusItem={openMenuCommunity}
                            id="composition-menu-community"
                            aria-labelledby="composition-button-community"
                            onKeyDown={handleListKeyDownCommunity}
                          >
                            <MenuItem>
                              <Link to="/members">
                                <span style={{ fontWeight: "300" }}>
                                  Members
                                </span>
                              </Link>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>


            ) : (
              <div
                onMouseEnter={() => setOpenMenuBecome(true)}
                onMouseLeave={() => setOpenMenuBecome(false)}
              >
                <Link>
                  <span ref={anchorRef4}>Become A Partner</span>
                </Link>
                <Popper
                  open={openMenuBecome}
                  anchorEl={anchorRef4.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: "111" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleMenuCloseBecome}>
                          <MenuList
                            autoFocusItem={openMenuBecome}
                            id="composition-menu4"
                            aria-labelledby="composition-button4"
                            onKeyDown={handleListKeyDown4}
                          >
                            <MenuItem>
                              <Link to="/become_a_partner/charity">
                                <span style={{ fontWeight: "300" }}>
                                  For Charity
                                </span>
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/become_a_partner/workplace">
                                <span style={{ fontWeight: "300" }}>
                                  For the Workplace
                                </span>
                              </Link>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
            {/* Become A partner*/}

            {/* FAQs */}

            <div

            >
              <Link to="/faqs">

                FAQs
              </Link>

            </div>
          </MenuDiv>
        </Container>
      </MyAppBar>

      <MobileView>
        <HeaderTop token={token} />
        <MenuButton onClick={handleDrawerOpen}>
          <Menu color="primary" />
        </MenuButton>
        <MobileDrawer
          anchor={"left"}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <List onClick={handleDrawerClose}>
            <ListItem>
              <Link to="/">
                <img src={Logo} alt="finalHour" title="Final Hour" />
              </Link>
              <Close sx={{ marginLeft: "20px" }} onClick={handleDrawerClose} />
            </ListItem>
            <ListItem button>
              <ListItemText>
                <Link to="/">Home</Link>
              </ListItemText>
            </ListItem>
            <ListItem button>
              {!token ? (
                <ListItemText>
                  <Link to="/sign_up">Join Now</Link>
                </ListItemText>
              ) : (

                <ListItemText>
                  <Link to="/dashboard">
                    Dashboard{" "}
                    <KeyboardArrowDown
                      sx={{ position: "relative", top: "5px" }}
                      fontSize="small"
                    />
                  </Link>
                </ListItemText>
              )}
            </ListItem>
            {token &&
              <MobileMenuLeft>
                <ListItem button>
                  <ListItemText>
                    <Link to="/causes/favourite">My Favourite Causes</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button>
                  <ListItemText>
                    <Link to="/dashboard/causes">My Donated Causes</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button>
                  <ListItemText>
                    <Link to="/dashboard/giving_preferences">
                      Giving Preferences
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem button>
                  <ListItemText>
                    <Link to="/dashboard/friends_list">
                      Friends List
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem button>
                  <ListItemText>
                    <Link to="/dashboard/account_settings">Account Settings</Link>
                  </ListItemText>
                </ListItem>
              </MobileMenuLeft>
            }
            <ListItem button>
              <ListItemText>
                <Link to="/causes">
                  Causes{" "}
                  {token && categories &&
                    <KeyboardArrowDown
                      sx={{ position: "relative", top: "5px" }}
                      fontSize="small"
                    />
                  }
                </Link>
              </ListItemText>
            </ListItem>
            <MobileMenuLeft>

              {token && categories &&
                categories.map((cats) => (
                  <ListItem button key={cats.categoryID}>
                    <ListItemText>
                      <Link to={`/causes/category/${cats.slug}`} state={cats}>
                        {cats.categoryName}
                      </Link>
                    </ListItemText>
                  </ListItem>
                ))}

            </MobileMenuLeft>
            {token ? (
              <ListItem button>
                <ListItemText>
                  <Link to="/workplaces">Workplaces</Link>
                </ListItemText>
              </ListItem>
            ) : (
              <ListItem button>
                <ListItemText>
                  <Link to="/about">About Final Hour</Link>
                </ListItemText>
              </ListItem>
            )}

            {token ? (
              <>
                <ListItem button>
                  <ListItemText>
                    <Link to="/community">Community</Link>
                    <KeyboardArrowDown
                      sx={{ position: "relative", top: "5px" }}
                      fontSize="small"
                    />
                  </ListItemText>
                </ListItem>
                <MobileMenuLeft>
                  <ListItem button>
                    <ListItemText>
                      <Link to="/members">Members</Link>
                    </ListItemText>
                  </ListItem>

                </MobileMenuLeft>
              </>
            ) : (
              <>
                <ListItem button>
                  <ListItemText>
                    <Link>
                      Become a Partner{" "}
                      <KeyboardArrowDown
                        sx={{ position: "relative", top: "5px" }}
                        fontSize="small"
                      />
                    </Link>
                  </ListItemText>
                </ListItem>


                <MobileMenuLeft>
                  <ListItem button>
                    <ListItemText>
                      <Link to="/become_a_partner/charity">For Charity</Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem button>
                    <ListItemText>
                      <Link to="/become_a_partner/workplace">For the Workplace</Link>
                    </ListItemText>
                  </ListItem>

                </MobileMenuLeft>

              </>
            )}
            <ListItem button>
              <ListItemText>
                <Link to="/faqs">FAQs</Link>
              </ListItemText>
            </ListItem>


          </List>
        </MobileDrawer>
      </MobileView>
    </>
  );
};

export default Navbar;
