import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { httpClient } from '../../../appUtility/Api'
import BreadCrumb from '../../../Components/BreadCrumb'
import AllCauses from '../../../Components/Causes/AllCauses'
import CauseCategory from '../../../Components/Causes/CauseCategory'
import CausesTop from '../../../Components/Causes/CausesTop'
import CausesContent from '../../../Components/Causes/CausesContent';
import Environment from '../../../Components/Causes/Environment'


const CauseCategoryPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [allData, setAllData] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryID, setCategoryID] = useState("");


  // useEffect(() => {
  //   httpClient.get(`cause-category/${location.state.categoryID}`).then(({data}) => {
  //     if(data.success){
  //       setAllData(data.data)
  //     }
  //   })

  // },[location.state])
  
  useEffect(() => {
    getCategorySingle(id);
  }, [id])

  useEffect(() => {
    getFeaturedCategories();

  }, []);

  const getCategorySingle = (id) => {
    httpClient.get(`cause-category/${id}`).then(({ data }) => {
      if (data.success) {
        setAllData(data.data)
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    })
  }
  const getFeaturedCategories = () => {

    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategory(data.data);

      }
    });
  };
 
  const handleRefetch = () => {
    getCategorySingle(id);
    getFeaturedCategories();
  };
  // console.log('loca', location);

  const handleLink = () => {
    navigate(`/causes/category/detail/${allData && allData.slug}`, { state: allData && allData });
  }

  
  return (
    <div>
        <BreadCrumb header={allData && allData.categoryName} subHeader1="Causes" Link1="/causes" subHeader2={allData && allData.categoryName} />
        {/* <CausesTop header={allData && allData.categoryName} description={allData && allData.categoryShortDescription || ""} image={(allData && allData.categoryImage) || ""} /> */}
        {/* <CauseCategory categories={allData && allData.subCategory} /> */}
        {category.map((cats, index) =>
        cats.categoryID === allData.categoryID && index < 6 && (
          <Environment category={cats} key={index} allData={allData} handleRefetch={handleRefetch} />
        )
      )}

        {/* <CausesContent content={allData && allData.categoryDescription || ""} /> */}
        {/* <AllCauses causeList={allData && allData.causes} /> */}
    </div>
  )
}

export default CauseCategoryPage