import {
    Container,
    Grid,
    styled,
    Snackbar,
    Skeleton,
    Box,
    Avatar,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import DeleteDialog from "../../../Components/Dashboard/DeleteDialog";
import FriendBox from "../../../Components/Dashboard/FriendsActivity/FriendBox";
import MuiAlert from "@mui/material/Alert";
import BlankImg from './../../../images/no-pictures.png';
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FriendSection = styled("div")(({ theme }) => ({
    padding: "40px 0",
    background: "#B9A8B7",
    //paddingRight: "3%",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("md")]: {
        paddingRight: "0",
    },
}));

const HeaderDiv = styled("div")(({ theme }) => ({
    display: "flex",
    color: "#000",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
        marginBottom: "10px",
    },
    "& h2": {
        margin: "0",
    },
    "& button": {
        borderRadius: "25px",
        backgroundColor: "#929292",
        color: "#fff",
        outline: "none",
        border: "transparent",
        padding: "7px 25px",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            background: "#6e6c6c"
        }
    },
}));



const HeaderTabs = styled("div")(({ theme }) => ({
    marginBottom: "20px",
    "& span": {
        cursor: "pointer",
        fontSize: "20px",
    },
}));

const FriendsActivity = () => {
    const navigate = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [currentValue, setCurrentValue] = useState("member");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [memberList, setMemberList] = useState([]);
    const [memberArr, setMemberArr] = useState([]);
    const [deleteID, setDeleteID] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])


    const [activityList, setActivityList] = useState([]);


    useEffect(() => {
        getMemberActivity();
    }, [])

    const getMemberActivity = () => {
        setLoading1(true);
        httpClient.get('member-connection/activity').then(({ data }) => {
            if (data.success) {
                setActivityList(data.data)
                setLoading1(false);

            }
        }).catch((err) => {
            if (err.response.status === 500) {
                console.log('internal server error')
                setLoading1(false);
            }
        })
    }

    const handleRefetch = () => {
        getMemberActivity();
        // getFeaturedCategories();
    };
    // console.log("menlist", memberList)


    // const handleClick = (member) => {
    //     var newSlug = member.friendMemberID.memberName
    //         .toLowerCase()
    //         .replace(/\s+/g, "-") // Replace whitespace with underscores
    //         .replace(/[^\w-]/g, ""); // Remove special characters except underscores and hyphens
    //     navigate(`/members/detail/${newSlug}`, { state: member.friendMemberID.friendMemberID });
    //     console.log("stateee", member.friendMemberID.friendMemberID);
    //     console.log("slug", newSlug);
    // };


    const handleLike = (id, donationID) => {
        //console.log("abc", id, donationID)
        httpClient
            .post(`member-connection/like-donations`, {
                invoiceID: id,
                likeStatus: 1,
                donationRuleID: donationID,
            })
            .then(({ data }) => {
                if (data.success) {
                    setMemberArr([...memberArr, id]);
                    setOpen(true);
                    setMessageState("success");
                    setMessage(data.message);
                    handleRefetch();
                } else {
                    setOpen(true);
                    setMessageState("error");
                    setMessage(data.message);
                }
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    navigate("/")
                }
            })
    };
    const handleDisLike = (id, donationID, donationLikeID) => {
        httpClient
            .post(`member-connection/like-donations/${donationLikeID}`, {
                invoiceID: id,
                likeStatus: 0,
                donationRuleID: donationID,

            })
            .then(({ data }) => {
                if (data.success) {
                    setMemberArr([...memberArr, id]);
                    setOpen(true);
                    setMessageState("success");
                    setMessage(data.message);
                    handleRefetch();
                } else {
                    setOpen(true);
                    setMessageState("error");
                    setMessage(data.message);
                }
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    navigate("/")
                }
            })
    };
    const handleAddComment = (id, donationID, comment) => {
        //console.log("handle", id, donationID, comment)
        setLoading(true);
        httpClient
            .post(`member-connection/comment-donations`, {
                invoiceID: id,
                donationRuleID: donationID,
                comment: comment,
                commentStatus: 1,
            })
            .then(({ data }) => {
                if (data.success) {
                    setMemberArr([...memberArr, id]);
                    setOpen(true);
                    setMessageState('success');
                    setMessage(data.message);
                    setLoading(false);
                    handleRefetch();
                } else {
                    setOpen(true);
                    setMessageState('error');
                    setMessage(data.message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    navigate('/');
                }
            });
    };

    const handleEditComment = (id, donationID, editComment, commentId) => {
        //console.log("handlessssss", id, donationID, editComment, commentId)
        setLoading(true);
        httpClient
            .post(`member-connection/comment-donations/${commentId}`, {
                invoiceID: id,
                donationRuleID: donationID,
                comment: editComment,
                commentStatus: 1,
            })
            .then(({ data }) => {
                if (data.success) {
                    setMemberArr([...memberArr, id]);
                    setOpen(true);
                    setMessageState('success');
                    setMessage(data.message);
                    setLoading(false);
                    handleRefetch();
                } else {
                    setOpen(true);
                    setMessageState('error');
                    setMessage(data.message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    navigate('/');
                }
            });
    };



    // const handleAcceptRequest = (id) => {
    //   httpClient
    //     .post(`member-connection/accept`, {
    //       friendMemberID: id,
    //     })
    //     .then(({ data }) => {
    //       if (data.success) {
    //         setOpen(true);
    //         setMessageState("success");
    //         setMessage(data.message);
    //         getMembers();
    //       } else {
    //         setOpen(true);
    //         setMessageState("error");
    //         setMessage(data.message);
    //       }
    //     }).catch((err) => {
    //       if (err.response.status === 401) {
    //         localStorage.clear();
    //         navigate("/")
    //       }
    //     })
    // };

    // const handleCancelRequest = (id) => {

    // };

    // const handleDelete = (id) => {
    //   setDeleteID(id);
    //   setOpenDeleteDialog(true);
    // };

    // const callBack = (call) => {
    //   if (call.open === false) {
    //     setOpenDeleteDialog(false);
    //   }

    //   if (call.success === true) {
    //     httpClient
    //       .post(currentValue === "request" ? `member-connection/reject` : `member-connection/remove`, {
    //         friendMemberID: deleteID,
    //       })
    //       .then(({ data }) => {
    //         if (data.success) {
    //           setOpen(true);
    //           setMessageState("success");
    //           setMessage(data.message);
    //           getMembers();
    //         } else {
    //           setOpen(true);
    //           setMessageState("error");
    //           setMessage(data.message);
    //         }
    //       }).catch((err) => {
    //         if (err.response.status === 401) {
    //           localStorage.clear();
    //           navigate("/")
    //         }
    //       })
    //   }
    // };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <BreadCrumb
                header="Community"
                subHeader1="Community"
                Link1="/dashboard"
                subHeader2="Friends Activity"
            />

            <FriendSection >
                <Container maxWidth="xl" >

                    {/* <HeaderTabs>
              <span
                onClick={() => setCurrentValue("member")}
                style={{ color: currentValue === "member" ? "#fff" : "inherit" }}
              >
                Friends{" "}
              </span>
              |{" "}
              <span
                onClick={() => setCurrentValue("request")}
                style={{ color: currentValue === "request" ? "#fff" : "inherit" }}
              >
                Requests{" "}
              </span>
              |{" "}
              <span
                onClick={() => setCurrentValue("pending")}
                style={{ color: currentValue === "pending" ? "#fff" : "inherit" }}
              >
                Pending
              </span>
            </HeaderTabs> */}
                    <div>

                        {loading1 ? (
                            <>   <Skeleton variant="circular" width={150} height={150} />
                                <Skeleton width="70%">
                                    <Typography>.</Typography>
                                </Skeleton>
                                <Skeleton variant="circular" width={150} height={150} />
                                <Skeleton width="70%">
                                    <Typography>.</Typography>
                                </Skeleton>
                                <Skeleton variant="circular" width={150} height={150} />
                                <Skeleton width="70%">
                                    <Typography>.</Typography>
                                </Skeleton>
                                <Skeleton variant="circular" width={150} height={150} />
                                <Skeleton width="70%">
                                    <Typography>.</Typography>
                                </Skeleton>
                                <Skeleton variant="circular" width={150} height={150} />
                                <Skeleton width="70%">
                                    <Typography>.</Typography>
                                </Skeleton>
                            </>
                        ) :

                            activityList.length > 0 ?
                                activityList.map((activity, index) => (

                                    <FriendBox

                                        key={activity.id}
                                        image={activity.memberImage ? activity.memberImage : BlankImg}
                                        name={activity.memberName}
                                        cause={activity.cause}
                                        date={activity.date}
                                        donationLiked={activity.donationLiked}
                                        hours={activity.hours + " Hrs"}
                                        amount={"$" + activity.amount}
                                        likes={activity.likes}
                                        handleLike={() =>
                                            handleLike(activity.id, activity.donationID)
                                        }
                                        handleDisLike={() =>
                                            handleDisLike(activity.id, activity.donationID, activity.donationLikeID)
                                        }
                                        handleAddComment={(comment) =>
                                            handleAddComment(activity.id, activity.donationID, comment)
                                        }
                                        handleEditComment={(comment, donationCommentID) =>
                                            handleEditComment(activity.id, activity.donationID, comment, donationCommentID)
                                        }
                                        loading={loading}
                                        comments={activity.comments}
                                        handleRefetch={handleRefetch}


                                    />
                                ))
                                : "No friend activity!"
                        }
                    </div>

                    {/* {loading1 && (
                        <>
                            <Skeleton variant="circular" width={250} height={250} />
                            <Skeleton variant="circular" width={250} height={250} />
                            <Skeleton variant="circular" width={250} height={250} />
                            <Skeleton variant="circular" width={250} height={250} />
                        </>
                    )} */}
                    {/* {!loading && memberList.length === 0 && (
              <Box pt={3} style={{ color: "#fff", textAlign: "center" }}>
                {currentValue === "member" && "No Friends Found"}
                {currentValue === "request" && "No Request Found"}
                {currentValue === "pending" && "No Pending Request Found"}
              </Box>
            )} */}
                </Container>

            </FriendSection>


            {/* {openDeleteDialog && (
          <DeleteDialog callBack={callBack} heading={"friend"} currentValue={currentValue} />
        )} */}

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default FriendsActivity;