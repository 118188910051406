import { Container, Grid, styled } from "@mui/material";
import React from "react";
import Contact from "./../../../images/contact.jpg";

const ContactDiv = styled("div")(({ theme }) => ({
  background: "#B8B6A9",
  padding: "40px 0",
  marginTop: "20px",
  position: "relative",
  "& h1": {
    margin: "0",
    fontWeight: "300",
    padding: "40px 0",
    fontSize: "70px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0",
      marginTop: "20px",
      fontSize: "40px",
    },
  },
  "& p": {
    margin: "0",
    paddingBottom: "20px",
    fontWeight: "300",
  },
}));

const ImageBox = styled("div")(({ theme }) => ({
    "& img": {
      width: "100%",
      height: "400px",
      objectFit: "cover",
    },
  }));

const ContactHeader = () => {
  return (
    <ContactDiv>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <h1>Contact Us</h1>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <ImageBox>
                <img src={Contact} alt={"Contact"} />
                {/* "https://images.unsplash.com/photo-1563906267088-b029e7101114?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" */}
            </ImageBox>
            
          </Grid>
        </Grid>
      </Container>
    </ContactDiv>
  );
};

export default ContactHeader;
