import {
  Container,
  Grid,
  styled,
  Snackbar,
  Skeleton,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import DeleteDialog from "../../../Components/Dashboard/DeleteDialog";
import MyFriendsBox from "../../../Components/Dashboard/MyFriendsBox";
import MuiAlert from "@mui/material/Alert";
import BlankImg from './../../../images/no-pictures.png';
//import { useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FriendSection = styled("div")(({ theme }) => ({
  background: "#9a6d97",
  padding: "5px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "10px 0",
  },
}));


const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(5,1fr)",
  display: "flex",
  gap: "10px",
  width: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    //gridTemplateColumns: "1fr",
    //gridGap: "20px",
    gap: "50px"
  },
}));

const HeaderTabs = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  "& span": {
    cursor: "pointer",
    fontSize: "30px",
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
    "& span": {
      cursor: "pointer",
      fontSize: "22px",
    },

  },
}));

const Logo = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  "& span": {
    background: theme.palette.primary.red,
    padding: "5px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "12px 3px 3px 3px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "4px",
    },
  },
}));

const FriendSection2 = ({ memberList, getMembers, currentValue }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //const [currentValue, setCurrentValue] = useState((location.state === "request" || location.state === "friendRequest") ? "request" : "member");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  //const [memberList, setMemberList] = useState([]);
  const [memberArr, setMemberArr] = useState([]);
  const [deleteID, setDeleteID] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  // useEffect(() => {
  //   getMembers();
  // }, [currentValue]);

  // const getMembers = () => {
  //   setLoading(true);
  //   setMemberList([]);
  //   httpClient.get(`member-connection/${currentValue}`).then(({ data }) => {
  //     if (data.success) {
  //       setMemberList(data.data);
  //       setLoading(false);
  //     }
  //   }).catch((err) => {
  //     if (err.response.status === 401) {
  //       localStorage.clear();
  //       navigate("/")
  //     }
  //   })
  // };

  const handleClick = (member) => {
    // var newSlug = member.friendMemberID.memberName
    //   .toLowerCase()
    //   .replace(/\s+/g, "-") // Replace whitespace with underscores
    //   .replace(/[^\w-]/g, ""); // Remove special characters except underscores and hyphens
    navigate(`/members/detail/${member.friendMemberID.slug || member.friendMemberID.friendMemberID}`, { state: member.friendMemberID.friendMemberID });
    // console.log("stateee", member.friendMemberID.friendMemberID);
    //console.log("slug", newSlug);
  };


  const handleAdd = (id) => {
    httpClient
      .post(`member-connection/add`, {
        friendMemberID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setMemberArr([...memberArr, id]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
  };

  const handleAcceptRequest = (id) => {
    httpClient
      .post(`member-connection/accept`, {
        friendMemberID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setTimeout(() => {
            getMembers();
          }, 2000)
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
  };

  const handleCancelRequest = (id) => {

  };

  const handleDelete = (id) => {
    setDeleteID(id);
    setOpenDeleteDialog(true);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false);
    }

    if (call.success === true) {
      httpClient
        .post(currentValue === "request" ? `member-connection/reject` : `member-connection/remove`, {
          friendMemberID: deleteID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              getMembers();
            }, 2000)
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        }).catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/")
          }
        })
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  return (
    <div>

      <FriendSection memberList={memberList}>
        <Container maxWidth="xl">


          <GridDiv>
            {!loading &&
              memberList.length > 0 &&
              memberList.map((member) => (
                <MyFriendsBox
                  key={member.friendMemberID.friendMemberID}
                  image={(member.friendMemberID.image !== "https://api.finalhour.org/uploaded/memImages" && member.friendMemberID.image !== "https://stagingapi.finalhour.org/uploaded/memImages") ? member.friendMemberID.image : ""}
                  memberName={member.friendMemberID.memberName}
                  city={
                    member.friendMemberID.address1 +
                    (member.friendMemberID.address2 &&
                      ", " + member.friendMemberID.address2)
                  }
                  occupation={member.friendMemberID.occupation}
                  hours={
                    member.hoursDonated
                  }
                  handleAdd={() =>
                    handleAdd(member.friendMemberID.friendMemberID)
                  }
                  handleDelete={() =>
                    handleDelete(member.friendMemberID.friendMemberID)
                  }
                  handleAcceptRequest={() =>
                    handleAcceptRequest(member.friendMemberID.friendMemberID)
                  }
                  handleCancelRequest={() =>
                    handleDelete(member.friendMemberID.friendMemberID)
                  }
                  handleClick={() => handleClick(member)}
                  messageState={memberArr.includes(member.friendMemberID.friendMemberID) ?
                    "success" : false}

                  currentValue={currentValue}
                />
              ))}
          </GridDiv>
        </Container>
      </FriendSection>

      {openDeleteDialog && (
        <DeleteDialog callBack={callBack} heading={"friend"} currentValue={currentValue} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FriendSection2;