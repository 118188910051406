import { styled } from "@mui/material";
import React from "react";
import BlankImage from './../../../../images/no-pictures.png';

const MemberDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  cursor: "pointer",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  "& img": {
    objectFit: "cover",
    // width: "100%",
    // height: "300px",
    width:"250px",
    height:"250px",
    borderRadius: "50%",
    border: "12px solid #926D96",
    [theme.breakpoints.down("md")]: {
      width: "250px",
      height: "250px",
    },
  },
  "& p": {
    margin: "0",
    fontWeight: "600",
  },
  "& small": {
    color: theme.palette.primary.grey,
  },
  "& span": {
    fontSize: "25px",
    lineHeight:"1"
  },
}));

const Line = styled("div")(({ theme }) => ({
  background: "#926D96",
  width: "60%",
  margin: "10px auto",
  height: "2px",
  borderRadius: "5px",
}));


const MemberBox = (props) => {
  // console.log("mprop", props);
  return (
    <MemberDiv onClick={props.handleClick}>
      <img src={props.image || BlankImage} alt={props.name} />
      <p>
        {props.memberName.length > 16
          ? `${props.memberName.substring(0, 16)}...`
          : props.memberName}
      </p>

      {props.title && <small>{props.title}</small>}
      {/* {props.hours ? ( */}
        <>
          <Line></Line>
          <span>
            <strong>{props.hours || "0"}</strong> {(props.hours == "1" || props.hours == "0" || props.hours == null) ? "hour" : "hours"}
          </span>
        </>
      {/* ) : (
        ""
      )
      } */}
    </MemberDiv>
  );
};

export default MemberBox;
