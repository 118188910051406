import { Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import WorkingBox from "./WorkingBox";

const WorkingSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background:"#ECE8DD",
  [theme.breakpoints.down("md")]: {
    padding:"20px",
  },
  "& h2": {
    fontSize:"50px",
    margin:"0",
    paddingBottom:"20px",
    fontWeight:"200",
    [theme.breakpoints.down("md")]: {
      fontSize:"40px",
      paddingBottom:"10px"      
    },
  },
  "& h3": {
    textAlign: "center",
    fontSize:"28px",
    margin: "0 25%",
    fontWeight: "500",
    color: "#888",
    "& strong":{
      fontWeight: "500",
      color:theme.palette.primary.red
    },
    [theme.breakpoints.down("md")]: {
      margin: "0",
      marginTop: "10px",
      fontSize:"20px"
    },
  },
}));

const WorkingDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexDirection:"row",
  flexWrap:"wrap",
  // alignItems:"center",
  justifyContent:"center",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const HowItWorks = ({token}) => {
  const [working, setWorking] = useState([]);
  useEffect(() => {
    if (token){}else{
    httpClient.get("work-process").then(({ data }) => {
      if (data.success) {
        setWorking(data.data);
      }
    });
  }
  }, []);

  return (
    <WorkingSection>
      <Container maxWidth="xl">
        <h2>How it Works</h2>
        <WorkingDiv>
          {working &&
            working.map((work,index) => (
              <WorkingBox
                key={work.id}
                image={work.image}
                text={work.body}
                arrow={(working.length === (index + 1)) ? false : true}
                index={index + 1}
              />
            ))}
        </WorkingDiv>
        <div>
          <h3>
            No extra skills or time out of your daily or weekly working routine
            required. <strong>Only your Final Hour.</strong>
          </h3>
        </div>
      </Container>
    </WorkingSection>
  );
};

export default HowItWorks;
