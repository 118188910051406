import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

const WelcomeDiv = styled("div")(({ theme }) => ({
  padding: "20px",
  textAlign: "center",
  fontFamily: "Epilogue, sans-serif !important",
  [theme.breakpoints.down("md")]: {
    padding:"10px"
  },
  "& img": {
    width: "200px",
    height: "200px",
    objectFit: "cover",
    borderRadius: "50%",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },
}));

const WelcomeBox = (props) => {
  return (
    <WelcomeDiv>
      <img src={props.image} />
      <Link to={props.link}>
        <div
          style={{
            color: "#fff",
            padding: "6px 20px",
            fontSize: "14px",
            background: props.color,
            borderRadius: "15px",
          }}
        >
          {props.text}
        </div>
      </Link>
    </WelcomeDiv>
  );
};

export default WelcomeBox;
