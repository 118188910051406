import React from 'react'
// import HeaderTop from './HeaderTop'
import Navbar from './Navbar'

const Header = ({token}) => {
  return (
    <>
        {/* <HeaderTop token={token} /> */}
        <Navbar token={token} />
    </>
  )
}

export default Header