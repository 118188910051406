import { AddPhotoAlternate, CoPresentOutlined, Facebook, Google, LinkedIn, RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  styled,
  TextField,
  useTheme,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import FacebookLoginRender from "react-facebook-login/dist/facebook-login-render-props";
// import ReactFacebookLogin from "react-facebook-login";
// import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookProvider, LoginButton, } from 'react-facebook';

// import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
//import LinkedInLogin from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { Link } from "react-router-dom";
import axios from 'axios';
import { scryRenderedComponentsWithType } from "react-dom/test-utils";
import SignupImage from '../../../images/signup-image.jpg'
import { httpClient } from "../../../appUtility/Api";


const SignupSection = styled("div")(({ theme }) => ({
  position: "relative",
  padding: "20px",
  background: theme.palette.primary.lightest,
  "& h2": {
    fontFamily: "Epilogue, sans-serif !important",
    margin: "0",
    fontWeight: "500",
    paddingBottom: "60px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
  "& p": {
    margin: "0",
  },
}));

const FormDiv = styled("div")(({ theme }) => ({
  marginLeft: "15%",
  marginBottom: "100px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
    marginBottom: "40px",
  },
}));

const FormGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  gridGap: "10px",
  marginTop: "60px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    marginTop: "30px",
  },
}));


const ResetButton = styled("button")(({ theme }) => ({
  background: theme.palette.primary.grey,
  color: "#fff",
  borderRadius: "15px",
  padding: "6px 15px",
  outline: "none",
  border: "2px solid transparent",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    background: theme.palette.primary.greyText,
  },
}));

const FacebookButton = styled("button")(({ theme }) => ({

  background: "#4c69ba",
  color: "#fff",
  padding: "10px",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  border: "1px solid transparent",
  cursor: "pointer",
  transition: "0.1s",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fontSize: "19px"
  },
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px"
  }
}))

const LinkedInButton = styled("button")(({ theme }) => ({
  background: "#0a66c2",
  color: "#fff",
  padding: "10px",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  border: "1px solid transparent",
  cursor: "pointer",
  transition: "0.1s",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fontSize: "19px"
  },
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px"
  }
}))


const GoogleButton = styled("button")(({ theme }) => ({
  background: "#ffffff",
  color: "#888",
  padding: "10px",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  border: "1px solid grey",
  cursor: "pointer",
  transition: "0.1s",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fontSize: "19px"
  },
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px"
  }
}))

const FallCategoryDiv = styled("div")(({ theme }) => ({
  border: "1px solid red",
  background: "#f1f1f1",
  padding: "5px 15px",
  marginBottom: "50px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& h5": {
    margin: "0",
  }
}))

const DropzoneDiv = styled("div")(({ theme }) => ({
  height: "100%",
  //   marginLeft: "15%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& img": {
    height: "300px",
    width: "300px",
    objectFit: "cover",
    border: `2px solid ${theme.palette.primary.greyBg}`,
  },
}));

const UploadDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  textAlign: "center",
  padding: "40px 0",
  height: "250px",
  width: "350px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2px",
  background: theme.palette.primary.greyBg,
  "&:hover": {
    //   border: `1px solid #333`,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "45px",
  },
}));

const PasswordDiv = styled("div")(({ theme }) => ({
  position: "relative",
  "& svg": {
    position: "absolute",
    right: "8px",
    top: "22px",
    color: "#999",
    fontSize: "18px",
    cursor: "pointer"
  },
}));

const SignUpImageBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 80,
  right: "135px",
  "& img": {
    height: "250px",
    width: "400px",
  },
  [theme.breakpoints.down("md")]: {
    display: "none"
  },
}))

let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
//let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;


const SignUpForm = (props) => {
  const APPID = "151634951157132";
  useEffect(() => {
    //console.log('inits')
    // console.log('app id', APPID)
    loadFacebookInit();
  }, []);

  const loadFacebookInit = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: APPID,
        cookie: true,  // enable cookies to allow the server to access
        // the session
        // autoLogAppEvents : true,
        xfbml: true,
        version: 'v2.5'
      });
    };


    // Load the SDK asynchronously

    (function (d, s, id) {
      // console.log("Loading fb api");
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  const JoinButton = styled(Button)(({ theme }) => ({
    background: (formValid && passwordValid & emailValid) ? theme.palette.primary.red : "#aaa",
    color: "#fff",
    borderRadius: "15px",
    padding: "5px 25px",
    outline: "none",
    border: "2px solid transparent",
    transition: "0.3s",
    cursor: "pointer",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.red,
      border: `2px solid ${theme.palette.primary.red}`,
    },
    "& span": {
      color: "#fff",
    }
  }));


  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    image: "",
    includeFeesOnDonation: false,
    acceptFriend: false
  });
  const [response, setResponse] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    ssoID: "",
    url: "",
    memberType: "",
    publicProfile: "",
    ssoType: "",
  });

  const [formValid, setFormValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordView, setPasswordView] = useState(false)
  const [facebookAccessToken, setFacebookAccessToken] = useState('');
  const [linkedinAccessToken, setLinkedinAccessToken] = useState(null);
  // const [response, setResponse] = useState([]);
  const [linkedinProfile, setLinkedinProfile] = useState({});

  useEffect(() => {
    if (props.userData.inviteEmail) {
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        emailAddress: props.userData.inviteEmail,
      }));
    }
  }, [props.userData.inviteEmail]);

  //password validation
  useEffect(() => {
    if (passwordRegex.test(formDetails.password)) {
      setPasswordValid(true)
    } else {
      setPasswordValid(false)
    }
  }, [formDetails.password])
  //password validation

  //email validation
  useEffect(() => {
    if (emailRegex.test(formDetails.emailAddress)) {
      setEmailValid(true)
    } else {
      setEmailValid(false);
    }
  }, [formDetails.emailAddress])
  //email validation

  //form validation
  useEffect(() => {
    if (formDetails.firstName && formDetails.lastName && formDetails.emailAddress && formDetails.password) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [formDetails])
  //form validation


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setFormDetails({
      ...formDetails,
      //includeFeesOnDonation: checked,
      acceptFriend: checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("subbmit");
    props.handleSubmit(formDetails);
  };
  const handleSSOSignup = (response) => {
    // e.preventDefault();
    props.handleSSOSignup(response);
  };
  const handleSSOSignupGoogle = (response) => {
    // e.preventDefault();
    props.handleSSOSignupGoogle(response);
  };

  const handleDrop = (file) => {
    // console.log("file", file);
    setFormDetails({
      ...formDetails,
      image: file[0],
    });
  };

  const handleResetImage = () => {
    setFormDetails({
      ...formDetails,
      image: "",
    });
  };


  const handleFacebook = () => {
    window.FB.login(function (response) {
      console.log("facebook res si >>>", response);
      if (response.authResponse) {
        setFacebookAccessToken(response.authResponse.accessToken);
        console.log('Facebook access token:', response.authResponse.accessToken);
        console.log('Welcome!  Fetching your information.... ');
        window.FB.api('/me', { fields: 'id, first_name, last_name, email, picture.height(300)' }, function (response) {
          setResponse(response);
          console.log('User profile:', response);
          handleSSOSignup(response);
          // Store the user's profile information or use it as needed
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'email' });


  };


  const handleFacebookError = (error) => {
    console.log('Facebook error:', error);
  };

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: '77vxgyhz60n63l',
  //   redirectUri: `${window.location.origin}/linkedin`,
  //   //redirectUri: `https://www.linkedin.com/developers/tools/oauth/redirect`,

  //   onSuccess: (code) => {
  //     console.log("code", code);
  //   },
  //   onError: (error) => {
  //     console.log("err",error);
  //   },
  // });



  // const handleFacebook = () => {
  //   window.FB.login(function(response) {
  //     if (response.authResponse) {
  //       setFacebookAccessToken(response.access_token);
  //       console.log('Facebook access token:', response.access_token);
  //       window.FB.api('/me', function(response) {
  //         console.log('User profile:', response);
  //         // Perform further actions with the user's profile data
  //       });

  //     } else {
  //       console.log('User cancelled login or did not fully authorize.');
  //     }
  //   });
  // };







  // google
  const handleLoginSuccess = (response) => {
    console.log('Login success:', response);
    // You can perform additional actions after successful sign-in
  };

  // Callback function to handle failed sign-in
  const handleLoginFailure = (response) => {
    console.log('Login failed:', response);
    // google
  };


  const responseGoogle = (response) => {
    console.log('google res', response)
  }



  const { linkedInLogin } = useLinkedIn({

    clientId: '77vxgyhz60n63l',
    redirectUri: `${window.location.origin}/linkedin`,

    scope: "openid email profile",
    //state: "1234",
    onSuccess: (code) => {

      console.log('onSuccess triggered', code);

      getLinkedInData(code);

    },


    onError: (error) => {

      console.log('error', error);
    },
  });

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: "77vxgyhz60n63l",
  //   redirectUri: `${window.location.origin}/linkedin`,
  //   onSuccess: (code) => {
  //     console.log(code);
  //     setCode(code);
  //     setErrorMessage("");
  //   },
  //   scope: "email profile",
  //   onError: (error) => {
  //     console.log(error);
  //     setCode("");
  //     setErrorMessage(error.errorMessage);
  //   },
  // });
  // const [code, setCode] = React.useState("");
  // const [errorMessage, setErrorMessage] = React.useState("");



  const handleLinkedInButtonClick = () => {
    //console.log("code");
    linkedInLogin();

    //console.log("code");
  };


  const getLinkedInData = async (code) => {
    // Exchange authorization code for access token
    console.log("here??", code);
    const tokenResponse = await fetch('https://www.linkedin.com/oauth/v2/accessToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        code: code,
        client_id: '77vxgyhz60n63l',
        client_secret: 'MRia3JZya0Jg8a81',
        redirect_uri: `${window.location.origin}/linkedin`,
      }),
    });

    const tokenData = await tokenResponse.json();
    const accessToken = tokenData.access_token;
    setLinkedinAccessToken(accessToken);
    console.log("accessToken ", accessToken);
    // Fetch profile data
    try {
      httpClient.get(`linkedin/get-user-info?linkedinToken=${accessToken}`)
        .then(({ data }) => {
          if (data) {
            setLinkedinProfile(data);
          }
        });

    } catch (error) {
      console.error('Error fetching profile:', error);
    }


  };
  console.log("linked-in profile", linkedinProfile);
  ////////////////////////////////////////////////////////////////




  // const [ user, setUser ] = useState([]);
  // const [ profile, setProfile ] = useState([]);

  // const handleGoogle = useGoogleLogin({
  //     onSuccess: (codeResponse) => setUser(codeResponse), 
  //     onError: (error) => console.log('Login Failed:', error)

  // });
  // console.log("codeResponse", user)


  // useEffect(() => {
  //         if (user) {
  //             axios
  //                 .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //                     headers: {
  //                         Authorization: `Bearer ${user.access_token}`,
  //                         Accept: 'application/json'
  //                     }
  //                 })
  //                 .then((res) => {
  //                     setProfile(res.data);
  //                     console.log("profile", profile);
  //                 })
  //                 .catch((err) => console.log(err));
  //         }
  //     },
  //     [ user ]
  // );


  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const [googleLoginCalled, setGoogleLoginCalled] = useState(false);

  const handleGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      setGoogleLoginCalled(true); // Set the flag to indicate that handleGoogle was called
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    if (googleLoginCalled) {
      const fetchProfileData = async () => {
        try {
          if (user) {
            const response1 = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: 'application/json'
              }
            });
            setProfile(response1.data);
            const response = response1.data
            console.log("profile", response);
            setResponse(response);
            console.log("profileyyy", response);
            handleSSOSignupGoogle(response);
            console.log("profilezzz", response);
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchProfileData();
    }
  }, [googleLoginCalled, user]);

  console.log("profile-last", profile);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SignupSection>
      <Container maxWidth="xl" style={{ position: 'relative' }}>
        {/* <h2>Sign Up</h2> */}

        <SignUpImageBox>
          <img src={SignupImage} alt="signup_image" />
        </SignUpImageBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            {props.fallCategory === "category" ?
              <FallCategoryDiv>
                <h5>Please sign up to access all the contents.</h5>
              </FallCategoryDiv> : ""
            }
            <p>
              Become part of the Final Hour community by entering in your
              details below.
            </p>
            <FormDiv>
              <FormGrid>
                <div>
                  <InputLabel>First Name</InputLabel>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="firstName"
                    value={formDetails.firstName}
                    sx={{ width: "100%", marginBottom: "20px" }}
                    helperText={formDetails.firstName ? "" : "Required"}
                  />
                </div>
                <div>
                  <InputLabel>Last Name</InputLabel>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="lastName"
                    value={formDetails.lastName}
                    sx={{ width: "100%", marginBottom: "20px" }}
                    helperText={formDetails.lastName ? "" : "Required"}
                  />
                </div>
                <div>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    color={emailValid ? "primary" : "error"}
                    variant="outlined"
                    onChange={handleChange}
                    name="emailAddress"
                    value={formDetails.emailAddress}
                    disabled={props.userData.inviteEmail ? true : false}
                    sx={{ width: "100%", marginBottom: "20px" }}
                    helperText={formDetails.emailAddress ? !emailValid ? "Invalid Email Address!" : "" : "Required"}
                  />
                </div>
                <div>
                  <InputLabel>Password</InputLabel>
                  <PasswordDiv>
                    <TextField
                      variant="outlined"
                      color={passwordValid ? "primary" : "error"}
                      error={formDetails.password.length !== 0 && !passwordValid}
                      type={passwordView ? "text" : "password"}
                      onChange={handleChange}
                      name="password"
                      value={formDetails.password}
                      sx={{ width: "100%", marginBottom: "20px" }}
                      // helperText="Must include 8 characters, 1 Uppercase, 1 Number"
                      helperText="Must include 8 characters, 1 Special character, 1 Uppercase and 1 Number."
                    />
                    <RemoveRedEye onClick={() => setPasswordView(!passwordView)} />
                  </PasswordDiv>
                </div>

              </FormGrid>
              {props.id &&
                <div style={{ marginBottom: "30px" }}>
                  <FormControlLabel
                    label={`Accept friend request from ${props.userData.firstName} ${props.userData.lastName}?`}
                    style={{ color: "#0009" }}
                    control={
                      <Checkbox
                        checked={formDetails.acceptFriend ? true : false}
                        value={formDetails.acceptFriend}
                        onChange={handleChangeCheck}
                      />
                    }
                  />
                </div>
              }

              {/* <div style={{ marginBottom: "30px" }}>
                <FormControlLabel
                  label="Include Fees on Donation?"
                  style={{ color: "#0009" }}
                  control={
                    <Checkbox
                      checked={formDetails.includeFeesOnDonation ? true : false}
                      value={formDetails.includeFeesOnDonation}
                      onChange={handleChangeCheck}
                    />
                  }
                />
              </div> */}
              {isMobile &&
                <DropzoneDiv>
                  <Dropzone
                    onDrop={handleDrop}
                  // accept="image/png, image/gif image/jpg" //whatever the file type needed
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                      rejectedFiles,
                    }) => {
                      return (
                        <div>
                          {formDetails.image ? (
                            formDetails.image.type ? (
                              <img
                                src={URL.createObjectURL(formDetails.image)}
                                //   className={classes.imgClass}
                                alt={formDetails.image.name}
                              />
                            ) : (
                              <img
                                src={formDetails.image}
                                //   className={classes.imgClass}
                                alt={formDetails.name}
                              />
                            )
                          ) : (
                            <UploadDiv {...getRootProps()}>
                              <input {...getInputProps()} />
                              <AddPhotoAlternate color="primary" />
                              {/* <p>Upload Image here</p> */}
                            </UploadDiv>
                          )}
                          {/* <div style={{ textAlign: "center", marginTop: "5px" }}>
                        <ResetButton
                          onClick={() =>
                            setFormDetails({
                              ...formDetails,
                              image: "",
                            })
                          }
                        >
                          {" "}
                          Reset Image
                        </ResetButton>
                      </div> */}
                        </div>
                      );
                    }}
                  </Dropzone>
                </DropzoneDiv>
              }

              <div>
                <p>
                  By clicking Join Now, you agree to the Final Hour{" "}
                  <Link to={"/user_agreement"} target="_blank"><strong>User Agreement</strong></Link>,
                  <Link to={"/privacy_policy"} target="_blank"><strong> Privacy Policy</strong></Link>, and{" "}
                  <Link to={"/cookie_policy"} target="_blank"><strong> Cookie Policy</strong></Link>.
                </p>
              </div>

              <Box mt={2} style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                {/* <FacebookLoginRender
                  // appId={APPID}
                  appId="1088597931155576"
                  autoLoad={true}
                  fields="name,email,picture"
                  callback={handleSuccess}
                  // onClick={handleFacebook}
                  onError={handleFacebookError}
                  render={renderProps => (
                    <FacebookButton > <Facebook fontSize="small" />  Sign up with Facebook </FacebookButton>
                  )}
                  /> */}

                {/* <FacebookProvider appId={APPID}> */}


                {/* //commented */}

                {/* <FacebookButton
                  onClick={handleFacebook}
                >
                  <Facebook fontSize="small" />  Sign up with facebook
                </FacebookButton> */}

                {/* <GoogleButton onClick={() => handleGoogle()}>
                  Sign up with Google 
                  <Google fontSize="small" />  
                  </GoogleButton> */}

                {/* <GoogleLogin
                  
                  onSuccess={credentialResponse => {
                    console.log('google response siss>>>', credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                /> */}
                {/* <GoogleLogin
                  clientId="111449903614-m8mosuasrho48r18mov7a5kia5pb0sms.apps.googleusercontent.com"
                  buttonText="Sign in with Google"
                  onSuccess={handleLoginSuccess}
                  onFailure={handleLoginFailure}
                  cookiePolicy={'single_host_origin'}
                /> */}

                {/* <img
      onClick={linkedinLoginnn}
      src={linkedin}
      alt="Sign in with Linked In"
      style={{ maxWidth: '180px', cursor: 'pointer' }}
    /> */}

                {/* <LinkedInButton onClick={handleLinkedInButtonClick}> <LinkedIn fontSize="small" /> Sign up with LinkedIn </LinkedInButton> */}

              </Box>

              <Box mt={2} textAlign="right">
                {props.loading ?
                  <JoinButton disabled={true} >
                    <CircularProgress style={{ height: "20px", width: "20px", marginRight: "10px" }} /> <span>Loading</span>
                  </JoinButton>
                  :
                  <JoinButton onClick={handleSubmit}
                    disabled={(formValid && passwordValid & emailValid) ? false : true}
                  >
                    Join Now
                  </JoinButton>
                }

              </Box>
            </FormDiv>
          </Grid>
          {!isMobile &&
            <Grid item xs={12} md={5}>

              <DropzoneDiv>
                <Dropzone
                  onDrop={handleDrop}
                // accept="image/png, image/gif image/jpg" //whatever the file type needed
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                    rejectedFiles,
                  }) => {
                    return (
                      <div>
                        {formDetails.image ? (
                          formDetails.image.type ? (
                            <img
                              src={URL.createObjectURL(formDetails.image)}
                              //   className={classes.imgClass}
                              alt={formDetails.image.name}
                            />
                          ) : (
                            <img
                              src={formDetails.image}
                              //   className={classes.imgClass}
                              alt={formDetails.name}
                            />
                          )
                        ) : (""
                          // <UploadDiv {...getRootProps()}>
                          //   <input {...getInputProps()} />
                          //   <AddPhotoAlternate color="primary" />
                          //   {/* <p>Upload Image here</p> */}
                          // </UploadDiv>
                        )}
                        {/* <div style={{ textAlign: "center", marginTop: "5px" }}>
                        <ResetButton
                          onClick={() =>
                            setFormDetails({
                              ...formDetails,
                              image: "",
                            })
                          }
                        >
                          {" "}
                          Reset Image
                        </ResetButton>
                      </div> */}
                      </div>
                    );
                  }}
                </Dropzone>
              </DropzoneDiv>
            </Grid>
          }
        </Grid>
      </Container>
    </SignupSection>
  );
};

export default SignUpForm;
