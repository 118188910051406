import {
    Box,
    Container,
    Grid,
    Pagination,
    PaginationItem,
    styled,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { httpClient } from "../../appUtility/Api";
  import BreadCrumb from "../../Components/BreadCrumb";
  import CauseBox from "../../Components/Workplace/WorkplaceNewsFeed/CauseBox";
  
  const CausesDiv = styled("div")(({ theme }) => ({
    background: "#EAB38B",
    padding: "40px 0",
    "& h2": {
      margin: "0",
      paddingBottom: "20px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px 0",
    },
  }));
  
  const PaginationBox = styled("div")(({ theme }) => ({
    padding: "40px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#4A4A4A",
    "& span": {
      fontSize: "16px",
      marginRight: "10px",
    },
    "& button": {
      color: "#4A4A4A",
    },
  }));
  
  const WorkplaceNewsFeed = () => {
    const navigate = useNavigate();
    const [newsList, setNewsList] = useState([]);
    const [lastPage, setLastPage] = useState("");
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(20);
    const [total, setTotal] = useState("");
    const [currPage, setCurrPage] = useState(1);
    const [count, setCount] = useState(20);
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      
      getNewsList();
    }, []);
  
    const getNewsList = () => {
      httpClient.get("news").then(({ data }) => {
        if (data.success) {
          setNewsList(data.data);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setTotal(data.meta.total);
          setCount(data.meta.per_page);
          setCurrPage(data.meta.current_page);
          setLastPage(data.meta.last_page);
        }
      }).catch((err) => {
        if(err.response.status === 401){
          localStorage.clear();
          navigate("/")
        }
      })
    };
  
    const handleChangePage = (e, value) => {
      httpClient
        .get(`news?page=${value}&pagination=${count}`)
        .then(({ data }) => {
          if (data.success) {
            setNewsList(data.data);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setTotal(data.meta.total);
            setCount(data.meta.per_page);
            setCurrPage(data.meta.current_page);
            setLastPage(data.meta.last_page);
          }
        }).catch((err) => {
          if(err.response.status === 401){
            localStorage.clear();
            navigate("/")
          }
        })
    };
  
    const handleClickCause = (news) => {
      var newSlug = news.newsCause.causeName
        .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
        .toLowerCase();
      navigate(`/causes/detail/${newSlug}`, { state: news.newsCause });
    };
  
    const handleClickNews = (news) => {
      var newSlug = news.newsTitle
        .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "_")
        .toLowerCase();
      navigate(`/more-news/detail/${newSlug}`, { state: news });
    };
    //console.log("newsListtttttt", newsList)
  
    return (
      <div>
        <BreadCrumb
          header="News"
          subHeader1="News"
          Link1="/"
          subHeader2="News Collection"
        />
        <CausesDiv>
          <Container maxWidth="xl">
            <h2> All News</h2>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {newsList && newsList.length > 0
                  ? newsList.map((news, index) => (
                      <CauseBox
                        image={news.newsImage}
                        handleClickCause={() => handleClickCause(news)}
                        handleClickNews={() => handleClickNews(news)}
                        date={news.newsDate}
                        newsTitle={news.newsTitle}
                        cause={news.newsCause.causeName}
                      />
                    ))
                  : "No News Found!"}
              </Grid>
              <Grid item xs={12}>
                <PaginationBox>
                  <span>
                    {from}-{to} of {total} items
                  </span>
                  <Pagination
                    count={lastPage}
                    page={currPage}                    
                    showFirstButton
                    showLastButton
                    shape="rounded"
                    onChange={(e, value) => handleChangePage(e, value)}
                  >
                    <PaginationItem
                      page={currPage}
                      // disabled
                    />
                  </Pagination>
                  {/* <Pagination
                    count={total}
                    page={currPage}
                    onChange={(e, value) => handleChangePage(e, value)}
                    showFirstButton
                    showLastButton
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        disabled={item.page !== currPage && item.page !== '...' ? true : false}
                      />
                    )}
                  /> */}
                 
                </PaginationBox>
              </Grid>
            </Grid>
          </Container>
        </CausesDiv>
      </div>
    );
  };
  
  export default WorkplaceNewsFeed;
  