import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { Collapse, Container, Snackbar, styled } from "@mui/material";
import SwitchHeader from "./SwitchHeader";
import { httpClient } from "../../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
import Environment from "../../../Components/Causes/Environment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Section = styled("div")(({ theme }) => ({
  padding:"40px 0",
  textAlign:"center",  
  position: "relative",
  zIndex: "1",
  // borderBottom: "1px solid #000",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& p": {
    fontWeight: "500",
    margin: "0",
  },
  "& h2": {
    margin: "0",
    fontSize:"35px",
    paddingBottom: "20px",
  },
}));

const GiveButton = styled("button")(({ theme }) => ({
  padding: "10px 30px",
  borderRadius: "100px",
  border: "1px solid transparent !important",
  color: "#fff",
  background: theme.palette.primary.red,
  marginLeft: "20px",
  transition: "0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
  },
  "&:hover": {
    background: "#fff !important",
    color: `${theme.palette.primary.red} !important`,
    border: `1px solid ${theme.palette.primary.red} !important`,
  },
}));

const CancelButton = styled("button")(({ theme }) => ({
  padding: "10px 30px",
  borderRadius: "100px",
  border: "1px solid #000",
  marginLeft: "10px",
  transition: "0.3s",
  cursor: "pointer",

  "&:hover": {
    background: theme.palette.primary.red,
    color: "#fff",
    border: `1px solid ${theme.palette.primary.red}`,
  },
}));

const SwitchSubscription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [allData, setAllData] = useState("");
  const [incomeDetails, setIncomeDetails] = useState("");
  const [category, setCategory] = useState("");
  const [showSwitch, setShowSwitch] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  useEffect(() => {
    getFeaturedCategories();
    if (location.state) {
      setAllData(location.state?.giveHours);
      setIncomeDetails(location.state?.incomeDetails[0]);
    }
  }, [location.state]);

  // console.log('allll data', allData);

  const handleSwitch = () => {
    setShowSwitch(!showSwitch);
  };


  const handleSwitchCause = (cause) => {
    // console.log('switch cause is', cause);

    if(window.confirm(`We are switching to ${cause.name}.`) === true){
      var taxamount = ((incomeDetails.hourlyIncome * allData.hours) * 0.005).toFixed(2);
      var donationAmount = (incomeDetails.hourlyIncome * allData.hours).toFixed(2);
      var amountWithStripeFee = (((incomeDetails.hourlyIncome * allData.hours) + 0.30) / 0.971).toFixed(2);
      var amount = ((incomeDetails.hourlyIncome * allData.hours) * 0.1) + ((incomeDetails.hourlyIncome * allData.hours) * 0.005) + (((incomeDetails.hourlyIncome * allData.hours) + 0.30) / 0.971).toFixed(2);

      let donations = {
        donationFrequency: allData.frequency,
        donationHour: parseInt(allData.hours),
        donationStartDate: allData.startDate,
        donationsDay: allData.donationDay,
        donationAmount: donationAmount,
        taxamount: taxamount,
        amountWithStripeFee: amountWithStripeFee,
        amount: amount,
      };

      setSelectedID(cause.causeID);
      setLoading(true);
      httpClient
          .post(`update-payment/${allData.ruleID}`, {
            causeID: allData.causeID,
            orgID: cause.organizationID.orgID,
            donations: donations,
            // card: card,
            newCauseID: cause.causeID || ''
          })
          .then(({ data }) => {
            if (data.success) {
              setOpen(true);
              setMessageState("success");
              setMessage("Switched to a new cause successfully!");
              setLoading(false);
              setSelectedID("");
              setTimeout(() => {
                var newSlug = cause.name
                  .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
                  .toLowerCase();
                navigate(`/causes/detail/${newSlug}`, { state: cause });
              }, 1500);
            } else {
              setOpen(true);
              setMessageState("error");
              setMessage("Failed to switch to a new cause!");
              setLoading(false);
            }
          }).catch(err => {
              setOpen(true);
              setMessageState("error");
              setMessage("Server Error!");
              setLoading(false);
          })
      }else{
        // console.log('cancel false')
      }
  }

  const handleCancel = () => {
    if(window.confirm("This will cancel the subscription. Are you sure?") === true){
      httpClient
      .post(`subscription-cancel`, {
        ruleID: allData?.ruleID,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setTimeout(() => {
            navigate(-1);
          },1000)
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
    }else{
    }
  };

  const getFeaturedCategories = () => {
    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategory(data.data);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <BreadCrumb header={"Cancel Subscription / Switch Subscription"} />
      <SwitchHeader
        header={"Cancel or Switch Subscription"}
        allData={allData && allData}
      />

      <Container maxWidth="xl">
        <Section>
          <h2>Do you really want to cancel the cause or switch the cause?</h2>

          <div>
            <GiveButton onClick={handleSwitch}>Switch the cause</GiveButton>

            <CancelButton onClick={handleCancel}>Cancel cause</CancelButton>
          </div>
        </Section>
      </Container>

      <Collapse in={showSwitch}>
        {category &&
          category.map(
            (cats, index) =>
              cats.categoryID === allData.categoryID.categoryID &&
              index < 6 && (
                <Environment category={cats} selectedID={selectedID} loading={loading} currCauseID={allData.causeID} key={index} switch={true} handleSwitchCause={handleSwitchCause} />
              )
          )}
      </Collapse>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SwitchSubscription;
