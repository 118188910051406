import { Notifications, Person4, Search } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  Switch,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import CauseDialog from "../../Causes/CauseDialog";
import LoginDialog from "../../LoginDialog";
import Logo from "./../../../images/finalhour_logo.png";
import Icon1 from "./../../../images/FW_ Final Hour improvement/assets-5.png"
import Icon2 from "./../../../images/FW_ Final Hour improvement/Assets-4.png"
// import RIghtDrawer from "../RIghtDrawer";
import HeaderRightDrawer from "../HeaderRightDrawer";

const HeaderDiv = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "right",
  padding: "15px 0",
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
  "& input": {
    padding: "7px 7px 7px 25px",
    borderRadius: "3px",
    outline: "none",
    fontSize: "16px",
    border: `1px solid ${theme.palette.primary.greyText}`,
    color: theme.palette.primary.greyText,
    [theme.breakpoints.down("md")]: {
      padding: "5px 20px",
      fontSize: "14px",
    },
  },
  "& svg": {
    position: "relative",
    right: "-25px",
    top: "6px",
    fontSize: "20px",
    color: theme.palette.primary.greyText,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  "& button": {
    padding: "10px 30px",
    borderRadius: "100px",
    border: "1px solid #000",
    marginLeft: "20px",
    transition: "0.3s",
    cursor: "pointer",
  },
  "& button:hover": {
    background: theme.palette.primary.red,
    color: "#fff",
    border: `1px solid ${theme.palette.primary.red}`,
  },
}));

const LogoDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  flex: "1",
  "& img": {
    position: "relative",
    left: "12%",
    [theme.breakpoints.down("md")]: {
      width: "215px",
      padding: "12px",
      left: "auto"
    },
  },
}));

const SearchFunctionDiv = styled("div")(({ theme }) => ({
  width: "250px",
  padding: "20px",
  marginTop: "10px",

  "& Button": {
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "14px",
  },
}));

const FlexSwitch = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #f1f1f1",
}));

// const GiveButton = styled("button")(({ theme }) => ({
//   padding: "10px 30px",
//   borderRadius: "100px",
//   border: "1px solid transparent !important",
//   color: "#fff",
//   background: theme.palette.primary.red,
//   marginLeft: "20px",
//   transition: "0.3s",
//   cursor: "pointer",
//   [theme.breakpoints.down("md")]: {
//     padding: "5px 10px",
//     marginLeft: "5px",
//   },
//   "&:hover": {
//     background: "#fff !important",
//     color: `${theme.palette.primary.red} !important`,
//     border: `1px solid ${theme.palette.primary.red} !important`,
//   },
// }));

const LoginButton = styled("button")(({ theme, disabled }) => ({
  padding: "10px 30px",
  borderRadius: "100px",
  background: "#fff !important",
  color: `${theme.palette.primary.red} !important`,
  border: `1px solid ${theme.palette.primary.red} !important`,

  marginLeft: "20px",
  transition: "0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
  },

}));

const SearchDiv = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    zIndex: 99,
  },
  transition: "transform 0.3s ease", // Add transition for smooth scaling
  "&:hover": {
    transform: "scale(1.2)", // Scale the div on hover
  },

}));

const LoginDiv = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    zIndex: 9,
  },

}));

const AvatarSection = styled("div")(({ theme }) => ({
  position: "relative",
  cursor: "pointer",
  "& small": {
    position: "absolute",
    top: "-5px",
    right: "-15px",
    background: theme.palette.primary.main,
    color: "#fff",
    width: "20px",
    height: "20px",
    textAlign: "center",
    fontSize: "12px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    position: "absolute",
    left: "55px",
  },
}));

const TopIcons = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    justifyContent: "flex-end"
  },
}));

const MyNotifyIcons = styled("div")(({ theme }) => ({
  position: "relative",
  marginLeft: "7px",
  cursor: "pointer",
  "& small": {
    zIndex: "111",
    position: "absolute",
    top: "-3px",
    right: "-12px",
    background: theme.palette.primary.main,
    color: "#fff",
    width: "20px",
    height: "20px",
    textAlign: "center",
    fontSize: "12px",
    borderRadius: "50%",
    cursor: "pointer",

  },
  "& svg": {
    position: "relative",
    right: "0px",
    top: "0px",
    fontSize: "22px",
    color: theme.palette.primary.greyText,
    [theme.breakpoints.down("md")]: {
      display: "block !important",
      fontSize: "20px"
    },
  },
  "& button": {
    padding: "7px",
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    border: `1px solid transparent`,
    background: "#d9d9d9",
    marginLeft: "0",
    transition: "0.3s",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px"
    },
  },
  "& button:hover": {
    background: "#d1d1d1",
    color: "inherit",
    border: `1px solid transparent`,
  },
}));

const NotifySection = styled("div")(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    justifyContent: "flex-end"
  },
}))

const member = localStorage.getItem("login");
const memberData = JSON.parse(member);

const HeaderTop = ({ token, infographic }) => {

  const tok = localStorage.getItem("guestToken");
  const guestToken = JSON.parse(tok);

 // console.log("gtoken", guestToken);

  const GiveButton = styled("button")(({ theme, disabled }) => ({
    padding: "10px 30px",
    borderRadius: "100px",
    border: "1px solid transparent !important",
    color: ((!guestToken && !token) || disabled) ? `${theme.palette.text.disabled} !important` : "#fff",
    background: ((!guestToken && !token) || disabled) ? theme.palette.action.disabledBackground : theme.palette.primary.red,
    marginLeft: "20px",
    transition: "0.3s",
    cursor: ((!guestToken && !token) || disabled) ? "not-allowed" : "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "5px 10px",
      marginLeft: "5px",
    },
    "&:hover": {
      background: ((!guestToken && !token) || disabled) ? `${theme.palette.action.disabledBackground} !important` : "#fff !important",
      color: ((!guestToken && !token) || disabled) ? `${theme.palette.text.disabled} !important` : `${theme.palette.primary.red} !important`,
      border: ((!guestToken && !token) || disabled) ? `1px solid transparent !important` : `1px solid ${theme.palette.primary.red} !important`,
      cursor: ((!guestToken && !token) || disabled) ? "not-allowed" : "pointer",
    },
  }));

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  //console.log("id", id);

  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);

  const [openPopper, setOpenPopper] = React.useState(false);
  const [openPopper2, setOpenPopper2] = React.useState(false);

  const [openGive, setOpenGive] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [requestCount, setRequestCount] = useState("");
  const [notificationCount, setNotificationCount] = useState("");
  const [allNotification, setAllNotification] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("causes");
  const [searchData, setSearchData] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (token) {
      getMemberRequest();
      getNotifications();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state == "reload") {
      navigate(location.pathname, { replace: true });
      window.location.reload();
    }
  }, [location.state == "reload"]);

  // useEffect(() => {
  // if (!token) {
  //   var timestamp = new Date().getTime();
  //   localStorage.setItem("guestToken", timestamp);
  // }
  // }, []);


  const getMemberRequest = () => {
    httpClient
      .get(`member-connection/request`)
      .then(({ data }) => {
        if (data.success) {
          setRequestCount(data.data.length);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          console.log("internal server error");
        }
      });
  };

  const getNotifications = () => {
    httpClient.get("my-notification").then(({ data }) => {
      if (data.success) {
        let count = 0;
        setAllNotification(data.data);

        data.data.map((nots) => {
          if (nots.viewed == 0) {
            count = count + 1;
          }
        });
        setNotificationCount(count);
      }
    });
  };

  const handleNotification = () => {
    setShowNotification(true);
  };

  const notificationCallback = (state, alreadyViewed) => {
    setShowNotification(state);
    if (alreadyViewed === true) {
      setNotificationCount("")
    }
  };

  const handleLogin = () => {
    // localStorage.setItem("token", JSON.stringify("123981jn321m"));
    // window.location.reload();
    setOpenLoginDialog(true);
  };

  const handleJoinNow = () => {
    window.location.href = `${window.location.origin}/sign_up`;
  };

  const loginCallback = (call) => {
    if (call.open === false) {
      setOpenLoginDialog(false);
    }
  };

  const handleEditProfile = () => {
    navigate("/dashboard/account_settings");
    handleClosePopper();
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location = "/";
  };

  const handlePopperOpen = () => {
    setOpenPopper((prev) => !prev);
  };

  const handlePopperOpen2 = () => {
    setOpenPopper2((prev) => !prev);
  };

  const handleClosePopper = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenPopper(false);
    } else if (event.key === "Escape") {
      setOpenPopper(false);
    }
  };

  const handleClosePopper2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpenPopper2(false);
  };

  const handleListKeyDown2 = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenPopper2(false);
    } else if (event.key === "Escape") {
      setOpenPopper2(false);
    }
  };

  const handleGive = () => {

    setOpenGive(true);

    //navigate("/causes");
  };

  const causeCallBack = (call) => {
    if (call.open === false) {
      setOpenGive(false);
    }
  };

  const handleSearchChange = () => {
    setOpenPopper2((prev) => !prev);
  };

  const handleChangeSwitch = (type) => {
    setSearchType(type);
  };

  const handleSearchApply = () => {
    setSearchLoading(true);
    httpClient
      .get(`search?search=${search}&type=${searchType}`)
      // &type=${searchType}
      .then(({ data }) => {
        if (data.success) {
          setSearchData(data);
          setSearchLoading(false);
          setOpenPopper2(false);
          navigate(`/search`, {
            state: { data: data, search: search, type: searchType },
          });
        }
      });
  };

  useEffect(() => {
    // const handleScroll = () => {
    //   const scrollY = window.scrollY;
    //   setIsScrolled(scrollY > 0);
    // };
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const threshold = 120; // Distance from the bottom in pixels

      if (scrollY === 0) {
        setIsScrolled(false);
      }
      else if (documentHeight - (scrollY + windowHeight) < threshold) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Container maxWidth="xl">
        <HeaderDiv>
          {token && (
            <TopIcons>
              <AvatarSection>
                {/* {requestCount ? (
                  <Tooltip title={requestCount + " Friend Requests"}>
                    <small onClick={() => navigate("/dashboard/friends_list")}>
                      {requestCount}
                    </small>
                  </Tooltip>
                ) : (
                  ""
                )} */}
                <Avatar
                  ref={anchorRef}
                  onClick={handlePopperOpen}
                  sx={{ border: "2px solid #B8B6A9" }}
                  src={memberData && memberData.member.image}
                >
                  {memberData.member.firstName.charAt(0)}
                </Avatar>

                <Popper
                  open={openPopper}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: "111" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClosePopper}>
                          <MenuList
                            autoFocusItem={openPopper}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={handleEditProfile}>
                              Edit Profile
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </AvatarSection>

              <NotifySection>
                <Tooltip title={(requestCount || "") + " Friend Requests"}>
                  <MyNotifyIcons>
                    {requestCount ? <small>{requestCount}</small> : ""}
                    {/* <IconButton
                      onClick={() => navigate("/dashboard/friends_list", { state: "request" })}
                    >
                      <Person4 />
                    </IconButton> */}
                    {<img src={Icon1} onClick={() => navigate("/dashboard/friends_list", { state: "request" })}/>}
                  </MyNotifyIcons>
                </Tooltip>

                <Tooltip title="Notifications">
                  <MyNotifyIcons>
                    {notificationCount ? (
                      <small>{notificationCount}</small>
                    ) : (
                      ""
                    )}
                    {/* <IconButton onClick={() => handleNotification()}>
                      <Notifications />
                    </IconButton> */}
                    {<img src={Icon2} onClick={() => handleNotification()}/>}
                  </MyNotifyIcons>
                </Tooltip>
              </NotifySection>
            </TopIcons>
          )}

          <LogoDiv>
            <Link to="/">
              <img src={Logo} alt="finalHour" title="Final Hour" />
            </Link>
          </LogoDiv>

          {!infographic &&

            <SearchDiv>


              <Search fontSize="small" />

              <TextField
                ref={anchorRef2}
                value={search}
                type="search"
                placeholder={`Search ${searchType}`}
                onClick={handleSearchChange}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={e => { if (e.key === "Enter") handleSearchApply() }}
              />


              <Popper
                open={openPopper2}
                anchorEl={anchorRef2.current}
                role={undefined}
                placement="bottom"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start" ? "left top" : "left bottom",
                      // zIndex: 9999,
                      //position: 'relative',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClosePopper2}>
                        {/* <MenuList
                          autoFocusItem={openPopper}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleEditProfile}>
                            Edit Profile
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList> */}
                        <SearchFunctionDiv
                          autoFocusItem={openPopper2}
                          onKeyDown={handleListKeyDown2}
                        >
                          <FlexSwitch>
                            <span>Members</span>
                            <Switch
                              color="secondary"
                              name="searchType"
                              onChange={() => handleChangeSwitch("members")}
                              checked={searchType === "members" ? true : false}
                            />
                          </FlexSwitch>

                          <FlexSwitch>
                            <span>Causes</span>
                            <Switch
                              color="secondary"
                              name="searchType"
                              onChange={() => handleChangeSwitch("causes")}
                              checked={searchType === "causes" ? true : false}
                            />
                          </FlexSwitch>

                          <FlexSwitch>
                            <span>Charity Partners</span>
                            <Switch
                              color="secondary"
                              name="searchType"
                              onChange={() => handleChangeSwitch("organization")}
                              checked={
                                searchType === "organization" ? true : false
                              }
                            />
                          </FlexSwitch>

                          {/* <FlexSwitch>
                          <span>Location</span>
                          <Switch
                            color="secondary"
                            name="searchType"
                            onChange={() => handleChangeSwitch("location")}
                            checked={searchType === "location" ? true : false}
                          />
                        </FlexSwitch> */}

                          <Box textAlign={"right"} mt={2}>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={handleClosePopper2}
                            >
                              Cancel
                            </Button>

                            {/* {searchLoading ?
                          <CircularProgress size={20} />  
                          : */}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={handleSearchApply}
                              disabled={searchLoading || search === ""}
                            >
                              Apply
                            </Button>
                            {/* } */}
                          </Box>
                        </SearchFunctionDiv>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              {/* <select name="search">
              <option value="organization">Organization</option>
              <option value="cause">Cause</option>
              <option value="member">Member</option>
                        </select> */}

            </SearchDiv>
          }

          <LoginDiv>
            {!token &&
              <LoginButton
                style={{
                  position: 'static',
                  top: 'auto',
                  left: '0',
                  zIndex: 100,
                }}
                onClick={handleLogin}>Login</LoginButton>
            }

            {infographic ?
              <LoginButton
                style={{
                  position: 'static',
                  top: 'auto',
                  left: '0',
                  zIndex: 100,
                }}
                onClick={handleJoinNow}>Join Now</LoginButton>
              :
              <GiveButton
                style={{
                  position: isScrolled ? 'fixed' : 'static',
                  top: isScrolled ? '40%' : 'auto',
                  left: isScrolled ? (isMobile ? '60%' : '90%') : '0',
                  zIndex: 100,
                }}
                onClick={token ? handleGive : handleGive}>Give Now</GiveButton>
            }
          </LoginDiv>
        </HeaderDiv>
      </Container>

      {/* {showNotification &&  */}
      <HeaderRightDrawer
        showNotification={showNotification}
        notificationCallback={notificationCallback}
        allNotification={allNotification}
      />
      {/* } */}

      {openLoginDialog && <LoginDialog loginCallback={loginCallback} />}

      {openGive && <CauseDialog
        id={(
          location.pathname.startsWith("/causes/detail/")) ? id : null}
        causeCallBack={causeCallBack} />}
    </div>
  );
};

export default HeaderTop;
