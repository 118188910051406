import { styled, Snackbar, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import FriendBoxWithBtn from "./FriendBoxWithBtn";
import BlankImg from './../../../images/no-pictures.png';
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import DeleteDialog from "../DeleteDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const FriendSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#B891B3",
  paddingLeft: "3%",
  height:"100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "0",
  },
}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#000",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& h2": {
    margin: "0",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c",
    },
  },
}));
const FriendsList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [friendList, setFriendList] = useState([]);
  const [deleteID, setDeleteID] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  useEffect(() => {
    getFriendList();
  }, []);

  const getFriendList = () => {
    setLoading(true);
    httpClient.get(`member-connection/member`).then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setFriendList(data.data);
      }
    });
  }

  const handleView = () => {
    navigate("/dashboard/friends_list");
  };

  const handleRemove = (id) => {
    setOpenDeleteDialog(true);
    setDeleteID(id)
  }

  const callBack = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false);
    }

    if (call.success === true) {
      httpClient
        .post(`member-connection/remove`, {
          friendMemberID: deleteID,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDeleteDialog(false);
            setDeleteID("")
            getFriendList();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const handleClick = (member) => {
  //   var newSlug = (member.firstName + " " + member.lastName)
  //     .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
  //     .toLowerCase();
  //   navigate(`/members/detail/${newSlug}`, { state: member });
  // };
  const handleClick = (member) => {
    var newSlug = member.friendMemberID.memberName
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace whitespace with underscores
      .replace(/[^\w-]/g, ""); // Remove special characters except underscores and hyphens
    navigate(`/members/detail/${newSlug}`, { state: member.friendMemberID.friendMemberID });
    //console.log("stateee", member.friendMemberID.friendMemberID);
    //console.log("slug", newSlug);
  };

  return (
    <FriendSection>
      <div className="rightContainerFull">
        <HeaderDiv>
          <h2>Friends List</h2>
          <button onClick={handleView}>View all</button>
        </HeaderDiv>
        <div>
          {!loading ? (
            friendList.length > 0 ? (
            friendList.map((friend,index) => (
              index <= 5 &&
              <FriendBoxWithBtn
                image={((friend.friendMemberID.image !== "https://api.finalhour.org/uploaded/memImages" && friend.friendMemberID.image !== "https://stagingapi.finalhour.org/uploaded/memImages") ? friend.friendMemberID.image : BlankImg) || BlankImg}
                name={friend.friendMemberID.memberName}
                date={moment(friend.friendshipAddedDate).format("ddd, MMM Do YYYY")}
                handleRemove={() => handleRemove(friend.friendMemberID.friendMemberID)}
                handleClick={() => handleClick(friend)}
              
              />
            ))) : "No friends found") :
            <>
              Fetching Friends..
            </>
          }

        </div>
      </div>

      {openDeleteDialog && (
        <DeleteDialog callBack={callBack} heading={"friend"} />
      )}


      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </FriendSection>
  );
};

export default FriendsList;
