import { Container, Grid, styled, Tooltip } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import { Info } from '@mui/icons-material';

const HowSection = styled("div")(({ theme }) => ({
    background: "#c8c8c8",
    padding: "40px 0",
    [theme.breakpoints.down("md")]: {
        padding: "20px 0",
    },
    "& h2": {
        margin: "0",
        paddingBottom: "20px",
    },
    "& h3": {
        margin: "0",
        paddingBottom: "30px",
        fontWeight: "300",
        fontSize: "33px",
        [theme.breakpoints.down("md")]: {
            fontSize: "25px",
            paddingBottom: "15px"
        },
    },
    "& p": {
        margin: "0",
        textAlign: "justify",
    },
    "& strong": {
        fontSize: "16px",
        color: theme.palette.primary.red,
        // margin:"0",
    },
}));

const Header = styled("div")(({ theme }) => ({
    width: "60%",
    [theme.breakpoints.down("md")]: {
        width: "100%"
    },
}))

const Intro = () => {
    return (
        <HowSection>
            <Container maxWidth="xl">
                {/* <Tooltip title="The component is static for now">
                    <Info style={{ color: "navyblue" }} />
                </Tooltip> */}
                {/* <h2>Introduction</h2> */}
                <Grid container spacing={6}>
                    {/* <Grid item xs={12} md={6}>


                        <p>
                            The purpose of an FAQ section is to provide quick and easy access to commonly asked questions, thereby saving time and effort for both the users and the organization providing the information. By addressing frequently asked questions upfront, it reduces the need for individuals to contact customer support or seek assistance, as they can find the answers they need readily available.
                        </p>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <p>
                            An effective FAQ section typically covers a range of relevant topics, addressing concerns and inquiries that users or customers commonly encounter. The questions and answers should be concise, clear, and provide accurate information. They should be written in a user-friendly manner, using simple language and avoiding technical jargon whenever possible.
                        </p>
                    </Grid> */}


                    <Grid item xs={12} md={12}>
                        {/* <Header>
              <h3>It’s as simple as this, giving feels good.</h3>
            </Header>*/}
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} md={6}>
                <p>
                  Effort spent identifying what another person can really
                  benefit from in a lasting way stirs feelings of empathy and
                  love.
                  <br />
                  <br />
                  Giving without knowing the other person can be even more
                  rewarding.
                  <br />
                  <br />
                  Giving anonymously - and spontaneously - can feel extremely
                  gratifying.
                </p>
              </Grid> */}
                            <Grid item xs={12} md={12}>
                                <p>
                                    Welcome to the Frequently Asked Questions (FAQ) section of <strong>Final Hour</strong> website. We have compiled a list of common questions to provide you with quick and helpful information. If you have any additional inquiries, please don't hesitate to contact us.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>



            </Container>
        </HowSection>
    );
};

export default Intro;
