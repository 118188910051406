import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import BreadCrumb from "../../../Components/BreadCrumb";
import CausesNewsFeed from "../../../Components/Dashboard/CausesNewsFeed";
import CommunityStatistics from "../../../Components/Dashboard/CommunityStatistics";
import FavouriteOrganizations from "../../../Components/Dashboard/FavouriteOrganizations";
import FriendsActivity from "../../../Components/Dashboard/FriendsActivity";
import FriendsLeaderBoard from "../../../Components/Dashboard/FriendsLeaderBoard";
import FriendsList from "../../../Components/Dashboard/FriendsList";
import MainProfile from "../../../Components/Dashboard/MainProfile";
import RecentDonations from "../../../Components/Dashboard/RecentDonations";
import WorkspaceLeaderBoard from "../../../Components/Dashboard/WorkspaceLeaderBoard";


const MainDashboard = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    //console.log('in dashboard')
  },[])

  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1={"Dashboard"}
        subHeader2={"Main"}
      />
      <MainProfile />
      
      <RecentDonations />
      
      {/* <Container maxWidth="xl"> */}
      {/* <div style={{ display:"flex", flexDirection:"row",  }}>
        
        </div> */}
      {/* </Container> */}
      {/* <Grid container>
        <Grid item xs={12} md={7}> */}
          <CausesNewsFeed />
        {/* </Grid>
        <Grid item xs={12} md={5} sx={{ 
          background: "#cca17a"
           }}> */}

          <FavouriteOrganizations />
         
        {/* </Grid> */}
        
      {/* </Grid> */}
    </div>
  );
};

export default MainDashboard;
