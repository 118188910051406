import { Container, Grid, styled } from "@mui/material";
import React from "react";
import AboutTime from "./AboutTime";
import AboutVideo from "./AboutVideo";

const AboutSection = styled("div")(({ theme }) => ({
  backgroundImage:
    "linear-gradient(to right, #b8b6a9 0%, #b8b6a9 50%,  #ecab3b 50%, #ecab3b 100%)",
  [theme.breakpoints.down("md")]: {
    background: "#b8b6a9 ",
  },
  height:"100%"
  // height:"600px"
}));

const GridDiv = styled("div")(({ theme }) => ({
  padding:"25px 0",
  position:"relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    padding:"10px 0",
  },
}));


const LogoDiv = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  position: "absolute",
  display: "flex",
  alignItems:"center",
  justifyContent:"center",
  height:"100%",
  width:"100%",
  [theme.breakpoints.down("md")]: {
    transform: "rotate(0deg)",
    top:"18px",
  },
  "& span": {
    background: theme.palette.primary.red,
    padding: "32px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "0px 35px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
      padding: "22px",
    },
  },
}));

const AboutTimeVideo = ({data}) => {

  return (
    <AboutSection>
      <Container maxWidth="xl">
        <GridDiv>
            <AboutTime data={data}/>
            <AboutVideo data={data} />
            <LogoDiv>
                  <span></span>
                  <span></span>
                </LogoDiv>
        </GridDiv>
      </Container>
    </AboutSection>
  );
};

export default AboutTimeVideo;
