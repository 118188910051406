import React, { createRef, useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { httpClient } from "../../appUtility/Api";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetSection = styled("div")(({ theme }) => ({
  padding: "20px",
  "& h2": {
    fontFamily: "Epilogue",
    margin: "0",
    fontWeight: "500",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
  "& p": {

  },
  "& h4": {
    marginLeft: "9%",
    fontWeight: "400",
  },
}));

const FormDiv = styled("div")(({ theme }) => ({
  marginLeft: "15%",
  marginBottom: "100px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
    marginBottom: "40px",
  },
}));

let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const ForgotPassword = () => {
  const JoinButton = styled(Button)(({ theme }) => ({
    background: emailValid ? theme.palette.primary.red : "#aaa",
    color: "#fff",
    borderRadius: "15px",
    padding: "8px 25px",
    outline: "none",
    border: "2px solid transparent",
    transition: "0.3s",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.red,
      border: `2px solid ${theme.palette.primary.red}`,
    },
    "& span": {
      color: "#fff",
    },
  }));

  const recaptchaRef = createRef();
  const [capcha, setCapcha] = useState("");
  const [username, setUsername] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");

  var baseURL =
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : (window.location.hostname === "finalhour.retailcare.com.au" ? "https://finalhour.retailcare.com.au" : "https://finalhour.org");


  useEffect(() => {
    if (emailRegex.test(username)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [username]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setSnackMessage("");
    setSnackStatus("");
  };

  const handleSubmit = (e) => {
    setLoading(true);
    httpClient
      .post("forgot-password", {
        username: username,
        GRecaptchaResponse: capcha,
        baseURL: baseURL,
      })
      .then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setSnackStatus("success");
          setSnackMessage(data.data.message);
          setLoading(false);
        }
        if (data.success === false) {
          setOpen(true);
          setSnackStatus("error");
          setSnackMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          setLoading(false);
          // setSnackMessage(data.message[0]);
        }
      });
  };

  return (
    <ResetSection>
      <Container maxWidth="xl">
        <h2>Forgot Password?</h2>
        <h4>Please enter your email below. We’ll send you a reset link
          to your mail shortly.</h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <FormDiv>
              <div>
                {/* <InputLabel>Email</InputLabel> */}
                <TextField
                  placeholder="Email"
                  color={emailValid ? "primary" : "error"}
                  variant="outlined"
                  onChange={(e) => setUsername(e.target.value)}
                  name="username"
                  value={username}
                  sx={{
                    width: "100%", marginBottom: "20px", "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                    },
                  }}

                  helperText={
                    username
                      ? !emailValid
                        ? "Invalid Email Address!"
                        : ""
                      : "Required"
                  }
                />
              </div>

              <div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  // sitekey={window.location.hostname === "localhost" ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI": process.env.REACT_APP_SITEKEY }
                  sitekey={(window.location.hostname === "localhost" || window.location.hostname === "finalhour.retailcare.com.au") ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : process.env.REACT_APP_SITEKEY}
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  // sitekey="6Lfa0Z4dAAAAAEZUx3FOYPz8s7KaahSaX6Ou7qK8"
                  onChange={(e) => setCapcha(recaptchaRef.current.getValue())}
                />
              </div>

              <Box mt={2} textAlign="right">
                {loading ? (
                  <JoinButton disabled={true}>
                    <CircularProgress
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <span>Loading</span>
                  </JoinButton>
                ) : (
                  <JoinButton
                    onClick={handleSubmit}
                    disabled={username && emailValid ? false : true}
                  >
                    Submit
                  </JoinButton>
                )}
              </Box>
            </FormDiv>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackStatus}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </ResetSection>
  );
};

export default ForgotPassword;
