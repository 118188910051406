import { Button, styled } from "@mui/material";
import React from "react";

const FriendDiv = styled("div")(({ theme }) => ({
  padding:"10px",
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  gridGap:"10px",
  alignItems:"center",
  cursor:"pointer",
  "& img":{
    height:"100px",
    width:"100px",
    objectFit: "cover",
    borderRadius:"50%"
  }
}));

const FlexFriend = styled("div")(({ theme }) => ({
  padding: "10px",
  borderBottom: "1px solid #000",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  "& h4": {
    margin: "0",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "5px 20px",
    textTransform:"inherit",
    fontSize:"13px",
    cursor: "pointer",
    transition:"0.3s",
    "&:hover":{
      background:"#6e6c6c"
    }
  },
}));

const FriendBoxWithBtn = (props) => {
  return (
    <FriendDiv >
      <div onClick={props.handleClick}>
        <img src={props.image} alt={props.name} />
      </div>
      <FlexFriend>
        <div onClick={props.handleClick}>
          <span>{props.date}</span>
          <h4>{props.name}</h4>
        </div>

        <div>
          <Button onClick={props.handleRemove}>Remove</Button>
        </div>
      </FlexFriend>
    </FriendDiv>
  );
};

export default FriendBoxWithBtn;
