import { Box, Button, Container, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import FeatureBox1 from "./FeatureBox1";

const FeatureSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: theme.palette.primary.orange,
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    fontSize:"50px",
    margin:"0",
    paddingBottom:"20px",
    fontWeight:"200",
    [theme.breakpoints.down("md")]: {
      fontSize:"40px",
      paddingBottom:"10px"      
    },
  },
}));

const FeatureGrid = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "1fr 1fr 1fr 1fr",
  // gridGap: "100px",
  display:"flex",
  flexDirection:"row",
  flexWrap:"wrap",
  gap:"120px",
  justifyContent:"center",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));

const FeatureButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  backgroundColor: "#f1f1f1",
  outline: "none",
  border: "1px solid #333",
  padding: "5px 15px",
  fontSize: "14px",
  cursor: "pointer",
  transition: "0.3s",
  textTransform:"none",
  "&:hover": {
    background: "#6e6c6c",
    color:"#fff"
  },
  [theme.breakpoints.down("md")]: {
    fontSize:"12px",
    padding:"5px"      
  },
}));

const FeatureCauses = (props) => {
  const navigate = useNavigate();
  const [causesList, setCausesList] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    setLoading(true);
    httpClient.get("featured-charity-cause").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setCausesList(data.data);
      }
    });
  }, []);

  const handleClickCause = (cause) => {
    // console.log('cause is', cause);

    var newSlug = cause.name.replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-").toLowerCase();
    

    navigate(`/causes/detail/${newSlug}`, { state: cause });
  };



  return (
    <FeatureSection>
      <Container maxWidth="xl">
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
        >
          <h2>Featured Charities</h2>
          <FeatureButton onClick={props.handleView}>View all</FeatureButton>
        </Box>
        {!loading ? (
          <FeatureGrid>
            {causesList &&
              causesList.map((cause) => (
                <FeatureBox1
                  key={cause.causeID}
                  image={cause.image}
                  name={cause.name}
                  hours={cause.hours}
                  category={cause.categoryID}
                  crisis={cause.crisis}
                  handleClickCause={() => handleClickCause(cause)}
                />
              ))}
          </FeatureGrid>
        ) : (
          <FeatureGrid>
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
          </FeatureGrid>
        )}
      </Container>
    </FeatureSection>
  );
};

export default FeatureCauses;
