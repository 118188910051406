import { Box, Container, styled } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FeatureBox from "../../Home/FeatureCauses/FeatureBox";
import CauseDialog from "../CauseDialog";

const AllCausesSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#D9D9D9",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },

  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "1fr 1fr 1fr 1fr",
  // gridGap: "100px",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  flexWrap:"wrap",
  gap:"100px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));

const AllCauses = ({causeList}) => {
  const navigate = useNavigate();
  const [openCauseDialog, setOpenCauseDialog] = useState(false);
  
  const handleClickCause = (cause) => {
    // setOpenCauseDialog(true);
    // console.log('cause >>>', cause);
    var newSlug = cause.name.replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-').toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: cause });
  };

  const handleLink = () => {
  }

  const causeCallBack = (child) => {
    //console.log(child)
    if (child.open === false) {
      setOpenCauseDialog(false);
    }
  };

  return (
    <AllCausesSection>
      <Container maxWidth="xl">
        <h2>Causes</h2>
        <GridDiv>
          {causeList.length > 0 ?
           causeList.map(cause => (
          <FeatureBox
            key={cause.causeID}
            image={cause.image}
            name={cause.name}
            hours={cause.hours}
            members={cause.totalMembers}
            handleClickCause={() => handleClickCause(cause)}
          />
          ))
          :
          <small>
            No causes found
          </small>
          }
        </GridDiv>
      </Container>
      {/* {openCauseDialog && <CauseDialog causeCallBack={causeCallBack} />} */}
    </AllCausesSection>
  );
};

export default AllCauses;
