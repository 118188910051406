const colors = {
    primary: {
        main: "#4A4A4A",
        light: "#e9e9eb",
        dark:"#2f2e34",
        darker: "#19181a",
        lighter: "#F2F2F4",
        lightest: "#F9F9F9",
        shadow: "0px 5px 20px 0px rgba(0,0,0,0.07)",
        red: "#EA3323",
        orange: "#ECAB3B",
        purple: "#A87FA2",
        grey: "#B8B6A9",
        greyText: "#4A4A4A",
        greyBg: "#EBEBEB",
        green: "#c5eedc"
    },
    secondary: {
        light: "#b39c4d",
        main:"#000000",
        dark: "#3358600"
    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: "#fff"
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "#fff"
    },
    textColor: "#f2f2f4"
}

export default colors;