import { Skeleton, styled } from "@mui/material";
import React from "react";
import {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import parse from "html-react-parser";


import LeftImage1 from './../../../../images/samples1/finalhour-home-carousel-left-01-min.jpg'
import LeftImage2 from './../../../../images/samples1/finalhour-home-carousel-left-02-min.jpg'
import LeftImage3 from './../../../../images/samples1/finalhour-home-carousel-left-03-min.jpg'
import LeftImage4 from './../../../../images/samples1/finalhour-home-carousel-left-04-min.jpg'
import LeftImage5 from './../../../../images/samples1/finalhour-home-carousel-left-05-min.jpg'
import LeftImage6 from './../../../../images/samples1/finalhour-home-carousel-left-06-min.jpg'
import LeftImage7 from './../../../../images/samples1/finalhour-home-carousel-left-07-min.jpg'
import LeftImage8 from './../../../../images/samples1/finalhour-home-carousel-left-08-min.jpg'
import LeftImage9 from './../../../../images/samples1/finalhour-home-carousel-left-09-min.jpg'
import LeftImage10 from './../../../../images/samples1/finalhour-home-carousel-left-10-min.jpg'
import LeftImage11 from './../../../../images/samples1/finalhour-home-carousel-left-11-min.jpg'
import LeftImage12 from './../../../../images/samples1/finalhour-home-carousel-left-12-min.jpg'
import LeftImage13 from './../../../../images/samples1/finalhour-home-carousel-left-13-min.jpg'
import LeftImage14 from './../../../../images/samples1/finalhour-home-carousel-left-14-min.jpg'
import LeftImage15 from './../../../../images/samples1/finalhour-home-carousel-left-15-min.jpg'

const AboutSection = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.grey,
  backgroundSize:"cover !important",
  padding: "40px 60px",
  height: "450px",
  position: "relative",
  display:"flex",
  flexDirection:"column",
  justifyContent:"flex-end",
 
  [theme.breakpoints.down("md")]: {
    padding: "20px 10px",
    height: "300px",
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
    padding: "20px 0",
    fontSize: "40px",
    color: "#fff",
    zIndex:"1",

    [theme.breakpoints.down("sm")]: {
      padding: "0px 0",
      marginTop: "20px",
      fontSize: "25px",
    },
  },
  "& p": {
    margin: "0",
    color: "#fff",
    paddingBottom: "10px",
    fontWeight: "300",
  },
}));

const ButtonDiv = styled("div")(({ theme }) => ({
  textAlign: "right",
  paddingBottom: "30px",
  paddingTop:"60px",
  [theme.breakpoints.down("md")]: {
    textAlign: "left",
    paddingBottom: "20px",
    paddingTop:"0px",
  },
  "& button": {
    color: theme.palette.primary.grey,
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "10px 20px",
    outline: "none",
    border: "1px solid #fff",
    transition: "0.3s",
    cursor: "pointer",
  },
  "& button:hover": {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const ContentDiv = styled("div")(({ theme }) => ({
  // display:"flex",
  // flexDirection:"column",
  // justifyContent:"space-between"
  zIndex:"1"
}));

const AboutTime = ({ data }) => {
 //console.log('about time ',data.sliders[0].data.leftImage);
  
  // const imagePaths = [
  //   LeftImage1,
  //   LeftImage2,
  //   LeftImage3,
  //   LeftImage4,
  //   LeftImage5,
  //   LeftImage6,
  //   LeftImage7,
  //   LeftImage8,
  //   LeftImage9,
  //   LeftImage10,
  //   LeftImage11,
  //   LeftImage12,
  //   LeftImage13,
  //   LeftImage14,
  //   LeftImage15,
  // ];
 const imagePaths = data && data.sliders.map(slider => slider.data.leftImage);
 //console.log("imgpath", imagePaths);
  // const [imagePaths, setImagePaths] = useState([]);

  // useEffect(() => {
  //   const storedImagePaths = localStorage.getItem('imagePathsL');
  //   if (storedImagePaths) {
  //     setImagePaths(JSON.parse(storedImagePaths));
  //   } else {
  //     const initialImagePaths = data && data.sliders.map(slider => slider.data.leftImage);
  //     setImagePaths(initialImagePaths);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   localStorage.setItem('imagePathsL', JSON.stringify(imagePaths));
  // }, [imagePaths]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % imagePaths.length);
    }, 7000);

    return () => {
      clearInterval(intervalId);
    };
  }, [imagePaths]);

  useEffect(() => {
    const preloadImages = (imagePaths) => {
      for (let i = 0; i < imagePaths.length; i++) {
        const img = new Image();
        img.src = imagePaths[i];
        //img.crossOrigin = 'anonymous';
      }
    };

    preloadImages(imagePaths);
  }, [imagePaths]);
  
    return (
      <AboutSection
        style={{
          background: `url(${imagePaths[currentImageIndex]}) center center no-repeat`,
     
          transition: "background-image 0.5s ease-in-out"
        }}
      >
        <h1>{data.title ? data.title : <Skeleton animation="wave" />}</h1>
        <ContentDiv>
          <div>
            {data.body ? (
              parse(data.body || "")
            ) : (
              <p>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </p>
            )}
          </div>
        </ContentDiv>
      </AboutSection>
    );
  };
  
  export default AboutTime;

   