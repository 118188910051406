import { Box, Container, Grid, Skeleton, styled,Button } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import CauseImageBox from "./CauseImageBox";
import OrangeDotted from './../../../images/samples/orange-dotted2.png';

const RootDiv = styled("div")(({theme}) => ({
  position:"relative",
  zIndex:"1",

}))

const CauseDiv = styled("div")(({ theme }) => ({
  // backgroundColor: "#EAB38B",
  // background: "linear-gradient(to bottom, #EAB38B 50%, #E3A16D 50%)",
  background: "linear-gradient(to bottom, #EAB38B 50%, #ef9f60 50%)",
  height: "400px",
  padding: "10px 0",
  position:"relative",
  paddingBottom: "80px",
  [theme.breakpoints.down("md")]: {
    height:"100%",
    paddingBottom: "0px",
  },

}));
const CauseText = styled("div")(({ theme }) => ({
  "& p": {
    fontSize: "23px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
}));

const CauseImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap:"40px",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const DottedImageDiv = styled("div")(({theme}) => ({
  background: "#f4893c",
  "& img":{
    width:"100%",
    height:"100%",
    // maxHeight:"170px",
    // maxWidth:"100%",
    // overflow:"hidden",
  },
  "& h2":{
    textAlign:"center",
    color:"#fff",
    margin: "0",
  },
  
}))

const CategoryDiv = styled("div")(({theme}) => ({
  paddingTop:"30px",
  paddingBottom:"50px",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  flexWrap:"wrap",
  gap:"15px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop:"20px",
    paddingBottom:"30px",
  },
  "& a":{
    border:"1px solid #333",
    borderRadius:"10px",
    padding:"5px 15px",
    display:"flex",
    gap:"10px",
    alignItems:"center",
    justifyContent:"center",
    "& img":{
      height:"20px",
      width:"20px",
      objectFit:"contain"
    },
    "& p":{
      margin:"0"
    }
  }
}))

const CauseToInvest = (props) => {
  const [category, setCategory] = useState([]);
  const [invested, setInvested] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFeaturedCategories();
    //getInvestInCauses();
  }, []);

  const getFeaturedCategories = () => {
    setLoading(true);
    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategory(data.data);
        setLoading(false);
      }
    });
  };

  const getInvestInCauses = () => {
    setLoading(true);
    httpClient.get("invest-in-causes").then(({ data }) => {
      if (data.success) {
        setInvested(data.data);
        setLoading(false);
      }
    });
  };

  return (
    <RootDiv>
    {/* {props.page === "causes" ? "" :
    <CauseDiv>
      <Container maxWidth="lg">
        
        <Grid container mt={4}>
          {!loading ? (
            <CauseImageDiv>
              {invested.map((invest) => (
               
                <CauseImageBox
                  key={invest.id}
                  text={invest.title}
                  image={invest.image}
                />
                // </Grid>
              ))}
            </CauseImageDiv>
          ) : (
            <>
              <CauseImageDiv>
                <Skeleton variant="circular" width={300} height={300} />
                <Skeleton variant="circular" width={300} height={300} />
                <Skeleton variant="circular" width={300} height={300} />
              </CauseImageDiv>
            </>
          )}

        </Grid>
      </Container>
    </CauseDiv>
  } */}


    <DottedImageDiv>
          <img src={OrangeDotted} alt="orange_dotted" /> 
          <Container maxWidth="lg">
            <h2>What causes do you want to invest your time with?</h2>
            <CategoryDiv>
              {category && category.map((cats,index) => {
                if (cats.parentCategoryID == null) {
                  return (
                    <Link
                      to={`/causes/category/${cats.slug}`}
                      state={cats}
                      style={{ backgroundColor: cats.categoryColor }}
                    >
                      <img src={cats.categoryIcon} at={cats.categoryName} />

                      <p>{cats.categoryName}</p>
                    </Link>
                  );
                }
              })}
            </CategoryDiv>
          </Container>
    </DottedImageDiv>

    </RootDiv>
  );
};

export default CauseToInvest;
