import {
  Box,
  Container,
  Grid,
  Skeleton,
  styled,
  Button,
  Tooltip,
  Snackbar,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import Transparent from "./../../../images/samples/transp.png";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import CrisisImage from "./../../../images/emergency.png";
import CauseDialog from "../CauseDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RootDiv = styled("div")(({ theme }) => ({}));

const CauseName = styled(Box)(({ theme }) => ({
  width: "360px",
  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const CauseHours = styled(Box)(({ theme }) => ({
  width: "160px",
  margin: "0 0px",
  fontSize: "16px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const CauseMembers = styled(Box)(({ theme }) => ({
  width: "260px",
  margin: "0 0px",
  fontSize: "16px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));
const CauseFavourite = styled(Box)(({ theme }) => ({
  width: "160px",
  margin: "0 0px",
  fontSize: "16px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //textAlign: "left",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const CauseButtonDiv = styled("div")(({ theme }) => ({
  width: "175px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //textAlign: "left",
    marginTop: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const CauseCategory = styled(Box)(({ theme }) => ({
  width: "360px",
  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "700",
  "& a": {
    width: "fit-content",
    border: "1px solid #333",
    borderRadius: "10px",
    padding: "5px 10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "20px",
      width: "20px",
      objectFit: "contain",
      border: "none",
      marginBottom: "0px",
    },
    "& p": {
      margin: "0",
      fontWeight: "400",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center", // Add this line
      "& a": {
        justifyContent: "center", // Add this line
      },
    },
  },
}));

const GiveButton = styled("button")(({ theme }) => ({
  padding: "10px 30px",
  borderRadius: "100px",
  border: "1px solid gray !important",
  color: theme.palette.primary.red,
  background: "#fff",
  marginLeft: "20px",
  transition: "0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
  },
  "&:hover": {
    background: `${theme.palette.primary.red} !important`,
    color: "#fff !important",
    border: `1px solid gray !important`,
  },
}));

const DisableButton = styled("button")(({ theme }) => ({
  padding: "10px 30px",
  borderRadius: "100px",
  border: "1px solid gray !important",
  color: "#333",
  background: "#f1f1f1",
  marginLeft: "20px",
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
  },
}));


const GridDiv = styled("div")(({ theme }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const RightDiv = styled("div")(({ theme }) => ({
  "& img": {
    height: "400px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      height: "350px",
    },
  },
  height: "400px",
  [theme.breakpoints.down("md")]: {
    height: "350px",
  },
}));

const CauseListDiv = styled("div")(({ theme }) => ({
  // padding:"40px 0"
  paddingTop: "70px",
  paddingBottom: "40px",
}));

const TransImage = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  zIndex: "-1",
  "& img": {
    width: "100%",
    height: "120px",
  },
}));

const CategoryContent = styled("div")(({ theme }) => ({
  paddingTop: "40px",
  paddingRight: "40px",
  position: "absolute",
  // paddingLeft:theme.spacing(15),
  width: "45%",
  top: "0",
  "& h2": {
    fontSize: "35px",
    margin: "0",
    paddingBottom: "20px",
  },
  "& p": {
    margin: "0",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0px",
    padding: "20px",
    width: "auto",
    "& h2": {
      fontSize: "25px",
    },
  },
}));

const CrisisImg = styled("div")(({ theme }) => ({
  "& img": {
    width: "25px",
    height: "25px",
    // position: "absolute",
    // bottom: "70px",
    // left: "85px",
    // border:"none",
    borderRadius: "initial"
  },
}));

const Environment = (props) => {
  const loginData = JSON.parse(localStorage.getItem('token'));
  const [open, setOpen] = useState(false);
  const [openGive, setOpenGive] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [causeArr, setCauseArr] = useState([]);
  const [loadingFav, setLoadingFav] = useState(false);

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  const handleFavourite = (id) => {
    setLoadingFav(true);
    httpClient
      .post("member-fav-cause", {
        causeID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setLoadingFav(false)
          setCauseArr([...causeArr, data.data]);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          // getAllCharityCauses();
          props.handleRefetch();
        } else {
          setLoadingFav(false)
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
  };

  const handleFavoriteRemove = (id) => {
    // let favID;
    // causeArr.map(cause => {
    //   if(cause.causeID === id){
    //     favID = cause.favCauseID;
    //   }
    // })
    setLoadingFav(true);
    httpClient.delete(`member-fav-cause/${id}`).then(({ data }) => {
      if (data.success) {
        setLoadingFav(false)
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        let newArr = causeArr.filter((cause) => cause.causeID !== id);
        setCauseArr(newArr);
        // getAllCharityCauses();
        props.handleRefetch();
      } else {
        setLoadingFav(false)
        setOpen(true);
        setMessageState("error");
        setMessage(data.message);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const LogoDiv = styled("div")(({ theme }) => ({
    transform: "rotate(90deg)",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      transform: "rotate(0deg)",
    },
    "& span": {
      background: props.category.categoryColor,
      padding: "32px",
      display: "inline-block",
      borderRadius: "50%",
      margin: "0px 35px",
      [theme.breakpoints.down("md")]: {
        margin: "0 20px",
        padding: "22px",
      },
    },
  }));

  const CauseBox = styled(Box)(({ theme }) => ({
    borderBottom: `2px solid ${props.category.categoryColor}`,
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center", // Add this line
    },
  }));

  const LeftDiv = styled("div")(({ theme }) => ({
    "& img": {
      height: "400px",
      width: "100%",
      objectFit: "cover",
      opacity: "0.2",
      [theme.breakpoints.down("md")]: {
        height: "350px",
      },
    },
    backgroundColor: props.category.categoryColor + "80",
    // backgroundColor: shadeColor(props.category.categoryColor, 30),
    height: "400px",
    [theme.breakpoints.down("md")]: {
      height: "350px",
    },
  }));

  const DottedImageDiv = styled("div")(({ theme }) => ({
    position: "relative",
    background: props.category.categoryColor + "50",
    // background: shadeColor(props.category.categoryColor, 50),
    zIndex: "1",

    "& h2": {
      textAlign: "center",
      color: "#fff",
      margin: "0",
    },
  }));

  const causeCallBack = (call) => {
    if (call.open === false) {
      setOpenGive(false);
    }
  };

  const handleClickCause = (cause) => {

    var newSlug = cause.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: { cause: cause, give: false } });
    // console.log("newSlugggggggg", newSlug);
  };

  const handleClickCauseGive = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    //navigate(`/causes/detail/${newSlug}`, { state: {cause:cats, give: true} });
    setId(newSlug);
    setOpenGive(true);
  }


  const [causeList, setCauseList] = useState("");

  useEffect(() => {
    if (props.currCauseID) {
      var newArray = props.category.causes && props.category.causes.filter(cause => {
        return cause.causeID !== props.currCauseID
      })
      setCauseList(newArray)
    } else {
      setCauseList(props.category.causes);
    }
  }, [props.category])

  // useEffect(() => {
  //   if(props.allData){
  //     setCauseList(props.allData.causes)
  //   }
  // },[props.allData])



  //console.log('props in all ', props)
  //console.log('caus', causeList)

  return (
    <RootDiv>
      {!props.switch && (
        <GridDiv>
          <LeftDiv>
            <img src={props.category.leftImage} alt="Image_1" />
            <CategoryContent className="leftContainerSlider">
              <h2>{props.category.categoryName}</h2>
              <p>{parse(props.category.categoryShortDescription || "")}</p>
            </CategoryContent>
          </LeftDiv>
          <RightDiv>
            <img src={props.category.rightImage} alt="Image_2" />
          </RightDiv>
          <LogoDiv>
            <span></span>
            <span></span>
          </LogoDiv>
        </GridDiv>
      )}

      <DottedImageDiv>
        {/* <div>
                <img src={GreenSolid} alt="orange_dotted" />
                </div> */}
        {!props.switch && (
          <TransImage>
            <img src={Transparent} alt="transparent_png" />
          </TransImage>
        )}
        <CauseListDiv>
          <Container maxWidth="xl">
            <div style={{ overflowX: "auto" }}>
              {causeList.length > 0 &&
                causeList.map((cause, index) => (
                  // index < 5 &&
                  <CauseBox>
                    <div
                      style={{ cursor: "pointer", dispay: "flex", flexDirection: "column", gap: "10px" }}
                      onClick={() => handleClickCause(cause)}
                    >
                      <CauseName>{cause.name}
                        {cause.crisis === 1 ? (
                          <CrisisImg>
                            <img src={CrisisImage} />
                          </CrisisImg>
                        ) : (
                          ""
                        )}
                      </CauseName>

                    </div>

                    <CauseCategory>
                      <Link
                        to={`/causes/category/${props.category.slug}`}
                        state={props.category}
                        style={{
                          background: props.category.categoryColor,
                        }}
                      >
                        <>
                          <img src={props.category.categoryIcon} alt="Icon" />
                          <p>{props.category.categoryName}</p>
                        </>
                      </Link>
                    </CauseCategory>
                    {/* <CauseHours>
                      <strong>{cause.hours} </strong>hours
                    </CauseHours>
                    <CauseMembers>
                      <strong>{cause.totalMembers} </strong>members
                    </CauseMembers> */}
                    <CauseHours>
                      <strong>{cause.hours || "0"} </strong> {(cause.hours == "0" || cause.hours == "1" || cause.hours == null) ? "hour" : "hours"}
                    </CauseHours>
                    <CauseMembers>
                      <strong>{cause.totalMembers || "0"} </strong> {(cause.totalMembers == "0" || cause.totalMembers == "1" || cause.totalMembers == null) ? "member" : "members"}
                    </CauseMembers>
                    <CauseFavourite>
                      {loginData ? (
                        cause.favorite !== 1 ? (
                        <Tooltip title="Add To Favorite">
                          <IconButton
                            onClick={() => handleFavourite(cause.causeID)}
                            disableRipple
                            disableFocusRipple
                            disabled={loadingFav}
                          >
                            <FavoriteBorder
                              sx={{ color: "gray", cursor: "pointer" }}
                            // onClick={() => handleFavourite(cause.causeID)}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Remove from Favorite">
                          <IconButton
                            onClick={() => handleFavoriteRemove(cause.favoriteID)}
                            disableRipple
                            disableFocusRipple
                            disabled={loadingFav}
                          >
                            <Favorite
                              sx={{ color: "#EA3323", cursor: "pointer" }}
                            // onClick={() => handleFavoriteRemove(cause.favoriteID)}
                            />
                          </IconButton>
                        </Tooltip>
                      )) : (
                        <Tooltip title="Join now to add to favourites">
                          <IconButton
                            
                            disableRipple
                            disableFocusRipple
                            disabled={loadingFav}
                          >
                            <FavoriteBorder
                              sx={{ color: "gray", cursor: "pointer" }}
                        
                            />
                          </IconButton>
                        </Tooltip>

                      )}
                    </CauseFavourite>
                    <CauseButtonDiv>
                      {!props.switch ? (
                        <GiveButton onClick={() => handleClickCauseGive(cause)}> Give</GiveButton>
                      ) : (
                        (cause.causeID === props.selectedID) && props.loading ?
                          <DisableButton disabled={true}>
                            <CircularProgress size={20} />
                          </DisableButton>
                          :
                          <GiveButton
                            onClick={() => props.handleSwitchCause(cause)}
                            disabled={props.loading}
                          >
                            Switch
                          </GiveButton>
                      )}
                    </CauseButtonDiv>
                  </CauseBox>
                ))}
            </div>
          </Container>
        </CauseListDiv>
      </DottedImageDiv>
      {openGive && <CauseDialog id={id} causeCallBack={causeCallBack} />}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </RootDiv>
  );
};

export default Environment;
