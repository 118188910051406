import { Box, Container, Snackbar, styled } from "@mui/material";
import React, { useState } from "react";
import WelcomeBox from "../WelcomeBox";
import MuiAlert from "@mui/material/Alert";
import { httpClient } from "../../../appUtility/Api";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const WelcomeSection = styled("div")(({ theme }) => ({
  padding: "20px",
  "& h2": {
    fontFamily: "Epilogue, sans-serif !important",
    margin: "0",
    fontWeight: "500",
    paddingBottom: "60px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
  },
  "& p": {
    margin: "0",
    fontFamily: "Epilogue, sans-serif !important",
  },
}));

const WelcomeGrid = styled("div")(({ theme }) => ({
  marginTop: "40px",
  marginBottom: "100px",
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  gridGap: "15px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    marginTop: "20px",
    marginBottom: "20px"
  },
}));

const Welcome = (props) => {
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleResendConfirmation = () => {
    setConfirmationLoading(true);
    httpClient
      .post(`confirmation/${props.memberID}`)
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setConfirmationLoading(false);
        }
        else {
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          setOpen(true);
          setMessageState("error");
          setConfirmationLoading(false);
        }
      });
  };
  return (
    <WelcomeSection>
      <Container maxWidth="xl">
        {/* <h2>Welcome</h2>
        <p>You’ve joined the Final Hour community.</p>
        <WelcomeGrid>
          <WelcomeBox
            image={
              "https://images.unsplash.com/photo-1482332486572-10fff1bedfbe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            text="Take the Tour"
            link="/tour"
            color="#ECAB3B"
          />
          <WelcomeBox
            image={
              "https://images.unsplash.com/photo-1668067611084-80ce6db6bbe9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
            }
            text="View your Progile"
            link="/profile"
            color="#B8B6A9"
          />
          <WelcomeBox
            image={
              "https://images.unsplash.com/photo-1651374262948-8a476eb6e975?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            }
            text="Discover Causes"
            link="/causes"
            color="#EAB38B"
          />
          <WelcomeBox
            image={
              "https://images.unsplash.com/photo-1513128034602-7814ccaddd4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80"
            }
            text="Find your Workplace"
            link="/workplace"
            color="#33BB88"
          />
          <WelcomeBox
            image={
              "https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
            }
            text="Find your Friends"
            link="/friends"
            color="#6C4667"
          />
        </WelcomeGrid> */}
        <Box display={"flex"} flexDirection="column" alignItems={"center"} justifyContent="center" height={400}>
          <h2>Thank you for Signing Up!</h2>
          <p>Please verify your Email. A link has been sent to your inbox.</p>
          <h5>
            Didn’t receive a verification email?{" "}
            {confirmationLoading ? (
              " Sending..."
            ) : (
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={handleResendConfirmation}
              >
                Click here to resend confirmation link.
              </span>
            )}
          </h5>

        </Box>
      </Container>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </WelcomeSection>
  );
};

export default Welcome;
