import {
  Container, Grid, styled, Snackbar, Button, Pagination,
  PaginationItem,
  Skeleton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/BreadCrumb";
import MyFriendsBox from "../../Components/Dashboard/MyFriendsBox";
import MuiAlert from "@mui/material/Alert";
import { httpClient } from "../../appUtility/Api";
import { useNavigate } from "react-router-dom";
import AddMemberDialog from "./AddMemberDialog";
import { ArrowCircleLeftOutlined, ArrowCircleLeftRounded, ArrowCircleRightOutlined, ArrowCircleRightRounded, NavigateBeforeRounded, NavigateNextRounded, NextPlanRounded } from "@mui/icons-material";
// import BlankImg from './../../images/no-pictures.png'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FriendSection = styled("div")(({ theme }) => ({
  background: "#9a6d97",
  padding: "5px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "10px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "10px 0",

  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(5,1fr)",
  display: "flex",
  gap: "10px",
  width: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    //gridTemplateColumns: "1fr",
    //gridGap: "20px",
    gap: "50px"
  },
}));
const PaginationBox = styled("div")(({ theme }) => ({
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#4A4A4A",
  "& span": {
    fontSize: "16px",
    marginRight: "10px",
  },
  "& button": {
    color: "#4A4A4A",
  },
}));

const Members2 = ({ memberList }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [friendID, setFriendID] = useState("");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  //const [memberList, setMembers] = useState([]);
  const [memberArr, setMemberArr] = useState([]);

  const [lastPage, setLastPage] = useState("");
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(20);
  const [total, setTotal] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [count, setCount] = useState(20);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   setLoading(true);
  //   httpClient.get("team-members").then(({ data }) => {
  //     if (data.success) {
  //       setMembers(data.data);
  //       setFrom(data.meta.from);
  //       setTo(data.meta.to);
  //       setTotal(data.meta.total);
  //       setCount(data.meta.per_page);
  //       setCurrPage(data.meta.current_page);
  //       setLastPage(data.meta.last_page);
  //       setLoading(false);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   handleChangePage();
  // }, []);

  // const handleChangePage = (e, value) => {
  //   setLoading(true);
  //   httpClient
  //     .get(`team-members?page=${value === undefined ? "" : value}&pagination=${count}`)
  //     .then(({ data }) => {
  //       if (data.success) {
  //         //setMembers(data.data);
  //         setFrom(data.meta.from);
  //         setTo(data.meta.to);
  //         setTotal(data.meta.total);
  //         setCount(data.meta.per_page);
  //         setCurrPage(data.meta.current_page);
  //         setLastPage(data.meta.last_page);
  //         setLoading(false);
  //       }
  //     })
  // };

  const handleAdd = (id) => {
    setOpenDialog(true);
    setFriendID(id);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenDialog(false);
      setFriendID("");
    }
    if (call.success === true) {
      httpClient
        .post(`member-connection/add`, {
          friendMemberID: friendID,
        })
        .then(({ data }) => {
          if (data.success) {
            setMemberArr([...memberArr, friendID]);
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDialog(false);
            setFriendID("");
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleClick = (member) => {
    // var newSlug = (member.firstName + " " + member.lastName)
    //   .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    //   .toLowerCase();
    navigate(`/members/detail/${member.slug}`, { state: member });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //console.log("memberAr", memberArr);
  const Logo = styled("div")(({ theme }) => ({
    transform: "rotate(90deg)",
    display: "inline-block",
    "& span": {
      background: theme.palette.primary.red,
      padding: "5px",
      display: "inline-block",
      borderRadius: "50%",
      margin: "12px 0px 0px 3px",
      [theme.breakpoints.down("md")]: {
        margin: "12px 0px 0px 3px",
        padding: "4px",
      },
    },
  }));


  return (
    <div>

      <FriendSection memberList={memberList} >

        <Container maxWidth="xl">

          <GridDiv>
            {!loading ? (
              memberList.length > 0 ? (
                memberList.map((member, index) => (
                  <MyFriendsBox
                    key={member.memberID}
                    image={member.image}
                    memberName={member.firstName + " " + member.lastName}
                    city={
                      member.address1 +
                      (member.address2 && ", " + member.address2)
                    }
                    occupation={member.occupation}
                    handleAdd={() => handleAdd(member.memberID)}
                    friend={member.friend}
                    messageState={
                      memberArr.includes(member.memberID) ? "success" : false
                    }
                    handleClick={() => handleClick(member)}
                    memberList={true}
                  />
                ))) : "No members found") :
              <>
                Fetching Members..
              </>
            }

          </GridDiv>



        </Container>

      </FriendSection>

      {openDialog && <AddMemberDialog callBack={callBack} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Members2;
