import { Container, Grid, styled, Tooltip } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Info } from "@mui/icons-material";
import parse from 'html-react-parser'

const TeamDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  // background: "#C7CEAB",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
}));

const Questions = ({ data }) => {
  return (
    <TeamDiv>
      <Container maxWidth="xl">
        {/* <Tooltip title="The component is static for now">
          <Info style={{ color: "navyblue" }} />
        </Tooltip> */}
        <h2>Questions</h2>
        {data &&
          data.map((question) => (
            <div>
              <h2 style={{ color:"red", fontWeight:"300" }}>{question.name}</h2>
              {question.faqs.map((faq) => (
                <div>
                  <Accordion
                    sx={{
                      marginBottom: "40px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, .25)",
                      }}
                    >
                      <Typography>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <Typography>{parse(faq.body || "")}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
          ))}
      </Container>
    </TeamDiv>
  );
};

export default Questions;
