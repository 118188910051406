import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CrisisImage from "./../../../../images/emergency.png";


const FeatureDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  cursor: "pointer",
  position: "relative",
  zIndex: "0",
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  "& img": {
    objectFit: "cover",
    width: "250px",
    height: "250px",
    borderRadius: "50%",
    border: "12px solid #C08F43",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      height: "250px",
      width: "250px",
    },
  },
  "& p": {
    margin: "0",
    fontWeight: "600",
  },
  "& span": {
    fontSize: "25px",
  }
}));
// const CategoryDiv = styled("div")(({ theme }) => ({
//   paddingTop: "30px",
//   paddingBottom: "50px",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   flexWrap: "wrap",
//   gap: "15px",
//   [theme.breakpoints.down("md")]: {
//     paddingTop: "20px",
//     paddingBottom: "30px",
//   },
//   "& a": {
//     border: "1px solid #333",
//     borderRadius: "10px",
//     padding: "5px 15px",
//     display: "flex",
//     gap: "10px",
//     alignItems: "center",
//     justifyContent: "center",
//     "& img": {
//       height: "20px",
//       width: "20px",
//       objectFit: "contain",
//       border: "none !important" 
//     },
//     "& p": {
//       margin: "0",
//       fontWeight: "300 !important"
//     }
//   }
// }))

const CategoryDiv = styled("div")(({ theme }) => ({
  margin: "10px 0",
  "& a": {
    border: "1px solid #333",
    borderRadius: "10px",
    padding: "5px 10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "20px",
      width: "20px",
      objectFit: "contain",
      border: "none",
      marginBottom: "0px",
    },
    "& p": {
      margin: "0",
      fontWeight: "400",
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {
     
      //width: "100%",
      marginBottom: "10px",
      marginLeft: "55px",
      flexDirection: "row",
      textAlign: "center",
      width: "60%"
      
    },
  },
}));

const FeatureBox1 = (props) => {
  //console.log("props>>>>", props.category)
  const token = localStorage.getItem("token");
  const tokenData = JSON.parse(token);

  const AddBox = styled("div")(({ theme }) => ({
    zIndex: "1",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "27px",
    width: "27px",
    right: "10px",
    top: "10px",
    borderRadius: "50%",
    padding: "6px",
    color: "pink",
    cursor: "pointer",
    backgroundColor: "#fafafa",
    [theme.breakpoints.down("md")]: {
      right: "10px",
      top: "10px",
    },
  }));

  const CrisisImg = styled("div")(({ theme }) => ({
    "& img": {
      width: "40px",
      height: "40px",
      position: "absolute",
      bottom: "140px",
      right: "0",
      border:"none",
      borderRadius:"initial"
    },
  }));


  return (
    <FeatureDiv>
      {props.remove && (
        <AddBox>
          <Tooltip title="Remove from Favorite">
            <Favorite onClick={props.handleFavoriteRemove} />
          </Tooltip>
        </AddBox>
      )}

      {props.favourite &&
        // props.messageState !== "success" ?
        (props.liked !== 1 ? (
          tokenData && (
            <AddBox>
              <Tooltip title="Add To Favorite">
                <FavoriteBorder onClick={props.handleFavourite} />
              </Tooltip>
            </AddBox>
          )
        ) : (
          <AddBox>
            <Tooltip title="Remove from Favorite">
              <Favorite onClick={props.handleFavoriteRemove} />
            </Tooltip>
          </AddBox>
        ))}
      <div style={{ position:"relative" }}>
        <div onClick={props.handleClickCause}>
          <img src={props.image} />
          {props.crisis === 1 ? (
            <CrisisImg>
              <img src={CrisisImage} />
            </CrisisImg>
          ) : (
            ""
          )}

          <p>
            {props.name.length > 16
              ? `${props.name.substring(0, 16)}...`
              : props.name}
          </p>
        </div>


        {props.category ? (
          <CategoryDiv>

            <Link
              // to={{ pathname: `/causes/category/${cats.slug}`, state: cats }}
              to={`/causes/category/${props.category.categorySlug}`}
              state={props.category}
              style={{ backgroundColor: props.category.categoryColor }}
            >
              <img
                src={props.category.categoryIcon}
                at={props.category.categoryName}
              />

              <p>{props.category.categoryName}</p>
            </Link>
          </CategoryDiv>
        ) : (
          ""
        )}
        {/* {props.hours ? ( */}
        <span>
          <strong>{props.hours || "0"}</strong> {(props.hours == "0" || props.hours == "1" || props.hours == null) ? "hour" : "hours"}
        </span>
        {/* ) : (
          ""
        )} */}
      </div>
    </FeatureDiv>
  );
};

export default FeatureBox1;
