import { Container, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import LeaderBoardComponent from "../LeaderBoardComponent";

const LeaderboardSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#6C4667",
}));

const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#fff",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "50px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
  "& button": {
    borderRadius: "25px",
    backgroundColor: "#929292",
    color: "#fff",
    outline: "none",
    border: "transparent",
    padding: "7px 25px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#6e6c6c"
    }
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(4,1fr)",
  // gridGap: "100px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "120px",
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "20px",
  },
}));

const FriendsLeaderBoard = () => {
  const navigate = useNavigate();
  const [friendList, setFriendList] = useState([])
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getFriendList()
  }, [])

  const getFriendList = () => {
    setLoading(true);
    httpClient.get('member-connection/leaderboard').then(({ data }) => {
      if (data.success) {
        setFriendList(data.data)
        setLoading(false);
      }
      setLoading(false);
    })
  }

  const handleClick = (member) => {
    var newSlug = (member.member.firstName + " " + member.member.lastName)
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/members/detail/${newSlug}`, { state: member.member });
  }

  return (
    <LeaderboardSection>
      <Container maxWidth="xl">
        <HeaderDiv>
          <h2>Friends Leaderboard</h2>
          <button>View all</button>
        </HeaderDiv>
        <GridDiv>
          {!loading ? (
            friendList.length > 0 ? (
              friendList.map((friend, index) => (
                index <= 4 &&
                <LeaderBoardComponent
                  image={friend.member?.image}
                  index={index + 1}
                  memberName={((friend.member?.firstName || "") + " " + (friend.member?.lastName || "")) || "-"}
                  hours={friend.sum}
                // hours={friend.donationHour + " hrs"}
                // handleClick={() => handleClick(friend)}
                // hours={friend.member.suburb + "," + friend.donationHour + "hrs"}
                />
              ))) : "No friends on leaderboard") :
            <>
              <Skeleton variant="circular" width={250} height={250} />
              <Skeleton variant="circular" width={250} height={250} />
              <Skeleton variant="circular" width={250} height={250} />
              <Skeleton variant="circular" width={250} height={250} />
            </>
          }
          {/* <LeaderBoardComponent
            image={
              "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            index="2nd"
            memberName="Sara Harrison"
            hours="City, 95 hours"
          />
          <LeaderBoardComponent
            image={
              "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            index="3rd"
            memberName="Sara Harrison"
            hours="City, 95 hours"
          />
          <LeaderBoardComponent
            image={
              "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            index="4th"
            memberName="Sara Harrison"
            hours="City, 95 hours"
          /> */}
        </GridDiv>
      </Container>
    </LeaderboardSection>
  );
};

export default FriendsLeaderBoard;
