import React from 'react'
import ForCharity from '../../Components/BecomeAPartner/ForCharity'
import ForWorplace from '../../Components/BecomeAPartner/ForWorkplace'
import BreadCrumb from '../../Components/BreadCrumb'

const BecomeAPartner = () => {
  return (
    <div>
        <BreadCrumb header="Become A Partner" subHeader1="Become A Partner" />
        <ForCharity />
        <ForWorplace />
    </div>
  )
}

export default BecomeAPartner