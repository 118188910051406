import { Box, Button, Container, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import FeatureImage from "./../../../images/samples/feature_member.jpg";
import DottedImage from "./../../../images/samples/Dotted-Purple.png";
import MemberBox from "./MemberBox";

const FeatureSection = styled("div")(({ theme }) => ({
  background: theme.palette.primary.purple,
}));

const FeatureDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  position:"relative",
  height: "650px",
  backgroundSize: "cover !important",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    fontSize: "50px",
    margin: "0",
    paddingBottom: "20px",
    fontWeight: "200",
    textShadow: "1px 1px 2px black, 0 0 1em #252526, 0 0 0.2em #101010",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      paddingBottom: "10px",
    },
    "& span": {
      color: "#333",
    },
  },
  "& p": {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const FeatureGrid = styled("div")(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "1fr 1fr 1fr 1fr",
  // gridGap: "100px",
  paddingTop:"30px",
  paddingBottom:"60px",
  display:"flex",
  gap:"100px",
  width:"100%",
  flexDirection:"row",
  flexWrap:"wrap",
  alignItems:"center",
  justifyContent:"center",
  [theme.breakpoints.down("md")]: {
    // gridTemplateColumns: "1fr",
    gap: "30px",
    paddingTop:"20px",
    paddingBottom:"30px",
  },
}));

const FeatureButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  backgroundColor: "#f1f1f1",
  outline: "none",
  border: "1px solid #333",
  padding: "5px 15px",
  fontSize: "14px",
  cursor: "pointer",
  transition: "0.3s",
  textTransform: "none",
  "&:hover": {
    background: "#6e6c6c",
    color:"#fff"
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    padding: "5px",
  },
    
}));

const DottedImageDiv = styled("div")(({ theme }) => ({
  zIndex: "1",
  position: "absolute",
  bottom:"-30px",
  width:"100%",
  "& img": {
    width: "100%",
    height: "90%",
    [theme.breakpoints.down("md")]: {
      height: "100px",
    },
  },
}));

const FeaturedMembers = ({ token, data }) => {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    getFeaturedMembers();
  }, []);

  const handleClick = (member) => {
    var newSlug = (member.firstName + " " + member.lastName)
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/members/detail/${newSlug}`, { state: member });
  };

  const getFeaturedMembers = () => {
    setLoading(true);
    httpClient.get("featured-members").then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setMembers(data.data);
      }
    });
  };

  const handleView = () => {
    navigate("/members");
  };

  return (
    <FeatureSection>
      <FeatureDiv
        style={{
          background: `url(${data?.images[1]?.image}) no-repeat center center fixed`,
        }}
      >
        <Container maxWidth="xl">
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
        >
          <h2>
            Featured <span>Members</span>
          </h2>
          {token && (
            <FeatureButton onClick={handleView}>View all</FeatureButton>
          )}
         </Box>
          <p>
            {data.section2}
          </p>
        </Container>
        <DottedImageDiv>
          <img src={DottedImage} alt="dotted_image" />
        </DottedImageDiv>
      </FeatureDiv>

      <Container maxWidth="xl">
        {!loading ? (
          <FeatureGrid>
            {members &&
              members.map((mem) => (
                <MemberBox
                  image={mem.image}
                  memberName={mem.firstName + " " + mem.lastName}
                  hours={mem.hours}
                  handleClick={() => handleClick(mem)}
                />
              ))}
          </FeatureGrid>
        ) : (
          <FeatureGrid>
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
            <Skeleton variant="circular" width={250} height={250} />
          </FeatureGrid>
        )}
      </Container>
    </FeatureSection>
  );
};

export default FeaturedMembers;
