import { Container, Grid, styled, Snackbar, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import DeleteDialog from "../../../Components/Dashboard/DeleteDialog";
import MyCausesBox from "../../../Components/Dashboard/MyCausesBox";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CausesDiv = styled("div")(({ theme }) => ({
  background: "#EAB38B",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));
const GridDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "100px",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridGap: "20px",
  },
}));

const MyCauses = () => {
  const navigate = useNavigate();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [causesList, setCausesList] = useState([]);
  const [favID, setFavID] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getMyCauses();
  }, []);

  const getMyCauses = () => {
    setLoading(true);
    httpClient.get("member-causes").then(({ data }) => {
      if (data.success) {     
        setCausesList(data.data);
        setLoading(false);
      }
    });
  };

  const handleClick = (cause) => {
    var newSlug = cause.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: cause });
  };

  const handleDelete = (id) => {
    setOpenDeleteDialog(true);
    setFavID(id);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false);
    }
    if (call.success === true) {
      httpClient.delete(`member-cause/${favID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          getMyCauses();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="My Causes"
      />
      <CausesDiv>
        <Container maxWidth="xl">
          <h2>My Causes</h2>
          <GridDiv>
            {!loading ? (
              causesList.length > 0
                ? (causesList.map((cause) => (
                  <MyCausesBox
                    key={cause.causeID}
                    image={cause.image}
                    cause={cause.name}
                    hours={cause.myHours}
                    // handleDelete={() => handleDelete(cause.favCauseID)}
                    handleClick={() => handleClick(cause)}
                  />
                ))) : "No donated causes found") :
              <>
                <Skeleton variant="circular" width={250} height={250} />
                <Skeleton variant="circular" width={250} height={250} />
                <Skeleton variant="circular" width={250} height={250} />
                <Skeleton variant="circular" width={250} height={250} />
              </>
            }
            {/* <MyCausesBox
              image="https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.unsplash.com/photo-1662730344749-0c6a154e58e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.unsplash.com/photo-1662730344749-0c6a154e58e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.unsplash.com/photo-1662730344749-0c6a154e58e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.unsplash.com/photo-1662730344749-0c6a154e58e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.unsplash.com/photo-1662730344749-0c6a154e58e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            />
            <MyCausesBox
              image="https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              cause="Food Crisis"
              hours="2 Hrs"
              handleDelete={handleDelete}
            /> */}
          </GridDiv>
        </Container>
      </CausesDiv>

      {openDeleteDialog && (
        <DeleteDialog heading={"cause"} callBack={callBack} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyCauses;
