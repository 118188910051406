import { Container, Grid, styled } from "@mui/material";
import React from "react";
import MemberBox from "../../Home/FeaturedMembers/MemberBox";

const TeamDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: "#C7CEAB",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
}));

const GridDiv = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridGap: "100px",
  [theme.breakpoints.down("md")]: {
    gridGap: "20px",
    gridTemplateColumns: "1fr",
  },
}));

const OurTeam = ({data}) => {

  return (
    <TeamDiv>
      <Container maxWidth="xl">
        <h2>Our Team</h2>
        <GridDiv>
          {data.length > 0 && data.map(team => (
          <MemberBox
            key={team.teamID}
            image={team.image}
            memberName={team.name}
            title={team.description}
          />
          ))}

          {/* <MemberBox
            image={
              "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
            }
            memberName="Hailey Krimson"
            title="Designer"
          />
          <MemberBox
            image={
              "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
            }
            memberName="Hailey Krimson"
            title="Designer"
          />
          <MemberBox
            image={
              "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
            }
            memberName="Hailey Krimson"
            title="Designer"
          /> */}
        </GridDiv>
      </Container>
    </TeamDiv>
  );
};

export default OurTeam;
