import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import React from "react";

const FeatureDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  cursor:"pointer",
  position:"relative",
  zIndex:"0",
  [theme.breakpoints.down("md")]: {
    padding:'0',
  },
  "& img": {
    objectFit: "cover",
    height:"280px",
    width: "280px",
    borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
      height:"250px",
      width:"250px"
    },
  },
  "& p":{
    margin:"0",
    fontWeight:"600"
  }
}));


const OrgBox = (props) => {
  const token = localStorage.getItem("token");
  const tokenData = JSON.parse(token);

  const AddBox = styled("div")(({theme}) => ({
      zIndex:"1",
      position: "absolute",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      height:"27px",
      width:"27px",
      right: "10px",
      top: "10px",
      borderRadius: "50%",
      padding: "6px",
      color: "pink",
      cursor:"pointer",
      backgroundColor: "#fafafa",
      [theme.breakpoints.down("md")]: {
        right:"10px",
        top:"10px"
      },
  }))
  const Line = styled("div")(({ theme }) => ({
    background: "#416C5B",
    width: "60%",
    margin: "10px auto",
    height: "2px",
    borderRadius: "5px",
  }));
  //console.log("prp>>>>", props);
  return (
    
    <FeatureDiv >
      {props.remove && (
      <AddBox>
        <Tooltip title="Remove from Favorite">
          <Favorite onClick={props.handleFavoriteRemove} />
        </Tooltip>
      </AddBox>
      )}

      {props.favourite && (
      // props.messageState !== "success" ?
      props.liked !== 1 ?
      tokenData &&
        <AddBox>
          <Tooltip title="Add To Favorite">
            <FavoriteBorder onClick={props.handleFavourite} />
          </Tooltip>
        </AddBox>
        :
        <AddBox>
          <Tooltip title="Remove from Favorite">
            <Favorite onClick={props.handleFavoriteRemove} />
          </Tooltip>
        </AddBox>
      )}
      <div onClick={props.handleClickCause}>
        <img src={props.image} />
        <p>
            {props.name.length > 16
              ? `${props.name.substring(0, 16)}...`
              : props.name}
          </p>
        
       {/* {props.hours ? ( */}
       <>
          <Line></Line>
          <span>
            <strong>{props.hours || "0"}</strong> {(props.hours == "0" || props.hours == "1" || props.hours == null) ? "hour" : "hours"}
          </span>
        </>
      {/* ) : (
        ""
      )} */}

      {/* {props.members ? ( */}
        <>
          <Line></Line>
          <span>
            <strong>{props.members || "0"}</strong> {(props.members == "0" || props.members == "1" || props.members == null) ? "Member" : "Members"}
          </span>
        </>

        <>
          <Line></Line>
          <span>
            <strong>{props.causes?.length || "0"}</strong> {(props.causes?.length == "0" || props.causes?.length == "1") ? "Campaign" : "Campaigns"}
          </span>
        </>
      {/* ) : (
        ""
      )} */}
      </div>

    </FeatureDiv>
  );
};

export default OrgBox;
