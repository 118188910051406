import { AccountCircleRounded, Add, ArrowForward, ArrowRight, Close, Mail, MailLock, MailOutline, Remove, VerifiedUser } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    styled,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as React from 'react';

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({

    color: "#fff",
    position: "relative",
    borderBottom: "2px solid #916e8e",
    fontFamily: "Epilogue",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
    "& span": {
        marginLeft: "15%",
        fontSize: "22px",
    },
    
    [theme.breakpoints.down('md')]: { // Mobile view adjustments
        "& span": {
            marginLeft: "35%", // Margin becomes 25% on small screens
        },
    },
}));


const StyledDialogContent = styled(DialogContent)(({ theme }) => ({

    color: "#fff",
    position: "relative",

    "& p": {
        marginLeft: "15%",
        paddingTop: "10px",
        fontSize: "22px",
        color: "black"
    },
}));

const MainDiv = styled("div")(({ theme }) => ({

    background: "#b891b3",
    borderRadius: "10px",

}));

const InviteDialog = (props) => {
    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        success: false,
    });

    const [formData, setFormData] = useState({
        email: [""], // Initialize with one email field
    });

    const [isYesDisabled, setIsYesDisabled] = useState(true);

    useEffect(() => {
        // Check if any email field is empty
        const allEmailsFilled = formData.email.every(email => email.trim() !== "");
        setIsYesDisabled(!allEmailsFilled);
        props.callBack(dialogDetails, formData);
    }, [dialogDetails, formData]);

    useEffect(() => {
        props.callBack(dialogDetails, formData);
    }, [dialogDetails, formData]);

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };

    const handleYes = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
            success: true,
        });
    };

    const handleAddEmail = () => {
        setFormData({
            ...formData,
            email: [...formData.email, ""],
        });
    };

    const handleRemoveEmail = (index) => {
        const newEmails = formData.email.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            email: newEmails.length > 0 ? newEmails : [""],
        });
    };

    const handleEmailChange = (index, value) => {
        const newEmails = formData.email.map((email, i) => i === index ? value : email);
        setFormData({
            ...formData,
            email: newEmails,
        });
    };

    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box p={1}>
                    <MainDiv>
                        <StyledHeaderTitle id="alert-dialog-title">
                            <span>Invite Friends</span>
                            {/* <AccountCircleRounded sx={{position:"absolute", top:"20%", left:"3%", color:"#a483a1", fontSize:"100px"}}/> */}
                            <Box sx={{ display: 'inline-block' }}>
                                <AccountCircleRounded
                                    sx={{
                                        position: "absolute",
                                        top: "20%",
                                        left: "5%",
                                        color: "#a483a1",
                                        fontSize: "100px"
                                    }}
                                />
                                <Typography
                                    sx={{
                                        position: "absolute",
                                        top: "15%",
                                        left: "13%",
                                        color: "red",
                                        fontSize: "38px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    +
                                </Typography>
                            </Box>

                        </StyledHeaderTitle>
                        <StyledDialogContent>
                            <p>List out the emails you wish to send invite requests.</p>
                            {formData.email.map((email, index) => (
                                <Box key={index} display="flex" alignItems="center" mt={2}>
                                    <TextField
                                        placeholder={`  Friends Email Address ${index + 1}`}
                                        variant="standard"
                                        value={email}
                                        sx={{
                                            width: "90%", height: "40px", fontSize: "12px", backgroundColor: "white",
                                            borderRadius: "30px", fontFamily: "Epilogue"
                                        }}
                                        onChange={(e) => handleEmailChange(index, e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutline sx={{ marginLeft: "10px" }} />
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                        }}
                                    />
                                    {index !== 0 && (
                                        <IconButton onClick={() => handleRemoveEmail(index)}>
                                            <Remove />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                            <Button
                                onClick={handleAddEmail}
                                color="error"
                                variant="contained"
                                startIcon={<Add sx={{ color: "black" }} />}
                                style={{
                                    marginTop: '20px',
                                    borderRadius: '60px',
                                    border: '1px solid black',
                                    textTransform: 'none',
                                    fontFamily: "Epilogue",
                                }}
                                disabled={formData.email.length >= 15}
                            >
                                Add Email
                            </Button>



                        </StyledDialogContent>
                        <DialogActions styled={{ margin: "5px 10px" }}>
                            <Button onClick={handleClose}
                                variant="contained"
                                startIcon={<Close sx={{ color: "black" }} />}
                                style={{
                                    marginTop: '20px',
                                    borderRadius: '60px',
                                    border: '1px solid black',
                                    textTransform: 'none',
                                    color: '#fff',
                                    backgroundColor: 'grey',
                                    fontFamily: "Epilogue",
                                }}>
                                Cancel
                            </Button>
                          
                                <Button
                                    onClick={handleYes}
                                    variant="contained"
                                    startIcon={<ArrowForward sx={{ color: "red" }} />}
                                    style={{
                                        marginTop: '20px',
                                        borderRadius: '60px',
                                        border: '1px solid black',
                                        textTransform: 'none',
                                        color: 'red',
                                        backgroundColor: '#e5e5e5',
                                        opacity:isYesDisabled && "0.5" 
                                    }}
                                    disabled={isYesDisabled}
                                >
                                    Invite Now
                                </Button>
                        
                        </DialogActions>
                    </MainDiv>
                </Box>
            </Dialog>
        </div>
    );
};

export default InviteDialog;
