import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { httpClient } from '../../../appUtility/Api'
import BreadCrumb from '../../../Components/BreadCrumb'
import AllCauses from '../../../Components/Causes/AllCauses'
import CauseCategory from '../../../Components/Causes/CauseCategory'
import CausesTop from '../../../Components/Causes/CausesTop'
import TrendingCauses from '../../../Components/CausesNew/TrendingCauses'

const Featured = () => {
  const location = useLocation();
  const [allData, setAllData] = useState("");
  const [categories, setCategories] = useState("");
  // console.log('location', location);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
    // getFeaturedCause();
    // getFeaturedCategory();
  },[])

  // const getFeaturedCause = () => {
  //   httpClient.get(`featured-charity-cause`).then(({data}) => {
  //     if(data.success){
  //       setAllData(data.data)
  //     }
  //   })
  // }

  // const getFeaturedCategory = () => {
  //   httpClient.get(`featured-cause-category`).then(({data}) => {
  //     if(data.success){
  //       setCategories(data.data)
  //     }
  //   })
  // }


  return (
    <div>
        <BreadCrumb header={"Featured"} subHeader1="Causes" Link1="/causes" subHeader2={"Featured"} />
        {/* <CausesTop header={allData && allData.categoryName} description={allData && allData.categoryDescription} /> */}
        {/* <CausesTop header={"Featured"} description={
          `<p>
          It doesn’t matter, who you are or what you do for a living, your
          time is valuable.
          <br />
          <br />
          Help make a difference in the world by donating an hour of it.
          <br />
          <br />
          Give the final hour of your working day, week or month to your
          preferred cause(s).
        </p>`
        } /> */}
        
        <TrendingCauses />

        {/* <CauseCategory categories={categories && categories} /> */}
        {/* <AllCauses causeList={allData && allData} /> */}
    </div>
  )
}

export default Featured