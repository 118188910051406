import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    styled,
    Slider,
    Tooltip,
    useTheme,
    useMediaQuery,
    CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { httpClient } from "../../appUtility/Api";
import moment, { now } from "moment/moment";
import MuiAlert from "@mui/material/Alert";

import { useParams } from "react-router-dom";
import CauseDialog from "../Causes/CauseDialog";


const MainTitle = styled(DialogTitle)(({ theme }) => ({

    padding: "20px 15%",
    [theme.breakpoints.down("md")]: {
        padding: "10px",
    },
}));



const CauseDiv = styled("div")(({ theme }) => ({
    padding: "0px 15%",
    borderBottom: "1px dashed #999",
    [theme.breakpoints.down("md")]: {
        padding: "10px",
    },
}));

const ButtonsDiv = styled("div")(({ theme }) => ({
    display: "inline-flex",
    flexDirection: "column",
}));

const ButtonTime = styled("button")(({ theme }) => ({
    fontSize: "22px",
    outline: "none",
    border: "1px solid transparent",
    borderRadius: "25px",
    padding: "10px 20px",
    margin: "5px",
    cursor: "pointer",
}));

const LogoDiv = styled("div")(({ theme }) => ({
    transform: "rotate(90deg)",
    display: "inline-block",
    position: "relative",
    top: "83px",
    "& span": {
        background: theme.palette.primary.red,
        padding: "15px",
        display: "inline-block",
        borderRadius: "50%",
        margin: "0px 17px",
        [theme.breakpoints.down("md")]: {
            margin: "0 10px",
            padding: "10px",
        },
    },
}));

const CauseName = styled("div")(({ theme }) => ({
    padding: "16.5px 14px 20px 14px",
    marginTop: "10px",
    border: "0.02px solid rgba(0, 0, 0, 0)",
    borderRadius: "4px",
    transition: "all 0.3s ease",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    color: "#000000",
    textAlign: "center",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    "&:hover": {
        transform: "translateY(-2px)",
    },
}));

const PrettoSlider = styled(Slider)({
    margin: "40px 10px 40px 10px",
    color: 'red',
    height: 5,
    width: "100%",
    '& .MuiSlider-track': {
        border: 'none',
    }, '& .MuiSlider-mark': {

        background: 'unset',

    }, '& .MuiSlider-markLabel': {

        marginTop: "50px",
        fontSize: 16,
        fontFamily: "Epilogue",
        color: '#212121',



    },
    '& .MuiSlider-thumb': {
        height: 30,
        width: 30,
        top: 50,
        backgroundColor: 'red',

        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            content: "''",
            position: 'absolute',
            top: -70,
            left: -2,
            right: 0,
            bottom: 0,
            backgroundColor: 'red',
            border: '2px solid currentColor',
            transform: 'rotateY(180deg)',
        },
    },

    '& .MuiSlider-valueLabel': {
        top: 100,
        fontSize: 16,
        fontFamily: "Epilogue",
        color: '#212121',
        background: 'unset',
        //overflow: "hidden",
        padding: 0,
        width: 30,
        height: 30,
        borderRadius: '50% 50% 50% 50%',
        backgroundColor: 'none',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


const GiveText = styled("div")(({ theme }) => ({
    borderBottom: "1px solid #000",
    textAlign: "center",
    "& h2": {
        margin: "0",
        fontSize: "35px",
        paddingBottom: "20px",
    }, "& strong": {
        fontSize: "35px",
        color: theme.palette.primary.red,
        // margin:"0",
    },
}));

const HourText = styled("div")(({ theme }) => ({
    paddingTop: "20px",
    textAlign: "center",
    "& h3": {
        margin: "0",

    }, "& strong": {
        //fontSize: "35px",
        color: theme.palette.primary.red,
        // margin:"0",
    },
}));

const FormDiv = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
    // padding: "30px 0",
    "& h2": {
        fontSize: "22px",
        // margin:"0",
    },
    "& .MuiTextField-root ": {
        padding: "10px",
        width: "90px",
    },
    "& .MuiSelect-select ": {
        padding: "10px",
        width: "100px",
    },
    "& input": {
        padding: "10px",
    },
}));

const member = localStorage.getItem("login");
const memberData = JSON.parse(member);

const tok = localStorage.getItem("guestToken");
const guestToken = JSON.parse(tok);

//console.log("mem", memberData);
//console.log("tok", guestToken);

const FooterGive = () => {


    const RedButton = styled(Button)(({ theme }) => ({
        background: ((validation1)) ? "#ccc" : theme.palette.primary.red,
        color: "#fff",
        padding: "5px 25px",
        borderRadius: "25px",
        outline: "none",
        border: "1px solid transparent",
        margin: "0 10px",
        cursor: "pointer",
        transition: "0.3s",
        textTransform: "inherit",
        "&:hover": {
            background: "transparent",
            border: `1px solid ${theme.palette.primary.red}`,
            color: theme.palette.primary.red,
        },
    }));


    const [causesList, setCausesList] = useState([]);
    const [dialog, setDialog] = useState({
        open: true,
    });
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [id, setId] = useState("");
    const [validation, setValidation] = useState(true);
    const [validation1, setValidation1] = useState(true);
    const [allData, setAllData] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [incomeDetails, setIncomeDetails] = useState([]);
    const [openCauseDialog, setOpenCauseDialog] = useState(false);
    const [footerGive, setFooterGive] = useState(false);
    const [incomeID, setIncomeID] = useState("");
    const [value, setValue] = useState(50);
    const [label, setLabel] = useState("38");
    const [loading, setLoading] = useState(false);

    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let date = moment().format("YYYY-MM-DD");
    let day = moment(date).format('dddd');

    const [giveHours, setGiveHours] = useState({
        hours: "1",
        frequency: "daily",
        startDate: date,
        donationDay: day,
        memberID: memberData !== null ? memberData?.member?.memberID : "",
        orgID: allData && allData.organizationID?.orgID,
        causeID: allData && allData.causeID,
        causeName: "",
        ruleID: ""
    })


    const handleClick = (data) => {
        setGiveHours({
            ...giveHours,
            frequency: data
        });
    };



    const handleNext = () => {
        setOpenCauseDialog(true);
        setStep(2);
        setFooterGive(true);
    };


    //props.causeCallBack(dialog);

    useEffect(() => {
        getCauses();
    }, []);

    useEffect(() => {

        if ((id !== undefined && id !== "")) {

            getCauseSingle(id);
        }
    }, [id]);

    useEffect(() => {
        var newSlug = giveHours.causeName?.replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
            .toLowerCase();

        setId(newSlug)
    }, [giveHours.causeName]);

    const getCauses = () => {
        setLoading(true);
        httpClient
            .get("all-charity-cause")
            .then(({ data }) => {
                if (data.success) {
                  
                    setCausesList(data.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();

                }
            });
    };

    const getCauseSingle = (id) => {
        httpClient.get(`charity-cause/${id}`).then(({ data }) => {
            if (data.success) {
                setAllData(data.data)
            }
        })
    }

    useMemo(() => {
        setGiveHours({
            ...giveHours,
            orgID: allData && allData.organizationID?.orgID,
            causeID: allData && allData.causeID,
            causeName: allData && allData.name,
            category: allData && allData.categoryID?.categoryName,
            hours: "1",
            frequency: "daily",
            startDate: date,
            donationDay: day,
            ruleID: allData && allData.myDonations?.ruleID
        })
        if (allData && allData.myDonations?.donationHour) {
            setUpdated(true);
        }
    }, [allData])

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "startDate") {
            let day = moment(value).format('dddd');
            setGiveHours({
                ...giveHours,
                startDate: value,
                donationDay: day
            });
        } else {
            setGiveHours({
                ...giveHours,
                [name]: value
            });
        }
    };



    useEffect(() => {
        if (giveHours.frequency && giveHours.causeName) {
            setValidation1(false)
        } else {
            setValidation1(true)
        }
    }, [giveHours])

    const causeCallBack = (call) => {
        if (call.open === false) {
            setOpenCauseDialog(false);
            setFooterGive(false);
        }
    };


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <MainTitle>
                <h2>Give Now</h2>
            </MainTitle>
            <CauseDiv>
                <p>Your time is valuable, help make a difference in the world by donating an hour of it.</p>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>

                        <div style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Select Your Cause</InputLabel>
                            <FormControl fullWidth>
                                <>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={giveHours.causeName}
                                        name="causeName"
                                        onChange={handleChange}
                                    >
                                        {causesList.map((cause) => (
                                            <MenuItem key={cause.name} value={cause.name} disabled={cause.organizationID.stripeSetup === 0}>
                                                {cause.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '-30px',
                                                marginTop: '-12px',
                                                zIndex: 1
                                            }}
                                        />
                                    )}

                                </>
                            </FormControl>
                        </div>

                    </Grid>
                    <Grid item xs={6} md={3}>
                        <LogoDiv>
                            <span></span>
                            <span></span>
                        </LogoDiv>
                    </Grid>
                    <Grid item xs={6} md={3} display="flex" alignItems="center">
                        <ButtonsDiv>
                            <ButtonTime
                                onClick={() => handleClick("daily")}
                                style={{
                                    background: giveHours.frequency === "daily" ? "#D9D9D9" : "transparent",
                                }}
                            >
                                Daily
                            </ButtonTime>
                            <ButtonTime
                                onClick={() => handleClick("weekly")}
                                style={{
                                    background: giveHours.frequency === "weekly" ? "#D9D9D9" : "transparent",
                                }}
                            >
                                Weekly
                            </ButtonTime>
                            <ButtonTime
                                onClick={() => handleClick("monthly")}
                                style={{
                                    background: giveHours.frequency === "monthly" ? "#D9D9D9" : "transparent",
                                }}
                            >
                                Monthly
                            </ButtonTime>
                            <ButtonTime
                                onClick={() => handleClick("yearly")}
                                style={{
                                    background: giveHours.frequency === "yearly" ? "#D9D9D9" : "transparent",
                                }}
                            >
                                Yearly
                            </ButtonTime>
                        </ButtonsDiv>
                    </Grid>
                </Grid>
            </CauseDiv>
            <Box textAlign="center" pb={'10px'} pt={'10px'}>
                <RedButton disabled={validation1} onClick={handleNext}>
                    Give
                </RedButton>
            </Box>
            {openCauseDialog && <CauseDialog id={id} step={step} giveHours={giveHours} causeCallBack={causeCallBack} footerGive={footerGive} />}
        </div>
    );
}

export default FooterGive;
