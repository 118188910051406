
import React, { useEffect } from "react";
import HeaderTop from "../../../Components/Header/HeaderTop";
import MainProfileNew from "../../../Components/Dashboard/MainProfileNew";
import { FeatureCauses, HowItWorks } from "../../../Components/Home";
import FooterGive from "../../../Components/FooterGive";


const InfographicDashboard = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    //console.log('in dashboard')
  },[])

  return (
    <div>
     <HeaderTop infographic={true}/>      
      <MainProfileNew/>
      <HowItWorks />
      <FeatureCauses />
      <FooterGive />  
    </div>
  );
};

export default InfographicDashboard;
