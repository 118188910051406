import { ArrowBack, Cancel, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import PaymentDialog from "../../PaymentDialog";
import { useNavigate } from "react-router-dom";

const MainDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "10px",
    borderRadius: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
  },
}));

const MainTitle = styled(DialogTitle)(({ theme }) => ({
  padding: "10px 5px",
  borderBottom: "1px solid #999",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const RedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.red,
  color: "#fff",
  padding: "10px 40px",
  outline: "none",
  borderRadius: "25px",
  border: "1px solid transparent",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.red} !important`,
    background: "transparent !important",
    color: `${theme.palette.primary.red} !important`,
  },
  "& span": {
    color: "#fff",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: "#fff",
  color: "#333",
  padding: "10px 40px",
  outline: "none",
  borderRadius: "25px",
  border: "1px solid #333",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.red} !important`,
    background: "transparent !important",
    color: `${theme.palette.primary.red} !important`,
  },
  "& span": {
    color: "#fff",
  },
}));

const ButtonsDiv = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "row",
}));

const ButtonTime = styled("button")(({ theme }) => ({
  fontSize: "22px",
  outline: "none",
  border: "1px solid transparent",
  borderRadius: "25px",
  padding: "10px 20px",
  margin: "5px",
  cursor: "pointer",
}));

const CancelHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CancelSubscription = (props) => {
  const navigate = useNavigate();

  const member = localStorage.getItem("login");
  const memberData = JSON.parse(member);

  const [time, setTime] = useState("");
  const [giveHours, setGiveHours] = useState({
    hours: "",
    frequency: "",
    startDate: null,
    donationDay: "",
    memberID: memberData && memberData.member.memberID,
    orgID: props.allData && props.allData.organizationID.orgID,
    causeID: props.allData && props.allData.causeID,
    causeName: props.allData && props.allData.name,
    ruleID: "",
    newCauseID: "",
    categoryID: props.allData && props.allData.categoryID,
  });
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [incomeDetails, setIncomeDetails] = useState("");
  const [hours, setHours] = useState("");
  const [another, setAnother] = useState("");
  const [causeList, setCauseList] = useState([]);
  const [currCauseList, setCurrCauseList] = useState([]);
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });
  const [switchLoading, setSwitchLoading] = useState(false);

  useEffect(() => {
    props.callBack(dialogDetails);
  }, [dialogDetails]);

  useEffect(() => {
    // getAllCauses();
    // getCurrCauses();
    getIncomeDetails();
    if (props.allData) {
      // setTime(props.allData.myDonations.donationFrequency);
      // setHours(props.allData.myDonations.donationHour);
      setGiveHours({
        ...giveHours,
        orgID: props.allData && props.allData.organizationID.orgID,
        causeID: props.allData && props.allData.causeID,
        causeName: props.allData && props.allData.name,
        hours: props.allData && props.allData.myDonations.donationHour,
        frequency: props.allData && props.allData.myDonations.donationFrequency,
        startDate: props.allData && props.allData.myDonations.donationStartDate,
        donationDay: props.allData && props.allData.myDonations.donationsDay,
        ruleID: props.allData && props.allData.myDonations.ruleID,
        categoryID: props.allData && props.allData.categoryID,
      });
    }
  }, []);

  useEffect(() => {
    if (currCauseList.length > 0 && causeList.length > 0) {
      var myArray = causeList.filter(function (cause) {
        // return !currCauseList.includes(cause);
        let lists = [];
        currCauseList.map((favs) => {
          lists.push(favs.causeID);
        });
        return !lists.includes(cause.causeID);
      });
      // console.log('filterred', myArray);
      setCauseList(myArray);
    }
  }, [another === true]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleCancel = () => {
    // setDialogDetails({
    //   ...dialogDetails,
    //   success: true,
    //   open: false,
    // });
    setSwitchLoading(true);
    setTimeout(() => {
      navigate("/causes/switchSubscription", {
        state: { giveHours: giveHours, incomeDetails: incomeDetails },
      });
    }, 4000);
  };

  const handleSwitch = () => {
    setOpenPaymentDialog(true);
  };

  const callBack = (call) => {
    if (call.open === false) {
      setOpenPaymentDialog(false);
    }
  };
  const handleNo = () => {
    setAnother(false);
  };

  const handleYes = () => {
    setAnother(true);
  };

  const handleClick = (data) => {
    // setTime(data);
    setGiveHours({
      ...giveHours,
      frequency: data,
    });
  };

  const handleChangeHours = (e) => {
    const { value } = e.target;
    // setHours(value);
    setGiveHours({
      ...giveHours,
      hours: value,
    });
  };

  const getAllCauses = () => {
    httpClient.get("all-charity-causes").then(({ data }) => {
      if (data.success) {
        setCauseList(data.data);
      }
    });
  };

  const getIncomeDetails = () => {
    httpClient.get("member-income/list").then(({ data }) => {
      if (data.success) {
        setIncomeDetails(data.data);
      }
    });
  };

  const getCurrCauses = () => {
    httpClient.get("member-causes").then(({ data }) => {
      if (data.success) {
        setCurrCauseList(data.data);
      }
    });
  };

  const handleChangeCause = (e) => {
    const { name, value } = e.target;
    causeList.map((cause) => {
      if (cause.causeID === value) {
        giveHours.causeName = cause.name;
      }
    });
    setGiveHours({
      ...giveHours,
      newCauseID: value,
    });
  };
  // console.log("props", props);
  // console.log('give hoyrs', giveHours);

  return (
    <MainDialog
      open={dialogDetails.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <MainTitle id="alert-dialog-title">
        Cancel Subscription?
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </MainTitle>
      <DialogContent>
        {switchLoading ? (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} style={{ height:"100px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid item sx={12}>
                <h4>Do you want to cancel the subscription?</h4>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      {switchLoading ? "" :
      <DialogActions>
        <RedButton onClick={handleClose} variant="outlined">
          No
        </RedButton>
        <CancelButton onClick={handleCancel} autoFocus variant="outlined">
          Yes
        </CancelButton>
      </DialogActions>
      }
    </MainDialog>
  );
};

export default CancelSubscription;
