import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
import PaymentDialog from "../../PaymentDialog";
import IncomeDialog from "../../IncomeDialog";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const GiveDiv = styled("div")(({ theme }) => ({
  //   padding: "50px 0",
  background: "#F0EFEF",
  padding: "50px 22%",
  textAlign:"center",
  "& h3":{
    fontSize:"22px",
    fontWeight:"500"
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const GiveText = styled("div")(({ theme }) => ({
  borderBottom: "1px solid #000",
  textAlign: "center",
  "& h2": {
    margin: "0",
    fontSize: "35px",
    paddingBottom: "20px",
  },
}));

const FormDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent:"center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  // padding: "30px 0",
  "& h2": {
    fontSize: "22px",
    // margin:"0",
  },
  "& .MuiTextField-root ": {
    padding: "10px",
    width: "90px",
  },
  "& .MuiSelect-select ": {
    padding: "10px",
    width: "100px",
  },
  "& input": {
    padding: "10px",
  },
}));



const member = localStorage.getItem("login");
const memberData =  JSON.parse(member);

const GiveHours = ({ allData }) => {

  const RedButton = styled(Button)(({ theme }) => ({
    background: validation ? "#ccc" : theme.palette.primary.red,
    color: "#fff",
    padding: "5px 25px",
    borderRadius: "25px",
    outline: "none",
    border: "1px solid transparent",
    margin: "0 10px",
    cursor: "pointer",
    transition: "0.3s",
    textTransform:"inherit",
    "&:hover": {
      background: "transparent",
      border: `1px solid ${theme.palette.primary.red}`,
      color: theme.palette.primary.red,
    },
  }));

  const [giveHours, setGiveHours] = useState({
    hours: "",
    frequency: "",
    startDate: null,
    donationDay: "",
    memberID: memberData && memberData.member.memberID,
    orgID: allData && allData.organizationID.orgID,
    causeID: allData && allData.causeID,
    causeName: allData && allData.name,
    ruleID:""
  });
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [incomeDetails, setIncomeDetails] = useState([])
  const [updated, setUpdated] = useState(false);
  const[validation, setValidation] = useState(true);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

   //console.log('all >>>>', allData)

  useMemo(() => {
    setGiveHours({
      ...giveHours,
      orgID: allData && allData.organizationID.orgID,
      causeID: allData && allData.causeID,
      causeName: allData && allData.name,
      hours: allData && allData.myDonations.donationHour,
      frequency: allData && allData.myDonations.donationFrequency,
      startDate: allData && allData.myDonations.donationStartDate,
      donationDay: allData && allData.myDonations.donationsDay,
      ruleID: allData && allData.myDonations.ruleID
    })
    if(allData && allData.myDonations.donationHour){
      setUpdated(true);
    }
  },[allData])

  useEffect(() => {
    if(giveHours.hours && giveHours.frequency && giveHours.startDate){
      setValidation(false)
    }else{
      setValidation(true)
    }
  },[giveHours])

  //console.log('give', giveHours);
  
  useEffect(() => {
    getIncomeDetails()
  },[])

  const getIncomeDetails = () => {
    httpClient.get('member-income/list').then(({data}) => {
        if(data.success){
            setIncomeDetails(data.data);
        }
    })
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "startDate"){
      let day = moment(value).format('dddd');
      setGiveHours({
        ...giveHours,
        startDate: value,
        donationDay:day 
      });  
    }else{
    setGiveHours({
      ...giveHours,
      [name]: value,
    });
    }
  };

  const handleSubmit = () => {
    setOpenPaymentDialog(true);
    // console.log('mem', memberData);
    // httpClient.post('donation-rule/create',{
    //   memberID: memberData.member.memberID,
    //   orgID: allData.organizationID.orgID,
    //   causeID: allData.causeID,
    //   donationFrequency: giveHours.frequency,
    //   donationHour: giveHours.hours,
    //   donationStartDate: giveHours.startDate,
    //   donationsDay: giveHours.donationDay
    // }).then(({data}) => {
    //   if(data.success){
    //     setOpen(true);
    //     setMessageState("success");
    //     setMessage(data.message);
    //   }else{
    //     setOpen(true);
    //     setMessageState("error");
    //     setMessage(data.message);
    //   }
    // })
  }


  const callBack = (call) => {
    if(call.open === false){
      setOpenPaymentDialog(false)
     
    }
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <GiveDiv>
      <Container maxWidth="xl">
        <GiveText>
          <h2> Give Your Hours </h2>
        </GiveText>
        {updated ?
        <h3>You are currently donating</h3>
        :
        <h3>How many Hours would you like to give?</h3>
        }
        <FormDiv>
          <TextField
            variant="outlined"
            placeholder="# Hours"
            name="hours"
            value={giveHours.hours}
            onChange={handleChange}
          />
          <span style={{ marginRight:"10px" }}> hrs </span>
          <FormControl placeholder="Per week" sx={{ width: "130px" }}>
            {/* <InputLabel id="demo-simple-select-label">Per Week</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={giveHours.frequency}
              // label="State"
              placeholder="Per week"
              name="frequency"
              onChange={handleChange}
            >
              <MenuItem value="daily">Per day</MenuItem>
              <MenuItem value="weekly">Per week</MenuItem>
              <MenuItem value="monthly">Per month</MenuItem>
              <MenuItem value="yearly">Per year</MenuItem>
            </Select>
          </FormControl>
          <span style={{ marginLeft:"15px" }}> from </span>
          <TextField
            value={giveHours.startDate}
            name="startDate"
            type="date"
            onChange={handleChange}
            sx={{ marginLeft: "7px", width:"150px !important" }}
            InputLabelProps={{ shrink: true }}
          />
        <RedButton disabled={validation} onClick={handleSubmit}>
          {updated ? "Update" : "Submit"}
        </RedButton>

        </FormDiv>
      </Container>

      {openPaymentDialog && (
      incomeDetails.length > 0 ?
      <PaymentDialog giveHours={giveHours} callBack={callBack} incomeDetails={incomeDetails[0]} />
      :
      <IncomeDialog callBack={callBack} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </GiveDiv>
  );
};

export default GiveHours;
