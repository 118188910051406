import { Edit, Favorite, FavoriteBorder, InsertCommentOutlined } from '@mui/icons-material';
import { Box, Grid, Card, styled, Container, Tooltip, Badge, Collapse, List, Avatar, TextField, Button, ListItem, ListItemAvatar, ListItemText, IconButton } from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment/moment';
//import 'emoji-mart/css/emoji-mart.css';
import EmojiPicker from 'emoji-picker-react';

//import { Picker } from 'emoji-mart';
import Popover from '@mui/material/Popover';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';


const Section = styled("div")(({ theme }) => ({
    background: "#F0EFEF",
    padding: "50px",
    [theme.breakpoints.down("md")]: {
        padding: "20px 0",
    },
}));


const GiveDiv = styled("div")(({ theme }) => ({
    //   padding: "50px 0",
    borderBottom: "1px solid transparent",
    display: "grid",
    gridTemplateColumns: "4fr 1fr",
    gridGap: "10px",
    alignItems: "center",

    "& h3": {
        fontSize: "22px",
        fontWeight: "500"
    },
    [theme.breakpoints.down("md")]: {
        padding: "20px ",
        //marginBottom: "20px",
        gridTemplateColumns: "1fr",
    },
}));

const GiveText = styled("div")(({ theme }) => ({
    //width:"85%",

    textAlign: "center",
    "& h2": {
        margin: "0",
        fontSize: "24px",
        paddingBottom: "20px",
    }, "& strong": {
        fontSize: "28px",
        color: theme.palette.primary.red,
        // margin:"0",
    },
}));

const Comment = styled("div")(({ theme }) => ({

    fontSize: "22px",
    fontWeight: "500",
    marginRight: "20px",

    [theme.breakpoints.down("md")]: {
        padding: "20px ",
        //marginBottom: "20px",
        flexDirection: "column",
        textAlign: "center",

    },
}));


const Comment1 = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 0.25fr 2fr 2fr",
    gridGap: "10px",
    alignItems: "center",

    "& p": {
        fontSize: "22px",
        fontWeight: "500"
    },
    [theme.breakpoints.down("md")]: {
        padding: "20px ",
        //marginBottom: "20px",
        gridTemplateColumns: "1fr",
    },
}));

const Comment2 = styled("div")(({ theme }) => ({
    minWidth: "1000px",
    display: "grid",
    gridTemplateColumns: "auto 3fr",
    gridGap: "10px",
    alignItems: 'start',

    "& p": {
        fontSize: "22px",
        fontWeight: "500"
    },
    [theme.breakpoints.down("md")]: {
        minWidth: "100%",
        padding: "20px ",
        //marginBottom: "20px",
        gridTemplateColumns: "1fr",
    },
}));





const Favourite = styled(Box)(({ theme }) => ({
    //width: "12%",
    margin: "0 0px",

    textAlign: "left",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        //textAlign: "left",
        flexDirection: "column",
        textAlign: "center",
    },
}));

const Line = styled("div")(({ theme }) => ({
    background: theme.palette.primary.red,
    width: "80%",
    margin: "10px auto",
    height: "2px",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));


const member = localStorage.getItem("login");
const memberData = JSON.parse(member);

const LikeComment = (props) => {
    //console.log("loading", props)
    const [showDrawer, setShowDrawer] = useState(false);
    const [comment, setComment] = useState('');
    //const [editComment, setEditComment] = useState('');
    const [showEditStatus, setShowEditStatus] = useState({});
    const [memberList, setMemberList] = useState([]);
    const [editComments, setEditComments] = useState('');

    const [emojiPickerAnchor, setEmojiPickerAnchor] = useState(null);
    const [emojiPickerAnchor1, setEmojiPickerAnchor1] = useState(null);
    
    const handleEmojiPickerOpen = (event) => {
        setEmojiPickerAnchor(event.currentTarget);
    };
    const handleEmojiPickerOpen1 = (event) => {
        setEmojiPickerAnchor1(event.currentTarget);
    };

    const handleEmojiPickerClose = () => {
        setEmojiPickerAnchor(null);
    };
    const handleEmojiPickerClose1 = () => {
        setEmojiPickerAnchor1(null);
    };

    const handleEmojiSelect = (emoji) => {
        //console.log("emoji", emoji);
        setEditComments((prev) => prev + emoji.emoji);
        handleEmojiPickerClose();
    };

    const handleEmojiSelect1 = (emoji) => {
        //console.log("emoji", emoji);
        setComment((prev) => prev + emoji.emoji);
        handleEmojiPickerClose();
    };



    const handleDraw = () => {
        setShowDrawer(!showDrawer);
    };

    // const handleEditCommentChange = (event, commentId) => {
    //     const updatedEditComments = {
    //       ...editComments,
    //       [commentId]: event.target.value,
    //     };
    //     setEditComments(updatedEditComments);
    //   };


    const handleSubmit = (event) => {
        event.preventDefault();
      
        props.handleAddComment(comment);
        //console.log("ererr111111111", comment);
        setComment(''); 

    };
    const handleSubmit1 = (event, commentId) => {
        event.preventDefault();
    
        props.handleEditComment(editComments, commentId);
        // console.log("ererr1222222222222", editComment, commentId);
        setEditComments(''); 
        setShowEditStatus(false);

    };

    //console.log("editcomments", editComments);
    //console.log("comments", props.comments)
    return (
        <Section>
            <Container maxWidth="xl">
                <Grid item md={12} xs={12}>
                    <GiveDiv>
                        <GiveText>
                            <h2> Do you wish to {props.likes !== 1 ? "like" : "unlike"} the news on <strong>{props.title}</strong> ?</h2>

                        </GiveText>
                        <Favourite>
                            {props.likes !== 1 ? (
                                <Tooltip title="Like">
                                    <FavoriteBorder
                                        sx={{ fontSize: "34px", paddingBottom: "5px", color: "red", cursor: "pointer" }}
                                        onClick={props.handleLike}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Dislike">
                                    {/* <Badge color="info" badgeContent={props.likes}> */}
                                    <Favorite
                                        sx={{ fontSize: "34px", paddingBottom: "5px", color: "#EA3323", cursor: "pointer" }}
                                        onClick={props.handleDisLike}
                                    />
                                    {/* </Badge> */}
                                </Tooltip>
                            )}
                        </Favourite>


                    </GiveDiv>
                </Grid>
                {/* {updated ?
          <h3>You are currently donating</h3>
          : */}



                <Box mb={3} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // Optional: Set a height for the container
                }}
                >

                    <Comment>Comment your valuable feedbacks here..</Comment>
                    <Favourite>
                        <Badge badgeContent={props.comments?.length} color="info">
                            <InsertCommentOutlined
                                sx={{ color: "purple", cursor: "pointer" }}
                                onClick={handleDraw}
                            />
                        </Badge>
                    </Favourite>

                </Box>


                {/* } */}

                <Collapse in={showDrawer}>


                    <Box mb={3} textAlign="center">
                        <h3>Comments</h3>
                    </Box>


                    <Box mb={3} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // Optional: Set a height for the container
                    }}
                    >

                        <List sx={{ width: '80%', minWidth: 360, bgcolor: 'transparent' }}>
                            {props.comments?.length > 0 ? (
                                props.comments.map((comment, index) => {

                                    const commentId = comment.newsCommentID; // Assuming you have a unique identifier for each comment
                                    const isShowEdit = showEditStatus[commentId] || false;
                                    // Get the sh

                                    return (
                                        <ListItem key={index} sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'auto 1fr auto',
                                            alignItems: 'start',
                                            borderBottom: '0.25px solid #888',
                                            '@media (max-width: 768px)': {

                                                gridTemplateColumns: '1fr',
                                                //alignItems: 'center',
                                                gap: '1rem',
                                                textAlign: 'center'
                                            }
                                        }}>
                                            {!isShowEdit ? (
                                                <>
                                                    <ListItemAvatar sx={{
                                                        marginTop: "10px",
                                                        '@media (max-width: 768px)': {

                                                            marginLeft: "42%",
                                                        }
                                                    }} >

                                                        <Avatar src={comment.commentedBy.image} />

                                                    </ListItemAvatar>


                                                    <ListItemText
                                                        primaryTypographyProps={{ fontFamily: 'Epilogue, sans-serif' }}
                                                        secondaryTypographyProps={{ fontFamily: 'Epilogue, sans-serif' }}
                                                        primary={comment.comment}
                                                        secondary={moment(comment.commentedDate).format('MMMM Do YYYY, h:mm:ss a')}
                                                    />
                                                    <IconButton
                                                        onClick={() => {
                                                            const commentId = comment.newsCommentID; // Assuming you have a unique identifier for each comment
                                                            setShowEditStatus((prevState) => ({
                                                                ...prevState,
                                                                [commentId]: !prevState[commentId], // Toggle the showEdit status for the clicked item
                                                            }));
                                                        }}
                                                    > {memberData && memberData.member.memberID === comment.commentedBy.memberID && (
                                                        <Edit fontSize="small" color="success" />
                                                    )}
                                                    </IconButton>


                                                </>
                                            ) : (

                                                <Grid item md={12} xs={12}>

                                                    <Comment2>


                                                        {memberData && (
                                                            <Avatar sx={{ marginRight: "20px" }}
                                                                src={memberData.member.image}>
                                                                {memberData.member.firstName.charAt(0)}
                                                            </Avatar>
                                                        )}
                                                        <form onSubmit={(event) => handleSubmit1(event, comment.newsCommentID)}>
                                                            <TextField
                                                                InputProps={{
                                                                    style: { fontFamily: 'Epilogue, sans-serif' },
                                                                    endAdornment: (
                                                                        <IconButton onClick={handleEmojiPickerOpen} edge="end">
                                                                            <InsertEmoticonIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}

                                                                sx={{ width: '100%' }}
                                                                id="standard-multiline-flexible"
                                                                label="Add comment here"
                                                                multiline
                                                                maxRows={4}
                                                                variant="standard"
                                                                value={editComments !== "" ? editComments : setEditComments(comment.comment)}
                                                                onChange={(event) => setEditComments(event.target.value)}

                                                            />


                                                            <Popover
                                                                open={Boolean(emojiPickerAnchor)}
                                                                anchorEl={emojiPickerAnchor}
                                                                onClose={handleEmojiPickerClose}
                                                            >
                                                                <EmojiPicker onEmojiClick={handleEmojiSelect} />
                                                            </Popover>
                                                            <Button
                                                                sx={{ marginTop: '20px' }}
                                                                type="submit"
                                                                size="small"
                                                                color="info"
                                                                variant="contained"
                                                                disabled={props.loading}
                                                            >
                                                                Send
                                                            </Button>
                                                            <Button
                                                                sx={{ marginTop: '20px', marginLeft: '10px' }}
                                                                size="small"
                                                                color="secondary"
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    const commentId = comment.newsCommentID; // Assuming you have a unique identifier for each comment
                                                                    setShowEditStatus((prevState) => ({
                                                                        ...prevState,
                                                                        [commentId]: false, // Set the showEdit status to false for the clicked item
                                                                    }));
                                                                    setEditComments("");
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </form>

                                                    </Comment2>

                                                </Grid>
                                            )}
                                        </ListItem>
                                    );
                                })
                            ) : (
                                <ListItemText primary="No comments yet" />
                            )}
                        </List>
                    </Box>
                    <Line></Line>
                    <Grid item md={12} xs={12}>
                        <Comment1>

                            <form></form>
                            <Avatar sx={{
                                marginRight: "20px",
                                '@media (max-width: 768px)': {

                                    marginLeft: "0%",
                                }
                            }}

                                src={memberData && memberData.member.image}
                            >
                                {memberData.member.firstName.charAt(0)}
                            </Avatar>

                            <form onSubmit={handleSubmit}>
                                <TextField
                                    InputProps={{
                                        style: { fontFamily: 'Epilogue, sans-serif' },
                                        endAdornment: (
                                            <IconButton onClick={handleEmojiPickerOpen1} edge="end">
                                                <InsertEmoticonIcon />
                                            </IconButton>
                                        ),
                                    }}
                                    sx={{ width: '100%' }}
                                    id="standard-multiline-flexible"
                                    label="Add comment here"
                                    //name="comment"
                                    multiline
                                    maxRows={4}
                                    variant="standard"
                                    value={comment} // Bind the comment value to the state
                                    onChange={(event) => setComment(event.target.value)} // Update the comment state on input change
                                />
                                <Popover
                                    open={Boolean(emojiPickerAnchor1)}
                                    anchorEl={emojiPickerAnchor1}
                                    onClose={handleEmojiPickerClose1}
                                >
                                    <EmojiPicker onEmojiClick={handleEmojiSelect1} />
                                </Popover>
                                <Button
                                    sx={{ marginTop: '20px' }}
                                    type="submit"
                                    size="small"
                                    color="info"
                                    variant="contained"
                                    disabled={props.loading}>
                                    Send
                                </Button>
                            </form>


                        </Comment1>
                    </Grid>






                </Collapse>
            </Container>
        </Section>

    )
}

export default LikeComment;