import AboutTime from "./AboutTime";
import AboutVideo from "./AboutVideo";
import {
  Button,
  Container,
  Grid,
  styled,
  Snackbar,
  Box,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "video-react/dist/video-react.css";
import parse from "html-react-parser";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CancelDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: "50%",
  right: "-93px",
  zIndex: "99",
  transform: "rotate(-90deg)",
}));

const login = localStorage.getItem("token");
const token = JSON.parse(login);

const GridDiv = styled("div")(({ theme }) => ({
  padding: "25px 0",
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    padding: "10px 0",
  },
}));

const LogoDiv = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    transform: "rotate(0deg)",
    top: "18px",
  },
  "& span": {
    background: theme.palette.primary.red,
    padding: "32px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "0px 35px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
      padding: "22px",
    },
  },
}));

const SwitchHeader = (props) => {
  //console.log('header switch', props);
  const AboutSection = styled("div")(({ theme }) => ({
    backgroundImage:
      `linear-gradient(to left, ${props.allData.categoryID?.color} 0%, ${props.allData.categoryID?.color} 50%,  #ecab3b 50%, #ecab3b 100%)`,
    [theme.breakpoints.down("md")]: {
      background: "#b8b6a9 ",
    },
    height:"100%"
    // height:"600px"
  }));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);

  };

  return (
    <AboutSection>
      <Container maxWidth="xl">
        <GridDiv>
          <AboutTime props={props} />
          <AboutVideo props={props} />
          <LogoDiv>
            <span></span>
            <span></span>
          </LogoDiv>
        </GridDiv>
      </Container>
    </AboutSection>
  );
};

export default SwitchHeader;
