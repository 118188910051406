import { KeyboardArrowUp } from "@mui/icons-material";
import { Container, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MoveToTopButton from "../MoveToTop";
// import logo from "./../../images/finalhour_favicon.png";

const FooterSection = styled("div")(({ theme }) => ({
  padding: "20px 0",
  flexShrink: 0,
  background: theme.palette.primary.greyBg,
  "& h3": {
    color: "#8D8656",
    fontWeight: "300",
    fontSize: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      textAlign: "center",
      margin: "0",
      paddingBottom: "20px",
    },
  },
}));

const FooterDiv = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  "& a": {
    fontSize: "16px",
    transition: "0.3s",
    [theme.breakpoints.down("md")]: {
      marginRight: "15px",
      fontSize: "14px",
    },
  },
  "& a:hover": {
    color: theme.palette.primary.red,
  },
}));

const FooterDivRight = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  fontSize: "16px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    marginTop: "20px",
    fontSize: "14px",
  },
  "& a": {
    marginRight: "40px",
    transition: "0.3s",
  },
  "& a:hover": {
    color: theme.palette.primary.red,
  },
  "& p": {
    margin: "0",
  },
}));

const Logo = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  "& span": {
    background: theme.palette.primary.red,
    padding: "5px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "-12px 2px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "4px",
    },
  },
}));

const DeskTopView = styled("div")(({ theme }) => ({
  position: "relative",
  display: "block",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MobileView = styled("div")(({ theme }) => ({
  position: "relative",
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const TopButtonDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "-150px",
  right: "0",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    top: "-127px",
  },
  "& svg": {
    fontSize: "40px",
    background: "#D9D9D9",
    padding: "3px",
    borderRadius: "50%",
  },
}));

const Footer = () => {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <FooterSection>
      <MoveToTopButton />
      <Container maxWidth="xl">
        <h3>It’s as simple as this, giving feels good.</h3>

        <DeskTopView>
          {/* <TopButtonDiv onClick={handleTop}>
          <KeyboardArrowUp />
        </TopButtonDiv> */}
          <Grid container>
            <Grid item xs={12} md={2}>
              <FooterDiv>
                <Link href="/">
                  <Logo>
                    <span></span> <span></span>
                  </Logo>
                </Link>
                <Link to="/contact">Contact Us</Link>
              </FooterDiv>
            </Grid>
            <Grid item xs={12} md={10}>
              <FooterDivRight>
                <Link to="/terms_and_conditions">Terms</Link>
                <Link to="/privacy_policy">Privacy Policy</Link>
                <Link to="/cookie_policy">Cookie Policy</Link>
                <p>
                  The Final Hour is a registered trademark © All rights reserved{" "}
                  {new Date().getFullYear()}
                </p>
              </FooterDivRight>
            </Grid>
          </Grid>
        </DeskTopView>

        <MobileView>
          {/* <TopButtonDiv onClick={handleTop}>
          <KeyboardArrowUp />
        </TopButtonDiv> */}
          <Grid container>
            <Grid item xs={12} md={2}>
              <FooterDiv>
                <Link href="/">
                  <Logo>
                    <span></span> <span></span>
                  </Logo>
                </Link>

                <Link to="/contact">Contact Us</Link>
                <Link to="/terms_and_conditions">Terms</Link>
                <Link to="/privacy_policy">Privacy Policy</Link>
                <Link to="/cookie_policy">Cookie Policy</Link>
              </FooterDiv>
            </Grid>
            <Grid item xs={12} md={10}>
              <FooterDivRight>
                <p>
                  The Final Hour is a registered trademark © All rights reserved{" "}
                  {new Date().getFullYear()}
                </p>
              </FooterDivRight>
            </Grid>
          </Grid>
        </MobileView>
      </Container>
    </FooterSection>
  );
};

export default Footer;
