// import React, { useEffect, useState } from "react";
// import { httpClient } from "../../appUtility/Api";
// import { Backdrop, CircularProgress, Snackbar } from "@mui/material";
// import MuiAlert from "@mui/material/Alert";

// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const Sitemap = () => {
//     const [loading, setLoading] = useState(false);
//     const [open, setOpen] = useState(false);
//     const [sitemapXml, setSitemapXml] = useState("");

//     useEffect(() => {
//         getSitemap();
//     }, []);

//     const getSitemap = () => {
//         setLoading(true);
//         httpClient.get(`https://stagingapi.finalhour.org/api/app/1.0/sitemap`).then(({ data }) => {
//             if (data) {
//                 const sitemapData = data.data;
//                 const xml = generateSitemapXml(sitemapData);
//                 setSitemapXml(xml);
//                 setLoading(false);
//                 setOpen(true);
//             }
//         });
//     };

//     const generateSitemapXml = (data) => {
//         let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
//         xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

//         data.staticPages.forEach(page => {
//             xml += '  <url>\n';
//             xml += `    <loc>${page.url}</loc>\n`;
//             xml += `    <lastmod>${page.lastModified}</lastmod>\n`;
//             xml += '  </url>\n';
//         });

//         data.categoryList.forEach(category => {
//             xml += '  <url>\n';
//             xml += `    <loc>${category.url}</loc>\n`;
//             xml += `    <lastmod>${category.lastModified}</lastmod>\n`;
//             xml += '  </url>\n';
//         });

//         data.causesList.forEach(cause => {
//             xml += '  <url>\n';
//             xml += `    <loc>${cause.url}</loc>\n`;
//             xml += `    <lastmod>${cause.lastModified}</lastmod>\n`;
//             xml += '  </url>\n';
//         });

//         data.companyList.forEach(company => {
//             xml += '  <url>\n';
//             xml += `    <loc>${company.url}</loc>\n`;
//             xml += `    <lastmod>${company.lastModified}</lastmod>\n`;
//             xml += '  </url>\n';
//         });

//         xml += '</urlset>';

//         return xml;
//     };

//     const handleClose = (event, reason) => {
//         if (reason === "clickaway") {
//             return;
//         }
//         setOpen(false);
//     };

//     return (
//         <div>
//             {loading ? (
//                 <Backdrop
//                     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//                     open={true}
//                 >
//                     <CircularProgress color="inherit" />
//                 </Backdrop>
//             ) : (
//                 <div>
//                     <pre>{sitemapXml}</pre>
//                     {/* <div 
//       dangerouslySetInnerHTML={{ __html: sitemapXml ? sitemapXml : "" }}
//     /> */}

//                     <Snackbar
//                         open={open}
//                         autoHideDuration={6000}
//                         onClose={handleClose}
//                     >
//                         <Alert onClose={handleClose} severity="info">
//                             Sitemap generated successfully!
//                         </Alert>
//                     </Snackbar>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Sitemap;

import React, { useEffect, useState } from "react";
import { httpClient } from "../../appUtility/Api";
import { Backdrop, CircularProgress, Snackbar, Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from "@mui/material";

const Sitemap = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [sitemapData, setSitemapData] = useState({
        staticPages: [],
        categoryList: [],
        causesList: [],
        companyList: [],
    });

    useEffect(() => {
        getSitemap();
    }, []);



    const getSitemap = () => {
        setLoading(true);
        httpClient.get(window.location.hostname === "www.finalhour.org" ? `https://api.finalhour.org/api/app/1.0/sitemap` : `https://stagingapi.finalhour.org/api/app/1.0/sitemap`).then(({ data }) => {
            if (data) {
                setSitemapData(data.data);
                setLoading(false);
                setOpen(true);
            }
        });
    };


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const renderTable = (data, title) => (
        <Paper elevation={3} sx={{ margin: '20px auto', padding: '20px', maxWidth: 900, fontFamily: 'Epilogue, sans-serif' }}>
            <Typography variant="h5" sx={{ fontFamily: 'Epilogue, sans-serif' }} component="h2" gutterBottom>{title}</Typography>
            <TableContainer >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontFamily: 'Epilogue, sans-serif' }}>URL</TableCell>
                            <TableCell sx={{ fontFamily: 'Epilogue, sans-serif' }}>Last Modified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ fontFamily: 'Epilogue, sans-serif' }}>
                                    {/* <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a> */}
                                    <Box
                                        component="a"
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                   
                                            textDecoration: 'none', // Remove underline by default
                                            fontFamily: 'Epilogue, sans-serif', // Apply font family
                                            '&:hover': {
                                                textDecoration: 'underline', // Underline on hover
                                                color: 'blue !important',
                                            },
                                        }}
                                    >
                                        {item.url}
                                    </Box>
                                </TableCell>
                                <TableCell>{item.lastModified}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

    return (
        <div>
            {loading ? (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <div>
                    {renderTable(sitemapData.staticPages, "Static Pages")}
                    {renderTable(sitemapData.categoryList, "Category List")}
                    {renderTable(sitemapData.causesList, "Causes List")}
                    {renderTable(sitemapData.companyList, "Company List")}
                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                    >
                        <Alert onClose={handleClose} severity="info">
                            Sitemap generated successfully!
                        </Alert>
                    </Snackbar>
                </div>
            )}
        </div>
    );
};

export default Sitemap;

