import {
  ChevronRight,
  Delete,
  Favorite,
  FavoriteBorder,
} from "@mui/icons-material";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Snackbar,
  Box,
  Tooltip,
  IconButton,
  Autocomplete,
  useMediaQuery,
  useTheme,
  CircularProgress,
  InputAdornment,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CrisisImage from "./../../../images/emergency.png";
import { useLocation } from "react-router";
import NoImage from "../../../images/no-pictures.png";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GivingDiv = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: theme.palette.primary.lightest,
  "& h2": {
    margin: "0",
    paddingBottom: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const Section1 = styled("div")(({ theme }) => ({
  padding: "20px 10%",
  borderBottom: "1px solid #000",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& p": {
    fontWeight: "500",
    margin: "0",
  },
}));
const Section2 = styled("div")(({ theme }) => ({
  padding: "50px 10%",
  borderBottom: "1px solid #000",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& p": {
    fontWeight: "500",
    margin: "0",
  },
  "& h4": {
    margin: "0",
    paddingBottom: "20px",
  },
}));
const Section3 = styled("div")(({ theme }) => ({
  padding: "50px 10%",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
  "& p": {
    fontWeight: "500",
    margin: "0",
  },
  "& h4": {
    margin: "0",
    paddingBottom: "20px",
  },
}));

const DownLoadButton = styled("button")(({ theme }) => ({
  outline: "none",
  padding: "7px 25px",
  border: "1px solid #000",
  borderRadius: "25px",
  transition: "0.3s",
  cursor: "pointer",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#333",
    color: "#fff",
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  border: "1px solid transparent !important",
  outline: "none",
  borderRadius: "25px",
  padding: "5px 25px",
  fontSize: "14px",
  background: theme.palette.primary.red,
  color: "#fff",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.red} !important`,
    color: theme.palette.primary.red,
    background: "#fff",
  },
}));

//existing causes

const Causes = styled("div")(({ theme }) => ({
  padding: "10px 0",
  display: "grid",
  gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr 1fr",
  rowGap: "10px",
  width: "100%",
  borderBottom: "1px dashed #999",
  justifyItems: "start",
  alignItems: "center",
  "& img": {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center", // Add this line
  },

  // borderBottom: "1px solid gray",
  //   padding: "10px 0px",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center", // Add this line
  //   [theme.breakpoints.down("md")]: {
  //     flexDirection: "column",
  //     textAlign: "center", // Add this line
  //   },
}));

const CausesRight = styled("div")(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  "& p": {
    marginRight: "150px",
    [theme.breakpoints.down("md")]: {
      marginRight: "20px",
    },
  },
}));

const CauseName = styled(Box)(({ theme }) => ({
  // width: "360px",
  margin: "0 0px",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const CauseCategory = styled(Box)(({ theme }) => ({
  // width: "360px",
  margin: "0 0px",
  fontSize: "16px",
  fontWeight: "700",
  "& a": {
    width: "fit-content",
    border: "1px solid #333",
    borderRadius: "10px",
    padding: "5px 10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "20px",
      width: "20px",
      objectFit: "contain",
      border: "none",
      marginBottom: "0px",
    },
    "& p": {
      margin: "0",
      fontWeight: "400",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center", // Add this line
    "& a": {
      justifyContent: "center", // Add this line
    },
  },
}));

const CrisisImg = styled("div")(({ theme }) => ({
  "& img": {
    width: "30px !important",
    height: "30px !important",
    // position: "absolute",
    // bottom: "80px !important",
    // left: "85px !important",
    border: "none !important",
    borderRadius: "initial !important"
  },
}));

const PaginationBox = styled("div")(({ theme }) => ({
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#4A4A4A",
  "& span": {
    fontSize: "16px",
    marginRight: "10px",
  },
  "& button": {
    color: "#4A4A4A",
  },
}));

const CauseButtonDiv = styled("div")(({ theme }) => ({
  // width: "175px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //textAlign: "left",
    marginTop: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const GiveButton = styled("button")(({ theme }) => ({
  padding: "10px 30px",
  borderRadius: "100px",
  border: "1px solid gray !important",
  color: theme.palette.primary.red,
  background: "#fff",
  marginTop: "10px",
  transition: "0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
    marginTop: "0",
  },
  "&:hover": {
    background: `${theme.palette.primary.red} !important`,
    color: "#fff !important",
    border: `1px solid gray !important`,
  },
}));

// const causesList = [
//   { id: "1", cause: "New Cause", addedDate: "2022-08-17" },
//   { id: "2", cause: "Test Cause", addedDate: "2022-08-17" },
//   { id: "3", cause: "Cause 3", addedDate: "2022-03-09" },
//   { id: "4", cause: "New Cause", addedDate: "2022-08-17" },
//   { id: "5", cause: "New Cause", addedDate: "2022-08-17" },
//   { id: "6", cause: "New Cause", addedDate: "2022-08-17" },
// ];

const GivingPreference = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const [preference, setPreference] = useState({
    frequent: "Week",
    salary: "",
    workTime: "",
    monthlyIncome: "",
    weekelyIncome: "",
    hourlyIncome: "",
    accountName: "",
    accountNumber: "",
    bsb: "",
    employerName: "",
    //employerinfo: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    startDate: "",
    endDate: "",
    ABN: "",
    ACN: "",
  });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingABN, setLoadingABN] = useState(false);
  const [loadingACN, setLoadingACN] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [incomeID, setIncomeID] = useState("");
  const [employerID, setEmployerID] = useState("");
  const [causesList, setCausesList] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [loadingFav, setLoadingFav] = useState(false);
  const [donationList, setDonationList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [count, setCount] = useState(20);
  const [loadingPref, setLoadingPref] = useState(false);
  //console.log("location.stet", location.state);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getIncomeList();
    getCauses();
    
  }, []);

  
  const getCauses = () => {
    httpClient
      .get("all-charity-cause")
      .then(({ data }) => {
        if (data.success) {
          setCausesList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getIncomeList = () => {
    Promise.all([
      httpClient.get("member-income/list"),
      httpClient.get("get-employer-detail"),
    ]).then(([res1, res2]) => {
      // console.log("res1", res1);
      // console.log("res2", res2);
      const data1 = res1.data;
      const data2 = res2.data;
      if (data1.data && data1.data.length > 0 && data2) {
        setPreference({
          ...preference,
          salary: data1.data[0].annualIncome,
          hourlyIncome: data1.data[0].hourlyIncome,
          monthlyIncome: data1.data[0].monthlyIncome,
          weekelyIncome: data1.data[0].weeklyIncome,
          workTime: data1.data[0].working,
          employerName: data2.data?.name || "",
          address: data2.data?.address || "",
          city: data2.data?.city || "",
          state: data2.data?.state || "",
          postalCode: data2.data?.postCode || "",
          ABN: data2.data?.abn || "",
          ACN: data2.data?.acn || "",
        });

        setIncomeID(data1.data[0] ? data1.data[0].incomeID : "")

      } else {
        console.log("no data");
      }
    });
  };

  //existing causes handles
  const handleClickCause = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, {
      state: { cause: cats, give: cats ? false : true },
    });
  };

  const handleFavourite = (id) => {
    setLoadingFav(true);
    httpClient
      .post("member-fav-cause", {
        causeID: id,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoadingFav(false);
          getCauses();
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
          setLoadingFav(false);
        }
      });
  };

  const handleFavoriteRemove = (id) => {

    setLoadingFav(true);

    httpClient.delete(`member-fav-cause/${id}`).then(({ data }) => {
      if (data.success) {
        setLoadingFav(false);
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);

        getCauses();
      } else {
        setLoadingFav(false);

        setOpen(true);
        setMessageState("error");
        setMessage(data.message);
      }
    });
  };

  const handleClickCauseGive = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, {
      state: { cause: cats, give: true },
    });
  };

  const handleChange = (e) => {

    const { name, value } = e.target;

    if (name === "salary") {
      // preference.salary = (value).toFixed(2);
      let monthlyvalue = (value / 12).toFixed(2);
      let weekValue = (value / 52).toFixed(2);
      let hourlyValue = (weekValue / 40).toFixed(2);
      setPreference({
        ...preference,
        monthlyIncome: monthlyvalue,
        weekelyIncome: weekValue,
        hourlyIncome: hourlyValue,
        salary: value,
      });
    } else {
      setPreference({
        ...preference,
        [name]: value,
      });
    }
    // Now, make the API call to retrieve autocomplete suggestions
    if (name === "ABN" || name === "ACN" || name === "employerName") {
      if (name === "ABN") {
        setLoadingABN(true);
      }
      if (name === "ACN") {
        setLoadingACN(true);
      }
      if (name === "employerName") {
        setLoading(true);
      }
      let formData = new FormData();
      formData.append("fieldValue", value);
      formData.append("limit", 30);
      formData.append("fieldName", name);
      httpClient.post('company/search', formData).then(({ data }) => {
        if (data.responseCode === 200) {
          setLoading(false);
          setLoadingABN(false);
          setLoadingACN(false);
        }

        if (data.success === true) {
          setCompanyData(data.result.records);
          setLoading(false);
          setLoadingABN(false);
          setLoadingACN(false);
        }
      })
    }

  };

  const handleSearchFilter = (value) => {

    setPreference({
      ...preference,
      employerName: value !== null ? (value['Company Name'] || "") : "",
      ABN: value !== null ? (value['ABN'] || "") : "",
      ACN: value !== null ? (value['ACN'] || "") : "",

    });
  };

  const handleSave = (e) => {
    //for Income
    if (incomeID === "") {
      httpClient
        .post("member-income/create", {
          annualIncome: parseFloat(preference.salary),
          monthlyIncome: parseFloat(preference.monthlyIncome),
          weeklyIncome: parseFloat(preference.weekelyIncome),
          hourlyIncome: parseFloat(preference.hourlyIncome),
          working: preference.workTime,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              window.location = location.state.state;
            }, 1000);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    } else {
      httpClient
        .post(`member-income/update/${incomeID}`, {
          annualIncome: parseFloat(preference.salary),
          monthlyIncome: parseFloat(preference.monthlyIncome),
          weeklyIncome: parseFloat(preference.weekelyIncome),
          hourlyIncome: parseFloat(preference.hourlyIncome),
          working: preference.workTime,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    }

    //for employer information
    httpClient
      .post("add-employer-detail", {
        name: preference.employerName,
        address: preference.address,
        city: preference.city,
        state: preference.state,
        postCode: preference.postalCode,
        abn: preference.ABN,
        acn: preference.ACN,
        //employerinfo: preference.employerinfo,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleDownload = () => {
    // httpClient.post('member-invoices-receipt',{
    //   startDate: preference.startDate,
    //   endDate: preference.endDate
    // }).then(({data}) => {
    //   if(data){
    //     console.log('data',data)
    //   }
    // })
    const loginData = JSON.parse(localStorage.getItem("token"));
    const token = loginData && loginData;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF ",
      Authorization: `Bearer ${token}`,
    };

    const config = {
      headers: headers,
      responseType: "blob", // Set the desired response type here
    };

    const data = {
      startDate: preference.startDate,
      endDate: preference.endDate,
    };
    httpClient
      .post(
        `member-invoices-receipt`,
        data,
        config
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `receipt_reconcillation_${preference.startDate}-${preference.endDate}.pdf`; // Set the desired file name
        link.click();
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // console.log("cause list", causesList);
  // console.log("preference", preference);

  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="Giving Preferences"
      />
      <GivingDiv>
        <Container maxWidth="xl">
          <h2>Giving Preferences</h2>

          {/* <Section1>
            <p>
              It’s as simple as this, giving feels good. <br />
              Select how frequently you would like to give:
            </p>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-frequent-label"
                name="frequent"
              >
                <FormControlLabel
                  value="Day"
                  control={<Radio />}
                  label="Day"
                  sx={{ marginRight: "30px" }}
                />
                <FormControlLabel
                  value="Week"
                  control={<Radio />}
                  label="Week"
                  sx={{ marginRight: "30px" }}
                />
                <FormControlLabel
                  value="Month"
                  control={<Radio />}
                  label="Month"
                  sx={{ marginRight: "30px" }}
                />
              </RadioGroup>
            </FormControl>
          </Section1> */}



          <Section2>
            <h4>Your Approximate Annual Salary:</h4>

            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  value={preference.salary}
                  name="salary"
                  label="salary"
                  placeholder="$60,000"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Working</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={preference.workTime}
                    label="Working"
                    name="workTime"
                    onChange={handleChange}
                  >
                    <MenuItem value="Full-time">Full-time</MenuItem>
                    <MenuItem value="Part-time">Part-time</MenuItem>
                    <MenuItem value="Hourly">Hourly</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={12} md={8}></Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={preference.monthlyIncome}
                  name="monthlyIncome"
                  label="Monthly Income"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={preference.weekelyIncome}
                  name="weekelyIncome"
                  label="Weekely Income"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={preference.hourlyIncome}
                  name="hourlyIncome"
                  label="Hourly Income"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Section2>

          <Section2>
            <h4>Employer Information:</h4>

            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.employerName}
                  name="employerName"
                  label="Name"
                  placeholder="Name"
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item xs={12} md={3}>

                <Autocomplete
                  disablePortal
                  inputValue={preference.employerName}
                  //options={rows.length > 0 ? rows : []}
                  options={companyData}
                  getOptionLabel={(option) => (option && option['Company Name']) || null}

                  renderOption={(props, option, index) => { return <li {...props} key={option._id}>{option && option['Company Name']}</li> }}

                  onChange={(event, newValue) => {
                    handleSearchFilter(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="employerName"
                      label="Company Name"
                      placeholder="Company Name"
                      value={preference.employerName || ""}
                      onChange={handleChange}
                      helperText={
                        preference.employerName === "" ?
                          "Add characters to get search results"
                          : ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading && (
                              <InputAdornment position="end">
                                <CircularProgress size={25} />
                              </InputAdornment>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />


              </Grid>
              <Grid item xs={12} md={9}></Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.address}
                  name="address"
                  label="Address"
                  placeholder="Address"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.city}
                  name="city"
                  label="City"
                  placeholder="City"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  value={preference.state}
                  name="state"
                  label="State"
                  placeholder="State"
                  onChange={handleChange}
                />
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={preference.state}
                    label="State"
                    name="state"
                    onChange={handleChange}
                  >
                    <MenuItem value="State 1">State 1</MenuItem>
                    <MenuItem value="State 2">State 2</MenuItem>
                    <MenuItem value="State 3">State 3</MenuItem>
                    <MenuItem value="State 4">State 4</MenuItem>
                    <MenuItem value="State 5">State 5</MenuItem>
                    <MenuItem value="State 6">State 6</MenuItem>
                    <MenuItem value="State 7">State 7</MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={preference.postalCode}
                  name="postalCode"
                  label="Postal Code"
                  placeholder="Postal Code"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>

                <Autocomplete
                  disablePortal
                  inputValue={preference.ABN}
                  //options={rows.length > 0 ? rows : []}
                  options={companyData}
                  getOptionLabel={(option) => (option && option['ABN']) || null}

                  renderOption={(props, option, index) => { return <li {...props} key={option._id}>{option && option['ABN']}</li> }}

                  onChange={(event, newValue) => {
                    handleSearchFilter(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      width="900px"
                      name="ABN"
                      label="ABN"
                      placeholder="ABN"
                      value={preference.ABN || ""}
                      onChange={handleChange}
                      helperText={
                        preference.ABN === "" ?
                          "Add characters to get search results"
                          : ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingABN && (
                              <InputAdornment position="end">
                                <CircularProgress size={25} />
                              </InputAdornment>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />


              </Grid>
              <Grid item xs={12} md={2}>

                <Autocomplete
                  disablePortal
                  inputValue={preference.ACN}
                  //options={rows.length > 0 ? rows : []}
                  options={companyData}
                  getOptionLabel={(option) => (option && option['ACN']) || null}

                  renderOption={(props, option, index) => { return <li {...props} key={option._id}>{option && option['ACN']}</li> }}

                  onChange={(event, newValue) => {
                    handleSearchFilter(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="ACN"
                      label="ACN"
                      placeholder="ACN"
                      value={preference.ACN || ""}
                      onChange={handleChange}
                      helperText={
                        preference.ACN === "" ?
                          "Add characters to get search results"
                          : ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingACN && (
                              <InputAdornment position="end">
                                <CircularProgress size={25} />
                              </InputAdornment>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />


              </Grid>
              <Grid item xs={12} md={8}></Grid>
              {/* <Grid item xs={12} md={3}>
                <TextField
                  value={preference.employerinfo}
                  name="employerinfo"
                  label="Employer Info (Optional)"
                  placeholder="Employer Info"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}
            </Grid>
          </Section2>

          <Section2>
            <h4>Receipts & Tax Reconcillation:</h4>
            <p style={{ marginBottom: "30px", marginTop: "20px" }}>
              Select Date Range :
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.startDate}
                  name="startDate"
                  label="Start Date"
                  type="date"
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                textAlign="center"
              >
                <ChevronRight />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.endDate}
                  name="endDate"
                  label="End Date"
                  type="date"
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={9}></Grid>
              <Grid item md={12} textAlign={"left"}>
                {/* <DownLoadButton href={`https://api.finalhour.org/api/app/1.0/frontend/member-invoices-receipt?startDate=${preference.startDate}&endData=${preference.endDate}`} download={"receipt_reconcillation"} target="_blank">Download</DownLoadButton> */}
                <DownLoadButton onClick={handleDownload}>
                  Download
                </DownLoadButton>
              </Grid>
              <Grid item xs={12} textAlign="right" sx={{ marginTop: "30px" }}>
                <SaveButton onClick={handleSave}>Save Changes</SaveButton>
              </Grid>
            </Grid>
          </Section2>

          {/* <Section2>
            <h4>Payment Details:</h4>

            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.accountName}
                  name="accountName"
                  label="Account Name"
                  placeholder="Account Name"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={9}></Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  value={preference.accountNumber}
                  name="accountNumber"
                  label="Account Number #"
                  placeholder="Account #"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={preference.bsb}
                  name="bsb"
                  label="BSB #"
                  placeholder="BSB #"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Section2> */}

          <Section3>
            <h3>Existing Causes:</h3>

            {!isMobile &&
              <Causes>
                <strong>Cause Name</strong>
                <strong>Category</strong>
                <strong>Hours</strong>
                <strong>Members</strong>
                <strong>Favourite</strong>
                <strong>Give</strong>
              </Causes>
            }

            {causesList.map((cause) => (
              <Causes id={cause.id}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickCause(cause)}
                >
                  <CauseName>
                    {cause.name}
                    {cause.crisis === 1 ? (
                      <CrisisImg>
                        <img src={CrisisImage} />
                      </CrisisImg>
                    ) : (
                      ""
                    )}
                  </CauseName>
                </div>

                <CauseCategory>
                  <Link
                    to={`/causes/category/${cause.categoryID.categorySlug}`}
                    state={cause.categoryID}
                    style={{ background: cause.categoryID.categoryColor }}
                  >
                    <>
                      <img src={cause.categoryID.categoryIcon} alt="Icon" />
                      <p>{cause.categoryID.categoryName}</p>
                    </>
                  </Link>
                </CauseCategory>
                <CauseName>
                  <strong>{cause.hours || "0"} </strong>{" "}
                  {cause.hours == "0" ||
                    cause.hours == "1" ||
                    cause.hours == null
                    ? "hour"
                    : "hours"}
                </CauseName>
                <CauseName>
                  <strong>{cause.totalMembers || "0"} </strong>{" "}
                  {cause.memberCount == "0" ||
                    cause.memberCount == "1" ||
                    cause.memberCount == null
                    ? "member"
                    : "members"}
                </CauseName>
                <CauseName>
                  {
                  
                    cause.favorite !== 1 ? (
                     

                      <Tooltip title="Add To Favorite">
                        <IconButton
                          onClick={() => handleFavourite(cause.causeID)}
                          disableRipple
                          disableFocusRipple
                          disabled={loadingFav}
                        >
                          <FavoriteBorder
                            sx={{ color: "grey", cursor: "pointer" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Remove from Favorite">
                        <IconButton
                          onClick={() => handleFavoriteRemove(cause.favoriteID)}
                          disableRipple
                          disableFocusRipple
                          disabled={loadingFav}
                        >
                          <Favorite
                            sx={{ color: "#EA3323", cursor: "pointer" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </CauseName>
                <CauseButtonDiv>
                  <GiveButton onClick={() => handleClickCauseGive(cause)}>
                    {" "}
                    Give
                  </GiveButton>
                </CauseButtonDiv>
              </Causes>
            ))}
          </Section3>





        </Container>
      </GivingDiv>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GivingPreference;
