import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { IconButton, styled } from "@mui/material";
import { Close } from "@mui/icons-material";
import { httpClient } from "../../../appUtility/Api";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const BoxList = styled("div")(({ theme }) => ({
  width: "350px",
  [theme.breakpoints.down("md")]: {
    width: "300px",
  },
}));

export default function HeaderRightDrawer(props) {
  const [state, setState] = React.useState(false);
  const [alreadyViewed, setAlreadyViewed] = React.useState(false);

  React.useEffect(() => {
    setState(props.showNotification);
    if(props.showNotification === true){
      viewNotification();
    }
  }, [props.showNotification]);

  React.useEffect(() => {
    props.notificationCallback(state, alreadyViewed);
  }, [state, alreadyViewed]);


  const viewNotification = () => {
    httpClient.get('view-notification').then(({data}) => {
      if(data.success){
        setAlreadyViewed(true);
      }
    })
  }


//   console.log("propsss", props);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" && 
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(true);
  };

  const handleClose = () => {
    // console.log('canclled');
    setState(false);
  };

  const list = (anchor) => (
    <BoxList
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div>
            <h2 style={{ marginLeft: "20px" }}>Notification</h2>
          </div>
          {/* <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div> */}
        </Box>
        <Divider />
        {props.allNotification.map((nots) => (
          <>
          <Link to={'/dashboard/friends_list'} state={nots.type}>
            <ListItem
              key={nots.notifID}
              disablePadding
              style={{ background: nots.viewed == 1 ? "inherit" : "#f1f1f1" }}
            >
              <ListItemButton style={{ display: "block" }}>
                <div>{nots.title}</div>
                <Box textAlign={"right"}>
                  <small style={{ color: "#777" }}>{moment(nots.createdDate).format('MMM Do YYYY, h:mm:ss a')}</small>
                </Box>
              </ListItemButton>
            </ListItem>

            <Divider />
            </Link>
          </>
        ))}
      </List>
    </BoxList>
  );

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      <React.Fragment>
        {/* <Button onClick={toggleDrawer('right', true)}>Click</Button> */}
        <SwipeableDrawer
          anchor={"right"}
          open={state}
          onClose={handleClose}
          // onOpen={toggleDrawer('right', true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}