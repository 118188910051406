import React from "react";
import { Helmet } from "react-helmet";
// import { useLocation } from "react-router-dom";

export default function HelmetMetaData(props) {

//    let location = useLocation();

//    let currentUrl = window.location.href;
   let currentUrl = window.location.href;
   let quote = props.quote !== undefined ? props.quote : "";
   let title = props.title !== undefined ? props.title : "Final Hour - It's about time.";
   let image = (props.image !== undefined || props.image !== "") ? props.image : "https://api.finalhour.org/uploaded/cms/section1/cms-2023060104330113.jpg";
   let description = props.description !== undefined ? props.description  : "Final Hour - It's about time. It doesn’t matter, who you are or what you do for a living, your time is valuable."+
   "Help make a difference in the world by donating an hour of it." + 
   "Give the final hour of your working day, week or month to your preferred causes.";
   let hashtag = props.hashtag !== undefined ? props.hashtag : "#final_hour";
return (
 <Helmet>
     <title>{title}</title>
     <meta charset="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content={currentUrl} />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/finalhour_favicon.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={title} />
     <meta property="quote" content={quote} />
     <meta name="description" content={description} />
     <meta property="image" content={image} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={title} />
     <meta property="og:quote" content={quote} />
     <meta property="og:hashtag" content={hashtag} />
     <meta property="og:image" content={image} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={currentUrl} />
     <meta property="og:site_name" content="FinalHour" />
     <meta property="og:description" content={description} />    </Helmet>
);
}